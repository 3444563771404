import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SecureSessionService } from '../services/secure-session.service';
@Injectable({
  providedIn: 'root',
})
export class TfaGuard {
  constructor(private _router: Router,
    private _secureSession: SecureSessionService,) {}
  canActivate(): boolean {
     if (this._secureSession.getObjectSession('USER_SESSION_PRE')) {
      return true;
    } else {
       this._router.navigate(['/auth/login']);
       return false;
     }
  }
}
