import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserMainService } from '../../../../../../user/services/user-main.service';
import { debounceTime, Subject } from 'rxjs';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { User } from '../../../../../../../core/interfaces/user.interface';
import { DEFAULT_VALUE, PAGE_SIZE_VALUE } from '../../../../../../../shared/data/suscriptions-payments/constants';
import { AuthActions } from '../../../../../../../core/state/auth/auth.actions';

@Component({
  selector: 'app-bank-settings',
  templateUrl: './bank-settings.component.html',
  styleUrl: './bank-settings.component.scss'
})
export class BankSettingsComponent implements OnInit {

  // FORMS
  public bankSettingsForm: FormGroup = new FormGroup({});

  // VARIABLES
  public id_country: number = 0;
  public alltBankData: any[] = [];
  public transformedDataBank: { label: string; value: number; }[] = [];
  public isFormModified = false;

  // PAGINATION
  public currentPageBanks = 1;
  public pageSize = 10;
  public holderSubject: Subject<string> = new Subject<string>();

  // OBSERVABLES
  public profile$ = this.store.select(selectAuth);
  public profile: User = {} as User;

  // ERROR
  public numberAccountError: boolean = false;

  // SEARCH
  public selectedBankId: number = 0;
  public criteria: string = '';


  constructor(
    private fb: FormBuilder,
    private _userMainService: UserMainService,
    private store: Store,

  ) {
    this.initForm();
    this.holderSubject
      .pipe(
        debounceTime(400)
      )
      .subscribe((term: any) => {
        this.alltBankData = [];
        this.getBankData(term);
      });
  }

  async ngOnInit(): Promise<void> {
    await this.loadProfileLogged();
    this.getBankData();
    this.detectChangesInForm();
  }

  //? LOAD DATA

  async loadProfileLogged() {
    await new Promise<void>((resolve, reject) => {
      this.profile$.subscribe({
        next: (res) => {
          if (res.idusuario !== -1) {
            this.profile = res;
            this.id_country = this.profile.id_pais;
            this.bankSettingsForm.disable();
            this.bankSettingsForm.patchValue({
              bankId: this.profile.id_banco_cuenta || null,
              accountNumber: this.profile.numero_cuenta || ''
            });
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  getBankData(searchTerm?: string) {
    this._userMainService.getAllBanksBySelect(
      this.currentPageBanks,
      this.pageSize,
      searchTerm,
      this.id_country
    ).subscribe((response) => {
      this.transformedDataBank = response.data
        .map(
          (item) => {
            return { label: item.bankName, value: item.bankId }
          }
        );
      this.alltBankData = [...this.alltBankData, ...this.transformedDataBank]
    })

  }

  //* FORMS
  initForm(): void {
    this.bankSettingsForm = this.fb.group({
      bankId: [null],
      accountNumber: [''],
    });
  }

  enableFormProfile() {
    if (this.bankSettingsForm.enabled) {
      this.bankSettingsForm.disable();
    } else {
      this.bankSettingsForm.enable();
    }
  }

  resetForm(bankSettingsForm: FormGroup) {
    this.loadProfileLogged();
    bankSettingsForm.disable();
  }

  onSubmitBankSettings() {
    const formData = new FormData();
    formData.append('userId', (this.profile?.idusuario).toString());
    formData.append('bankId', this.bankSettingsForm.get('bankId')?.value);
    formData.append('username', this.profile?.usuario);
    formData.append('accountNumber', this.bankSettingsForm.get('accountNumber')?.value);
    formData.append('identification', this.profile?.identificacion);
    formData.append('externalId', this.profile?.id_externo);

    this.store.dispatch(
      AuthActions['[Auth]RequestUpdateUser']({
        updatedUser: formData,
      })
    );

    this.bankSettingsForm.disable();
  }

  detectChangesInForm(): void {
    this.bankSettingsForm.valueChanges.subscribe(() => {
      const currentValues = this.bankSettingsForm.value;
      this.isFormModified = (
        currentValues.bankId !== this.profile.id_banco_cuenta ||
        currentValues.accountNumber !== this.profile.numero_cuenta
      );
    });
  }

  //*NG-SELECT
  onScrollToEnd() {
    this.currentPageBanks++;
    this.getBankData(this.criteria)
  }

  onSearch(term: string) {
    this.criteria = term.trim();
    this.alltBankData = [];
    this.currentPageBanks = PAGE_SIZE_VALUE;
    this.holderSubject.next(term);
  }
  onSearchBank() {
    this.bankSettingsForm.get('bankId')?.setValue(null);
  }

  //? VALIDATIONS
  validateField(event: any, fieldName: string): void {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9-]*$/;
    if (fieldName === 'numero_cuenta') {
      this.numberAccountError = !regex.test(value);
    }
  }

}
