import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../../../environments/environment';
import { User } from '../../../../../../../core/interfaces/user.interface';
import { ChargesWithdrawalsRequestService } from '../../../../../../../core/services/charges-withdrawals-request.service';
import { DynamicModalService } from '../../../../../../../core/services/dynamic-modal.service';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import { formatAmount } from '../../../../../../../core/utils/format-numeric.utils';
import { STATE_CLASSES, STATE_NAMES, TRANSACTION_CLASSES, TRANSACTION_ICON_CLASSES, TRANSACTION_TYPE_VALUES, TRANSACTION_TYPES } from '../../../../../../../shared/data/suscriptions-payments/constants';
import { AlertService } from '../../../../../../../shared/services/alert.service';
import { EditCwRequestComponent } from '../edit-cw-request/edit-cw-request.component';

@Component({
  selector: 'app-view-pay',
  templateUrl: './view-pay.component.html',
  styleUrl: './view-pay.component.scss'
})
export class ViewPayComponent implements OnInit {

  // LOADINGS
  public isLoading: boolean = true;
  public isLoadingButton: boolean = false;

  //REDUX
  public profile$ = this.store.select(selectAuth);
  public userData!: User;

  public cwId: number = 0;
  public cwData: any;
  public transactionTypes = TRANSACTION_TYPES;
  public DEFAULT_VALUE: number = 0
  public isPaymentButtonDisabled: boolean = false;

  //IMG
  public imgSrc!: any;
  public urlResponse: any;
  public file: any;
  public numero_comprobante: any;
  public baseUrl: string = environment.API_FILES;

  //STATES
  public STATE_NAMES = STATE_NAMES;

  // TRANSACTION TYPES
  public TRANSACTION_TYPE_VALUES = TRANSACTION_TYPE_VALUES;

  constructor(
    private store: Store,
    private _dynamicModal: DynamicModalService,
    private _cwService: ChargesWithdrawalsRequestService,
    private activeModal: NgbActiveModal,
    private alertService: AlertService,
    private _toastrSvc: ToastrService,
  ) {
    this.cwId = this._dynamicModal.getModalData();
  }


  ngOnInit(): void {
    this.getUserInfo();
    this.getCwInfo();
    this.getImgCw();
  }

  getCwInfo() {
    this.isLoading = true;
    this._cwService.getCw(this.cwId).subscribe((res) => {
      this.cwData = res.data;
      this.cwData.monto = this.formatAmount(this.cwData.monto, this.cwData.id_pais);
      this.isLoading = false;
    })
  }

  getUserInfo() {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario) {
        this.userData = profile;
      }
    });
  }

  getImgCw() {
    this.isLoading = true;
    this._cwService.getCw(this.cwId).subscribe((response) => {
      this.numero_comprobante = response.data.comprobante;
      this.urlResponse = response.data.foto_comprobante;
      this.urlResponse = this.urlResponse.replace(/ /g, '%20');
      this.file = this.urlResponse.replace(/\\/g, '/');
      this.imgSrc = this.baseUrl + '/' + this.file;
      this.isLoading = false;
    });
  }

  formatAmount(monto: number, idPais: number): string {
    return this.formatAmountWithCountry(monto, idPais);
  }

  formatAmountWithCountry(amount: number, countryId: number): string {
    return formatAmount(Number(amount), countryId === 1 ? 'EC' : 'PE');
  }

  //STATE
  getStateClasses(state: number) {
    return STATE_CLASSES[state] || { text: '', classes: {} };
  }

  //TRANSACTION TYPE
  getTransactionIconClass(type: number): string {
    return TRANSACTION_ICON_CLASSES[type] || '';
  }

  getTransactionClass(type: number): string {
    return TRANSACTION_CLASSES[type] || '';
  }

  closeModal() {
    this.activeModal.close();
  }

  // ACTIONS
  setState(id: number, newState: number, idusuarioSet: number) {
    // Utiliza la alerta de confirmación
    this.alertService
      .showConfirmAlert(
        '¿Estás seguro?',
        'No podrás revertir esto!',
        'Sí, cambiar estado!',
        'Cancelar'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.updateState(id, newState, idusuarioSet);
        }
      });
  }

  updateState(id: number, newState: number, idusuarioSet: number) {
    this._cwService.setState(id, newState, idusuarioSet).subscribe(
      (response) => {
        this.alertService.showSuccessAlert(
          'Se cambió el estado!',
          'La solicitud cambió su estado.'
        );
        this.closeModal()
      },
      (error) => {
        this._toastrSvc.error('Error al actualizar el estado');
      }
    );
  }

  // MODAL
  openEditCw(id: any, estado: any) {
    this._dynamicModal.openModal(
      EditCwRequestComponent,
      'Edición de Solicitud de Cargas o Retiros',
      'xl',
      false,
      { id, estado }
    );
  }
}
