import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './pages/auth/login/login.component';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component';
import { full } from './shared/routes/full.routes';
import { content } from './shared/routes/routes';
import { mobile } from './shared/routes/mobile.routes';
import { ProfileDetailComponent } from './pages/profile/pages/profile-detail/profile-detail.component';
import { FullComponent } from './shared/components/layout/full/full.component';
import { LoggedInAuthGuard } from './core/guards/loggedIn.guard';
import { Error404Component } from './shared/error/error404/error404.component';
import { TwoFactoryAuthenticationComponent } from './pages/auth/two-factory-authentication/two-factory-authentication.component';
import { TfaGuard } from './core/guards/tfa.guard';
import { MobileComponent } from './shared/components/layout/mobile/mobile.component';
import { MobileGuard } from './core/guards/mobile.guard';
import { UnlockUserComponent } from './pages/auth/unlock-user/unlock-user.component';
import { UnlockGuard } from './core/guards/unlock.guard';

const routes: Routes = [
  {
    path: '',
    component: ContentComponent,
    children: content,
    canActivate: [AuthGuard, UnlockGuard],
    canActivateChild: [UnlockGuard],
  },
  {
    path: 'auth/login',
    component: LoginComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: 'auth/two-factory-authentication',
    component: TwoFactoryAuthenticationComponent,
    canActivate: [TfaGuard],
  },
  {
    path: 'auth/unlock-user',
    component: UnlockUserComponent,
    // canActivate: [TfaGuard],
  },
  {
    path: 'full',
    component: FullComponent,
    children: full,
    canActivate: [UnlockGuard],
    canActivateChild: [UnlockGuard],
  },
  {
    path: 'mobile',
    component: MobileComponent,
    children: mobile,
    canActivate: [MobileGuard, UnlockGuard],
    canActivateChild: [UnlockGuard],
  },
  {
    path: '**',
    component: Error404Component,
    canActivate: [UnlockGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
