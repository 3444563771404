<div class="ltr">
  <div class="page-wrapper Dubai" id="canvas-bookmark">
    <div class="page-header" style="position: relative">
      <div class="header-wrapper row m-0">
        <div
          class="nav-right col-xxl-12 col-xl-12 col-md-12 col-12 pull-right right-header p-0 ms-auto"
        >
          <ul class="nav-menus">
            <li class="balance-nav" *ngIf="roleId == 4">
              <div class="balance-info">
                <button
                  class="refresh-button me-3 rounded-2"
                  [disabled]="isLoading"
                  ngbTooltip="Refrescar Saldo"
                  tooltipClass="custom-tooltip"
                  (click)="reloadTable()"
                >
                  <i class="fas fa-sync-alt"></i>
                </button>
                <div style="width: 100%; min-width: 75px">
                  <span *ngIf="isLoading">
                    <i class="fas fa-spinner fa-spin"></i>
                  </span>
                  <span
                    style="margin: 0px"
                    *ngIf="!isLoading"
                    ngbTooltip="Bono Saldo"
                    tooltipClass="custom-tooltip"
                  >
                    {{ country === 1 ? "$" : "S/" }}
                    {{
                      bonusBalanceData !== null &&
                      bonusBalanceData !== undefined
                        ? bonusBalanceData
                        : "0"
                    }}
                  </span>
                </div>
              </div>
            </li>
            <!-- <li class="onhover-dropdown">
            <app-notification></app-notification>
          </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="page-body-wrapper">
      <div class="page-body" style="margin-top: 0px">
        <main [@fadeInAnimation]="routerOutletState">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
    </div>
  </div>
</div>
