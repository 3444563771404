<div class="container-fluid basic_table">
  <div class="row">
    @if(!isMobile){
    <app-breadcrumb
      [title]="'Daily' | translate"
      [items]="['Reports']"
      [active_item]="'Daily' | translate"
    ></app-breadcrumb>
    }
    <div class="col-sm-12">
      <!-- here filter -->
      <div class="row mt-2">
        <div class="col-sm-12 col-xl-12">
          <div class="card">
            <div class="card-header">
              <h5
                class="card-title d-flex justify-content-between align-items-center"
              >
                {{ "Daily" | translate }}
                <button
                  class="btn btn-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#filterCardContent"
                  aria-expanded="false"
                  aria-controls="filterCardContent"
                  (click)="toggleFilterForm()"
                >
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-chevron-up': showFilterForm,
                      'fa-chevron-down': !showFilterForm
                    }"
                  ></i>
                </button>
              </h5>
            </div>
            <div id="filterCardContent" class="collapse">
              <div class="card-body row">
                <div
                  [ngClass]="{ open: showFilterForm }"
                  id="filterForm"
                  [ngStyle]="getFilterFormStyle()"
                >
                  <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                      <div class="row mb-3">
                        <div class="col-md-4">
                          <label for="field.id" class="col-form-label"
                            >Fecha</label
                          >
                          <shared-datepicker-v1
                            [initialRange]="initialRange"
                            (onRangeChange)="onDateSelected($event)"
                          />
                        </div>
                        @if(!isMobile && id_rol !== ROLES.AGENT && id_rol !==
                        ROLES.AFFILIATE){
                        <div class="col-md-4">
                          <label class="col-form-label">Pais</label>
                          <ng-select
                            [items]="optionsCountries$ | async"
                            formControlName="countryId"
                            (change)="selectedCountry()"
                            placeholder="Selecciona un país"
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-4">
                          <label for="field.id" class="col-form-label"
                            >Agente</label
                          >
                          <ng-select
                            (open)="onSearch('')"
                            [ngModel]="selectedsItem"
                            [items]="allAgentsData"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="agents"
                            placeholder="Select one agente"
                            class="mb-2"
                            (scrollToEnd)="onScrollToEnd()"
                            [multiple]="true"
                            (change)="updateFilter()"
                            (search)="onSearch($event.term)"
                            (clear)="onSearch('')"
                          ></ng-select>
                        </div>
                        }
                      </div>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        adiButton
                        class="btn btn-sm btn-head mb-3"
                        type="submit"
                        [disabled]="form.invalid"
                      >
                        Consultar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <!-- isForMobile -->
        @if (!(isMobile$|async)) {
        <div class="card-body custom-datatable noscroll">
          <div class="custom-datatable table-responsive">
            <table class="table">
              <thead class="text-start">
                @for (column of columnsTableDaily; track $index) {
                <th>{{ column.name }}</th>
                }
              </thead>
              @if (isLoading) {
              <tbody class="text-start">
                <tr>
                  <td colspan="13">
                    <!-- Ajusta el colspan según el número de columnas -->
                    <div class="loader-box">
                      <div class="loader-16"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
              }@if (!isLoading) {
              <tbody class="text-start">
                @for (item of reportDaily; track $index) {
                <tr>
                  <td class="text-center">
                    <!-- Botón para mostrar/ocultar detalle -->
                    @if (item.hasMessage) {
                    <span class="notification-dot"></span>
                    }
                    <button
                      (click)="toggleDetail($index, item)"
                      class="primary-button"
                    >
                      <i
                        class="fa-solid"
                        [ngClass]="!showDetail[$index] ? 'fa-plus' : 'fa-minus'"
                      ></i>
                    </button>
                  </td>
                  <td>
                    <span>{{ item?.fecha | date : "dd/MM/yyyy HH:mm" }}</span>
                  </td>
                  <td>
                    <span>{{ item.agente }}</span>
                  </td>
                  <td>
                    <span>{{ item.cierre_mes_pasado }}</span>
                  </td>
                  <td>
                    <span>{{ item.abonos }}</span>
                  </td>
                  <td>
                    <span>{{ item.pagos }}</span>
                  </td>
                  <td>
                    <span>{{ item.cargas }}</span>
                  </td>
                  <td>
                    <span>{{ item.retiros }}</span>
                  </td>
                  <td>
                    <span>{{ item.punto_fisico }}</span>
                  </td>
                  <td>
                    <span>{{ item.bono_comprado }}</span>
                  </td>
                  <td>
                    <span>{{ item.bono_comprado }}</span>
                  </td>
                  <td>
                    <span>{{ item.bono_cargado }}</span>
                  </td>
                  <td>
                    <span style="font-weight: bold">{{
                      (
                        (+item.cierre_mes_pasado || 0) +
                        (+item.abonos || 0) +
                        (-item.pagos || 0) +
                        (-item.cargas || 0) +
                        (+item.retiros || 0) +
                        (-item.punto_fisico || 0) +
                        (+item.bono_comprado || 0) +
                        (+item.bono_comprado || 0) +
                        (-item.bono_cargado || 0)
                      ).toFixed(2)
                    }}</span>
                  </td>
                </tr>
                @if (showDetail[$index]) {
                <!-- preguntar primero si existe comentario si existe mostrar en un input disable -->
                <!-- luego en la izquierda un boton de editar y que se habilite el input
                  y el boton de editar se cambia por el de guardar, y cancelar y haga todo
                  proceso respectivo, sino hay muestre un mensaje de no existe comentario para este registro
                  y salga un boton de + a la izquierda que haga lo mismo que el de editar arribas-->
                <tr>
                  @if (this.detailComment[$index]) {
                  <td colspan="13">
                    <div class="row">
                      <div class="col-md-10">
                        <textarea
                          style="resize: none"
                          type="text"
                          [value]="
                            editMode[$index]
                              ? originalComment[$index]
                              : detailComment[$index]
                          "
                          class="form-control"
                          (input)="onCommentChange($event, $index)"
                          [disabled]="!editMode[$index]"
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                      <div
                        class="col-md-2 d-flex align-items-center justify-content-end"
                      >
                        @if(!isMobile && id_rol !== ROLES.AGENT && id_rol !==
                        ROLES.AFFILIATE){
                        <div class="me-2">
                          <button
                            (click)="toggleEditMode($index, item)"
                            class="secondary-button"
                          >
                            <i
                              class="fa-solid"
                              [ngClass]="
                                editMode[$index]
                                  ? 'fa-floppy-disk'
                                  : 'fa-pen-to-square'
                              "
                            ></i>
                          </button>
                        </div>
                        }
                        <button
                          class="btn btn-sm btn-head btn-danger"
                          *ngIf="editMode[$index]"
                          (click)="cancelEdit($index)"
                        >
                          <i class="fa-solid fa-x"></i>
                        </button>
                      </div>
                    </div>
                  </td>

                  }@else {
                  <td colspan="13">
                    <div class="row">
                      <div class="col-md-10">
                        @if (editMode[$index]) {
                        <textarea
                          class="form-control"
                          style="resize: none"
                          type="text"
                          [value]="detailComment[$index] || ''"
                          (input)="onCommentChange($event, $index)"
                          [disabled]="!editMode[$index]"
                          cols="30"
                          rows="10"
                        ></textarea>
                        }@else {
                        <div class="detalle">No hay comentario</div>
                        }
                      </div>
                      <div
                        class="col-md-2 d-flex align-items-center justify-content-end"
                      >
                        @if (!isMobile && id_rol !== ROLES.AGENT && id_rol !==
                        ROLES.AFFILIATE) {

                        <div class="me-2">
                          <button
                            (click)="toggleEditMode($index, item)"
                            class="btn btn-sm btn-head btn-success"
                          >
                            <i
                              class="fa-solid"
                              [ngClass]="
                                editMode[$index] ? 'fa-floppy-disk' : 'fa-plus'
                              "
                            ></i>
                          </button>
                        </div>
                        }
                        <button
                          *ngIf="editMode[$index]"
                          (click)="cancelEdit($index)"
                          class="btn btn-sm btn-head btn-danger"
                        >
                          <i class="fa-solid fa-x"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                  }
                </tr>
                } }@empty {
                <tr>
                  <td colspan="13">
                    <p>Sin registros disponibles!</p>
                  </td>
                </tr>
                }
              </tbody>

              <tfoot>
                <td>Detalle</td>
                <td></td>
                <td></td>
                <td>{{ totalClosure }}</td>
                <td>{{ totalSubscriptions }}</td>
                <td>{{ totalPayments }}</td>
                <td>{{ totalLoads }}</td>
                <td>{{ totalWithdrawals }}</td>
                <td>{{ totalPhysicalPoints }}</td>
                <td>{{ totalPurchasedBonus }}</td>
                <td>{{ totalPurchasedBonus }}</td>
                <td>{{ totalLoadedBonus }}</td>
                <td>
                  {{
                    (
                      (+totalClosure || 0) +
                      (+totalSubscriptions || 0) +
                      (-totalPayments || 0) +
                      (-totalLoads || 0) +
                      (+totalWithdrawals || 0) +
                      (-totalPhysicalPoints || 0) +
                      (+totalPurchasedBonus || 0) +
                      (+totalPurchasedBonus || 0) +
                      (-totalLoadedBonus || 0)
                    ).toFixed(2)
                  }}
                </td>
              </tfoot>
              }
            </table>
          </div>
        </div>
        }@else {
        <div class="card-body">
          @if (isLoading) {
          <!-- Ajusta el colspan según el número de columnas -->
          <div class="loader-box">
            <div class="loader-16"></div>
          </div>
          }@else { @for (item of reportDaily; track $index ) {
          <div class="card mb-3">
            <div class="card-body custom-card">
              <div
                class="card-more-button-holder"
                (click)="toggleMenu($index, item)"
              >
                @if (item.hasMessage) {
                <span
                  class="notification-dot"
                  style="right: -4px; top: 1px"
                ></span>
                }
                <i class="fa-solid fa-ellipsis"></i>
              </div>
              <div class="card-expand-button" (click)="expandCard($index)">
                <i
                  class="fa-solid"
                  [ngClass]="
                    cardExpanded[$index] ? 'fa-chevron-up' : 'fa-chevron-down'
                  "
                ></i>
              </div>
              <div
                class="card-c-holder"
                [ngClass]="{ openCard: cardExpanded[$index] }"
              >
                @for (column of columnsTableDaily.slice(0, 7); track $index) {
                @if (column.value) {
                <div class="geneKeyValue card-list-col">
                  <p
                    role="heading"
                    aria-level="1"
                    class="geneKeyValue__label ellipsis-text"
                  >
                    {{ column.name }}
                  </p>
                  <br />
                  <p class="geneKeyValue__value ellipsis-text">
                    {{ item[column.value] }}
                  </p>
                </div>
                } } @if (cardExpanded[$index]) { @for (column of
                columnsTableDaily.slice(7); track $index) { @if (column.value) {
                <div class="geneKeyValue card-list-col">
                  <p
                    role="heading"
                    aria-level="1"
                    class="geneKeyValue__label ellipsis-text"
                  >
                    {{ column.name }}
                  </p>
                  <p class="geneKeyValue__value ellipsis-text">
                    {{ item[column.value] }}
                  </p>
                </div>
                } } }
              </div>
            </div>
          </div>
          } }
        </div>
        }
        <div class="card-footer">
          @if (isLoading) {
          <p class="card-text placeholder-glow text-center">
            <span class="placeholder col-4"></span>
          </p>
          }
          <div class="card-footer" [ngClass]="isLoading ? 'd-none' : 'd-block'">
            <shared-custom-pagination
              [collectionSize]="totalRecords"
              (pageChange)="onPageChange($event)"
              (pageSizeChange)="onLimitChange($event)"
            ></shared-custom-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <button (click)="toggleMenu(0,'')">Abrir Menú</button> -->

<div
  class="overlay"
  *ngIf="showMenu || showSubMenu"
  (click)="closeMenu()"
></div>
<div class="popover1" *ngIf="showMenu">
  <ul>
    <li
      (click)="openSubMenu(actualItem)"
      style="border-bottom: 1px solid var(--chart-border)"
    >
      View Card
    </li>
    @if (detailComment[actualIndex]) {
    <li
      (click)="openSubMenu(detailComment[actualIndex])"
      style="border-top: 1px solid var(--chart-border)"
    >
      view comment
    </li>
    }@else { @if(!isMobile && id_rol !== ROLES.AGENT && id_rol !==
    ROLES.AFFILIATE){
    <li
      (click)="openSubMenu(detailComment[actualIndex])"
      style="border-top: 1px solid var(--chart-border)"
    >
      add comment
    </li>
    } }
    <li (click)="closeMenu()" style="border-top: 1px solid var(--chart-border)">
      Close
    </li>
  </ul>
</div>
<div class="popover1" *ngIf="showSubMenu">
  <div class="submenu">
    <ul>
      <li (click)="closeSubMenu()">Regresar</li>
    </ul>
    <div class="submenu-content">
      @switch (typeof(subMenuItem)) {@case ('object') { @for (column of
      columnsTableDaily; track $index) {
      <div class="row" style="max-width: 98%">
        <div class="col-6 ellipsis-text" style="font-weight: bold">
          <p>{{ column.name }}:</p>
        </div>
        <div class="col-6 ellipsis-text">
          {{ subMenuItem[column.value] }}
        </div>
      </div>
      } }@case ('string') {
      <div class="row" style="max-width: 98%">
        <div class="col-10">
          <textarea
            style="resize: none"
            type="text"
            [value]="
              editMode[actualIndex]
                ? originalComment[actualIndex]
                : detailComment[actualIndex]
            "
            class="form-control"
            (input)="onCommentChange($event, actualIndex)"
            [disabled]="!editMode[actualIndex]"
            cols="3"
            rows="13"
          ></textarea>
        </div>
        @if(!isMobile && id_rol !== ROLES.AGENT && id_rol !== ROLES.AFFILIATE){

        <div class="col-2 align-self-center">
          <button
            (click)="toggleEditMode(actualIndex, actualItem)"
            class="primary-button"
          >
            <i
              class="fa-solid"
              [ngClass]="
                editMode[actualIndex] ? 'fa-floppy-disk' : 'fa-pen-to-square'
              "
            ></i>
          </button>
          <button
            class="btn btn-sm btn-head btn-danger mb-2"
            *ngIf="editMode[actualIndex]"
            (click)="cancelEdit(actualIndex)"
          >
            <i class="fa-solid fa-x"></i>
          </button>
        </div>
        }
      </div>
      }@case ('undefined') {
      <div class="row" style="max-width: 98%">
        <div class="col-10">
          @if (editMode[actualIndex]) {
          <textarea
            class="form-control"
            style="resize: none"
            type="text"
            [value]="detailComment[actualIndex] || ''"
            (input)="onCommentChange($event, actualIndex)"
            [disabled]="!editMode[actualIndex]"
            cols="30"
            rows="10"
          ></textarea>
          }@else {
          <div class="detalle">No hay comentario</div>
          }
        </div>
        <div class="col-2 align-self-center">
          <button
            (click)="toggleEditMode(actualIndex, actualItem)"
            class="primary-button"
          >
            <i
              class="fa-solid"
              [ngClass]="editMode[actualIndex] ? 'fa-floppy-disk' : 'fa-plus'"
            ></i>
          </button>
          <button
            *ngIf="editMode[actualIndex]"
            (click)="cancelEdit(actualIndex)"
            class="secondary-button"
          >
            <i class="fa-solid fa-x"></i>
          </button>
        </div>
      </div>
      } }
    </div>
  </div>
</div>
