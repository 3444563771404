import { Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { ClosureComponent } from '../../pages/reports/closure/closure.component';

export const full: Routes = [
  {
    path: 'error-page',
    loadChildren: () =>
      import('../error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'logged-another-device',
    loadChildren: () =>
      import('../error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../../pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () =>
      import('../coming-soon/coming-soon.module').then(
        (m) => m.ComingSoonModule
      ),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('../maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule
      ),
  },
  {
    path: 'closure/:token',
    component: ClosureComponent,
  },
];
