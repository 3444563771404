import { Injectable } from '@angular/core';
import Tesseract from 'tesseract.js';
import { BANKS, WORDS } from '../data/suscriptions-payments/constants';

@Injectable({
  providedIn: 'root',
})
export class OcrService {
  constructor() { }

  async readReceiptNumber(imagePath: string): Promise<string> {
    return Tesseract.recognize(imagePath, 'spa')
      .then(({ data: { text } }) => {
        let match = text.match(/Comprobante:\s(\d+)/);
        if (!match) {
          match = text.match(/No\.00*(\d+)/);
        }
        if (!match) {
          match = text.match(/Número de comprobante:\s*(\d+)/);
        }
        if (!match) {
          match = text.match(/Comprobante Nro.\s*(\d+)/);
        }

        if (match && match[1]) {
          return match[1];
        } else {
          throw new Error(
            'Número de comprobante no encontrado. Por favor ingresar manualmente.'
          );
        }
      })
      .catch((error) => {
        console.error('Error al procesar la imagen:', error);
        throw error;
      });
  }
  async readAmount(imagePath: string): Promise<string> {
    return Tesseract.recognize(imagePath, 'spa')
      .then(({ data: { text } }) => {
        let match = text.match(
          /\$\s*([0-9]{1,3}(?:\.[0-9]{3})*(?:,[0-9]{2})?|[0-9]+(?:,[0-9]{2})?)/
        );

        if (match && match[1]) {
          return match[1].replace('.', '').replace(',', '.');
        } else {
          throw new Error(
            'Monto no encontrado. Por favor ingresar manualmente.'
          );
        }
      })
      .catch((error) => {
        console.error('Error al procesar la imagen:', error);
        throw error;
      });
  }

  async readBank(imagePath: string): Promise<string> {
    return Tesseract.recognize(imagePath, 'spa')
      .then(({ data: { text } }) => {
        const bankName = this.extractBankNameFromText(text);
        if (bankName) {
          return bankName;
        } else {
          throw new Error(
            'Banco no encontrado. Por favor ingresar manualmente.'
          );
        }
      })
      .catch((error) => {
        console.error('Error al procesar la imagen:', error);
        throw error;
      });
  }
  async readImageText(imageUrl: string): Promise<string> {
    return Tesseract.recognize(imageUrl, 'spa')
      .then(({ data: { text } }) => {
        const words = this.extractDescriptionFromText(text);
        const formattedWords = words.join(' ');
        // console.log('Palabras clave reconocidas en la imagen:', formattedWords);
        if (words.length >= 0) {
          return formattedWords;
        } else {
          throw new Error('Hubo un error al obtener el detalle.');
        }
      })
      .catch((error) => {
        console.error('Error al procesar la imagen:', error);
        throw error;
      });
  }

  private extractBankNameFromText(text: string): string | null {
    const keywords = BANKS;
    const match = keywords.find((keyword) =>
      text.toLowerCase().includes(keyword.toLowerCase())
    );

    return match || null;
  }

  private extractDescriptionFromText(text: string): string[] {
    const keywords = WORDS;
    const matchesSet = new Set<string>();

    keywords.forEach(keyword => {
      if (text.toLowerCase().includes(keyword.toLowerCase())) {
        matchesSet.add(keyword);
      }
    });

    return Array.from(matchesSet);
  }

}
