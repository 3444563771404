<div class="container-fluid" [formGroup]="countryForm">
  <div class="col-sm-12">
    <label>Selecciona un país para continuar</label>
    <ng-select
      [items]="optionsCountries$ | async"
      required=""
      placeholder="Selecciona un país"
      bindValue="value"
      bindLabel="label"
      formControlName="selectedCountry"
      (change)="onCountrySelected($event)"
      [loading]="isLoading"
    >
      <ng-template ng-label-tmp *ngIf="isLoading">
        Cargando países...
      </ng-template>
    </ng-select>
  </div>
  <div class="mt-3 d-flex justify-content-end">
    <button
      class="btn btn-sm btn-head"
      (click)="next()"
      [disabled]="!isValidCountry"
    >
      Continuar
    </button>
  </div>
</div>

