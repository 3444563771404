<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="authentication-main mt-0">
      <div class="row">
        <div class="col-12">
          <div class="login-card login-dark">
            <div>
              <div>
                <a class="logo" routerLink="/">
                  <img
                    class="img-fluid for-light"
                    width="100px"
                    src="assets/images/logo/logo_sorti.png"
                    alt="looginpage"
                  />
                  <img
                    class="img-fluid for-dark"
                    src="assets/images/logo/logo_sorti_dark.png"
                    alt="looginpage"
                  />
                </a>
              </div>
              <div class="login-main">
                <form
                  class="theme-form"
                  [formGroup]="tfaForm"
                  (ngSubmit)="onSubmit()"
                >
                  <h5>Autenticación De Dos Factores</h5>
                  <div class="form-group">
                    <label class="col-form-label"
                      >Ingresa tu código de seguridad</label
                    >
                    <input
                      class="form-control"
                      input
                      id="TFA"
                      autofocus
                      class="form-control"
                      placeholder="Code"
                      formControlName="TFA"
                      placeholder="*********"
                    />
                  </div>
                  <div class="form-group mb-0">
                    <button
                      class="primary-button d-block w-100"
                      [disabled]="!tfaForm.valid"
                      type="submit"
                    >
                      Enviar Código
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
