import {
  Directive,
  HostBinding,
  HostListener,
  Input,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[validateDecimalNumberOnly]',
})
export class ValidateDecimalNumberOnlyDirective {
  @HostBinding('autocomplete') public autocomplete = 'off';

  @Input() public decimalPlaces?: number = 2;

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) public onKeyPress(
    event: KeyboardEvent
  ) {
    const input = this.el.nativeElement;
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Allow control keys like backspace, delete, arrow keys, etc.
    if (this.isControlKey(event)) {
      return true;
    }

    // Allow one minus sign at the start if the input is empty

    // Allow only one dot (decimal point)
    if (keyValue === '.' && input.value.indexOf('.') === -1) {
      return true;
    }

    // Allow only digits
    if (!/^[0-9]$/.test(keyValue)) {
      return false;
    }

    // Check for decimal places
    const valueAfterInput = this.getValueAfterInput(
      input.value,
      keyValue,
      input.selectionStart
    );
    if (this.hasExceededDecimalPlaces(valueAfterInput)) {
      return false;
    }

    return true;
  }

  @HostListener('paste', ['$event']) public onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text') ?? '';
    const input = this.el.nativeElement;
    const valueAfterPaste = this.getValueAfterInput(
      input.value,
      pastedText,
      input.selectionStart
    );

    if (
      !this.isValidNumber(valueAfterPaste) ||
      this.hasExceededDecimalPlaces(valueAfterPaste)
    ) {
      event.preventDefault();
    }
  }

  private isControlKey(event: KeyboardEvent): boolean {
    const controlKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
      'Enter',
    ];
    return controlKeys.includes(event.key);
  }

  private getValueAfterInput(
    currentValue: string,
    inputValue: string,
    cursorPosition: number
  ): string {
    const valueBeforeCursor = currentValue.substring(0, cursorPosition);
    const valueAfterCursor = currentValue.substring(cursorPosition);
    return valueBeforeCursor + inputValue + valueAfterCursor;
  }

  private isValidNumber(value: string): boolean {
    return /^-?\d*\.?\d*$/.test(value);
  }

  private hasExceededDecimalPlaces(value: string): boolean {
    if (!value.includes('.')) {
      return false;
    }
    const decimalPart = value.split('.')[1];
    return decimalPart.length > this.decimalPlaces!;
  }
}
