import { Component, OnDestroy, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { FilterCommunicationService } from '../../../../../../../core/services/filter-communication.service';
import { EventService } from '../../../../../../../shared/services/event.service';
import {
  CONFIG_BASE_DATE_RANGEPICKER,
  SORT_DIRECTION,
} from '../../../../../../../core/constants/constants';
import {
  columnsTableList,
  bonusTypes,
} from '../../../../../../../shared/data/bonus/balance-bonus/constants';
import {
  MOVEMENT_CLASSES,
  STATE_SP_CLASSES,
  movementType,
} from '../../../../../../../shared/data/suscriptions-payments/constants';
import { SubscriptionPaymentService } from '../../../../../../../core/services/subscription-payment.service';
import moment from 'moment';
import { formatAmount } from '../../../../../../../core/utils/format-numeric.utils';
import Swal from 'sweetalert2';
import { StateService } from '../../../../../../../core/services/state.service';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { selectScreen } from '../../../../../../../core/state/screen/screen.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../../../../core/services/user.service';
import { Subscription } from 'rxjs';
import { PAYMENTS_BUTTON_PERMISSIONS } from '../../../../../../../shared/data/config/constants-buttons';
import {
  Permission,
  MainPermission,
} from '../../../../../../../core/interfaces/permision.interface';
import { DynamicModalService } from '../../../../../../../core/services/dynamic-modal.service';
import { EditSpComponent } from '../../forms/edit-sp/edit-sp.component';

@Component({
  selector: 'app-list-sp-table',
  templateUrl: './list-sp-table.component.html',
  styleUrl: './list-sp-table.component.scss',
})
export class ListSpTableComponent implements OnInit, OnDestroy {
  // LOADER
  public isLoading: boolean = false;

  // TABLE
  public bonusData: any;
  public paginatedBonusData: any;
  public columnsTable = columnsTableList;
  public totalDolares: string = '';
  public totalSoles: string = '';
  public states: any;
  public actualIndex!: number;
  public actualItem: any;
  public cardExpanded: boolean[] = [];
  public subMenuItem: any;
  public isMobile: boolean = false;
  public token!: string;
  public SORT_DIRECTION = SORT_DIRECTION;
  public PAYMENTS_BUTTON_PERMISSIONS = PAYMENTS_BUTTON_PERMISSIONS;

  // PAGINATION
  public collectionSize: number = 0;
  public page: number = 1;
  public limit: number = 10;
  public sortColumn: string | null = null;
  public sortDirection: string | null = null;

  //FILTER
  public selectedFilter: any;
  public defaultFilter: any;
  public startDate = CONFIG_BASE_DATE_RANGEPICKER.initialRange[0];
  public endDate = CONFIG_BASE_DATE_RANGEPICKER.initialRange[1];
  public subscribe: Subscription = new Subscription();

  //EXCEL
  public bonusTypes = bonusTypes;

  //LOGGEDSUSER
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  public rol!: number;

  // REDUX
  public isMobile$ = this.store.select(selectScreen);
  public isMobileRedux!: boolean;

  constructor(
    private _filterCommunicationService: FilterCommunicationService,
    private eventService: EventService,
    private spService: SubscriptionPaymentService,
    private stateService: StateService,
    private store: Store,
    private router: Router,
    private activedRouter: ActivatedRoute,
    private _userService: UserService,
    private dynamicModal: DynamicModalService
  ) {}

  async ngOnInit(): Promise<void> {
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    this.isMobile$.subscribe((isMobile) => {
      this.isMobileRedux = isMobile;
    });
    this.columnsTable = this.isMobile
      ? columnsTableList.filter((column) => column.name !== 'Acciones')
      : columnsTableList;
    if (this.isMobile) {
      await this.loadTokenLogged();
    } else {
      await this.loadProfileLogged();
    }
    this.getDataFromFilter();
    this.getStatesSp();
  }

  //? LOAD DATA
  async loadProfileLogged() {
    await new Promise<void>((resolve, reject) => {
      this.profile$.subscribe({
        next: (res) => {
          if (res.idusuario !== -1) {
            this.profile = res;
            this.rol = res.id_rol;
            this.columnsTable = [2, 10].includes(this.rol)
              ? this.columnsTable.filter((column) => column.name !== 'Acciones')
              : this.columnsTable;
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
  async loadTokenLogged() {
    await new Promise<void>((resolve, reject) => {
      this.activedRouter.params.subscribe((params) => {
        this.token = params['token'];
        this._userService.getUserByTokenApp(this.token).subscribe({
          next: (res) => {
            if (res.data.idusuario) {
              this.profile = res.data;
              resolve();
            } else {
              reject(new Error('No user id found'));
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    });
  }
  getData(): void {
    this.isLoading = true;
    this.defaultFilter = this.selectedFilter
      ? this.selectedFilter
      : {
          type_user: '0',
          date_star: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
          date_end: moment(this.endDate).format('YYYY-MM-DD 23:59:59'),
        };
    const filterWithSorting = {
      ...this.defaultFilter,
      ...(this.sortColumn &&
        this.sortDirection && {
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        }),
    };
    this.spService
      .getAllSp(this.page, this.limit, filterWithSorting)
      .subscribe((res) => {
        const response = res.data;
        this.isLoading = false;
        this.totalDolares = response.totalMontoSummary.Ecuador
          ? formatAmount(Number(response.totalMontoSummary.Ecuador), 'EC')
          : '$ 0';
        this.totalSoles = response.totalMontoSummary.Peru
          ? formatAmount(Number(response.totalMontoSummary.Peru), 'PE')
          : 'S/ 0';
        this.bonusData = response.data.map(
          (item: { creado: moment.MomentInput; id_movimiento: number }) => {
            return {
              ...this.formatSpData(item),
              movementTypeLabel: this.getMovementType(item.id_movimiento),
            };
          }
        );
        this.collectionSize = response.totalCount;
      });
  }

  onSort(columnValue: string | undefined) {
    const valueToSort = columnValue ?? '';
    if (this.sortColumn === valueToSort) {
      this.sortDirection =
        this.sortDirection === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC;
    } else {
      this.sortColumn = valueToSort;
      this.sortDirection = SORT_DIRECTION.ASC;
    }
    this.getData();
  }

  getMovementType(movmentType: number) {
    const type = movementType.find((t) => Number(t.value) === movmentType);
    return type ? type.label : 'Desconocido';
  }

  getMovementClass(type: number): string {
    return MOVEMENT_CLASSES[type] || '';
  }

  getMovementIconClass(type: number): string {
    return this.getMovementIconClassFromTypes(type) || '';
  }

  getMovementIconClassFromTypes(type: number): string {
    // Aquí puedes definir los íconos específicos para cada tipo de movimiento, similar a TRANSACTION_ICON_CLASSES
    const MOVEMENT_ICON_CLASSES: { [key: number]: string } = {
      1: 'fas fa-credit-card', // Ejemplo de ícono para 'Credito'
      2: 'fas fa-money-bill', // Ejemplo de ícono para 'Debito'
    };
    return MOVEMENT_ICON_CLASSES[type] || '';
  }

  getMovementTypeLabel(type: number): string {
    const typeObj = movementType.find((t) => Number(t.value) === type);
    return typeObj ? typeObj.label : 'Desconocido';
  }

  getStatesSp() {
    this.stateService.geatAllDataSp().subscribe((response) => {
      this.states = response.data.filter(
        (state: { idestado: number }) => state.idestado !== 13
      );
    });
  }

  formatSpData(item: any) {
    const monto = formatAmount(
      Number(item.monto),
      item.id_pais === 1 ? 'EC' : 'PE'
    );

    return {
      ...item,
      monto: monto,
    };
  }

  //?FILTER
  getDataFromFilter() {
    this.subscribe = this._filterCommunicationService.currentFilter.subscribe(
      (filter) => {
        if (this.isMobile) {
          if (filter) {
            this.selectedFilter = filter;
            this.getData();
          }
        } else {
          this.selectedFilter = filter;
          this.getData();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this._filterCommunicationService.resetFilter();
  }

  //*BUTTONS
  reloadTable() {
    this.isLoading = true;
    this.sortColumn = null;
    this.sortDirection = null;
    this.getData();
  }
  setStatus(
    idabonos_pagos: number,
    id_estado: number,
    usuario_updated_id: number
  ) {
    Swal.fire({
      title: '¿Estás seguro que  quieres cambiar el estado?',
      text: 'No podrás revertir esto!',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      confirmButtonText: 'Si, cambiar!',
      cancelButtonText: 'No, cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spService
          .setState(idabonos_pagos, id_estado, usuario_updated_id)
          .subscribe((response) => {
            Swal.fire({
              title: 'Estado actualizado 👌!',
              text: 'El estado fue actualizado correctamente.',
              icon: 'success',
            });
          });
      }
    });
  }

  exportToExcel() {
    const fileName = 'abono_pagos_data.xlsx';
    const worksheetName = 'Abonos y Pagos Data';
    const excelData = this.bonusData.map((item: any) => {
      const montoOriginal = item.monto.trim();
      const montoConFormato = montoOriginal
        .replace(/[$S/]/g, '')
        .replace(/−/g, '-')
        .replace(/\s/g, '');

      const montoSinMoneda = parseFloat(montoConFormato.replace(',', '.'));
      const moneda = item.monto.includes('$')
        ? 'USD'
        : item.monto.includes('S/')
        ? 'PEN'
        : 'Desconocido';

      return {
        Estado: STATE_SP_CLASSES[item.id_estado]?.text || 'Desconocido',
        Creado: item.fecha,
        Agent: item.agent?.usuario,
        Comprobante: item.n_comprobante,
        Monto: montoSinMoneda,
        Moneda: moneda,
        'Tipo de Movimiento':
          movementType.find((type) => type.value === String(item.id_movimiento))
            ?.label || 'Desconocido',
        Banco: item.spBankPerIdBanco?.banco,
        Titular: item.spUserPerIdUsuTitular?.usuario,
        Asigno: item.updated_by?.usuario,
        Observacion: item.observacion,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
    XLSX.writeFile(workbook, fileName);
  }

  //*PAGINATION
  // Métodos para manejar el cambio de página y el cambio de límite
  onPageChange(page: number): void {
    this.page = page;
    this.getData();
  }

  onLimitChange(limit: number): void {
    this.limit = limit;
    this.getData();
  }

  //? STATE CLASSES
  getStateClasses(state: number) {
    return STATE_SP_CLASSES[state] || { text: '', classes: {} };
  }

  //* CARD
  showMenu: boolean = false;
  showSubMenu: boolean = false;
  toggleMenu(index: number, item: any) {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.actualIndex = index;
      this.actualItem = item.observacion;
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }
  expandCard(index: number) {
    this.cardExpanded[index] = !this.cardExpanded[index];
  }
  closeMenu() {
    this.showMenu = false;
    this.showSubMenu = false;
    document.body.style.overflowY = 'auto';
  }
  openSubMenu(item: any) {
    this.subMenuItem = item;
    this.showMenu = false;
    this.showSubMenu = true;
  }
  closeSubMenu() {
    this.showMenu = true;
    this.showSubMenu = false;
  }

  hasPermission(permissionId: number): boolean {
    return this.profile?.rol?.permisos_principales.some(
      (mainPermission: MainPermission) =>
        mainPermission.permisos.some(
          (permission: Permission) => permission.id === permissionId
        )
    );
  }
  openReverseCw(item: any) {
    this.dynamicModal.openModal(
      EditSpComponent,
      'Editar Lista Abonos y Pagos',
      'xl',
      false,
      { item: item, filter: this.selectedFilter }
    );
  }
}
