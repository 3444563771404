<div class="card">
  <div
    class="d-flex justify-content-center align-items-center"
    style="padding: 15px"
  >
    <h5 class="d-flex w-100 justify-content-between align-items-center">
      Opciones Avanzadas
      <button class="btn btn-link" (click)="toggleChart()">
        <i
          [class]="'fas fa-arrow-' + (showChart ? 'up' : 'down')"
          style="font-size: 14px; color: #5a6268"
        ></i>
      </button>
    </h5>
  </div>
  <div class="card-body row">
    <div
      [ngClass]="{ open: showChart }"
      id="chart"
      [ngStyle]="getShowChartStyle()"
      class="row row-mobile"
    >
      <div class="col-xl-9 col-md-9">
        <div class="card card-filter">
          <h5 class="mt-4 mb-3">Filtro</h5>
          <app-filter-cw></app-filter-cw>
        </div>
      </div>
      <div class="col-xl-3 col-md-3">
        <div
          class="card mb-sm-0 ps-3 pe-3 pt-3"
          [ngStyle]="{
            height: '91.5%',
            'overflow-y': statesChart.length > 6 ? 'auto' : 'hidden'
          }"
        >
          @if (isLoadingData) {
          <div
            class="d-flex align-items-center justify-content-center"
            style="height: 100%; width: 100%"
          >
            <div class="loader-box">
              <div class="loader-16"></div>
            </div>
          </div>
          } @else {
          <div class="row">
            @for (item of statesChart; track $index) { @if (item.count != 0) {
            <div class="col-md-6 col-xl-6 mb-4">
              <div class="card mb-sm-0">
                <div
                  class="balance-card widget-hover fw-bold text-white"
                  [style.background]="item.customColor.background"
                >
                  <div class="justify-content-start align-items-start">
                    <span class="f-light" style="font-size: 0.85rem">{{
                      item.title
                    }}</span>
                    <h6 class="mt-1 mb-0">{{ item.count }}</h6>
                  </div>
                </div>
              </div>
            </div>
            } }
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
