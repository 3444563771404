import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Language } from '../../interfaces/language.interface';

export const featuredSelector =
  createFeatureSelector<Readonly<Language>>('language');

export const selectLanguage = createSelector(
  featuredSelector,
  (state) => state.language
);
