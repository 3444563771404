<div class="container-fluid">
  @if (isLoading) {
  <!-- Ajusta el colspan según el número de columnas -->
  <div class="loader-box">
    <div class="loader-16"></div>
  </div>
  }@if (!isLoading) {
  <form
    class="theme-form mega-form"
    [formGroup]="cwReverseForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="row">
      <div class="col-sm-12 col-xl-12">
        <div class="row">
          <div class="col-md-4">
            <label>Tipo Transaccion</label>
            <ng-select
              [items]="optionsTypeTransactions$ | async"
              bindLabel="label"
              bindValue="value"
              formControlName="tipo_transaccion"
              required=""
              placeholder="Selecciona un tipo de transacción"
            >
            </ng-select>
            @if (cwReverseForm.controls.tipo_transaccion!.touched &&
            cwReverseForm.controls.tipo_transaccion.errors?.required) {
            <div class="text-danger font-error mt-2">
              El Tipo de transacción es requerido
            </div>
            }
          </div>
          <div class="col-md-4">
            <label>Monto</label>
            <input
              #montoInput
              type="text"
              class="form-control custom-input"
              id="monto"
              required=""
              placeholder="Monto"
              formControlName="monto"
            />
          </div>
          <div class="col-md-4">
            <div class="media">
              <label class="col-form-label m-r-10"
                >{{ isAgent ? "Agente" : "Jugador" }} *</label
              >
              <div class="media-body text-end switch-sm icon-state">
                <label class="switch">
                  <input type="checkbox" (change)="toggleSwitch()" />
                  <span class="switch-state"></span>
                </label>
              </div>
            </div>
            @if (isAgent) {
            <ng-select
              [items]="allAgentsData"
              [bindLabel]="'label'"
              [bindValue]="'value'"
              formControlName="id_agente"
              placeholder="Selecciona un agente"
              (scrollToEnd)="onScrollToEnd()"
              (search)="onSearch($event.term)"
              (clear)="onSearch('')"
              (open)="onSearch('')"
              (change)="onSelectionChange($event)"
            ></ng-select>
            }@else {
            <ng-select
              [items]="allPlayersData"
              [bindLabel]="'label'"
              [bindValue]="'value'"
              formControlName="id_jugador"
              placeholder="Selecciona un jugador"
              (scrollToEnd)="onScrollToEnd()"
              (search)="onSearch($event.term)"
              (clear)="onSearch('')"
              (open)="onSearch('')"
              (change)="onSelectionChange($event)"
            ></ng-select>
            } @if (isAgent) { @if (cwReverseForm.controls.id_agente!.touched &&
            cwReverseForm.controls.id_agente.errors?.required) {
            <div class="text-danger font-error mt-2">
              El Agente es requerido
            </div>
            } }@else { @if (cwReverseForm.controls.id_jugador!.touched &&
            cwReverseForm.controls.id_jugador.errors?.required) {
            <div class="text-danger font-error mt-2">
              El Jugador es requerido
            </div>
            } }
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <label>
              Detalle (Detalla el motivo del reverso.
              <span>¡DEBE SER DIFERENTE AL COMENTARIO ANTERIOR!</span>)
            </label>
            <textarea
              rows="3"
              class="form-control"
              formControlName="detalle"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-4 mb-4" />
    <div class="text-end">
      <button type="button" class="btn btn-cancel me-1" (click)="closeModal()">
        Cancelar
      </button>
      @if(isLoadingButton){
      <button class="btn btn-create" type="submit" disabled>
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </button>
      }@else {
      <button
        type="submit"
        class="btn btn-create me-1"
        [disabled]="!isDetailModified"
      >
        Reversar
      </button>
      }
    </div>
  </form>
  }
</div>
