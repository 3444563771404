import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  BsDaterangepickerConfig,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import {
  defaultInitialDateRangepickerV1,
  defaultMinDatepickerV1,
  defaultRangesDatepickerV1,
} from '../../../../core/constants/constants';
import { DatepickerV1Range } from '../../../../core/interfaces/components.interface';
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'shared-datepicker-v1',
  templateUrl: './datepicker-v1.component.html',
  styleUrl: './datepicker-v1.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DatepickerV1Component implements OnInit {
  @Input()
  public ranges: DatepickerV1Range[] = defaultRangesDatepickerV1;

  @Input()
  public initialRange: Date[] = defaultInitialDateRangepickerV1;

  @Input()
  public minDate: Date = new Date(defaultMinDatepickerV1);

  @Input()
  public maxDate: Date = defaultInitialDateRangepickerV1[1];

  @Input()
  public minMode: 'day' | 'month' = 'day';

  @Output()
  public onRangeChange: EventEmitter<{ startDate: Date; endDate: Date }> =
    new EventEmitter<{ startDate: Date; endDate: Date }>();

  public bsConfig?: Partial<BsDaterangepickerConfig>;

  constructor(private readonly _localeService: BsLocaleService) {
    defineLocale('es', esLocale);
  }

  ngOnInit(): void {
    this._localeService.use('es');
    this.setBsConfig();
  }

  private setBsConfig() {
    this.bsConfig = {
      containerClass: 'theme-blue',
      keepDatesOutOfRules: true,
      showPreviousMonth: true,
      minMode: this.minMode,
      useUtc: true,
      preventChangeToNextMonth: true,
    };
    if (this.ranges.length > 0) {
      this.bsConfig['ranges'] = this.ranges;
    }
  }

  public onValueChange(dateRange: (Date | undefined)[] | undefined) {
    if (!dateRange) return;
    const validDates = dateRange.filter(
      (date) => date instanceof Date
    ) as Date[];

    if (validDates.length === 2) {
      this.onRangeChange.emit({
        startDate: validDates[0],
        endDate: validDates[1],
      });
    }
  }
}
