import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChargesWithdrawalsRequestService } from '../../../../../../../core/services/charges-withdrawals-request.service';
import { DEFAULT_VALUE, PAGE_SIZE_VALUE, TRANSACTION_TYPE_LABELS, TRANSACTION_TYPE_VALUES, TRANSACTION_TYPES } from '../../../../../../../shared/data/suscriptions-payments/constants';
import { distinctUntilChanged, of, switchMap } from 'rxjs';
import { UserService } from '../../../../../../../core/services/user.service';
import { PlayerService } from '../../../../../../../core/services/player.service';
import { DynamicModalService } from '../../../../../../../core/services/dynamic-modal.service';
import { ToastrService } from 'ngx-toastr';
import { AGENT_ID_DEFAULT, userOnlyActive } from '../../../../../../../core/constants/constants';
import { UserMainService } from '../../../../../../user/services/user-main.service';

@Component({
  selector: 'app-reverse-cw-request',
  templateUrl: './reverse-cw-request.component.html',
  styleUrl: './reverse-cw-request.component.scss'
})
export class ReverseCwRequestComponent implements OnInit {

  public data: any;
  public cw_id: number = 0;
  public cwReverseForm!: FormGroup;
  public isAgent = true;
  public optionsTypeTransactions$: any;
  public optionsAgents$: any;
  public optionsPlayers$: any;
  public currentPageAgents = 1;
  public countAgents = 0;
  public currentPagePlayers = 1;
  public countPlayers = 0;
  public currentPageHolders = 1;
  public countHolders = 0;
  public pageSize = 10;
  public allAgentsData: any[] = [];
  public allPlayersData: any[] = [];
  public id_country: any;
  public criteria: any;
  public cwData: any;
  public isLoadingButton = false;
  public selectedItem!: any;
  public isLoading: boolean = true;
  public initialDetalle: string = '';
  public isDetailModified = false;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private _cwService: ChargesWithdrawalsRequestService,
    private _userService: UserService,
    private _playerService: PlayerService,
    private _dynamicModal: DynamicModalService,
    private _toastrSvc: ToastrService,
    private _userMainService: UserMainService,
  ) {
    this.initForm();

  }

  ngOnInit(): void {
    this.data = this._dynamicModal.getModalData();
    this.id_country = this.data.country;
    this.getCwData()
  }

  //?LOAD DATA
  getCwData() {
    this.isLoading = true;
    this._cwService.getCw(this.data.id).subscribe((response) => {
      this.cwData = response.data
      this.updateData(this.cwData)
      this.isAgent = this.cwData.id_agente !== AGENT_ID_DEFAULT;
      this.loadTypeTransactions();
      this.loadAgents();
      this.loadPlayers();
      this.monitorDetailChanges();
      this.isLoading = false;
    })
  }
  loadTypeTransactions() {
    const transactionType = this.cwReverseForm.get('tipo_transaccion')?.value;
    if (transactionType === TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT.toString() || transactionType === TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL.toString()) {
      return;
    }

    const baseFilteredTransactionTypes = TRANSACTION_TYPES.filter(
      (item) => item.label !== TRANSACTION_TYPE_LABELS.FINANCIAL && item.label !== TRANSACTION_TYPE_LABELS.CREDIT_DEPOSIT && item.label !== TRANSACTION_TYPE_LABELS.CREDIT_WITHDRAWAL
    );

    let filteredTransactionTypes;

    if (this.isAgent) {
      filteredTransactionTypes = baseFilteredTransactionTypes.filter(
        (item) => item.value !== TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER.toString() && item.value !== TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER.toString()
      );
    } else {
      filteredTransactionTypes = baseFilteredTransactionTypes.filter(
        (item) =>
          item.value === TRANSACTION_TYPE_VALUES.LOAD_BALANCE.toString() || item.value === TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE.toString() || item.value === TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT.toString()
      );
    }

    this.optionsTypeTransactions$ = of(filteredTransactionTypes);
  }
  loadAgents(searchTerm?: string) {
    this._userMainService
      .getAllReferredAndAgents(
        this.currentPageAgents,
        this.pageSize,
        searchTerm,
        this.id_country,
        userOnlyActive
      )
      .pipe(
        distinctUntilChanged(),
        switchMap((response) => {
          const transformedData = response.data.map((item: any) => ({
            label: item.usuario,
            value: item.idusuario,
            whiteList: item.agente_white_list?.lista_negra,
          }));
          const uniqueData = transformedData.filter(
            (newItem: any) =>
              !this.allAgentsData.some(
                (existingItem) => existingItem.value === newItem.value
              )
          );
          this.allAgentsData = [...this.allAgentsData, ...uniqueData];
          return of(this.allAgentsData);
        })
      )
      .subscribe((data) => {
        this.optionsAgents$ = of(data);
      });
  }
  loadPlayers(searchTerm?: string) {
    this._playerService
      .getPlayers(
        this.id_country,
        this.currentPagePlayers,
        this.pageSize,
        searchTerm
      )
      .subscribe((response) => {
        const transformedData = response.data
          .map(
            (item: { usuario_jugador: any; id_jugador: any; id_usuario: any }) => {
              return { label: item.usuario_jugador, value: item.id_jugador, id_usuario: item.id_usuario };
            }
          );
        transformedData.push({ value: this.cwData.id_jugador, label: this.cwData.player })
        this.optionsPlayers$ = of(transformedData);
        this.allPlayersData = [...this.allPlayersData, ...transformedData];
      });
  }

  //?MODAL
  closeModal() {
    this.activeModal.close();
    this.cwReverseForm.reset();
  }

  //SWITCHES
  toggleSwitch() {
    this.isAgent = !this.isAgent;
    if (this.isAgent) {
      this.cwReverseForm.get('id_jugador')?.reset();
      this.cwReverseForm.get('id_agente')?.setValue(null);
    } else {
      this.cwReverseForm.get('id_agente')?.reset();
      this.cwReverseForm.get('id_jugador')?.setValue(null);
    }

    this.isAgent ? this.loadAgents() : this.loadPlayers();
    this.loadTypeTransactions();
  }

  //*NG-SELECT
  onScrollToEnd() {
    if (this.isAgent) {
      this.currentPageAgents++;
      if (this.isAgent) {
        this.loadAgents(this.criteria);
      } else {
        this.loadAgents();
      }
    } else {
      this.currentPagePlayers++;
      if (!this.isAgent) {
        this.loadPlayers(this.criteria);
      } else {
        this.loadPlayers();
      }
    }
    this.currentPageHolders++;
  }
  onSearch(term: string) {
    this.criteria = term;
    this.allAgentsData = [];
    this.allPlayersData = [];
    this.currentPageAgents = PAGE_SIZE_VALUE;
    this.currentPagePlayers = PAGE_SIZE_VALUE;
    if (this.isAgent) {
      this.loadAgents(term);
    } else {
      this.loadPlayers(term);
    }
  }
  onSelectionChange(event: any) {
    const controlName = this.isAgent ? 'id_agente' : 'id_jugador';
    const oppositeControlName = this.isAgent ? 'id_jugador' : 'id_agente';
    const selectedValue = this.cwReverseForm.get(controlName)?.value;

    // Establecer el valor del control seleccionado
    this.cwReverseForm.removeControl('id_personas');
    this.cwReverseForm.addControl(
      controlName,
      this.fb.control(selectedValue, Validators.required)
    );

    this.cwReverseForm.get(oppositeControlName)?.setValue(oppositeControlName === 'id_agente' ? event.id_usuario : DEFAULT_VALUE);
  }
  monitorDetailChanges() {
    this.cwReverseForm.get('detalle')?.valueChanges.subscribe((value) => {
      this.isDetailModified = value !== this.initialDetalle;
    });
  }

  //*FORM
  initForm(): void {
    this.cwReverseForm = this.fb.group({
      id: [null],
      tipo_transaccion: [null, Validators.required],
      monto: [null, Validators.required],
      id_agente: [null],
      id_jugador: [null],
      estado: [null],
      detalle: [null, Validators.required],
    });
  }
  updateData(data: any) {
    this.cwReverseForm.patchValue({
      id: data.id,
      tipo_transaccion: data.tipo_transaccion.toString(),
      monto: data.monto,
      id_agente: data.id_agente,
      id_jugador: data.id_jugador,
      estado: data.estado,
      detalle: data.detalle !== "null" ? data.detalle : ''
    })
    this.allAgentsData = [{
      label: this.cwData.agent,
      value: this.cwData.id_agente
    }]
    this.allPlayersData = [{
      label: this.cwData.player,
      value: this.cwData.id_jugador
    }]
    this.initialDetalle = data.detalle !== "null" ? data.detalle : '';
    data.id_agente !== DEFAULT_VALUE ? this.loadAgents() : this.loadPlayers();
  }
  get selectedFormControl(): FormControl {
    return this.isAgent ? this.cwReverseForm.get('id_agente') as FormControl : this.cwReverseForm.get('id_jugador') as FormControl;
  }
  onSubmit() {
    this.checkFormStatus();
    if (this.cwReverseForm.valid && this.isDetailModified) {
      let cwRequestData = this.cwReverseForm.value;

      this.isLoadingButton = true
      this.sendFormData({ id_pais: this.cwData.id_pais, ...cwRequestData });
    } else {
      this._toastrSvc.error(
        'Rellena todo el formulario o verifica el monto mínimo!',
        '',
        {
          progressBar: true,
          positionClass: 'custom-toastr-top',
        }
      );
      this.isLoadingButton = false;
    }
  }
  sendFormData(cwRequestData: any) {
    this._cwService.createReverse(cwRequestData).subscribe((response) => {
      this.isLoadingButton = false;
      this.closeModal();
      this._toastrSvc.success('Solicitud reversada correctamente!.', '', {
        progressBar: true,
        positionClass: 'custom-toastr-top',
      });
    }, (err) => {
      this.isLoadingButton = false;
    });
  }
  checkFormStatus() {
    const invalidFields = [];
    const formControls = this.cwReverseForm.controls;

    for (const name in formControls) {
      if (formControls[name].invalid) {
        invalidFields.push(name);
      }
    }
  }

}
