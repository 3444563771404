import { createReducer, on } from '@ngrx/store';
import { ScreenActions } from './screen.actions';
import { AVAILABLE_LANGUAGES } from '../../constants/constants';

const initialState = {
  isMobile: false,
};

export const screenReducer = createReducer(
  initialState,
  on(ScreenActions['[Screen]Change'], (state, payload) => {
    return { ...state, ...payload };
  })
);
