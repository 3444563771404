import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Socket, io } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket!: Socket;
  private messageRoomSubject: Subject<any> = new Subject<any>();
  private messageNSPSubject: Subject<any> = new Subject<any>();
  constructor() { }

  connectToNamespace(namespace: string, room?: string): void {
    // const url = 'http://192.168.100.5:3008/' + namespace;
    const url = 'https://socketv2.zgameslatam.com/' + namespace;
    // const url = 'http://localhost:3005/' + namespace;

    if (!this.socket || !this.socket.connected) {
      this.socket = io(url);

      this.socket.on('connect', () => {
        // console.log('Connected to namespace: ' + url);
      });
      this.socket.on('message', (msg) => {
        if (msg.origin) {
          this.messageNSPSubject.next(msg);
        } else {
          this.messageRoomSubject.next(msg);
        }
      });
      this.socket.on('disconnect', () => {
        console.log('Disconnected from namespace');
      });
    }
  }
  getMessageNSPSubject(): Subject<any> {
    return this.messageNSPSubject;
  }
  getMessageRoomSubject(): Subject<any> {
    return this.messageRoomSubject;
  }
  joinRoom(room: string): void {
    if (this.socket && this.socket.connected) {
      this.socket.emit('joinRoom', room);
      console.log('Joined room: ' + room);
    } else {
      setTimeout(() => {
        this.joinRoom(room);
      }, 500);
    }
  }

  leaveRoom(room: string): void {
    if (this.socket && this.socket.connected) {
      this.socket.emit('leaveRoom', room);
      console.log('Left room: ' + room);
    }
  }
  disconnectFromNamespace(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
