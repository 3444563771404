<div class="container-fluid basic_table">
  <div class="row">
    <div class="card">
      <div class="card-header">
        <div class="text-end">
          <button
            class="btn btn-sm btn-refresh me-3"
            [disabled]="isLoading"
            ngbTooltip="Recargar Tabla"
            tooltipClass="custom-tooltip"
            (click)="reloadTable()"
          >
            <i class="fas fa-sync-alt"></i>
          </button>
          <button
            class="btn btn-sm btn-head me-3"
            [disabled]="isLoading"
            (click)="exportToExcel()"
            ngbTooltip="Descargar Información en .xls"
            tooltipClass="custom-tooltip"
          >
            <i class="far fa-file-excel"></i>
          </button>
        </div>
      </div>
      @if (!(isMobile$ | async)) {
      <div class="card-body custom-datatable noscroll">
        <div class="table-responsive signal-table custom-datatable">
          <table class="table table-hover table-responsive uppercase-table">
            <thead class="mx-auto text-center">
              <tr>
                @for (column of columnsTable; track $index) {
                <th (click)="onSort(column.value)">
                  {{ column.name }}
                  <i
                    *ngIf="
                      sortColumn === column.value &&
                      sortDirection === SORT_DIRECTION.ASC
                    "
                    class="fas fa-sort-up"
                  ></i>
                  <i
                    *ngIf="
                      sortColumn === column.value &&
                      sortDirection === SORT_DIRECTION.DESC
                    "
                    class="fas fa-sort-down"
                  ></i>
                  <i
                    *ngIf="sortColumn !== column.value"
                    class="fas fa-sort"
                  ></i>
                </th>
                }
              </tr>
            </thead>
            @if (isLoading) {
            <tbody class="text-center">
              <tr>
                <td colspan="12">
                  <!-- Ajusta el colspan según el número de columnas -->
                  <div class="loader-box">
                    <div class="loader-16"></div>
                  </div>
                </td>
              </tr>
            </tbody>
            } @if (!isLoading) { @for (item of bonusData; track $index) {
            <tbody class="text-start">
              <tr>
                @if (!isMobile) { @if (!([2, 10].includes(rol))) {
                <td class="mx-auto text-start">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="btn-group">
                      @if(hasPermission(PAYMENTS_BUTTON_PERMISSIONS.MORE_ACCTIONS)
                      ||
                      hasPermission(PAYMENTS_BUTTON_PERMISSIONS.CANCELATE_PAYMENTS)
                      || hasPermission(PAYMENTS_BUTTON_PERMISSIONS.EDIT_SP) ) {
                      @if(hasPermission(PAYMENTS_BUTTON_PERMISSIONS.MORE_ACCTIONS)
                      ) {
                      <div ngbDropdown class="btn-group" role="group">
                        <button
                          type="button"
                          class="btn btn-sm mt-2 btn-head"
                          style="padding: 0.375rem 0.9rem"
                          id="dropdownBasic1"
                          ngbDropdownToggle
                        >
                          <i class="fas fa-exchange-alt"></i>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                          @for (state of states; track $index) {
                          <button
                            class="dropdown-item text-black"
                            (click)="
                              setStatus(
                                item.idabonos_pagos,
                                state.n_estado,
                                this.profile.idusuario
                              )
                            "
                          >
                            {{ state.estado }}
                          </button>
                          }
                        </div>
                      </div>
                      }
                      @if(hasPermission(PAYMENTS_BUTTON_PERMISSIONS.CANCELATE_PAYMENTS))
                      {
                      <button
                        class="btn btn-sm mt-2 btn-danger"
                        style="padding: 0.375rem 0.9rem"
                        [disabled]="item.id_estado === 5"
                        (click)="
                          setStatus(
                            item.idabonos_pagos,
                            5,
                            this.profile.idusuario
                          )
                        "
                        ngbTooltip="Cancelar Solicitud"
                        tooltipClass="custom-tooltip"
                      >
                        <i class="fa fa-ban"></i>
                      </button>
                      } @if(hasPermission(PAYMENTS_BUTTON_PERMISSIONS.EDIT_SP))
                      {
                      <button
                        class="btn btn-sm mt-2 btn-info"
                        style="padding: 0.375rem 0.9rem"
                        [disabled]="item.id_estado === 5"
                        (click)="openReverseCw(item)"
                        ngbTooltip="Editar Item"
                        tooltipClass="custom-tooltip"
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                      } } @else { No hay Acciones Disponibles }
                    </div>
                  </div>
                </td>
                } }
                <td class="mx-auto text-start">
                  <div class="d-flex justify-content-start align-items-center">
                    <span
                      [ngClass]="getStateClasses(item.id_estado).classes"
                      [innerHTML]="getStateClasses(item.id_estado).text"
                      class="status-span"
                    ></span>
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div
                    class="d-flex justify-content-start align-items-center ps-5"
                  >
                    <i class="fas fa-clock fa-sm me-2 informative-icon"></i>
                    <span>{{
                      item.fecha | date : "dd/MM/yyyy HH:mm:ss" : "UTC"
                    }}</span>
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div
                    class="d-flex justify-content-start align-items-center ps-5"
                  >
                    @if (item.agent?.usuario) {
                    <i class="fas fa-user-tie fa-sm me-2 sorti-2"></i>
                    <span>{{ item.agent?.usuario }}</span>
                    }@else{ @if (item.cuenta) {
                    <i class="fas fa-book fa-sm me-2 sorti-2"></i>
                    <span>{{ item.cuenta?.nombre_cuenta }}</span>
                    } }
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div
                    class="d-flex justify-content-start align-items-center ps-5"
                  >
                    <i class="fas fa-ticket-alt me-2 informative-icon"></i>
                    <span>{{ item.n_comprobante }}</span>
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div
                    class="d-flex justify-content-start align-items-center ps-4"
                  >
                    <strong>{{ item.monto }}</strong>
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div
                    class="d-flex justify-content-start align-items-center ps-4"
                    [ngClass]="getMovementClass(item.id_movimiento)"
                  >
                    <i
                      [ngClass]="getMovementIconClass(item.id_movimiento)"
                      class="me-2"
                    ></i>
                    {{ getMovementTypeLabel(item.id_movimiento) }}
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div class="ps-5">
                    <strong>{{ item.spBankPerIdBanco?.banco }}</strong>
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div class="ps-5-mod">
                    {{ item.spUserPerIdUsuTitular?.usuario }}
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div class="ps-3">
                    {{ item.updated_by?.usuario }}
                  </div>
                </td>
                <td
                  class="observation-column"
                  [ngbTooltip]="item.observacion"
                  tooltipClass="custom-tooltip"
                  container="body"
                >
                  {{ item.observacion }}
                </td>
                <td class="mx-auto text-start">
                  <div
                    class="d-flex justify-content-start align-items-center ps-5"
                  >
                    <span>{{
                      item.fecha_bancaria | date : "dd/MM/yyyy HH:mm:ss" : "UTC"
                    }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
            } }
          </table>
          @if(isLoading){
          <div
            class="d-flex justify-content-between align-items-center mb-3 me-3 ms-3"
          >
            <p class="card-text placeholder-glow text-end">
              <span class="placeholder col-7 mb-2"></span>
            </p>
            <p class="card-text placeholder-glow text-end">
              <span class="placeholder col-7 mb-2"></span>
            </p>
          </div>
          } @else {
          <div
            class="d-flex justify-content-between align-items-center mb-3 me-3 ms-3"
          >
            <div class="total-monto-container mt-3">
              Total en Dólares: <strong>{{ totalDolares }}</strong>
            </div>
            <div class="total-monto-container text-end mt-3">
              Total en Soles: <strong>{{ totalSoles }}</strong>
            </div>
          </div>
          }
        </div>
      </div>
      }@else {
      <div class="card-body">
        @if (isLoading) {
        <div class="loader-box">
          <div class="loader-16"></div>
        </div>
        }@else { @for (item of bonusData; track $index ) {
        <div class="card mb-3">
          <div class="card-body custom-card">
            <div
              class="card-more-button-holder"
              (click)="toggleMenu($index, item)"
            >
              <i class="fa-solid fa-ellipsis"></i>
            </div>
            <div class="card-expand-button" (click)="expandCard($index)">
              <i
                class="fa-solid"
                [ngClass]="
                  cardExpanded[$index] ? 'fa-chevron-up' : 'fa-chevron-down'
                "
              ></i>
            </div>
            <div
              class="card-c-holder"
              [ngClass]="{ openCard: cardExpanded[$index] }"
            >
              @for (column of columnsTable.slice(0, 7); track $index) { @if
              (column.value) {
              <div class="geneKeyValue card-list-col">
                <p
                  role="heading"
                  aria-level="1"
                  class="geneKeyValue__label ellipsis-text"
                >
                  {{ column.name }}
                </p>
                <br />
                @switch (column.value) { @case ('id_estado') {
                <p class="geneKeyValue__value ellipsis-text">
                  <span
                    [ngClass]="getStateClasses(item.id_estado).classes"
                    [innerHTML]="getStateClasses(item.id_estado).text"
                    class="status-span"
                  ></span>
                </p>
                }@case ('movementTypeLabel') {
                <p
                  class="geneKeyValue__value ellipsis-text"
                  [ngClass]="getMovementClass(item.id_movimiento)"
                >
                  <i
                    [ngClass]="getMovementIconClass(item.id_movimiento)"
                    class="me-2"
                  ></i>
                  <span>
                    {{ getMovementTypeLabel(item.id_movimiento) }}
                  </span>
                </p>
                } @case ('agent') { @if (item.agent?.usuario) {
                <p class="geneKeyValue__value ellipsis-text">
                  {{ item[column.value]?.usuario }}
                </p>
                }@else{
                <p class="geneKeyValue__value ellipsis-text">Sin Agente</p>
                } }@case ('spBankPerIdBanco') {
                <strong>
                  <p class="geneKeyValue__value ellipsis-text">
                    {{ item[column.value].banco }}
                  </p>
                </strong>
                }@default {
                <p class="geneKeyValue__value ellipsis-text">
                  {{ item[column.value] }}
                </p>
                } }
              </div>
              } } @if (cardExpanded[$index]) { @for (column of
              columnsTable.slice(7); track $index) { @if (column.value) {
              <div class="geneKeyValue card-list-col">
                <p
                  role="heading"
                  aria-level="1"
                  class="geneKeyValue__label ellipsis-text"
                >
                  {{ column.name }}
                </p>
                @switch (column.value) {@case ('spBankPerIdBanco') {
                <p class="geneKeyValue__value ellipsis-text">
                  {{ item[column.value].banco }}
                </p>
                }@case ('spUserPerIdUsuTitular') {
                <p class="geneKeyValue__value ellipsis-text">
                  {{ item[column.value].usuario }}
                </p>
                }@case ('updated_by') {
                <p class="geneKeyValue__value ellipsis-text">
                  {{ item[column.value].usuario }}
                </p>
                }@default {
                <p class="geneKeyValue__value ellipsis-text">
                  {{ item[column.value] }}
                </p>
                } }
              </div>
              } } }
            </div>
          </div>
        </div>
        } }
      </div>
      }
      <div class="card-footer">
        <shared-custom-pagination
          [collectionSize]="collectionSize"
          (pageChange)="onPageChange($event)"
          (pageSizeChange)="onLimitChange($event)"
        ></shared-custom-pagination>
      </div>
    </div>
  </div>
</div>

<!-- OBSERVATION -->
<div
  class="overlay"
  *ngIf="showMenu || showSubMenu"
  (click)="closeMenu()"
></div>
<div class="popover1" *ngIf="showMenu">
  <ul>
    <li
      (click)="openSubMenu(actualItem)"
      style="border-top: 1px solid var(--chart-border)"
    >
      <i class="fas fa-comment-dots informative-icon me-2"></i> Ver Observacion
    </li>
    <li (click)="closeMenu()" style="border-top: 1px solid var(--chart-border)">
      <i class="fas fa-times informative-icon me-2"></i> Close
    </li>
  </ul>
</div>
<div class="popover1" *ngIf="showSubMenu">
  <div class="submenu">
    <ul>
      <li (click)="closeSubMenu()">
        <i class="fas fa-reply informative-icon me-2"></i> Regresar
      </li>
    </ul>
    <div class="submenu-content">
      <div class="row" style="max-width: 98%">
        <div [innerHTML]="actualItem"></div>
      </div>
    </div>
  </div>
</div>
