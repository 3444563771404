import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-with-video',
  templateUrl: './page-with-video.component.html',
  styleUrls: ['./page-with-video.component.scss'],
})
export class PageWithVideoComponent implements OnInit {
  countdownDate: Date = new Date('Sep 30, 2024 00:00:00');
  countdownInterval: any;
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  constructor() {}

  ngOnInit(): void {
        this.startCountdown();
  }

  startCountdown() {
    this.countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = this.countdownDate.getTime() - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(this.countdownInterval);
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
      }
    }, 1000);
  }
}
