import { createActionGroup, props } from '@ngrx/store';
import { Config } from '../../interfaces/theme.interface';
interface ChangeSettingsPayload {
  settings?: { [key: string]: any };
  color?: { [key: string]: any };
}
export const ThemeActions = createActionGroup({
  source: 'Theme',
  events: {
    '[Theme] Change': props<ChangeSettingsPayload>(),
  },
});
