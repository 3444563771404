import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoNegativeNumberAndOnlyNumber]',
})
export class NoNegativeNumberAndOnlyNumberDirective {
  constructor() {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
      'Delete',
      'Control',
      'Meta',
      'Shift',
      '.',
    ];

    if (allowedKeys.includes(event.key) || !isNaN(Number(event.key))) {
      if (event.key === '.' && inputElement.value.includes('.')) {
        event.preventDefault();
      }
      if (
        inputElement.value.replace('.', '').length >= 8 &&
        !allowedKeys.includes(event.key)
      ) {
        event.preventDefault();
      }
      return;
    }

    event.preventDefault();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    if (!clipboardData) {
      return;
    }

    const pastedText = clipboardData.getData('text');

    if (!this.isValidInput(pastedText)) {
      event.preventDefault();
    }
  }

  private isValidInput(input: string): boolean {
    const regex = /^\d*\.?\d{0,2}$/;
    return regex.test(input) && input.replace('.', '').length <= 8;
  }
}
