import { Component, OnInit } from '@angular/core';
import { DynamicModalService } from '../../../../../../../core/services/dynamic-modal.service';
import { ChargesWithdrawalsRequestService } from '../../../../../../../core/services/charges-withdrawals-request.service';
import { UserService } from '../../../../../../../core/services/user.service';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import { environment } from '../../../../../../../../environments/environment';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ACCOUNT_TYPES, convertCountryCode, COUNTRY_CODE_MAP, DOCUMENT_TYPES, ECUADOR_BANK_CODES, getCurrencyByCountry, PAYOUT_STAGES, PERU_BANK_CODES, STATE_CLASSES, TRANSACTION_CLASSES, TRANSACTION_ICON_CLASSES, TRANSACTION_TYPES } from '../../../../../../../shared/data/suscriptions-payments/constants';
import { AlertService } from '../../../../../../../shared/services/alert.service';
import { formatAmount } from '../../../../../../../core/utils/format-numeric.utils';
import { ImageViewComponent } from '../../image-view/image-view.component';
import { BootstrapModalConfig } from '../../../../../../../core/interfaces/IBootstrapModal.interface';
import { TfaFormComponent } from '../../../../../../profile/pages/profile-detail/components/forms/tfa-form/tfa-form.component';
import { BootstrapModalService } from '../../../../../../../core/services/boostrap-modal.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { EncryptionService } from '../../../../../../../core/services/encryption.service';
import { ValidatePaymentRequest } from '../../../../../../../core/interfaces/payment.interfaz';
import { User } from '../../../../../../../core/interfaces/user.interface';
import { PaymentService } from '../../../../../../../core/services/payment.service';

@Component({
  selector: 'app-view-pay-out-detail',
  templateUrl: './view-pay-out-detail.component.html',
  styleUrl: './view-pay-out-detail.component.scss',
  providers: [DatePipe]
})
export class ViewPayOutDetailComponent implements OnInit {

  // LOADINGS
  public isLoading: boolean = true;
  public isLoadingButton: boolean = false;

  //REDUX
  public profile$ = this.store.select(selectAuth);
  public userData!: User;

  //MOBILE
  public isMobile: boolean = false;
  public token!: string;

  //IMG
  public baseUrl: string = environment.API_FILES;
  public previewImage: any

  public cwId: number = 0;
  public cwData: any;
  public transactionTypes = TRANSACTION_TYPES;
  public DEFAULT_VALUE: number = 0
  public isPaymentButtonDisabled: boolean = false;

  constructor(
    private _dynamicModal: DynamicModalService,
    private _cwService: ChargesWithdrawalsRequestService,
    private _userService: UserService,
    private store: Store,
    private activedRouter: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private activeModal: NgbActiveModal,
    private alertService: AlertService,
    private _bsService: BootstrapModalService,
    private toastr: ToastrService,
    private encryptionService: EncryptionService,
    private paymentService: PaymentService
  ) {
    this.cwId = this._dynamicModal.getModalData();
  }

  ngOnInit(): void {
    const fullUrl = this.router.url;

    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      this.loadUserProfileForMobile();
    } else {
      this.getUserInfo();
    }
    this.getCwInfo();
  }

  loadUserProfileForMobile(): void {
    this.activedRouter.params.subscribe((params) => {
      this.token = params['token'];
      this._userService.getUserByTokenApp(this.token).subscribe({
        next: (res) => {
          if (res.data.idusuario) {
            this._userService.getUser(res.data.idusuario).subscribe({
              next: (res) => {
                this.userData = res.data;
              }
            });
          }
        }
      });
    });
  }

  getCwInfo() {
    this.isLoading = true;
    this._cwService.getCw(this.cwId).subscribe((res) => {
      this.cwData = res.data
      let firstName: string;
      let lastName: string;
      ({ firstName, lastName } = this.splitName(this.cwData.nombre_titular));
      this.cwData.creado = this.formatDate(this.cwData.creado);
      this.cwData.monto = this.formatAmount(this.cwData.monto, this.cwData.id_pais);
      this.isLoading = false;
    })
  }

  getUserInfo() {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario) {
        this.userData = profile;
        this.previewImage = this.formatUrl(this.userData?.foto_selfie);
      }
    });
  }

  getAccountTypeLabel(tipoCuenta: number): string {
    if (tipoCuenta === ACCOUNT_TYPES.CURRENT) {
      return 'Corriente';
    } else if (tipoCuenta === ACCOUNT_TYPES.SAVINGS) {
      return 'Ahorros';
    } else {
      return 'Desconocido';
    }
  }

  //FORMATTERS
  formatAmount(monto: number, idPais: number): string {
    return this.formatAmountWithCountry(monto, idPais);
  }

  formatDate(fecha: string): string {
    const fechaFormateada = this.datePipe.transform(fecha, 'dd/MM/yyyy');
    return fechaFormateada ?? '';
  }

  splitName(fullName: string | undefined): { firstName: string, lastName: string } {
    if (!fullName) {
      return { firstName: '', lastName: '' };
    }

    const nameParts = fullName.trim().split(' ');

    if (nameParts.length === 1) {
      return { firstName: nameParts[0], lastName: '' };
    }

    const lastName = nameParts.pop()!;
    const firstName = nameParts.join(' ');

    return { firstName, lastName };
  }


  formatAmountWithCountry(amount: number, countryId: number): string {
    return formatAmount(Number(amount), countryId === 1 ? 'EC' : 'PE');
  }

  //IMG
  formatUrl(url: string) {
    return this.baseUrl + '/' + url.replace(/ /g, '%20').replace(/\\/g, '/');
  }

  //?MODAL
  openImageModal(cwId: any) {
    this._dynamicModal.openModal(
      ImageViewComponent,
      'Comprobante',
      'md',
      true,
      cwId
    );
  }

  closeModal() {
    this.activeModal.close();
  }

  //STATE
  getStateClasses(state: number) {
    return STATE_CLASSES[state] || { text: '', classes: {} };
  }

  //TRANSACTION TYPE
  getTransactionIconClass(type: number): string {
    return TRANSACTION_ICON_CLASSES[type] || '';
  }

  getTransactionClass(type: number): string {
    return TRANSACTION_CLASSES[type] || '';
  }

  //PAYMENT
  createValidatePaymentRequest(cwData: any, firstName: string, lastName: string): ValidatePaymentRequest {
    const amountString = cwData?.monto.replace(/[^0-9.]/g, '');
    const amount = parseFloat(amountString);
    const countryCode = convertCountryCode(cwData.pais.codigo);
    const currency = getCurrencyByCountry(countryCode);
    const customerId = cwData.id_agente !== this.DEFAULT_VALUE ? cwData.id_agente : cwData.id_jugador;
    const userName = cwData.id_agente !== this.DEFAULT_VALUE ? cwData.agent : cwData.player;
    let request = {
      country: countryCode,
      amount: amount,
      currency: currency,
      orderId: cwData.id,
      beneficiary: {
        name: firstName,
        lastName: lastName,
        document: {
          type: DOCUMENT_TYPES.ID,
          number: cwData.identificacion
        },
        customerId: customerId,
        userName: userName
      },
      destination: {
        bankAccount: {
          bankCode: this.getBankCode(cwData.banco.banco, countryCode),
          accountType: cwData.tipo_cuenta,
          accountNumber: cwData.numero_cuenta
        }
      }
    };

    return request
  }

  getBankCode(bankName: string, countryCode: string): string {
    if (countryCode === COUNTRY_CODE_MAP.EC) {
      return ECUADOR_BANK_CODES[bankName];
    } else if (countryCode === COUNTRY_CODE_MAP.PE) {
      return PERU_BANK_CODES[bankName];
    } else {
      return '000';
    }
  }

  validatePayment() {
    const minAmount = 5;
    const maxAmount = 1000;
    const amountString = this.cwData?.monto.replace(/[^0-9.]/g, '');
    const amount = parseFloat(amountString);

    if (this.userData?.TFA !== 1) {
      this.alertService.showHtmlAlert(
        'Autenticación de dos factores requerida',
        'Para realizar el pago, activa la TFA desde los ajustes de usuario.',
        'Ir a Ajustes'
      ).then((result) => {
        if (result.isConfirmed) {
          this.activeModal.dismiss();
          this.router.navigate(['/user/profile']);
        }
      });
      return;
    }

    if (amount < minAmount || amount > maxAmount) {
      this.alertService.showErrorAlert(
        'Monto no válido',
        'El monto mínimo es de $5 y el máximo de $1000. Si excede el límite, divida la transacción.'
      );
      return;
    }
    this.processPayment();
  }

  processPayment() {
    this.alertService.showTextInputAlert(
      'Verificación de dos factores',
      'Ingrese el código de verificación',
      'Ingrese el código aquí...',
      'Verificar',
      'Cancelar'
    ).then((result) => {
      if (result.isConfirmed) {
        const verificationCode = result.value;
        this.verifyCode(verificationCode);
      } else {
        this.alertService.showErrorAlert(
          'Verificación necesaria',
          'El código de verificación es obligatorio para realizar pagos.'
        );
      }
    });
  }

  verifyCode(code: string) {
    this.profile$.subscribe((profile) => {
      const userId = profile.idusuario ? profile.idusuario : '';
      if (!userId) {
        this.alertService.showErrorAlert('Error', 'Usuario no identificado.');
        return;
      }

      this._userService.verifyLogin(userId, code).subscribe({
        next: (response) => {
          this._userService.getUser(response.data.idusuario).subscribe({
            next: async () => {
              // this.activeModal.dismiss();
              const { firstName, lastName } = this.splitName(this.cwData.nombre_titular);
              const request = this.createValidatePaymentRequest(this.cwData, firstName, lastName);
              const encryptedData = this.encryptionService.encrypt(JSON.stringify(request));
              const encryptedKey = this.encryptionService.encrypt(JSON.stringify({ company: environment.COMPANY }));
              this.paymentService.generateApiKey(encryptedKey).subscribe((res) => {
                const apiKey = res.data;
                if (apiKey) {
                  this.paymentService.createWithdrawal(encryptedData, apiKey).subscribe((withdrawalRes) => {
                    if (withdrawalRes.data.output.stage === PAYOUT_STAGES.INPROGRESS) {
                      this.isPaymentButtonDisabled = true;
                    }
                  }, (err) => {
                    console.error('Error al crear el retiro:', err);
                  });
                }
              }, (err) => {
                console.error('Error al generar la clave:', err);
              });
            },
            error: (err) => {
              console.error('Error al obtener el usuario:', err);
            }
          });
        },
        error: (error) => {
          this.alertService.showErrorAlert('Código incorrecto', 'El código de verificación es incorrecto.');
        }
      });
    });
  }


}
