// TABLE
export const columnsTableAgents = [
  {
    name: 'Acciones',
    value: 'acciones',
  },
  {
    name: 'Id Agente',
    value: 'externalId',
  },
  {
    name: 'Username',
    value: 'username',
  },
  {
    name: 'Celular',
    value: 'cellphone',
  },
  {
    name: 'Estado',
    value: 'activate',
  },
  {
    name: 'Tipo Usuario',
    value: 'userType',
  },
  {
    name: 'Porcentaje',
    value: 'ownPercentage',
  },
  {
    name: 'Arrastre',
    value: 'dragType',
  },
  {
    name: 'Porcentaje Fijo',
    value: 'fixedPercentage',
  },
  {
    name: 'Referido Por',
    value: 'userRef',
  },
  {
    name: 'Nombres',
    value: 'names',
  },
  {
    name: 'Identificación',
    value: 'identification',
  },
  {
    name: 'País',
    value: 'country',
  },
  {
    name: 'Provincia',
    value: 'province',
  },
  {
    name: 'Ciudad',
    value: 'city',
  },

  {
    name: 'Grupo Bancario',
    value: 'groupBankAccount',
  },
  {
    name: 'Creado',
    value: 'creationDate',
  },
  {
    name: 'Observación',
    value: 'observation',
  },
  {
    name: 'Usuario Crea',
    value: 'userAdd',
  },
  {
    name: 'Usuario Edita',
    value: 'userSet',
  },
];
export const yesNoOptions = [
  { value: '1', label: 'Si' },
  { value: '0', label: 'No' },
];
export const dragOptions = [
  { value: '1', label: 'Arrastre Normal' },
  { value: '0', label: 'Sin Arrastre' },
  { value: '2', label: 'Arrastre V2' },
];
export const stateAgents = [
  { value: '0', label: 'Bloqueado' },
  { value: '1', label: 'Activo' },
  { value: '2', label: 'En Espera' },
];
export const percentageOptionsAgents = [
  { value: '10', label: '10% (Agente Afiliador)' },
  { value: '20', label: '20% (Agente Afiliador)' },
  { value: '25', label: '25% (Agente Afiliador)' },
  { value: '30', label: '30%' },
  { value: '35', label: '35%' },
  { value: '40', label: '40%' },
  { value: '45', label: '45%' },
  { value: '50', label: '50%' },
  { value: '55', label: '55% (Caso Especial)' },
];
export const tipoCierreOptionsAgents = [
  { value: '0', label: 'Normal NGR' },
  { value: '2', label: 'Normal Apostado' },
  { value: '1', label: 'Especial' },
];

export const actividadAgenteOptionsAgents = [
  { value: '3', label: 'Nuevo' },
  { value: '4', label: 'Activo' },
  { value: '5', label: 'Muy Activo' },
  { value: '2', label: 'Poco Activo' },
  { value: '1', label: 'Inactivo' },
];
// poner siempre 2 decimales si quiere anadir mas porcentajes
export const percentageRefAgents = [
  { value: '0.50', label: '0.5%' },
  { value: '1.00', label: '1%' },
  { value: '2.00', label: '2%' },
  { value: '3.00', label: '3%' },
  { value: '5.00', label: '5%' },
  { value: '7.00', label: '7%' },
  { value: '8.00', label: '8%' },
  { value: '10.00', label: '10%' },
];
export const typeAgents = [
  { value: '3', label: 'Agente' },
  { value: '4', label: 'Agente Afiliador' },
];
export const typeAgentsSelect = [
  { value: 3, label: 'Agente', roleId: 4, userType: 'Agente' },
  {
    value: 4,
    label: 'Agente Afiliador',
    roleId: 5,
    userType: 'Agente Afiliador',
  },
];
export const typeAdminSelect = [
  { value: '1', label: 'Administrador', userType: 'Administrador' },
  { value: '0', label: 'Master', userType: 'Master' },
];
export const columnsTableHolders = [
  {
    name: 'Acciones',
    value: 'acciones',
  },
  {
    name: 'Identificación',
    value: 'identification',
  },
  {
    name: 'Nombres',
    value: 'names',
  },
  {
    name: 'Estado',
    value: 'activate',
  },
  {
    name: 'Celular',
    value: 'cellphone',
  },
  {
    name: 'Username',
    value: 'username',
  },
  {
    name: 'Tipo Usuario',
    value: 'userType',
  },
  {
    name: 'Creado',
    value: 'creationDate',
  },
  {
    name: 'País',
    value: 'country',
  },
  {
    name: 'Provincia',
    value: 'province',
  },
  {
    name: 'Ciudad',
    value: 'city',
  },
  {
    name: 'Observación',
    value: 'observation',
  },
  {
    name: 'Usuario Crea',
    value: 'userAdd',
  },
  {
    name: 'Usuario Edita',
    value: 'userSet',
  },
  {
    name: 'Correo (Empresa)',
    value: 'corporateMail',
  },
  {
    name: 'Celular (Empresa)',
    value: 'corporatePhone',
  },
];
export const stateHolders = [
  { value: '0', label: 'Bloqueado' },
  { value: '1', label: 'Activo' },
  { value: '2', label: 'En Espera' },
];
export const typeHolders = [
  { value: '3', label: 'Agente' },
  { value: '4', label: 'Agente Afiliador' },
];

export const columnsTableAdministratives = [
  {
    name: 'Acciones',
    value: 'acciones',
  },
  {
    name: 'Identificación',
    value: 'identification',
  },
  {
    name: 'Rol',
    value: 'roleName',
  },
  {
    name: 'Nombres',
    value: 'names',
  },
  {
    name: 'Estado',
    value: 'activate',
  },
  {
    name: 'Celular',
    value: 'cellphone',
  },
  {
    name: 'Username',
    value: 'username',
  },
  {
    name: 'Tipo Usuario',
    value: 'userType',
  },
  {
    name: 'Creado',
    value: 'creationDate',
  },
  {
    name: 'País',
    value: 'country',
  },
  {
    name: 'Provincia',
    value: 'province',
  },
  {
    name: 'Ciudad',
    value: 'city',
  },
  {
    name: 'Grupo De Pago Sueldo',
    value: 'salaryGroup',
  },
  {
    name: 'Grupo De Pago Sueldo Quincena',
    value: 'salaryGroupWeekenly',
  },
  {
    name: 'S. Quincena',
    value: 'fortnightSalary',
  },
  {
    name: 'S. Fin De Mes',
    value: 'endMonthSalary',
  },
  {
    name: 'Tipo De Sueldo',
    value: 'salaryType',
  },
  {
    name: 'Observación',
    value: 'observation',
  },
  {
    name: 'Usuario Crea',
    value: 'userAdd',
  },
  {
    name: 'Usuario Edita',
    value: 'userSet',
  },
];

export const stateAdministratives = [
  { value: '0', label: 'Bloqueado' },
  { value: '1', label: 'Activo' },
  { value: '2', label: 'En Espera' },
];
export const typeAdministratives = [
  { value: '0', label: 'Master' },
  { value: '1', label: 'Administrador' },
];
export const typeSalary = [
  { value: '1', label: 'Solo Fin De Mes' },
  { value: '2', label: 'Fin De Mes Y Quincena' },
];
export const groupsSalary = [
  { value: 1, label: 'Grupo 1' },
  { value: 2, label: 'Grupo 2' },
  { value: 3, label: 'Grupo 3' },
  { value: 4, label: 'Grupo 4' },
  { value: 5, label: 'Grupo 5' },
  { value: 6, label: 'Grupo 6' },
  { value: 7, label: 'Grupo 7' },
  { value: 8, label: 'Grupo 8' },
  { value: 9, label: 'Grupo 9' },
  { value: 10, label: 'Grupo 10' },
];
export const groupsSalaryWeekenly = [
  { value: 1, label: 'Grupo 15' },
  { value: 2, label: 'Grupo 16' },
  { value: 3, label: 'Grupo 17' },
];

export const USERS_TYPES = {
  PLAYER_BD: 4,
  AGENTS_BD: 3,
  PLAYER: 'P',
  AGENTS: 'A',
};

export const SALARY_TYPES_ENABLE_AND_DISABLE = {
  END_OF_MONTH_ONLY: '1',
  END_OF_MONTH_AND_FORTNIGHT: '2',
};
export const ACTIVATION_TYPES = {
  LOCKED: 0,
  ACTIVE: 1,
  WAITING: 2,
};

export const USER_TYPES = {
  MASTER: 0,
  ADMINISTRATIVE: 1,
  HOLDER: 2,
  AGENT: 3,
  AFFILIATE: 4,
};

export const CLOSURE_TYPES = {
  SPECIAL: 1,
  NORMAL_NGR: 2,
  NORMAL_BET: 3,
};

export const HAS_UNLIMITED_BONUS = {
  TRUE: 1,
  FALSE: 0,
};

export const HAS_AUTHORIZE_LOAN = {
  TRUE: 1,
  FALSE: 0,
};

export const HAS_FIXED_PERCENTAGE = {
  TRUE: 1,
  FALSE: 0,
};

export const DRAG_TYPES = {
  TRUE: 1,
  FALSE: 0,
  DRAG_V2: 2,
};
export const SALARY_TYPES = {
  END_OF_MONTH_ONLY: 1,
  END_OF_MONTH_AND_FORTNIGHT: 2,
};

// PLAYERS
export const COLUMNS_PLAYERS = [
  { name: 'Acciones' },
  { name: 'ID Jugador' },
  { name: 'Estado jugador' },
  { name: 'ID Jugador (Agents)' },
  { name: 'ID Agente (Agents)' },
  { name: 'Usuario Jugador (Agents)' },
  { name: 'Usuario Agente (Agents)' },
  { name: 'Usuario Sorti' },
  { name: 'País' },
  { name: 'Nombres' },
  { name: 'Apellidos' },
  { name: 'Identificación' },
  { name: 'Correo' },
  { name: 'Teléfono' },
  { name: 'Creado' },
  { name: 'Actualizado' },
  { name: 'Mensaje Bot' },
  { name: 'Monto Carga 3M' },
  { name: 'Monto Retiro 3M' },
  { name: 'Código Referencia' },
];

export interface StateClass {
  text: string;
  classes: { [key: string]: boolean };
}

export const STATE_PLAYERS: { [key: number]: StateClass } = {
  0: { text: 'Pendiente', classes: { 'bg-pending': true } },
  1: { text: 'Creado', classes: { 'bg-create': true } },
  2: { text: 'Contactado', classes: { 'bg-contacted': true } },
};

export const STATE_PLAYERS_TEXT = {
  PENDING: 'Pendiente',
  CREATED: 'Creado',
  CONTACTED: 'Contactado',
};

export const STATE_PLAYERS_VALUES = {
  PENDING: 0,
  CREATED: 1,
  CONTACTED: 2,
};

export const ATC_NUMBER = 593992786951;
