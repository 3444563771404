import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  ApiPathEnum,
  CodeBonusEndpointEnum,
} from '../constants/endpoints.enum';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class CodeBonusService {
  public API_URL_CODE_BONUS = `${environment.API_URL}${ApiPathEnum.CodeBonus}`;
  /*  public API_URL_ACCOUNTING_ACCOUNT = `${environment.API_URL}${ApiPathEnum.AccountingAccount}`;
  public API_URL_COUNTRY = `${environment.API_URL}${ApiPathEnum.Country}`;
  public API_URL_FINANCIAL = `${environment.API_URL}${ApiPathEnum.Financial}`; */

  constructor(private http: HttpClient) {}

  getAllCodeBonus(page: number, limit: number, filters?: any): Observable<any> {
    const url = `${this.API_URL_CODE_BONUS}${CodeBonusEndpointEnum.GetAllBonusCode}`;
    const params = { page: page.toString(), limit: limit.toString() };
    return this.http.post<any>(url, { params, filters });
  }

  updateStateBonusCode(request: any): Observable<any> {
    const localDate = moment.tz('America/Guayaquil');
    const formattedDate =
      localDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + '+00:00';
    const data = {
      ...request,
      fecha_modificacion: formattedDate,
    };
    const url = `${this.API_URL_CODE_BONUS}${CodeBonusEndpointEnum.UpdateStateBonusCode}`;
    return this.http.put<any>(url, data);
  }
  createCodeBonus(request: any): Observable<any> {
    const localDate = moment.tz('America/Guayaquil');
    const formattedDate =
      localDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + '+00:00';
    const data = {
      ...request,
      fecha: formattedDate,
      fecha_creacion: formattedDate,
      fecha_modificacion: formattedDate,
    };
    const url = `${this.API_URL_CODE_BONUS}${CodeBonusEndpointEnum.CreateBonusCodes}`;
    return this.http.post<any>(url, data);
  }
  getAllTypeBonus(): Observable<any> {
    const url = `${this.API_URL_CODE_BONUS}${CodeBonusEndpointEnum.GetAllTypeBonus}`;
    return this.http.get<any>(url);
  }
  getCodeBonusByAgent(
    agentId: number,
    page: number,
    limit: number,
    filters?: any
  ): Observable<any> {
    const params = {
      page,
      limit,
      agentId,
    };
    return this.http.post<any>(`${this.API_URL_CODE_BONUS}/get-by-agent`, {
      params,
      filters,
    });
  }
  getAgentBalance(request: any): Observable<any> {
    const url = `${this.API_URL_CODE_BONUS}${CodeBonusEndpointEnum.GetAgentBalance}`;
    return this.http.post<any>(url, request);
  }
}
