<div class="container-fluid basic_table">
<div class="row">
  <div class="col-xxl-12 col-xl-12 box-cSol-12">
    <div class="col-sm-12">
      <div class="row mt-3">
        <div class="col-12">
          <div class="mb-sm-0">
            <app-list-sp-table-filter></app-list-sp-table-filter>
          </div>
        </div>
      </div>
      <app-list-sp-table></app-list-sp-table>
    </div>
  </div>
</div>
</div>
