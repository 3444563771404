import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  ApiPathEnum,
  HolderBankEndpointEnum,
} from '../constants/endpoints.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BankAccountsResponse,
  Filters,
  HolderAccountResponse,
} from '../../pages/accounting/banking/interface/bank-accounts.interface';
import { ApiResponse } from '../interfaces/api-response.inteface';

@Injectable({
  providedIn: 'root',
})
export class HolderAccountBankService {
  public API_URL = `${environment.API_URL}${ApiPathEnum.HolderAccountHolder}`;

  constructor(private http: HttpClient) {}

  getAllHoldersAccountData(
    page: number,
    limit: number,
    countryId: number,
    bankId: number,
    searchTerm?: string
  ): Observable<HolderAccountResponse> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    if (bankId) {
      params = params.set('bankId', bankId);
    }
    if (countryId) {
      params = params.set('countryId', countryId);
    }
    return this.http.get<HolderAccountResponse>(
      `${this.API_URL}${HolderBankEndpointEnum.GetHoldersAccountBankData}`,
      { params }
    );
  }
  public getAllBankAccounts(
    page: number,
    limit: number,
    filters?: Filters
  ): Observable<ApiResponse<BankAccountsResponse>> {
    const url = `${this.API_URL}${HolderBankEndpointEnum.GetAllBankAccounts}`;
    const params = { page: page.toString(), limit: limit.toString() };
    return this.http.post<ApiResponse<BankAccountsResponse>>(url, {
      params,
      filters,
    });
  }

  updateSatusBankAccounts(data: {
    bankHolderId: number;
    active: number;
  }): Observable<ApiResponse<BankAccountsResponse>> {
    return this.http.put<ApiResponse<BankAccountsResponse>>(
      `${this.API_URL}/update-bank-account-active`,
      data
    );
  }
  createBankAccounts(
    formData: FormData
  ): Observable<ApiResponse<BankAccountsResponse>> {
    return this.http.post<ApiResponse<BankAccountsResponse>>(
      `${this.API_URL}/create-bank-account`,
      formData
    );
  }

  updateBankAccounts(
    formData: FormData
  ): Observable<ApiResponse<BankAccountsResponse>> {
    return this.http.patch<ApiResponse<BankAccountsResponse>>(
      `${this.API_URL}/update-bank-account`,
      formData
    );
  }
}
