import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ExcelExportService {

  constructor() { }

  exportToExcel(data: any[], fileName: string, headers: string[]) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Cargas y Retiros Sol');

    worksheet.getRow(1).values = headers;

    const startRow = 2;
    data.forEach((item: any, index: number) => {
      worksheet.getRow(startRow + index).values = Object.values(item);
    });

    worksheet.columns.forEach(column => {
      if (column !== undefined && column.eachCell !== undefined) {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, cell => {
          const cellValue = cell.value ? cell.value.toString() : '';
          maxLength = Math.max(maxLength, cellValue.length);
        });
        column.width = maxLength + 2;
      }
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
}
