<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <!-- Renderizar campos del formulario -->
  @for (object of data; track $index) { @if (object.style==="row") {
  <div class="col-sm-12 col-lg-12 col-xl-12">
    <div class="row mb-3">
      @for (field of object.formData; track $index) {<!-- aqui modificar el for para que lo haga dependiendo del columnsPerRow-->
      <!-- aqui preguntar que cuantas columnsPerRow-->
      @if (object.columnsPerRow) {
      <div [ngClass]="getColumnClassRow(object.columnsPerRow)">
        <!-- este es para 4-->
        <label for="field.id" class="col-form-label">{{ field.label }}</label>
        @switch (field.control) { @case ('input') { @if (field.type==='text') {
        <input
          type="text"
          placeholder="type a {{ field.label }}"
          class="form-control custom-input custom-input mb-2"
          [formControlName]="field.id"
          (keyup)="updateFilter()"
        />
        } @if(field.type === 'number'){
        <input
          type="number"
          placeholder="type a {{ field.label }}"
          class="form-control custom-input custom-input mb-2"
          [formControlName]="field.id"
          inputmode="numeric"
          pattern="[0-9]*"
          (keyup)="updateFilter()"
        />
        }
        <!-- aqui van los tipos de inputs -->
        } @case ('select') { @if (field.options) {
        <ng-select
          class="mb-2"
          placeholder="Select one {{ field.label }}"
          [items]="fieldOptions[field.id]"
          bindLabel="name"
          [formControlName]="field.id"
          (change)="updateFilter()"
          (scrollToEnd)="onScroll()"
        ></ng-select>
        } } @case('dateInput'){
        <div id="reportrange" class="form-control custom-input mb-2">
          <i class="fa fa-calendar"></i>&nbsp;&nbsp;
          <span>{{ dateSelected }} </span>
        </div>
        } @default {
        <input
          type="text"
          class="form-control custom-input custom-input mb-2"
          [formControlName]="field.id"
          (keyup)="updateFilter()"
        />
        } }
      </div>
      } }
    </div>
  </div>
  }@else{
  <div class="row mb-3">
    @for (item of getColumnCount(object); track $index) { @if
    (object.rowsPerColumn) {
    <div
      class="col-sm-12 col-md-{{
        12 / (object.formData.length / object.rowsPerColumn)
      }}"
    >
      @for (field of object.formData|
      slice:$index*object.rowsPerColumn:($index+1)*object.rowsPerColumn; track
      $index) { @switch (field.control) { @case ('input') { @if
      (field.type==='text') {
      <input
        type="text"
        placeholder="type a {{ field.label }}"
        class="form-control custom-input custom-input mb-2"
        [formControlName]="field.id"
        (keyup)="updateFilter()"
      />
      }
      <!-- aqui van los tipos de inputs -->
      } @case ('select') { @if (field.options) {
      <ng-select
        class="mb-2"
        placeholder="Select one {{ field.label }}"
        [items]="field.options"
        (change)="updateFilter()"
        [formControlName]="field.id"
        bindLabel="name"
      ></ng-select>
      } } @case('dateInput'){
      <div id="reportrange" class="form-control custom-input mb-2">
        <i class="fa fa-calendar"></i>&nbsp;&nbsp;
        <span>{{ dateSelected }} </span>
      </div>
      } @default {
      <input
        type="text"
        class="form-control custom-input custom-input mb-2"
        [formControlName]="field.id"
        (keyup)="updateFilter()"
      />
      } } }
    </div>
    } }
  </div>
  } }
</form>
<ng-template #fields let-field>
  @switch (field.control) { @case ('input') { @if (field.type==='text') {
  <input
    type="text"
    placeholder="type a {{ field.label }}"
    class="form-control custom-input custom-input mb-2"
    [formControlName]="field.id"
  />
  }
  <!-- aqui van los tipos de inputs -->
  } @case ('select') { @if (field.options) {
  <ng-select
    class="mb-2"
    placeholder="Select one {{ field.label }}"
    [items]="field.options"
    bindLabel="name"
  ></ng-select>
  } } @case('dateInput'){
  <div id="reportrange" class="form-control custom-input mb-2">
    <i class="fa fa-calendar"></i>&nbsp;&nbsp;
    <span>{{ dateSelected }} </span>
  </div>
  } @default {
  <input
    type="text"
    class="form-control custom-input custom-input mb-2"
    [formControlName]="field.id"
  />
  } }
</ng-template>
