import { Component, OnInit } from '@angular/core';
import { EventService } from '../../../../shared/services/event.service';
import { style } from '@angular/animations';
import { SocketService } from '../../../../core/services/socket.service';

@Component({
  selector: 'app-request-cw',
  templateUrl: './request-cw.component.html',
  styleUrl: './request-cw.component.scss',
})
export class RequestCwComponent implements OnInit {
  public pendingCount: number = 0;
  public processedCount: number = 0;
  public chargeNotIncomeCount: number = 0;
  public correctionCount: number = 0;
  public botProblemCount: number = 0;
  public reverseCount: number = 0;
  public totalCount: number = 0;
  public loadingSate!: boolean;
  public pending = {};
  public chargeNotIncome = {};
  public processed = {};
  public botProblem = {};
  public correction = {};
  public total = {};
  public progress = {};
  public data: any;

  constructor(private eventService: EventService) { }

  ngOnInit(): void {
    this.loadData();
  }

  updatePending() {
    this.pending = {
      title: 'Pendiente',
      colorClass: 'success',
      icon: 'fas fa-clock',
      value: this.pendingCount,
    };
  }

  updateProcessed() {
    this.processed = {
      title: 'Procesado',
      colorClass: 'success',
      icon: 'fas fa-ticket-alt',
      value: this.processedCount,
    };
  }

  updateChargeNotIncome() {
    this.chargeNotIncome = {
      title: 'Cargado No Ingresado',
      colorClass: 'success',
      icon: 'fas fa-check-square',
      value: this.chargeNotIncomeCount,
    };
  }

  updatebotProblem() {
    this.processed = {
      title: 'Problema Bot',
      colorClass: 'success',
      icon: 'fas fa-check-double',
      value: this.botProblemCount,
    };
  }

  updateCorrection() {
    this.correction = {
      title: 'Correción',
      colorClass: 'success',
      icon: 'fas fa-check-double',
      value: this.correctionCount,
    };
  }

  updateTotal() {
    this.total = {
      title: 'Total de Solicitudes',
      colorClass: 'success',
      icon: 'fas fa-equals',
      value: this.totalCount,
    };
  }

  loadData() {
    this.data = [
      {
        style: 'row',
        columnsPerRow: 3,
        formData: [
          {
            label: 'Comprobante',
            id: 'comprobante',
            control: 'input',
            type: 'text',
          },
          { label: 'Monto', id: 'monto', control: 'input', type: 'number' },
          { label: 'Agente', id: 'agent', control: 'select', options: [1, 2] },
          {
            label: 'Transferencia Interbancaria',
            id: 'tipo_transferencia',
            control: 'select',
            options: [1, 2],
          },
          {
            label: 'Tipo transacción',
            id: 'tipo_transferencia',
            control: 'select',
            options: [1, 2],
          },
          { label: 'Estado', id: 'estado', control: 'select', options: [1, 2] },
          { label: 'Creado', id: 'dateRange', control: 'dateInput' },
          { label: 'Actualizado', id: 'dateRange', control: 'dateInput' },
          {
            label: 'Banco',
            id: 'banco',
            control: 'select',
            options: [1, 2],
          },
          {
            label: 'Identifiación',
            id: 'identifiaccion',
            control: 'input',
            type: 'text',
          },
          {
            label: 'Beneficiario',
            id: 'beneficiario',
            control: 'input',
            type: 'text',
          },
          {
            label: 'N° de cuenta',
            id: 'numero_cuenta',
            control: 'input',
            type: 'text',
          },
          {
            label: 'Creado por',
            id: 'crado_por',
            control: 'select',
            options: [1, 2],
          },
          {
            label: 'Depositado',
            id: 'depositado',
            control: 'select',
            options: [1, 2],
          },
          {
            label: 'Titular',
            id: 'titular',
            control: 'select',
            options: [1, 2],
          },
        ],
      },
    ];
  }

  onTotalPendingChange(pendingtotal: number) {
    this.pendingCount = pendingtotal;
    this.updatePending();
    this.emitEvent({ action: 'totalPendingChange', value: pendingtotal });
  }

  onTotalProcessedChange(processedCountTotal: number) {
    this.processedCount = processedCountTotal;
    this.updateProcessed();
    this.emitEvent({
      action: 'totalProcessedChange',
      value: processedCountTotal,
    });
  }

  onTotalChargeNotIncomeChange(chargeNotIncomeCountTotal: number) {
    this.chargeNotIncomeCount = chargeNotIncomeCountTotal;
    this.updateChargeNotIncome();
    this.emitEvent({
      action: 'totalChargeNotIncomeChange',
      value: chargeNotIncomeCountTotal,
    });
  }

  onTotalCorrectionChange(correctionCountTotal: number) {
    this.correctionCount = correctionCountTotal;
    this.updateCorrection();
    this.emitEvent({
      action: 'totalCorrectionChange',
      value: correctionCountTotal,
    });
  }

  onTotalBotProblemChange(botProblemCountTotal: number) {
    this.botProblemCount = botProblemCountTotal;
    this.updateCorrection();
    this.emitEvent({
      action: 'totalBotProblemChange',
      value: botProblemCountTotal,
    });
  }

  onTotalReverseChange(reverseCountTotal: number) {
    this.botProblemCount = reverseCountTotal;
    this.updateCorrection();
    this.emitEvent({
      action: 'totalReverseChange',
      value: reverseCountTotal,
    });
  }

  onTotalChange(totalCountTotal: number) {
    this.totalCount = totalCountTotal;
    this.updateTotal();
    this.emitEvent({
      action: 'totalCountChange',
      value: totalCountTotal,
    });
  }

  loading(loading: boolean) {
    this.loadingSate = loading;
    this.emitEvent({ action: 'loading', value: loading });
  }

  emitEvent(event: any) {
    this.eventService.sendEvent(event);
  }
}
