<div
  class="modal fade"
  id="tfaModal"
  #tfaModal
  tabindex="-1"
  role="dialog"
  aria-labelledby="tfaModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <!-- Agregamos la clase modal-dialog-centered -->
    <div class="modal-content">
      <div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Autenticación de dos factores</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <form
    class="theme-form needs-validation"
    [class.was-validated]="!tfaForm.valid"
    novalidate=""
    [formGroup]="tfaForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="form-group">
      <label for="dateOfBirth">Ingrese el código de autenticación</label>
      <div class="input-group">
        <input id="TFA" class="form-control" placeholder="Code" formControlName="TFA" />
        @if (tfaForm.controls.TFA.touched && tfaForm.controls.TFA.errors?.required) {
          <div
            class="invalid-feedback"
          >
            This field is required.
          </div>
        }
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Save click')"
  >
    Save
  </button>
</div>
    </div>
  </div>
</div>
