<div class="container-fluid p-0">
    <div class="comingsoon">
        <div class="comingsoon-inner text-center">
            <img class="img-100" src="assets/images/other-images/coming-soon-Logo.png" alt="">
            <h5>WE ARE COMING SOON</h5>
            <div class="countdown" id="clockdiv">
                <ul>
                    <li><span class="time digits" id="days"></span><span class="title">days</span></li>
                    <li><span class="time digits" id="hours"></span><span class="title">Hours</span></li>
                    <li><span class="time digits" id="minutes"></span><span class="title">Minutes</span></li>
                    <li><span class="time digits" id="seconds"></span><span class="title">Seconds</span></li>
                </ul>
            </div>
            <div><a class="btn btn-primary-gradien btn-lg" routerLink='/dashboard/default'><i class="fa-solid fa-house fa-lg" style="color: #ffffff;"></i></a></div>
        </div>
    </div>
</div>
