import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Color, Config } from '../../interfaces/theme.interface';

export const featuredSelector =
  createFeatureSelector<Readonly<Config>>('theme');

export const selectSettings = createSelector(
  featuredSelector,
  (state) => state.settings
);
export const selectColor = createSelector(
  featuredSelector,
  (state) => state.color
);
export const selectConfig = createSelector(featuredSelector, (state) => state);
