import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { ToastService } from 'angular-toastify';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from '../../../../../../core/services/country.service';
import { DynamicModalService } from '../../../../../../core/services/dynamic-modal.service';
import { CreateFormsBcComponent } from '../create-forms-bc/create-forms-bc.component';

@Component({
  selector: 'app-select-country-bc',
  templateUrl: './select-country-bc.component.html',
  styleUrl: './select-country-bc.component.scss'
})
export class SelectCountryBcComponent implements OnInit {
  public optionsCountries$: any;
  public selectedCountryId: any = 1; // Valor predeterminado
  public selectedCountryName!: any;
  public isValidCountry: boolean = true;
  public countryForm: FormGroup; // Formulario
  public isLoading: boolean = true; // Estado de carga

  constructor(
    private _countryService: CountryService,
    private toast: ToastService,
    private dynamicModal: DynamicModalService,
    private activeModal: NgbActiveModal,
    private _toastrSvc: ToastrService
  ) {
    this.countryForm = new FormGroup({
      selectedCountry: new FormControl(this.selectedCountryId, Validators.required),
    });
  }

  ngOnInit(): void {
    this.getCountries();
    this.loadDefaultCountry();
  }

  getCountries() {
    this.isLoading = true;
    setTimeout(() => {
      this._countryService.getAllCountries().subscribe((response) => {
        const transformedData = response.data.map(
          (item: { id_pais: any; pais: any }) => {
            return { label: item.pais, value: item.id_pais };
          }
        );

        this.optionsCountries$ = of(transformedData);
        this.isLoading = false; // Finaliza la carga
      });
    }, 500);
  }

  onCountrySelected(event: any) {
    this.selectedCountryId = event.value;
    this.isValidCountry =
      this.selectedCountryId === 1 || this.selectedCountryId === 2;
    if (!this.isValidCountry) {
      this._toastrSvc.error('No está disponible este país.', '', {
        progressBar: true,
        positionClass: 'custom-toastr-top',
      });
    }
    this._countryService
      .getCountryById(this.selectedCountryId)
      .subscribe((response) => {
        this.selectedCountryName = response.data.pais;
      });
  }

  loadDefaultCountry() {
    this._countryService.getCountryById(this.selectedCountryId).subscribe((response) => {
      this.selectedCountryName = response.data.pais;
    });
  }

  next() {
    if (!this.isValidCountry) {
      return;
    }
    if (!this.countryForm.get('selectedCountry')?.value) {
      this._toastrSvc.error(
        'Por favor, selecciona un país para continuar.',
        '',
        {
          progressBar: true,
          positionClass: 'custom-toastr-top',
        }
      );
      return;
    }
    this.activeModal.close();
    this.openCreateBcFormModal();
  }

  openCreateBcFormModal() {
    this.dynamicModal.openModal(
      CreateFormsBcComponent,
      `Crear Código De Bono (${this.selectedCountryName})`,
      'xl',
      false,
      this.selectedCountryId
    );
  }

}
