<!-- <app-breadcrumb
  [title]="'Bonus Code'"
  [items]="['Bonus']"
  [active_item]="'All Bonus Code'"
></app-breadcrumb> -->
<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-xxl-12 col-xl-12 box-cSol-12">
      <div class="col-sm-12">
        <div class="row mt-3">
          <div class="col-12">
            <div class="mb-sm-0">
              <app-filter-bonus-code></app-filter-bonus-code>
            </div>
          </div>
        </div>
        <app-bonus-code-table></app-bonus-code-table>
      </div>
    </div>
  </div>
</div>
