<!-- error-403 start-->
<div class="error-wrapper">
  <div class="container">
    <img class="img-100" src="assets/images/other-images/sad.png" alt="" />
    <div class="error-heading">
      <h2 class="headline font-success">OOPS!</h2>
    </div>
    <div class="col-md-8 offset-md-2">
      <p class="sub-content">
        Hay otro dispositivo conectado en tu cuenta. Por favor cierra sesion y
        vuelve a Ingresar
      </p>
    </div>
    <!-- <div>
      <a class="btn btn-success-gradien btn-lg" routerLink="/dashboard/default"
        >BACK TO HOME PAGE</a
      >
    </div> -->
  </div>
</div>
<!-- error-403 end-->
