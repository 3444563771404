<div class="media profile-media">
  @if (photoSelfie) {
        <img
          [src]="previewImage"
          alt="Vista Previa"
          class="fixed-size rounded-circle"
        />
        } @else {
        <img
          class="fixed-size rounded-circle"
          alt=""
          src="assets/images/no-image.jpg"
        />
        }
  <div class="media-body">
    <span class="description">{{ username }}</span>
    <p class="mb-0 font-roboto">
      <span class="description">{{ nombre_rol }}</span
      ><i class="middle fa fa-angle-down"></i>
    </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li>
    <a routerLink="/user/profile"
      ><app-feather-icons [icon]="'user'"></app-feather-icons
      ><span>Account </span></a
    >
  </li>
  <li (click)="logoutFunc()">
    <app-feather-icons [icon]="'log-in'"></app-feather-icons
    ><span>Log out</span>
  </li>
</ul>
