import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormObject } from '../../../core/interfaces/dynamic-form.interface';
import moment from 'moment';
import { Subject, debounceTime } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrl: './dynamic-form.component.scss',
})
export class DynamicFormComponent {
  @Input() data: FormObject[] = [];
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() scrollEnd: EventEmitter<any> = new EventEmitter<any>();

  public today = moment();
  public tomorrow = moment().startOf('day').add(1, 'day');
  // public startDate = moment().subtract(1, 'month').startOf('month');
  public startDate = moment('1900-01-01');
  // public endDate = moment().startOf('month');
  public endDate = moment('3000-12-31');
  public dateSelected = `${this.startDate.format(
    'YYYY/MM/DD'
  )} - ${this.endDate.format('YYYY/MM/DD')}`;
  public ranges: any = {};
  form: FormGroup;
  private filterSubject: Subject<string> = new Subject<string>();
  fieldOptions: { [key: string]: any[] } = {};
  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({}); // Inicializar FormGroup vacío
    this.filterSubject
      .pipe(
        debounceTime(500) // Adjust debounce time as needed (e.g., 300 milliseconds)
      )
      .subscribe((value: any) => {
        this.data.forEach((data) => {
          data.formData.forEach((form) => {
            if (form.control === 'dateInput') {
              const controlId = form.id;
              value[controlId] = this.dateSelected;
            }
            if (form.control === 'select') {
              this.fieldOptions[form.id] = [...form.options];
            }
          });
        });
        this.formSubmit.emit(value);
      });
  }
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      this.data.forEach((data) => {
        data.formData.forEach((field: any) => {
          if (field.control === 'select') {
            this.fieldOptions[field.id] = [...field.options];
          }
          this.form.addControl(
            field.id,
            this.fb.control(null, Validators.required)
          );
        });
      });
      this.configDatePicker();
    }
  }
  configDatePicker(): void {
    this.initRanges();
    if (
      this.startDate.isSame(this.ranges['All'][0], 'day') &&
      this.endDate.isSame(this.ranges['All'][1], 'day')
    ) {
      this.dateSelected = 'All';
    }

    $(() => {
      const dateRangeCallback = (
        startDate: moment.Moment,
        endDate: moment.Moment
      ): void => {
        if (
          startDate.isSame(this.ranges['All'][0], 'day') &&
          endDate.isSame(this.ranges['All'][1], 'day')
        ) {
          this.dateSelected = 'All';
        } else {
          this.dateSelected = `${startDate.format(
            'YYYY/MM/DD'
          )} - ${endDate.format('YYYY/MM/DD')}`;
        }
        this.filterSubject.next(this.form.value);
      };

      $('#reportrange').daterangepicker(
        {
          startDate: this.ranges['All'][0], // Establecer startDate como el inicio del rango 'All'
          endDate: this.ranges['All'][1], // Establecer endDate como el final del rango 'All'
          ranges: this.ranges,
          alwaysShowCalendars: true, // Mostrar siempre los calendarios
        },
        dateRangeCallback
      );

      $('#reportrange')
        .data('daterangepicker')
        .setStartDate(this.ranges['All'][0]);
      $('#reportrange')
        .data('daterangepicker')
        .setEndDate(this.ranges['All'][1]);
    });
  }

  initRanges() {
    // const translations = this._translateService.instant(this.translationKeys);
    this.ranges = {
      ['All']: [moment('1900-01-01'), moment('3000-12-31')],
      ['Today']: [moment(), moment().startOf('day').add(1, 'day')],
      ['Yesterday']: [moment().subtract(1, 'day'), moment()],
      ['Last 7 days']: [
        moment().subtract(7, 'day'),
        moment().startOf('day').add(1, 'day'),
      ],
      ['Last 30 days']: [
        moment().subtract(30, 'day'),
        moment().startOf('day').add(1, 'day'),
      ],
      ['This month']: [
        moment().startOf('month'),
        moment().add(1, 'month').startOf('month'),
      ],
      ['Last month']: [
        moment().subtract(1, 'month').startOf('month'),
        moment().startOf('month'),
      ],
    };
  }
  onSubmit() {
    // if (this.form.valid) {
    //   this.formSubmit.emit(this.form.value); // Emitir los datos del formulario cuando sea válido
    // }
  }
  updateFilter() {
    this.filterSubject.next(this.form.value);
  }

  getColumnClassRow(columnsPerRow: number): string {
    switch (columnsPerRow) {
      case 1:
        return 'col-md-12';
      case 2:
        return 'col-md-6';
      case 3:
        return 'col-md-4';
      case 4:
        return 'col-md-3';
      case 6:
        return 'col-md-2';
      default:
        return '';
    }
  }
  getColumnCount(object: any): number[] {
    const columnCount = Math.ceil(
      object.formData.length / object.rowsPerColumn
    );
    columnCount;

    return Array(columnCount).fill(0);
  }
  onScroll() {
    this.scrollEnd.emit('scrollEnd');
  }
  funtion(json: any) {
    const jsonObject = JSON.parse(json);
    return jsonObject;
  }
  test() {
    console.log('ass');
  }
}
