import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, of, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from '../../../../../../../../environments/environment';
import { ChargesWithdrawalsRequestService } from '../../../../../../../core/services/charges-withdrawals-request.service';
import { DynamicModalService } from '../../../../../../../core/services/dynamic-modal.service';
import { FinancialService } from '../../../../../../../core/services/financial.service';
import { HolderAccountBankService } from '../../../../../../../core/services/holder-account-bank.service';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import {
  ROLES,
  USER_TYPES,
} from '../../../../../../../shared/data/config/constants';
import {
  ACCOUNT_TYPES,
  ACCOUNT_TYPES_TEXT,
  ALERTS_TYPES,
  ALLOWED_TYPES_FOR_AGENT_PLAYER,
  ALLOWED_TYPES_FOR_AGENT_READONLY,
  ALLOWED_TYPES_FOR_AGENT_READONLY_SPECIAL,
  ALLOWED_TYPES_FOR_BANK,
  ALLOWED_TYPES_FOR_BENEFICIARY,
  ALLOWED_TYPES_FOR_CHECK_RECEIPT,
  ALLOWED_TYPES_FOR_HOLDER_BY_EDIT,
  ALLOWED_TYPES_FOR_IDENTIFICATION,
  ALLOWED_TYPES_FOR_MONTO,
  ALLOWED_TYPES_FOR_MONTO_BANK_ID_ACCOUNT,
  ALLOWED_TYPES_FOR_MONTO_BANK_RECEIPT,
  ALLOWED_TYPES_FOR_PLAYER_READONLY,
  ALLOWED_TYPES_FOR_PLAYER_READONLY_SPECIAL,
  ALLOWED_TYPES_FOR_RECEIPT,
  ALLOWED_TYPES_FOR_STATUS_RISKY_RECEIPT,
  ALLOWED_TYPES_FOR_UPLOAD_WITHDRAWAL_DIRECT,
  DEFAULT_VALUE,
  ERROR_MESSAGES,
  INTERBANK_TRANSDFER_OPTIONS,
  INTERBANK_TRANSDFER_OPTIONS_VALUES,
  NULL_VALUE_TEXT,
  PAGE_SIZE_VALUE,
  PROCESSING_TEXT,
  STATE_NAMES,
  TRANSACTION_TYPE_VALUES,
  TRANSACTION_TYPES,
  TRUE_CHECK_VALUE,
  UNKNOWN_VALUE_TEXT,
} from '../../../../../../../shared/data/suscriptions-payments/constants';
import { ImageCompressionService } from '../../../../../../../shared/services/image-compression.service';
import { OcrService } from '../../../../../../../shared/services/ocr.service';
import { UserMainService } from '../../../../../../user/services/user-main.service';
import moment from 'moment-timezone';
import { AlertService } from '../../../../../../../shared/services/alert.service';
interface Alert {
  type: string;
  message: string;
}

interface FilePreview {
  file: File;
  previewVisible: boolean;
  urlFile: string;
}

@Component({
  selector: 'app-edit-cw-request',
  templateUrl: './edit-cw-request.component.html',
  styleUrl: './edit-cw-request.component.scss',
})
export class EditCwRequestComponent implements OnInit {
  public cw_id: number = 0;
  public cwData: any;
  public cwEditForm!: FormGroup;
  public id_country!: number;
  public optionsBanks$: any;
  public optionsHolder$: any;
  public optionsInterbankTransfer$: any;
  public transformedDataBank: any;
  public selectedBankId!: number;
  public allHoldersData: any[] = [];
  public allClientBankData: any[] = [];
  public allEnterpriseBankData: any[] = [];
  public selectedAccountNumber: any[] = [];
  public currentPageHolders = 1;
  public currentPageBanks = 1;
  public pageSize = 10;
  public criteria: any;
  public receipVoucher: any;
  public reciptError: boolean = false;
  public isManual = false;
  public inappropiatedComment: any;
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  public tipo_usuario: any;
  public rol!: number;
  public alloweEnterprise: boolean = false;
  public transfer_Type: any;
  public isLoading: boolean = true;
  public isLoadingButton = false;
  public transactionLabel: string = '';
  public transferLabel: string = '';
  public blackList: boolean = false;
  public entityType: string = '';
  public isAgent = true;
  public isFileRequired: boolean = false;
  public roles = ROLES;
  public userType = USER_TYPES;
  private holderSubject: Subject<string> = new Subject<string>();
  public isSaving: boolean = true;
  public accountTypeLabel: string = 'Ahorros';
  public isRiskyTicket: boolean = true;
  public DEFAULT_VALUE = DEFAULT_VALUE;
  private updatingValidators: boolean = false;

  //ALERTS
  public alerts: Alert[] = [];

  //IMG
  public previewImage: any;
  public selectedFiles: FilePreview[] = [];
  public imgSrc!: any;
  public urlResponse: any;
  public file: any;
  public baseUrl: string = environment.API_FILES;
  public idBank: any;
  public idBankEnterprise: any;
  public transferInterbankId: any;
  public recipt: any;
  public data: any;
  public player: string = '';
  public agent: string = '';
  @ViewChild('fileInput') fileInput!: ElementRef;
  public TRANSACTION_TYPE_VALUES = TRANSACTION_TYPE_VALUES;
  public ALLOWED_TYPES_FOR_BANK = ALLOWED_TYPES_FOR_BANK;
  public ALLOWED_TYPES_FOR_AGENT_READONLY = ALLOWED_TYPES_FOR_AGENT_READONLY;
  public ALLOWED_TYPES_FOR_PLAYER_READONLY = ALLOWED_TYPES_FOR_PLAYER_READONLY;
  public ALLOWED_TYPES_FOR_AGENT_READONLY_SPECIAL =
    ALLOWED_TYPES_FOR_AGENT_READONLY_SPECIAL;
  public ALLOWED_TYPES_FOR_PLAYER_READONLY_SPECIAL =
    ALLOWED_TYPES_FOR_PLAYER_READONLY_SPECIAL;
  public ALLOWED_TYPES_FOR_AGENT_PLAYER = ALLOWED_TYPES_FOR_AGENT_PLAYER;
  public ALLOWED_TYPES_FOR_HOLDER_BY_EDIT = ALLOWED_TYPES_FOR_HOLDER_BY_EDIT;
  public ALLOWED_TYPES_FOR_RECEIPT = ALLOWED_TYPES_FOR_RECEIPT;
  public ALLOWED_TYPES_FOR_IDENTIFICATION = ALLOWED_TYPES_FOR_IDENTIFICATION;
  public ALLOWED_TYPES_FOR_BENEFICIARY = ALLOWED_TYPES_FOR_BENEFICIARY;
  public ALLOWED_TYPES_FOR_CHECK_RECEIPT = ALLOWED_TYPES_FOR_CHECK_RECEIPT;
  public ALLOWED_TYPES_FOR_UPLOAD_WITHDRAWAL_DIRECT =
    ALLOWED_TYPES_FOR_UPLOAD_WITHDRAWAL_DIRECT;
  public ALLOWED_TYPES_FOR_STATUS_RISKY_RECEIPT =
    ALLOWED_TYPES_FOR_STATUS_RISKY_RECEIPT;
  public ALLOWED_TYPES_FOR_MONTO = ALLOWED_TYPES_FOR_MONTO;
  public ALLOWED_TYPES_FOR_MONTO_BANK_ID_ACCOUNT =
    ALLOWED_TYPES_FOR_MONTO_BANK_ID_ACCOUNT;
  public ALLOWED_TYPES_FOR_MONTO_BANK_RECEIPT =
    ALLOWED_TYPES_FOR_MONTO_BANK_RECEIPT;
  public identificationError: boolean = false;
  public numberAccountError: boolean = false;

  constructor(
    private _dynamicModal: DynamicModalService,
    private _cwService: ChargesWithdrawalsRequestService,
    private _userMainService: UserMainService,
    private _financialService: FinancialService,
    private _holderAccountBank: HolderAccountBankService,
    private store: Store,
    private _toastrSvc: ToastrService,
    private fb: FormBuilder,
    private ocrService: OcrService,
    private sanitizer: DomSanitizer,
    private activeModal: NgbActiveModal,
    private _imageCompressionService: ImageCompressionService,
    private alertService: AlertService
  ) {
    this.initForm();
    this.holderSubject.pipe(debounceTime(400)).subscribe(async (term) => {
      this.allHoldersData = [];
      this.allEnterpriseBankData = [];
      this.allClientBankData = [];
      await this.loadHolders(term);
      this.getBankData(term);
    });
  }

  ngOnInit(): void {
    this.data = this._dynamicModal.getModalData();
    this.getCwData();
    this.loadTypeTransfer();
    this.validatorsReciptImg();
    const isManualFromStorage = localStorage.getItem('isManual');
    this.isManual = isManualFromStorage === 'true';
  }

  //? LOAD DATA
  getCwData() {
    this.isLoading = true;
    this._cwService.getCw(this.data.id).subscribe(async (response) => {
      this.cwData = response.data;
      this.id_country = this.cwData.id_pais;
      if (this.id_country) {
        this.getBankData();
      }
      if (
        this.cwData.jugador_white_list?.lista_negra === 1 ||
        this.cwData.agente_white_list?.lista_negra === 1
      ) {
        this.blackList = true;
      }

      this.player = this.cwData.player;
      this.agent = this.cwData.agent;
      this.reciptError = false;
      this.idBank = this.cwData.id_banco;
      this.idBankEnterprise = this.cwData.id_banco_empresa;
      this.recipt = this.cwData.foto_comprobante;
      this.cwEditForm.patchValue({
        monto: Number(this.cwData.monto),
        ...this.cwData,
      });
      if (this.cwData.id_jugador) {
        this.entityType = 'jugador';
      } else if (this.cwData.id_agente) {
        this.entityType = 'agente';
      }
      if (this.cwData.tipo_cuenta === ACCOUNT_TYPES.CURRENT) {
        this.isSaving = false;
        this.accountTypeLabel = ACCOUNT_TYPES_TEXT.CURRENT;
      } else {
        this.isSaving = true;
        this.accountTypeLabel = ACCOUNT_TYPES_TEXT.SAVINGS;
      }
      const transactionType = TRANSACTION_TYPES.find(
        (type) => type.value === this.cwData.tipo_transaccion.toString()
      );
      this.transactionLabel = transactionType
        ? transactionType.label
        : UNKNOWN_VALUE_TEXT;
      if (this.cwData.transferencia_interbancaria !== null) {
        this.cwEditForm
          .get('transferencia_interbancaria')
          ?.setValue(this.cwData.transferencia_interbancaria.toString());
      }
      if (this.cwData.foto_comprobante) {
        this.previewImage = this.formatUrl(this.cwData.foto_comprobante);
      } else {
        this.previewImage = null;
      }
      if (
        this.cwData.comprobante === NULL_VALUE_TEXT ||
        this.cwData.comprobante_empresa === NULL_VALUE_TEXT
      ) {
        this.cwEditForm.get('comprobante')?.setValue('');
        this.cwEditForm.get('comprobante_empresa')?.setValue('');
      }
      if (this.cwData.banco?.idabpa_banco !== null) {
        this.cwEditForm
          .get('id_banco')
          ?.setValue(this.cwData.banco?.idabpa_banco);
      }
      if (this.cwData.id_banco_empresa === DEFAULT_VALUE) {
        this.cwEditForm.get('id_banco_empresa')?.setValue(null);
      } else if (this.cwData.id_banco_empresa !== null) {
        this.cwEditForm
          .get('id_banco_empresa')
          ?.setValue(this.cwData.id_banco_empresa);
      }
      if (this.cwData.detalle === NULL_VALUE_TEXT) {
        this.cwEditForm.get('detalle')?.setValue('');
      }
      if (this.cwData.nombre_titular === NULL_VALUE_TEXT) {
        this.cwEditForm.get('nombre_titular')?.setValue('');
      }
      if (
        [
          TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
          TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
          TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
          TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
          TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
          TRANSACTION_TYPE_VALUES.FINANCIAL,
          TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL,
        ].includes(this.cwData.tipo_transaccion)
      ) {
        this.cwEditForm.get('cargar_retirar_directo')?.disable();
      }
      this.isLoading = false;
      if (this.idBank) {
        if (
          [
            Number(INTERBANK_TRANSDFER_OPTIONS_VALUES.YES),
            Number(INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS),
          ].includes(this.cwData.transferencia_interbancaria)
        ) {
          this.selectedBankId = this.idBankEnterprise;
        } else {
          this.selectedBankId = this.idBank;
        }
        await this.loadHolders();
      }
      if (this.cwData.id_titular === DEFAULT_VALUE) {
        this.cwEditForm.get('id_titular')?.setValue(null);
      } else {
        this.cwEditForm.get('id_titular')?.setValue(this.cwData.id_titular);
      }
    });
    this.isRiskyTicket =
      this.cwData?.estado_boleta_riesgosa === TRUE_CHECK_VALUE;
  }

  getBankData(searchTerm?: string) {
    this._userMainService
      .getAllBanksBySelect(
        this.currentPageBanks,
        this.pageSize,
        searchTerm,
        this.id_country
      )
      .subscribe((response) => {
        this.transformedDataBank = response.data.map((item) => {
          return { label: item.bankName, value: item.bankId };
        });
        this.allEnterpriseBankData = [
          ...this.allEnterpriseBankData,
          ...this.transformedDataBank,
        ];
        this.allClientBankData = [
          ...this.allClientBankData,
          ...this.transformedDataBank,
        ];
      });
  }

  async loadHolders(searchTerm?: string) {
    await new Promise((resolve, reject) =>
      this._holderAccountBank
        .getAllHoldersAccountData(
          this.currentPageHolders,
          this.pageSize,
          this.id_country,
          this.selectedBankId,
          searchTerm
        )
        .subscribe(
          (res) => {
            const response = res.data;

            const transformedData = response.data.map(
              (item: {
                holder: any;
                id_usuario: any;
                bank: any;
                account_number: any;
                id_banco: any;
              }) => {
                return {
                  label:
                    item.holder +
                    ' (' +
                    item.bank +
                    ' - ' +
                    item.account_number +
                    ')',
                  value: item.id_usuario,
                };
              }
            );
            this.optionsHolder$ = of(transformedData);
            this.allHoldersData = [...this.allHoldersData, ...transformedData];
            resolve(null);
          },
          (err) => reject(err)
        )
    );
  }

  loadTypeTransfer() {
    this.optionsInterbankTransfer$ = of(INTERBANK_TRANSDFER_OPTIONS);
    this.cwEditForm
      .get('transferencia_interbancaria')
      ?.setValue(INTERBANK_TRANSDFER_OPTIONS_VALUES.NO.toString());
  }

  loadProfileLogged() {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario) {
        this.profile = profile;
        this.tipo_usuario = this.profile.tipo_usuario.toString();
        this.rol = this.profile.id_rol;
      }
    });
  }

  //?MODAL
  closeModal() {
    this.activeModal.close();
    this.cwEditForm.reset();
  }

  //SWITCHES
  manualSwitch() {
    this.isManual = !this.isManual;
    this.validatorsReciptImg();
    this.reciptError = false;
    localStorage.setItem('isManual', String(this.isManual));
  }

  toggleAccountType(event: any): void {
    this.isSaving = !this.isSaving;
    this.accountTypeLabel = this.isSaving
      ? ACCOUNT_TYPES_TEXT.SAVINGS
      : ACCOUNT_TYPES_TEXT.CURRENT;
  }

  //*NG-SELECT
  async onBankSelected(event: any) {
    this.selectedBankId = event.value;
    if (this.isInterbankTransaction) {
      const interbankTransferType = this.cwEditForm.get(
        'transferencia_interbancaria'
      )?.value;
      if (
        interbankTransferType ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.YES.toString() ||
        interbankTransferType ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS.toString()
      ) {
        this.selectedBankId =
          this.cwEditForm.get('id_banco_empresa')?.value || this.selectedBankId;
      } else if (
        interbankTransferType ===
        INTERBANK_TRANSDFER_OPTIONS_VALUES.NO.toString()
      ) {
        this.selectedBankId = this.cwEditForm.get('id_banco')?.value;
        this.cwEditForm.get('id_titular')?.setValue(null);
      }
    } else {
      if (this.cwEditForm.get('id_banco')?.value) {
        this.selectedBankId = this.cwEditForm.get('id_banco')?.value;
        this.cwEditForm.get('id_titular')?.setValue(null);
      }
    }
    if (this.selectedBankId) {
      this.cwEditForm.get('id_titular')?.enable();
      await this.loadHolders();
    } else {
      this.cwEditForm.get('id_titular')?.disable();
    }
  }

  async onScrollToEnd() {
    this.currentPageHolders++;
    this.currentPageBanks++;
    this.getBankData(this.criteria);
    await this.loadHolders(this.criteria);
  }

  onSearch(term: string) {
    this.criteria = term;
    this.allHoldersData = [];
    this.allClientBankData = [];
    this.allEnterpriseBankData = [];
    this.currentPageHolders = PAGE_SIZE_VALUE;
    this.currentPageBanks = PAGE_SIZE_VALUE;
    this.holderSubject.next(term);
  }

  onSearchBank() {
    this.selectedBankId = DEFAULT_VALUE;
  }

  //*!ALERTS
  clearDangerAlerts(): void {
    this.alerts = this.alerts.filter(
      (alert) => alert.type !== ALERTS_TYPES.DANGER
    );
  }

  showDangerAlert(message: string): void {
    this.alerts.push({ type: ALERTS_TYPES.DANGER, message: message });
  }

  closeAlert(alert: Alert): void {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  watchFormChanges(): void {
    this.cwEditForm.get('comprobante')?.valueChanges.subscribe((val) => {
      if (val) {
        this.clearDangerAlerts();
      }
    });
  }

  //* IMAGES
  triggerFileInput(event?: Event): void {
    if (event) {
      event.stopPropagation();
    }
    this.fileInput.nativeElement.click();
  }
  async onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        this._toastrSvc.error('Solo se permiten imágenes en formato JPG o PNG');
        return;
      }

      try {
        const compressedFile =
          await this._imageCompressionService.compressImage(file);
        const url = this.fileUrl(compressedFile);

        this.selectedFiles = [
          {
            file: compressedFile,
            previewVisible: true,
            urlFile: url,
          },
        ];
        this.previewImage = url;
        this.processFile(compressedFile);
        event.target.value = '';
      } catch (error) {
        this._toastrSvc.error('Error al comprimir la imagen');
      }
    }
  }

  isInvalidFileType(url: string): boolean {
    const invalidExtensions = ['pdf', 'docx', 'doc'];
    const fileExtension = url.split('.').pop()?.toLowerCase();
    return invalidExtensions.includes(fileExtension || '');
  }

  validateField(event: any, fieldName: string): void {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9-]*$/;

    if (fieldName === 'identificacion') {
      this.identificationError = !regex.test(value);
    }
    if (fieldName === 'numero_cuenta') {
      this.numberAccountError = !regex.test(value);
    }
  }

  processFile(file: File) {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (event.target) {
        const imageData = event.target.result as string;

        this.processImage(imageData);
        if (!this.isManual) {
          this.reciptError = false;
          this.cwEditForm.patchValue({
            comprobante: PROCESSING_TEXT,
            detalle: PROCESSING_TEXT,
          });
          try {
            const receiptNumber = await this.ocrService.readReceiptNumber(
              imageData
            );
            this.receipVoucher = receiptNumber;
            if (
              this.cwData.tipo_transaccion ===
                TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE ||
              this.cwData.tipo_transaccion ===
                TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION
            ) {
              this.cwEditForm.patchValue({
                comprobante_empresa: receiptNumber,
              });
            } else {
              this.cwEditForm.patchValue({ comprobante: receiptNumber });
            }
          } catch {
            this.showDangerAlert('Error al leer el número de comprobante.');
            this.cwEditForm.patchValue({
              comprobante: null,
            });
          }
          try {
            const bankName = await this.ocrService.readBank(imageData);
            const bankControl = this.cwEditForm.get('id_banco');
            const bankEnterpiseControl =
              this.cwEditForm.get('id_banco_empresa');
            if (bankControl) {
              const bankOption = this.transformedDataBank.find(
                (option: any) => {
                  const cleanedBankName = bankName
                    .replace(/Banco\s*/i, '')
                    .trim();
                  return (
                    option.label.toLowerCase() === cleanedBankName.toLowerCase()
                  );
                }
              );
              if (bankOption) {
                if (
                  this.cwData.tipo_transaccion ===
                    TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE ||
                  this.cwData.tipo_transaccion ===
                    TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION
                ) {
                  bankEnterpiseControl?.setValue(bankOption.value);
                  this.onBankSelected({ value: bankOption.value });
                } else {
                  bankControl.setValue(bankOption.value);
                  this.onBankSelected({ value: bankOption.value });
                }
              }
            }
          } catch {
            this.showDangerAlert('Error al leer el banco.');
            this.cwEditForm.patchValue({ id_banco: null });
          }
          try {
            const text = await this.ocrService.readImageText(imageData);
            this.inappropiatedComment = text;
            this.cwEditForm.get('detalle')?.patchValue(text);
            if (text) {
              this.cwEditForm.get('estado_boleta_riesgosa')?.patchValue(true);
            }
          } catch {
            this.showDangerAlert(
              'Error al leer el monto, ingresar manualmente.'
            );
            this.cwEditForm.patchValue({
              detalle: null,
            });
          }
        }
      }
    };
    reader.readAsDataURL(file);
  }

  fileUrl(file: File): any {
    const url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  formatUrl(url: string) {
    return this.baseUrl + '/' + url.replace(/ /g, '%20').replace(/\\/g, '/');
  }

  uploadFiles(files: FileList) {
    if (files.length > 0) {
      const file = files[0];

      if (!file.type.startsWith('image/')) {
        this._toastrSvc.error('Solo se permiten imágenes en formato JPG o PNG');
        return;
      }

      const url = this.fileUrl(file);

      this.selectedFiles = [
        {
          file: file,
          previewVisible: true,
          urlFile: url,
        },
      ];
    }
  }

  onDrop($event: DragEvent) {
    $event.preventDefault();
    const files = $event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.uploadFiles(files);
      this.processFile(files[0]);
    }
  }

  onDragOver($event: DragEvent) {
    $event.preventDefault();
  }

  removeImage() {
    this.previewImage = undefined;
  }

  processImage(imageData: string | ArrayBuffer | null) {
    if (imageData) {
      this.previewImage = imageData.toString();
    }
  }

  //* FORM
  initForm(): void {
    this.cwEditForm = this.fb.group({
      tipo_transaccion: [null, Validators.required],
      monto: [null],
      id_banco: [null],
      id_banco_empresa: [null],
      id_titular: [null],
      comprobante: ['', [Validators.pattern(/^[a-zA-Z0-9]*$/)]],
      comprobante_empresa: [''],
      nombre_titular: [null],
      transferencia_interbancaria: [null],
      detalle: [null],
      identificacion: [null],
      numero_cuenta: [null],
      estado_revisar_boleta: false,
      permitirComprobante: false,
      cargar_retirar_directo: false,
      estado_boleta_riesgosa: false,
      estado_tiempo_espera: false,
    });

    this.loadProfileLogged();
    this.updateFileRequirement(this.cwEditForm.get('tipo_transaccion')?.value);
    this.cwEditForm.get('tipo_transaccion')?.valueChanges.subscribe((value) => {
      this.updateTransactionValidators(value, this.tipo_usuario);
      this.updateFileRequirement(value);
    });

    this.cwEditForm.get('id_banco_empresa')?.valueChanges.subscribe(() => {
      this.toggleTitularControl();
    });

    this.cwEditForm.get('tipo_transaccion')?.valueChanges.subscribe(() => {
      this.toggleTitularControl();
    });

    this.cwEditForm
      .get('transferencia_interbancaria')
      ?.valueChanges.subscribe(() => {
        this.transfer_Type = this.cwEditForm.get(
          'transferencia_interbancaria'
        )?.value;
        this.alloweEnterprise =
          this.transfer_Type ===
            INTERBANK_TRANSDFER_OPTIONS_VALUES.YES.toString() ||
          this.transfer_Type ===
            INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS.toString();
        this.cwEditForm.get('id_titular')?.setValue(null);
      });

    this.cwEditForm
      .get('transferencia_interbancaria')
      ?.valueChanges.subscribe(() => {
        const transferencia_interbancaria = this.cwEditForm.get(
          'transferencia_interbancaria'
        )?.value;

        if (
          transferencia_interbancaria ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.YES.toString()
        ) {
          this.checkBankEquality();
        }
      });

    this.cwEditForm.get('id_banco')?.valueChanges.subscribe((value) => {
      const transferencia_interbancaria = this.cwEditForm.get(
        'transferencia_interbancaria'
      )?.value;
      if (
        transferencia_interbancaria ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.NO.toString() ||
        transferencia_interbancaria ===
          INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS.toString()
      ) {
        this.cwEditForm.get('id_banco_empresa')?.setValue(value);
      }
    });

    this.cwEditForm.get('id_banco')?.valueChanges.subscribe(() => {
      this.updateTitularFieldState();
    });

    this.cwEditForm.get('id_banco_empresa')?.valueChanges.subscribe(() => {
      this.updateTitularFieldState();
    });
  }

  updateFileRequirement(transactionType: string): void {
    this.isFileRequired =
      transactionType === TRANSACTION_TYPE_VALUES.LOAD_BALANCE.toString();
    this.cwEditForm.get('foto_comprobante')?.updateValueAndValidity();
  }

  updateTitularFieldState(): void {
    const bancoCliente = this.cwEditForm.get('id_banco')?.value;
    const bancoEmpresa = this.cwEditForm.get('id_banco_empresa')?.value;

    if (bancoCliente || bancoEmpresa) {
      this.cwEditForm.get('id_titular')?.enable();
    } else {
      this.cwEditForm.get('id_titular')?.disable();
      this.cwEditForm.get('id_titular')?.setValue(null);
    }
  }

  convertBooleanFieldsToNumbers(data: any): any {
    const convertedData = { ...data };
    const fieldsToConvert = [
      'estado_revisar_boleta',
      'permitirComprobante',
      'estado_boleta_riesgosa',
    ];

    fieldsToConvert.forEach((field) => {
      if (convertedData[field] === true) {
        convertedData[field] = TRUE_CHECK_VALUE;
      } else {
        convertedData[field] = DEFAULT_VALUE;
      }
    });

    return convertedData;
  }

  onSubmit() {
    if (this.cwData?.tipo_transaccion !== TRANSACTION_TYPE_VALUES.FINANCIAL) {
      this.markFormGroupTouched(this.cwEditForm);
    }
    this.checkFormStatus();
    this.setDefaultValues();
    const transferencia_interbancaria = this.cwEditForm.get(
      'transferencia_interbancaria'
    )?.value;
    if (
      transferencia_interbancaria ===
        INTERBANK_TRANSDFER_OPTIONS_VALUES.YES.toString() ||
      transferencia_interbancaria ===
        INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS.toString()
    ) {
      const idBankValue = this.cwEditForm.get('id_banco_empresa')?.value;
      const voucherValue = this.cwEditForm.get('comprobante_empresa')?.value;
      this.cwEditForm.get('id_banco_empresa')?.setValue(idBankValue);
      this.cwEditForm.get('comprobante_empresa')?.setValue(voucherValue);
    } else {
      this.cwEditForm.get('id_banco_empresa')?.setValue(0);
      this.cwEditForm.get('comprobante_empresa')?.setValue('');
    }

    if (
      !(
        this.cwData.tipo_transaccion ===
          TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE ||
        this.cwData.tipo_transaccion ===
          TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION ||
        this.cwData.tipo_transaccion ===
          TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER
      )
    ) {
      if (this.checkBankEquality()) {
        return;
      }
    }

    let cwRequestData = this.cwEditForm.value;

    const errorMessages: { [key: string]: string } = {
      [TRANSACTION_TYPE_VALUES.LOAD_BALANCE]: ERROR_MESSAGES.LOAD_BALANCE,
      [TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE]:
        ERROR_MESSAGES.LOAD_BONUS_BALANCE,
      [TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER]:
        ERROR_MESSAGES.LOAD_RISK_VOUCHER,
      [TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER]:
        ERROR_MESSAGES.WITHDRAW_RISK_VOUCHER,
    };

    if (
      [
        TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
        TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
        TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
        TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
      ].includes(cwRequestData.tipo_transaccion) &&
      !this.previewImage
    ) {
      const errorMessage = errorMessages[cwRequestData.tipo_transaccion];
      this._toastrSvc.error(errorMessage);
      return;
    }

    cwRequestData.tipo_cuenta = this.isSaving
      ? ACCOUNT_TYPES.SAVINGS
      : ACCOUNT_TYPES.CURRENT;

    if (
      this.cwEditForm.valid ||
      this.cwData?.tipo_transaccion === TRANSACTION_TYPE_VALUES.FINANCIAL
    ) {
      cwRequestData = this.convertBooleanFieldsToNumbers(cwRequestData);
      this.isLoadingButton = true;

      if (
        [
          TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT.toString(),
          TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL.toString(),
        ].includes(cwRequestData.tipo_transaccion)
      ) {
        cwRequestData.id_banco = cwRequestData.id_banco || DEFAULT_VALUE;
        cwRequestData.id_banco_empresa =
          cwRequestData.id_banco_empresa || DEFAULT_VALUE;
        cwRequestData.id_titular = cwRequestData.id_titular || DEFAULT_VALUE;
      }

      if (this.cwData?.tipo_transaccion === TRANSACTION_TYPE_VALUES.FINANCIAL) {
        this.alertService
          .showConfirmAlert(
            '¿Estás seguro?',
            'Vas a editar una solicitud enviando datos vacíos!',
            'Sí, editar!',
            'Cancelar'
          )
          .then((result) => {
            if (result.isConfirmed) {
              this.sendFormData(cwRequestData);
            } else {
              this.isLoadingButton = false;
            }
          });
      } else {
        this.sendFormData(cwRequestData);
      }
    } else {
      this._toastrSvc.error(
        'Rellena todo el formulario o verifica el monto mínimo!'
      );
      this.isLoadingButton = false;
    }
  }

  sendFormData(cwRequestData: any) {
    const formData = new FormData();
    const localDate = moment.tz('America/Guayaquil');
    const formattedDate =
      localDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + '+00:00';

    formData.append('actualizado', formattedDate);
    formData.append('tipo_transaccion', cwRequestData.tipo_transaccion);
    formData.append('id', this.data.id.toString());
    formData.append('id_banco', cwRequestData.id_banco);
    const transferenciaInterbancaria =
      cwRequestData.transferencia_interbancaria;
    if (
      transferenciaInterbancaria === INTERBANK_TRANSDFER_OPTIONS_VALUES.NO ||
      transferenciaInterbancaria === INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS
    ) {
      formData.append('id_banco_empresa', cwRequestData.id_banco);
    } else {
      if (
        cwRequestData.id_banco_empresa !== undefined &&
        cwRequestData.id_banco_empresa !== null
      ) {
        formData.append('id_banco_empresa', cwRequestData.id_banco_empresa);
      } else if (this.cwData.id_banco_empresa) {
        formData.append(
          'id_banco_empresa',
          this.cwData.id_banco_empresa.toString()
        );
      } else {
        formData.append('id_banco_empresa', DEFAULT_VALUE.toString());
      }
    }
    if (
      this.cwData.tipo_transaccion ===
        TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE ||
      this.cwData.tipo_transaccion ===
        TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION
    ) {
      formData.append('comprobante', '');
    } else {
      formData.append('comprobante', (cwRequestData.comprobante || '').trim());
    }
    formData.append(
      'comprobante_empresa',
      (cwRequestData.comprobante_empresa || '').trim()
    );
    formData.append(
      'identificacion',
      (cwRequestData.identificacion || '').trim()
    );
    formData.append('detalle', cwRequestData.detalle);
    formData.append('id_usuario_set', this.profile.idusuario);
    formData.append('nombre_titular', cwRequestData.nombre_titular);
    if (
      cwRequestData.id_titular !== undefined &&
      cwRequestData.id_titular !== null
    ) {
      formData.append('id_titular', cwRequestData.id_titular);
    } else if (this.cwData.id_titular) {
      formData.append('id_titular', this.cwData.id_titular.toString());
    } else {
      formData.append('id_titular', DEFAULT_VALUE.toString());
    }
    formData.append(
      'transferencia_interbancaria',
      cwRequestData.transferencia_interbancaria
    );

    if (this.cwData.estado === STATE_NAMES.CORRECTION) {
      formData.append('estado', this.cwData.estado_anterior);
      formData.append('estado_anterior', this.cwData.estado);
    } else {
      formData.append('estado', this.data.estado);
    }
    formData.append('estado_revisar_boleta', this.cwData.estado_revisar_boleta);
    formData.append('estado_tiempo_espera', cwRequestData.estado_tiempo_espera);
    formData.append('permitirComprobante', cwRequestData.permitirComprobante);
    formData.append(
      'estado_boleta_riesgosa',
      cwRequestData.estado_boleta_riesgosa
    );
    formData.append(
      'numero_cuenta',
      (cwRequestData.numero_cuenta || '').trim()
    );
    formData.append('tipo_cuenta', cwRequestData.tipo_cuenta);
    if (this.selectedFiles.length > DEFAULT_VALUE) {
      formData.append(
        'foto_comprobante',
        this.selectedFiles[DEFAULT_VALUE].file
      );
    } else if (this.recipt) {
      formData.append('foto_comprobante', this.recipt);
    } else {
      formData.append('foto_comprobante', '');
    }
    this._toastrSvc.info('Procesando...');
    this._cwService.setRequest(formData).subscribe(
      (response) => {
        this.isLoadingButton = false;
        this.closeModal();
        this._toastrSvc.success('Solicitud editada correctamente!.');
      },
      (err) => {
        this.isLoadingButton = false;
        this._toastrSvc.error('Error al enviar el formulario.');
      }
    );
  }

  setDefaultValues(): void {
    const fields = ['id_banco', 'id_banco_empresa', 'id_titular'];
    fields.forEach((field) => {
      const control = this.cwEditForm.get(field);
      if (!control?.value) {
        control?.setValue(DEFAULT_VALUE);
      }
    });
  }

  validatorsReciptImg() {
    const userEnteredReceipt = this.cwEditForm.get('comprobante')?.value;
    const imageReceipt = this.receipVoucher;
    if (userEnteredReceipt !== imageReceipt && !this.isManual) {
      this.reciptError = true;
    } else {
      this.reciptError = false;
    }
  }

  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  handleConfirmationRt() {
    const currentStatus = this.cwEditForm.get('estado_boleta_riesgosa')?.value;
    Swal.fire({
      title: '¿Estás seguro?',
      text: currentStatus
        ? '¿Estás seguro de marcar Boleta Riesgosa?'
        : '¿Estás seguro de desmarcar Boleta Riesgosa?',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
    }).then((result) => {
      if (result.isConfirmed) {
        const formControl = this.cwEditForm.get('estado_boleta_riesgosa');
        if (formControl) {
          formControl.setValue(currentStatus);
        }

        if (currentStatus) {
          this.cwEditForm.patchValue({
            estado_revisar_boleta: false,
          });
        }
      } else {
        this.cwEditForm.patchValue({
          estado_boleta_riesgosa: !currentStatus,
        });
      }
    });
  }

  handleConfirmationCr() {
    const currentStatus = this.cwEditForm.get('permitirComprobante')?.value;
    Swal.fire({
      title: '¿Estás seguro?',
      text: currentStatus
        ? '¿Estás seguro de marcar Permitir Comporbante Repetido?'
        : '¿Estás seguro de desmarcar Permitir Comporbante Repetido?',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const formControl = this.cwEditForm.get('permitirComprobante');
        if (formControl) {
          formControl.setValue(currentStatus);
        }
      } else {
        this.cwEditForm.patchValue({
          permitirComprobante: !currentStatus,
        });
      }
    });
  }

  handleConfirmationTe() {
    const currentStatus = this.cwEditForm.get('estado_tiempo_espera')?.value;
    Swal.fire({
      title: '¿Estás seguro?',
      text: currentStatus
        ? '¿Estás seguro de marcar Transacción en espera?'
        : '¿Estás seguro de desmarcar Transacción en espera?',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const formControl = this.cwEditForm.get('estado_tiempo_espera');
        if (formControl) {
          formControl.setValue(currentStatus);
        }
      } else {
        this.cwEditForm.patchValue({
          estado_tiempo_espera: !currentStatus,
        });
      }
    });
  }

  handleConfirmationRb() {
    const currentStatus = this.cwEditForm.get('estado_revisar_boleta')?.value;
    Swal.fire({
      title: '¿Estás seguro?',
      text: currentStatus
        ? '¿Estás seguro de marcar Revisar boleta?'
        : '¿Estás seguro de desmarcar Revisar boleta?',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const formControl = this.cwEditForm.get('estado_revisar_boleta');
        if (formControl) {
          formControl.setValue(currentStatus);
        }

        if (currentStatus) {
          this.cwEditForm.patchValue({
            estado_boleta_riesgosa: false,
          });
        }
      } else {
        this.cwEditForm.patchValue({
          estado_revisar_boleta: !currentStatus,
        });
      }
    });
  }

  handleCheckboxChange(checkboxName: string) {
    if (checkboxName === 'estado_boleta_riesgosa') {
      this.handleConfirmationRt();
    }
    if (checkboxName === 'estado_revisar_boleta') {
      this.handleConfirmationRb();
    }
    if (checkboxName === 'permitirComprobante') {
      this.handleConfirmationCr();
    }
    if (checkboxName === 'estado_tiempo_espera') {
      this.handleConfirmationTe();
    } else {
      const formControl = this.cwEditForm.get(checkboxName);

      if (formControl) {
        if (formControl.value) {
          if (checkboxName === 'estado_revisar_boleta') {
            this.cwEditForm.patchValue({
              estado_boleta_riesgosa: false,
            });
          } else if (checkboxName === 'estado_boleta_riesgosa') {
            this.cwEditForm.patchValue({
              estado_revisar_boleta: false,
            });
          }
        }
      }
    }
  }

  updateTransactionValidators(
    transactionType: string,
    tipo_usuario: string
  ): void {
    if (this.updatingValidators) return;

    this.updatingValidators = true;

    if (this.cwData?.tipo_transaccion === TRANSACTION_TYPE_VALUES.FINANCIAL) {
      this.clearAllValidators();
      this.cwEditForm.updateValueAndValidity();
      this.updatingValidators = false;
      return;
    }

    this.clearAllValidators();

    const validatorsRequired = Validators.required;

    if (
      [
        TRANSACTION_TYPE_VALUES.LOAD_BALANCE.toString(),
        TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE.toString(),
        TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT.toString(),
      ].includes(transactionType)
    ) {
      this.setValidators(
        ['monto', 'id_banco', 'comprobante'],
        validatorsRequired
      );
      if (tipo_usuario !== USER_TYPES.AGENT_LEVEL_ONE.toString()) {
        this.setValidators(
          ['id_agente', 'id_jugador', 'id_titular'],
          validatorsRequired
        );
      }
    } else if (['1', '4'].includes(transactionType)) {
      this.setValidators(
        ['monto', 'id_banco', 'identificacion', 'numero_cuenta'],
        validatorsRequired
      );
      if (tipo_usuario !== USER_TYPES.AGENT_LEVEL_ONE.toString()) {
        this.setValidators(
          ['id_agente', 'id_jugador', 'id_titular'],
          validatorsRequired
        );
      }
    } else if (['6', '7'].includes(transactionType)) {
      this.setValidators(['monto'], validatorsRequired);
      if (tipo_usuario !== USER_TYPES.AGENT_LEVEL_ONE.toString()) {
        this.setValidators(['id_agente', 'id_jugador'], validatorsRequired);
      }
    }

    this.cwEditForm.updateValueAndValidity();

    this.updatingValidators = false;
  }

  clearAllValidators(): void {
    Object.keys(this.cwEditForm.controls).forEach((key) => {
      this.cwEditForm.get(key)?.clearValidators();
      this.cwEditForm.get(key)?.updateValueAndValidity();
    });
  }

  setValidators(fields: string[], validator: ValidatorFn): void {
    fields.forEach((field) => {
      const control = this.cwEditForm.get(field);
      if (control) {
        control.setValidators(validator);
        control.updateValueAndValidity();
      }
    });
  }

  checkFormStatus() {
    const invalidFields = [];
    const formControls = this.cwEditForm.controls;
    for (const name in formControls) {
      if (formControls[name].invalid) {
        invalidFields.push(name);
      }
    }
    if (invalidFields.length > DEFAULT_VALUE) {
    } else {
    }
  }

  checkBankEquality(): boolean {
    const transferencia_interbancaria = this.cwEditForm.get(
      'transferencia_interbancaria'
    )?.value;

    if (
      transferencia_interbancaria ===
        INTERBANK_TRANSDFER_OPTIONS_VALUES.YES.toString() &&
      ![
        TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL,
        TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT,
        TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
        TRANSACTION_TYPE_VALUES.FINANCIAL,
      ].includes(this.cwEditForm.get('tipo_transaccion')?.value)
    ) {
      const id_banco = this.cwEditForm.get('id_banco')?.value;
      const id_banco_empresa = this.cwEditForm.get('id_banco_empresa')?.value;
      if (id_banco === id_banco_empresa) {
        if (
          this.cwData?.tipo_transaccion !== TRANSACTION_TYPE_VALUES.FINANCIAL
        ) {
          this._toastrSvc.error('Los bancos no pueden ser iguales!', '', {
            progressBar: true,
            positionClass: 'custom-toastr-top',
          });
        }
        return true;
      }
    }
    return false;
  }

  isTitularEnabled(): boolean {
    const tipoTransaccion = this.cwEditForm.get('tipo_transaccion')?.value;
    const interbankTransfer = this.cwEditForm.get(
      'transferencia_interbancaria'
    )?.value;
    const isBankEnterpriseSelected =
      !!this.cwEditForm.get('id_banco_empresa')?.value;
    const isWithdrawTransaction = [
      TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
    ].includes(tipoTransaccion);
    const isInterbankTransfer = [
      INTERBANK_TRANSDFER_OPTIONS_VALUES.YES,
      INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS,
    ].includes(interbankTransfer);

    return (
      isBankEnterpriseSelected ||
      !(isWithdrawTransaction || isInterbankTransfer)
    );
  }

  toggleTitularControl(): void {
    if (this.isTitularEnabled()) {
      this.cwEditForm.get('id_titular')?.enable();
    } else {
      this.cwEditForm.get('id_titular')?.disable();
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  isTransactionTypeAllowed(
    transactionType: string | number,
    allowedTypes: (string | number)[]
  ): boolean {
    return allowedTypes.map(String).includes(String(transactionType));
  }

  isBankSelected(): boolean {
    return !!this.cwEditForm.get('id_banco')?.value;
  }

  get isInterbankTransaction(): boolean {
    const validInterbankOptions = [
      INTERBANK_TRANSDFER_OPTIONS_VALUES.YES,
      INTERBANK_TRANSDFER_OPTIONS_VALUES.OTHERS,
      INTERBANK_TRANSDFER_OPTIONS_VALUES.NO,
    ];

    return validInterbankOptions.includes(
      this.cwEditForm.get('transferencia_interbancaria')?.value
    );
  }
}
