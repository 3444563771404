import { Component } from '@angular/core';
@Component({
  selector: 'app-list-lw',
  templateUrl: './list-lw.component.html',
  styleUrl: './list-lw.component.scss',
})
export class ListLwComponent {

  constructor() {}
}
