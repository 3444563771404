import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UnlockGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(): boolean {
    if (this.authService.isLocked()) {
      this.router.navigate(['/auth/unlock-user']);
      return false;
    }
    return true;
  }

  /**
   * THIS ALLOWS YOU TO CONTROL THE CHILD
   * ROUTES TO PREVENT GOING BACK IF THE
   * USER IS BLOCKED
   **/

  canActivateChild(): boolean {
    return this.canActivate();
  }
}
