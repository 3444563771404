import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BootstrapModalService } from '../../../../../../core/services/boostrap-modal.service';

@Component({
  selector: 'app-modal-view-details-bonus-code',
  templateUrl: './modal-view-details-bonus-code.component.html',
  styleUrl: './modal-view-details-bonus-code.component.scss'
})
export class ModalViewDetailsBonusCodeComponent {
  private _subscriptions: Subscription[] = [];
  //private _selectedBanner: IBannerAccount | undefined = undefined;
  constructor(
    private _toastr: ToastrService,
    private _bsModalService: BootstrapModalService,
    //private _bannerService: BannerService,
   // private bankListService: BankListService,
  ) {}

  ngOnInit(): void {
  /*   this.getModalData(); */
  }

 /*  getModalData(): void {
    this._subscriptions.push(
      this._bsModalService.getDataIssued().subscribe(
        (response) => (this._selectedBanner = response.banner),
        (error) => this.handleError(error),
      ),
    );
  } */

  desactiveBanner(): void {
   /*  if (!this._selectedBanner || !this._selectedBanner._id) return;
    this._subscriptions.push(
      this._bannerService.deleteBanner(this._selectedBanner._id).subscribe(
        (response) => (this.handleSuccess(response.message), this.closeModal()),
        (error) => this.handleError(error),
      ),
    ); */

  }

  closeModal(): void {
    this._bsModalService.updateModalClosed(true);
  }

  private handleError(error: any): void {
    this._toastr.error("Internal server error", "Error");
  }

  private handleSuccess(message: { en: string; es: string }): void {
    this._toastr.success(message.en, "Success");
    //this.bankListService.notifyBankRestored();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
