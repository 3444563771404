import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TRANSACTION_TYPE_VALUES } from '../../../data/suscriptions-payments/constants';
import { environment } from '../../../../../environments/environment';
import { FinancialService } from '../../../../core/services/financial.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-forms-modal',
  templateUrl: './forms-modal.component.html',
  styleUrl: './forms-modal.component.scss',
})
export class FormsModalComponent implements OnInit {
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
  dynamicComponentContainer!: ViewContainerRef;
  @Input() component: any;
  componentRef!: ComponentRef<any>;
  @Input()
  title!: string;
  public isChatOpen: boolean = false;
  @Input() isEditForm!: boolean;
  @Input() id_financiero!: string;
  @Input() tipoTransaccion!: number;
  @Input() fotoComprobante!: string;
  @Input() id_material!: number;
  @Input() is_edit_material!: boolean;
  public TYPE_TRANSACTION = TRANSACTION_TYPE_VALUES;
  public imgSrc: SafeResourceUrl | string = '';
  public urlResponse!: string;
  public file: SafeResourceUrl | string = '';
  public baseUrl: string = environment.API_FILES;

  constructor(
    public activeModal: NgbActiveModal,
    config: NgbModalConfig,
    private resolver: ComponentFactoryResolver,
    private _financialService: FinancialService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  showModal: boolean = false;

  ngAfterViewInit() {
    if (this.component) {
      const componentFactory = this.resolver.resolveComponentFactory(
        this.component
      );
      this.dynamicComponentContainer.clear();
      this.componentRef =
        this.dynamicComponentContainer.createComponent(componentFactory);

      if (this.componentRef.instance) {
        this.componentRef.instance.isEditForm = this.isEditForm;
        this.componentRef.instance.tipoTransaccion = this.tipoTransaccion;
        this.componentRef.instance.fotoComprobante = this.fotoComprobante;
        this.componentRef.instance.id_material = this.id_material;
        this.componentRef.instance.is_edit_material = this.is_edit_material;
      }
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.getImgCw();
  }
  getImgCw() {
    if (this.tipoTransaccion === TRANSACTION_TYPE_VALUES.FINANCIAL) {
      if (this.id_financiero) {
        this._financialService
          .getFinancialMovement(this.id_financiero)
          .subscribe((response) => {
            const document = response.data.documents[0];
            if (document) {
              this.fotoComprobante = document.ruta;
              this.urlResponse = this.fotoComprobante
                .replace(/ /g, '%20')
                .replace(/\\/g, '/');

              const fullUrl = `${this.baseUrl}/${this.urlResponse}`;

              if (document.tipo === 'application/pdf') {
                this.imgSrc = ''; // No es una imagen
                this.file = this.getSafeUrl(fullUrl + '#zoom=50'); // Incluye zoom aquí
              } else {
                this.imgSrc = this.getSafeUrl(fullUrl); // Imagen
                this.file = '';
              }
            } else {
              this.imgSrc = '';
            }
          });
      }
    }
  }

  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  toggleChat() {
    this.isChatOpen = !this.isChatOpen;
  }
}
