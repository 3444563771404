<div class="container-fluid p-0">
    <div class="comingsoon auth-bg-video">
        <video class="bgvideo-comingsoon" id="bgvid" poster="assets/images/other-images/coming-soon-bg.jpg"
            playsinline="" autoplay="" muted="" loop="">
            <source src="assets/video/auth-bg.mp4" type="video/mp4">
        </video>
        <div class="comingsoon-inner text-center">
            <img class="img-100" src="assets/images/other-images/coming-soon-Logo.png" alt="">
            <h5>WE ARE COMING SOON</h5>
            <div class="countdown" id="clockdiv">
                <ul>
                    <li><span class="time digits">{{ days }}</span><span class="title">days</span></li>
          <li><span class="time digits">{{ hours }}</span><span class="title">Hours</span></li>
          <li><span class="time digits">{{ minutes }}</span><span class="title">Minutes</span></li>
          <li><span class="time digits">{{ seconds }}</span><span class="title">Seconds</span></li>
                </ul>
            </div>
            <div><a class="btn btn-primary-gradien btn-lg" routerLink='/dashboard/default'><i class="fa-solid fa-house fa-lg" style="color: #ffffff;"></i></a></div>
        </div>
    </div>
</div>
