import { createReducer, on } from '@ngrx/store';
// import { User } from 'src/app/core/interfaces/user.interface';
import { AuthActions } from './auth.actions';
import { User } from '../../interfaces/user.interface';

export const initialState: Readonly<User> = {
  //interface user
  idusuario: -1,
  id_usuproveedor: -1,
  id_usuproveedor2: -1,
  id_usuproveedor3: -1,
  id_externo: '',
  tiene_ruc: 0,
  identificacion: '',
  actividad_principal: '',
  nombres: '',
  celular: '',
  usuario: '',
  contrasena: '',
  tipo_usuario: -1,
  tipo_usuario_admin: -1,
  activar: -1,
  fecha_creacion: new Date(),
  porcentaje_propio: -1,
  porcentaje_slot: -1,
  arrastre: -1,
  porcentaje_fijo: -1,
  actividad_agente: -1,
  porcentaje_dar: -1,
  porcentaje_dar2: -1,
  porcentaje_dar3: -1,
  requerimientos: '',
  fecha_nacimiento: new Date(),
  id_provincia: -1,
  ciudad: '',
  direccion: '',
  correo: '',
  tipo_cierre: -1,
  autorizar_prestamo: -1,
  entorno: '',
  buscar_palabra1: '',
  buscar_palabra2: '',
  buscar_palabra3: '',
  observacion: '',
  permisos_root: -1,
  cargar_desde: '',
  id_excel: -1,
  comprobar: '',
  created_at: new Date(),
  updated_at: new Date(),
  id_pais: -1,
  grupo_ws: '',
  bono_mes: -1,
  porcentaje_apostado: -1,
  actualizado: new Date(),
  id_usuario_add: -1,
  id_usuario_set: -1,
  id_grupo_banco: -1,
  token_app: '',
  id_ciudad: -1,
  foto_cedula_frente: '',
  foto_cedula_detras: '',
  foto_selfie: '',
  detalle_editado_movil: '',
  clave_agent: '',
  TFA: 0,
  twofactorysecret: '',
  bono_ilimitado: 0,
  id_rol : 0

};

export const authReducer = createReducer(
  initialState,
  on(AuthActions['[Auth]LoadPreviewSession'], (state) => {
    return { ...state, loading: true };
  }),
  on(AuthActions['[Auth]RequestLogin'], (state) => {
    return { ...state, loading: true };
  }),
  on(AuthActions['[Auth]LoginSuccess'], (state, payload) => {
    return { ...state, ...payload, loading: false };
  }),
  on(AuthActions['[Auth]LoginFailed'], (state, payload) => {
    return { ...state, message: payload.message, loading: false };
  }),
  on(AuthActions['[Auth]Logout'], () => {
    return { ...initialState };
  }),
  on(AuthActions['[Auth]UpdateUser'], (state, payload) => {
    return { ...state, ...payload, loading: false };
  }),
  on(AuthActions['[Auth]RequestUpdateUser'], (state) => {
    return { ...state, loading: true };
  }),
  on(AuthActions['[Auth]UpdateFailed'], (state, payload) => {
    return { ...state, message: payload.message, loading: false };
  }),
  on(AuthActions['[Auth]AccountState'], (state, payload) => {
    return { ...state, hours: payload.password, loading: false };
  }),
  on(AuthActions['[Auth]UpdateData'], (state, payload) => {
    return { ...state, balance: payload.balance, loading: false };
  }),
  on(AuthActions['[Auth]TwoFactorAuth'], (state, payload) => {
    return { ...state, twoFactorAuth: payload.twoFactorAuth, loading: false };
  }),
  on(AuthActions['[Auth]DesactiveTwoFactorAuth'], (state, payload) => {
    return { ...state, loading: false };
  })
);
export const pre_auth = createReducer(
  initialState,
  on(AuthActions['[Auth]LoadPrePreviewSession'], (state) => {
    return { ...state, loading: true };
  }),
  on(AuthActions['[Auth]LoginPre-Success'], (state, payload) => {
    return { ...state, ...payload, loading: true };
  })
);
