import { ActionReducerMap } from '@ngrx/store';
import { languageReducer } from './language/language.reducers';
import { Language } from '../interfaces/language.interface';
import { Screen } from '../interfaces/screen.interface';
// import { User } from '../../core/interfaces/user.interface';
// import { authReducer, pre_auth } from './auth/auth.reducers';
// import { betSlipReducer } from './bet-slip/bet-slip.reducers';
// import {
//   Bets
// } from '../interfaces/sport-event.interface';

// import { MenuData } from '../interfaces/quickAccess.interface';
// import { leagueReducer } from './league/league.reducer';
import * as authEffects from './auth/auth.effects';
import { User } from '../interfaces/user.interface';
import { authReducer, pre_auth } from './auth/auth.reducers';
import { Config } from '../interfaces/theme.interface';
import { themeReducer } from './theme/theme.reducers';
import { screenReducer } from './screen/screen.reducers';
// import * as leagueEffects from './league/league.effects';
// import { BetSlipEffects } from './bet-slip/bet-slip.effects';
// import { expandedSidebarReducer } from './sidebar/expanded-sidebar.reducer';

export interface AppState {
  auth: Readonly<any>;
  pre_auth: Readonly<User>;
  language: Readonly<Language>;
  theme: Readonly<Config>;
  screen: Readonly<Screen>;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  language: languageReducer,
  auth: authReducer,
  theme: themeReducer,
  pre_auth: pre_auth,
  screen: screenReducer,
};

// export const ROOT_EFFECTS = [authEffects, leagueEffects, BetSlipEffects];
export const ROOT_EFFECTS = [authEffects];
