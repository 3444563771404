<div class="modal-header d-flex justify-content-between">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    class="bg-transparent border-0 fs-6"
    type="button"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fas fa-times" style="color: #000000"></i>
  </button>
</div>
<div class="modal-body">
  <ng-container #dynamicComponentContainer></ng-container>
  @if (isEditForm && tipoTransaccion === TYPE_TRANSACTION.FINANCIAL) {
  <div class="chat-bubble" (click)="toggleChat()">
    <i class="fas fa-image"></i>
  </div>

  <div class="chat-container" [class.chat-open]="isChatOpen">
    <div class="chat-header">
      <h5>Respaldo Movimiento Financiero</h5>
    </div>
    <div class="chat-body">
      @if (imgSrc) {
      <img [src]="imgSrc" alt="Imagen de solicitud" class="img-fluid mt-3" />
      }@else if(file){
      <iframe
        [src]="file"
        type="application/pdf"
        width="100%"
        height="500"
        style="border: none"
      ></iframe>
      }
    </div>
  </div>
  }
</div>
