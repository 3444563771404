<div class="container-fluid">
  <div class="edit-profile">
    <div class="row">
      <div class="col-xl-4">
        <app-my-profile></app-my-profile>
      </div>
      <div class="col-xl-8 mt-responsive">
        <app-edit-profile></app-edit-profile>
      </div>
      <div class="col-md-12">
        <app-security-settings></app-security-settings>
      </div>
      @if (![roles.AGENT, roles.AFFILIATE].includes(rolId)) {
      <div class="col-md-12">
        <app-bank-settings></app-bank-settings>
      </div>
      }
    </div>
  </div>
</div>
