<div class="modal-body">
    <div class="row">
      <div class="col">
        <h5>Old Values</h5>
        @if (oldValueIsObject) {
        <ul>
          @for (keyValuePair of data.oldvalue | keyvalue; track $index) {
          <li>{{ keyValuePair.key }}: {{ keyValuePair.value }}</li>
          }@empty {
          <p>Sin cambios entrantes</p>
          }
        </ul>
        } @if (!oldValueIsObject) { @if (data.oldvalue) {
        <p>{{ data.oldvalue | json }}</p>
        }@else {
  
        <p>Sin cambios entrantes</p>
        } }
      </div>
      <div class="col">
        <h5>New Values</h5>
        @if (newValueIsObject) {
        <ul>
          @for (keyValuePair of data.newvalue | keyvalue; track $index) {
          <li>{{ keyValuePair.key }}: {{ keyValuePair.value }}</li>
          }@empty {
          <p>Sin cambios entrantes</p>
          }
        </ul>
        } @if (!newValueIsObject) { @if (data.newvalue) {
        <p>{{ data.newvalue | json }}</p>
        }@else {
        <p>Sin cambios entrantes</p>
        } }
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="activeModal.close('Close click')"
    >
      Close
    </button>
  </div>
  