import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AffiliateEndpointEnum, ApiPathEnum } from '../constants/endpoints.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {

  public API_URL = `${environment.API_URL}${ApiPathEnum.Affiliate}`;

  constructor(private http: HttpClient) { }

  getAffiliateInfo(filters?: any): Observable<any> {
    let params = new HttpParams();

    if (filters) {
      if (filters.id_usuario_agente) {
        params = params.append('id_usuario_agente', filters.id_usuario_agente);
      }
      if (filters.datestart) {
        params = params.append('startDate', filters.datestart);
      }
      if (filters.dateend) {
        params = params.append('endDate', filters.dateend);
      }
    }
    return this.http.get<any>(
      `${this.API_URL}${AffiliateEndpointEnum.AffiliateInfoData}`,
      { params }
    );
  }
}
