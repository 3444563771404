import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  ApiPathEnum,
  CityEndPointEnum,
  CountryEndPointEnum,
  ProvincesEndPointEnum,
} from '../constants/endpoints.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  public API_URL = `${environment.API_URL}${ApiPathEnum.Country}`;
  public API_URL_CITY = `${environment.API_URL}${ApiPathEnum.City}`;
  public API_URL_PROVINCE = `${environment.API_URL}${ApiPathEnum.Province}`;

  constructor(private http: HttpClient) {}

  getAllCountries(): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL}${CountryEndPointEnum.GetCountryData}`
    );
  }

  getAllCity(
    page: number,
    limit: number,
    searchTerm?: string,
    id_provincia?: number
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    if (id_provincia) {
      params = params.set('id_provincia', id_provincia.toString());
    }
    return this.http.get<any>(
      `${this.API_URL_CITY}${CityEndPointEnum.GetCityData}`,
      { params }
    );
  }

  getAllProvinces(
    page: number,
    limit: number,
    searchTerm?: string,
    id_pais?: number
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    if (id_pais) {
      params = params.set('id_pais', id_pais.toString());
    }
    return this.http.get<any>(
      `${this.API_URL_PROVINCE}${ProvincesEndPointEnum.GetProvincesData}`,
      { params }
    );
  }

  getAllCountriesByfilters(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    return this.http.get<any>(
      `${this.API_URL}${CountryEndPointEnum.GetCountryDataByFilter}`,
      { params }
    );
  }

  getCountryById(id: number): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL}${CountryEndPointEnum.GetCountryById}`,
      { id }
    );
  }
  getProvincesById(id: number): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL_PROVINCE}${ProvincesEndPointEnum.GetProvincesById}`,
      { id }
    );
  }
  getCityById(id: number): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL_CITY}${CityEndPointEnum.GetCityById}`,
      { id }
    );
  }
}
