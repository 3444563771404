@if (selectSettings.layout_version === ''||selectSettings.layout_version ===
'light-only') {
<ngx-spinner
  bdColor="rgb(255,255,255)"
  size="medium"
  color="var(--primary-button-color)"
  type="ball-fall"
  [fullScreen]="true"
  ><p style="color: black">Cargando...</p></ngx-spinner
>
}@else {
<ngx-spinner
  bdColor="rgb(28,29,38)"
  size="medium"
  color="var(--primary-button-color)"
  type="ball-fall"
  [fullScreen]="true"
  ><p style="color: white">Cargando...</p></ngx-spinner
>
}
<app-tap-to-top></app-tap-to-top>
<router-outlet></router-outlet>
<lib-toastify-toast-container
  [transition]="'slide'"
  [iconLibrary]="'font-awesome'"
></lib-toastify-toast-container>
