<div class="email-right-aside bookmark-tabcontent">
  <div class="card email-body radius-left">
    <div class="ps-0">
      <div class="tab-content">
        <div
          class="tab-pane fade active show"
          id="pills-created"
          role="tabpanel"
          aria-labelledby="pills-created-tab"
        >
          <div class="card mb-0">
            <div
              class="card-header d-flex justify-content-between align-items-center"
            >
              <h5 class="mb-0">Herramientas de Marketing</h5>
              <div class="input-group-append" style="margin-right: -12px">
                <button
                  class="primary-button-sm me-2"
                  type="button"
                  (click)="downloadQrCode()"
                  [disabled]="!this.selectedFilter?.id_usuario_agente"
                  [ngbTooltip]="
                    this.selectedFilter?.id_usuario_agente
                      ? 'Descargar QrCode'
                      : 'Selecciona un afiliado para descargar el QrCode'
                  "
                  tooltipClass="custom-tooltip"
                >
                  <i class="fa-solid fa-qrcode"></i>
                </button>
                <button
                  class="primary-button-sm"
                  type="button"
                  (click)="downloadImage()"
                  [disabled]="!this.selectedFilter?.id_usuario_agente"
                  [ngbTooltip]="
                    this.selectedFilter?.id_usuario_agente
                      ? 'Descargar Banner'
                      : 'Selecciona un afiliado para descargar el banner'
                  "
                  tooltipClass="custom-tooltip"
                >
                  <i class="fa fa-download"></i>
                </button>
              </div>
            </div>
            <div class="card-body p-3 d-flex flex-column align-items-center">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="clipboardExample2"
                  spellcheck="false"
                  #textareaInput
                  readonly
                  style="padding-bottom: 0px; padding-top: 0px"
                  [value]="inputUrl"
                />
                <div class="input-group-append">
                  <button
                    class="primary-button-sm"
                    type="button"
                    (click)="copyInputMessage(textareaInput)"
                    [disabled]="!this.selectedFilter?.id_usuario_agente"
                    [ngbTooltip]="
                      this.selectedFilter?.id_usuario_agente
                        ? 'Copiar URL'
                        : 'Selecciona un afiliado para generar la URL'
                    "
                    tooltipClass="custom-tooltip"
                  >
                    <i class="fa fa-copy"></i>
                  </button>
                </div>
              </div>
              @if(!this.selectedFilter?.id_usuario_agente){
              <div class="qr-container mt-3" #qrContainer>
                <img
                  src="../../../../../../assets/images/AFILIADO.png"
                  alt="Descripción de la imagen"
                  class="img-fluid rounded"
                />
              </div>
              } @else {
              <div class="qr-container mt-3" #qrContainer>
                <img
                  src="../../../../../../assets/images/bannerQr2.jpg"
                  alt="Descripción de la imagen"
                  class="img-fluid rounded"
                />
                <div class="user-info">{{ usuario }}</div>
                <qrcode
                  [qrdata]="this.url"
                  [allowEmptyString]="true"
                  [cssClass]="'center'"
                  [colorDark]="'#187522'"
                  [colorLight]="'#ffffffff'"
                  [elementType]="'canvas'"
                  [errorCorrectionLevel]="'H'"
                  [imageSrc]="'../../../../../../assets/images/icono.png'"
                  [imageHeight]="50"
                  [imageWidth]="50"
                  [margin]="4"
                  [scale]="1"
                  [width]="130"
                  class="qr-code"
                ></qrcode>
                <qrcode
                  #qrCode
                  style="z-index: -1"
                  [qrdata]="this.url"
                  [allowEmptyString]="true"
                  [cssClass]="'center'"
                  [colorDark]="'#187522'"
                  [colorLight]="'#ffffffff'"
                  [elementType]="'canvas'"
                  [errorCorrectionLevel]="'H'"
                  [imageHeight]="100"
                  [imageWidth]="100"
                  [margin]="0"
                  [scale]="1"
                  [width]="256"
                  class="qr-code"
                ></qrcode>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
