import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
@Injectable({
  providedIn: 'root',
})
export class MobileGuard {
  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute
  ) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // const fullUrl = this._activatedRoute;
    const url = state.url;
    const token = url.split('/').pop()?.split('?')[0];
    const queryParams = url.split('?')[1];
    return await new Promise((resolve, reject) => {
      this._userService.getUserByTokenApp(token as string).subscribe({
        next: (res) => {
          console.log(res);
          resolve(res);
          return true;
        },
        error: (error: any) => {
          reject(error);
          let urlRedirect = '/full/error-page/error-403';
          if (queryParams === 'isModeDark=true') {
            urlRedirect = '' + urlRedirect + '?' + queryParams + '';
          }
          this._router.navigateByUrl(urlRedirect);
          return false;
        },
      });
    });
  }
}
