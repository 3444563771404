import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { selectPreAuth } from '../../../../../core/state/auth/auth.selector';
import { UserService } from '../../../../../core/services/user.service';
import { AuthActions } from '../../../../../core/state/auth/auth.actions';
import { ToastService } from 'angular-toastify';

@Component({
  selector: 'app-tfa-modal',
  templateUrl: './tfa-modal.component.html',
  styleUrl: './tfa-modal.component.scss',
})
export class TfaModalComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('tfaModal') tfaModal!: ElementRef;
  tfaForm: FormGroup;
  profile: any;
  id: string = '';
  isgood: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private toast: ToastService,
    private fb: FormBuilder,
    private store: Store,
    private _userService: UserService
  ) {
    this.tfaForm = this.fb.group({
      TFA: ['', Validators.required],
    });
  }

  public profile$ = this.store.select(selectPreAuth);

  ngOnInit(): void {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario) {
        this.id = profile.idusuario;
      }
    });
  }

  ngAfterViewInit() {
    this.registerModalEvent();
  }

  registerModalEvent() {
    const modal = this.tfaModal.nativeElement;
    modal.addEventListener('hidden.bs.modal', () => {
      if (!this.isgood) {
        this.store.dispatch(AuthActions['[Auth]Logout']());
        this.toastr.info(
          'Ingrese el codigo de autenticación para iniciar sesión correctamente',
          '',
          {
            timeOut: 5000,
          }
        );
      }
    });
  }

  onSubmit() {
    const twoFactorAuth = this.tfaForm.value;
    this._userService.verifyLogin(this.id, twoFactorAuth.TFA).subscribe({
      next: (response) => {
        this.isgood = true;
        this._userService.getUser(response.data.idusuario).subscribe({
          next: (response) => {
            this.store.dispatch(
              AuthActions['[Auth]LoginSuccess'](response.data)
            );
            this.toast.default(`Bienvenido, ${response.data.usuario}`);
            this.closebutton.nativeElement.click();
          },
          error: (error) => {},
        });
      },
      error: (error) => {
        const invalidFeedback = document.querySelector('.invalid-feedback');
        if (invalidFeedback) {
          invalidFeedback.textContent =
            'El código de autenticación es incorrecto.';
        }
      },
    });
  }
}
