<div class="d-flex justify-content-between">
  <h5>Estadistica</h5>
  <div class="text-end">
    <button
      type="button"
      class="primary-button-sm mb-3 me-3"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample"
    >
      <i class="fas fa-filter"></i>
    </button>
  </div>
</div>
<div id="collapseExample" [ngbCollapse]="isCollapsed">
  <div class="card mb-0 fixed-width-card">
    <div class="card-body text-start" style="padding: 11px">
      <form [formGroup]="affiliateFilterForm">
        <div class="d-flex flex-wrap justify-content-between">
          @if (this.profile?.id_rol !== ROLES.AFFILIATE) {
          <div class="mb-3 me-3 flex-grow-1">
            <label class="col-form-label">Afiliado</label>
            <ng-select
              [items]="allAffiliatesData"
              bindLabel="label"
              bindValue="value"
              class="mb-2 w-100 fixed-width-select"
              formControlName="id_usuario_agente"
              placeholder="Selecciona una opción"
              (scrollToEnd)="onScrollToEnd()"
              (search)="onSearch($event.term)"
              (clear)="onSearch('')"
              (open)="onSearch('')"
            ></ng-select>
          </div>
          }
          <div class="mb-3 flex-grow-1">
            <label class="col-form-label">Fecha</label>
            <shared-datepicker-v1
              [initialRange]="initialRange"
              (onRangeChange)="onDateSelected($event)"
            />
          </div>
        </div>
      </form>
      <div class="text-end" style="padding-right: 2%">
        @if (this.profile?.id_rol !== ROLES.AFFILIATE) {
        <button
          type="button"
          class="primary-button-cancel me-1"
          (click)="resetForm()"
        >
          Restablecer
        </button>
        }
        <button class="primary-button me-1" (click)="sendFilter()">
          Filtrar
        </button>
      </div>
    </div>
  </div>
</div>
