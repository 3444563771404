import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../core/services/auth.service';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../../../../core/state/auth/auth.actions';
import { selectAuth } from '../../../../../core/state/auth/auth.selector';
import { UserService } from '../../../../../core/services/user.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  public userName!: string;
  public profileImg: string = 'assets/images/dashboard/profile.jpg';
  profile$ = this.store.select(selectAuth);
  public username: string = '';
  public userType: string = '';
  public photoSelfie: string = '';
  public id: number = 0;
  public idusuario: string = '';
  public nombre_rol: string = '';
  public userLevelName: string = '';
  public previewImage: any;
  public baseUrl: string = environment.API_FILES;

  constructor(
    public router: Router,
    private store: Store,
    private _userService: UserService
  ) {}

  ngOnInit() {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario != -1) {
        this.id = profile.idusuario;
        this.idusuario = profile.idusuario.toString();
        this.username = profile.usuario;
        this.photoSelfie = profile.foto_selfie;
        this.previewImage = this.formatUrl(profile.foto_selfie);
        this.getUserType();
        this.getUser();
      }
    });
  }

  formatUrl(url: string) {
    return this.baseUrl + '/' + url.replace(/ /g, '%20').replace(/\\/g, '/');
  }

  getUserType() {
    this._userService.getUserType(this.id).subscribe((response) => {
      this.userLevelName = response.data.userlevelname;
    });
  }
  getUser() {
    this._userService.getUser(this.idusuario).subscribe((response) => {
      this.nombre_rol = response.data.rol.nombre_rol

    });
  }

  logoutFunc() {
    this.store.dispatch(AuthActions['[Auth]Logout']());
    this.router.navigateByUrl('auth/login');
  }

  hasExtension(url: string): boolean {
    return /\.(jpg|jpeg|png|gif|bmp)$/.test(url);
  }
}
