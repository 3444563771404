import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private isMobileSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isMobile$: Observable<boolean> = this.isMobileSubject.asObservable();

  private isBelow900Subject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isBelow900$: Observable<boolean> = this.isBelow900Subject.asObservable();

  constructor() {
    this.detectScreenSize();
    window.addEventListener('resize', () => {
      this.detectScreenSize();
    });
  }

  private detectScreenSize() {
    const width = window.innerWidth;
    const isMobile = width <= 768;
    const isBelow900 = width <= 960;

    this.isMobileSubject.next(isMobile);
    this.isBelow900Subject.next(isBelow900);
  }
}
