<input
  type="text"
  placeholder="Daterangepicker"
  class="form-control"
  bsDaterangepicker
  [minDate]="minDate"
  [maxDate]="maxDate"
  [bsValue]="initialRange"
  [bsConfig]="bsConfig"
  (bsValueChange)="onValueChange($event)"
/>
