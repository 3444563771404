import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { delay, filter, map, withLatestFrom } from 'rxjs/operators';
import { AVAILABLE_THEME } from './core/constants/constants';
import { Settings } from './core/interfaces/theme.interface';
import { ScreenSizeService } from './core/services/screen-size.service';
import { SocketService } from './core/services/socket.service';
import { ScreenActions } from './core/state/screen/screen.actions';
import { selectScreen } from './core/state/screen/screen.selectors';
import { ThemeActions } from './core/state/theme/theme.actions';
import { selectConfig } from './core/state/theme/theme.selectors';
import { selectAuth } from './core/state/auth/auth.selector';
import { UserService } from './core/services/user.service';
import { InactivityService } from './shared/services/inactivity.service';
import { ROLES } from './shared/data/config/constants';
import { User } from './core/interfaces/user.interface';
import {
  MainPermission,
  Permission,
} from './core/interfaces/permision.interface';
import { USER_LIST_PERMISSIONS } from './shared/data/config/constants-buttons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'frontend-base-project';

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );
  selectSettings!: Settings;
  messageSubscription: Subscription;
  private audioContext: AudioContext;
  private audioBuffer!: AudioBuffer;
  private audioContextFinancial: AudioContext;
  private audioBufferFinancial!: AudioBuffer;
  private routerSubscription!: Subscription;
  screenSizeSubscription!: Subscription;
  public isMobile$ = this.store.select(selectScreen);
  public profile$ = this.store.select(selectAuth);
  public profile!: User;
  idRol!: number;
  isMobile!: Boolean;
  lastParameter!: string;
  public isMobileRedux!: boolean;
  public isModeDarkParam!: boolean;
  public firtsTimeForParam: boolean = false;
  constructor(
    private loader: LoadingBarService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private store: Store,
    private socketService: SocketService,
    private _toastrSvc: ToastrService,
    private screenSizeService: ScreenSizeService,
    private _userService: UserService,
    private activedRouter: ActivatedRoute,
    private inactivityService: InactivityService
  ) {
    this.detectAndSetTheme();
    this.socketService.connectToNamespace('sorti365');
    this.messageSubscription = this.socketService
      .getMessageNSPSubject()
      .subscribe((msg) => {
        if (this.hasPermission(USER_LIST_PERMISSIONS.VIEW_NOTIFICATIONS)) {
          switch (msg.origin) {
            case 'cargas_retiros_solicitud':
              if (!this.isMobile) {
                if (msg.action === 'A') {
                  this._toastrSvc.info(
                    `Usuario: ${msg.data.usuario},<br>Mensaje: ${msg.data.message}`,
                    'Notificación!!'
                  );
                  this.playAudio();
                }
              }
              break;
            case 'financial':
              if (!this.isMobile) {
                if (msg.action === 'A') {
                  this._toastrSvc.info(
                    `Usuario: ${msg.data.usuario},<br>Mensaje: ${msg.data.message}`,
                    'Notificación!!'
                  );
                  this.playAudioFinancial();
                }
              }
              break;
            default:
              break;
          }
        }
      });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.spinner.show();
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      }
    });
    this.audioContext = new AudioContext();
    this.audioContextFinancial = new AudioContext();
    this.screenSizeSubscription = this.screenSizeService.isMobile$.subscribe(
      (isMobile: boolean) => {
        if (this.isMobileRedux !== isMobile) {
          this.store.dispatch(
            ScreenActions['[Screen]Change']({
              isMobile: isMobile,
            })
          );
        }
      }
    );
  }

  async ngOnInit(): Promise<void> {
    await new Promise((resolve, reject) => {
      this.routerSubscription = this.router.events
        .pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe({
          next: (event: any) => {
            const fullUrl = event.urlAfterRedirects;
            this.isMobile = fullUrl.includes('/mobile');
            this.lastParameter =
              fullUrl.split('/')[fullUrl.split('/').length - 1];
            resolve(true);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
    if (this.isMobile) {
      this._userService.getUserByTokenApp(this.lastParameter).subscribe({
        next: (res) => {
          if (res.data.idusuario) {
            this._userService.getUser(res.data.idusuario).subscribe({
              next: (res) => {
                const profile = res.data;
                this.idRol = profile.id_rol;
              },
              error: (err) => {
                this.idRol = ROLES.UNDEFINED;
              },
            });
          }
        },
        error: (err) => {},
      });
    } else {
      this.profile$.subscribe((profile) => {
        this.profile = profile;
        this.idRol = profile.id_rol;
      });
    }
    this.isMobile$.subscribe((isMobile) => {
      this.isMobileRedux = isMobile;
    });
    this.loadAudio('assets/audio/iphone-notificacion.mp3');
    this.loadAudioFinancial('assets/audio/cash_register.mp3');
  }

  hasPermission(permissionId: number): boolean {
    return this.profile?.rol?.permisos_principales.some(
      (mainPermission: MainPermission) =>
        mainPermission.permisos.some(
          (permission: Permission) => permission.id === permissionId
        )
    );
  }

  async detectAndSetTheme() {
    const layoutVersion = localStorage.getItem('layoutVersion');
    // if (layoutVersion === 'dark-only' || isModeDarkParam) {
    if (layoutVersion === 'dark-only') {
      this.store.dispatch(
        ThemeActions['[Theme]Change']({
          settings: {
            layout_version: 'dark-only',
          },
        })
      );
    }
    let count = 0;
    this.activedRouter.queryParams.subscribe((params: { [x: string]: any }) => {
      count++;
      if (count === 2) {
        const isModeDarkParam = params['isModeDark'];
        if (isModeDarkParam) {
          this.selectSettings = {
            layout: 'Dubai',
            layout_type: 'ltr',
            layout_version: 'dark',
            icon: 'stroke-svg',
          };
        } else {
          this.selectSettings = {
            layout: 'Dubai',
            layout_type: 'ltr',
            layout_version: 'light-only',
            icon: 'stroke-svg',
          };
        }
      }
    });
    this.store.select(selectConfig).subscribe({
      next: (res) => {
        this.selectSettings = res.settings;
      },
      error: (error) => {
        this.selectSettings.layout_version = AVAILABLE_THEME.WHITE;
        console.error(`Error: ${error}`);
      },
    });
  }

  ngOnDestroy(): void {
    this.socketService.disconnectFromNamespace();
    this.screenSizeSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
  playAudio(): void {
    const source = this.audioContext.createBufferSource();
    source.buffer = this.audioBuffer;
    source.connect(this.audioContext.destination);
    source.start();
  }

  playAudioFinancial(): void {
    const source = this.audioContextFinancial.createBufferSource();
    source.buffer = this.audioBufferFinancial;
    source.connect(this.audioContextFinancial.destination);
    source.start();
  }

  loadAudio(url: string): void {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((buffer) => this.audioContext.decodeAudioData(buffer))
      .then((audioBuffer) => (this.audioBuffer = audioBuffer));
  }

  loadAudioFinancial(url: string): void {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((buffer) => this.audioContextFinancial.decodeAudioData(buffer))
      .then((audioBuffer) => (this.audioBufferFinancial = audioBuffer));
  }
}
