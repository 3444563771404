import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../../core/state/auth/auth.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public show: boolean = false;
  rememberMeChecked: boolean = false;
  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    const storedUsername = localStorage.getItem('rememberedUsername');
    const storedPass = localStorage.getItem('rememberedPass');
    if (storedUsername && storedPass) {
      this.loginForm.patchValue({ username: storedUsername });
      this.loginForm.patchValue({ password: storedPass });
      this.rememberMeChecked = true;
    }
  }
  onRememberMeChange() {
    if (this.rememberMeChecked) {
      this.rememberMeChecked = false;
    } else {
      this.rememberMeChecked = true;
    }
  }

  showPassword() {
    this.show = !this.show;
  }

  onSubmit() {
    if (this.rememberMeChecked) {
      localStorage.setItem(
        'rememberedUsername',
        this.loginForm.get('username')?.value
      );
      localStorage.setItem(
        'rememberedPass',
        this.loginForm.get('password')?.value
      );
    } else {
      localStorage.removeItem('rememberedUsername');
      localStorage.removeItem('rememberedPass');
    }
    const { username, password } = this.loginForm.value;
    if (username && password) {
      this.store.dispatch(
        AuthActions['[Auth]RequestLogin']({
          username,
          password,
        })
      );
    }
  }
}
