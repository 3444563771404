import { Component, Inject, ViewChild } from '@angular/core';
import { CodeBonusService } from '../../../../core/services/code-bonus.service';
import { selectUser } from '../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Subscription, filter } from 'rxjs';
import { LayoutService } from '../../../services/layout.service';
import { NavService } from '../../../services/nav.service';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { UserService } from '../../../../core/services/user.service';
import { ThemeActions } from '../../../../core/state/theme/theme.actions';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrl: './mobile.component.scss',
})
export class MobileComponent {
  public elem: any;
  public bonusBalanceData: any[] = [];
  public isLoading: boolean = true;
  private subscriptions: Subscription[] = [];
  public countryId = 1;
  public codeBonus$ = this.store.select(selectUser);
  public country: number | null = null;
  public agentId: any = null;
  public roleId: number | null = null;
  public token!: string;
  routerOutletState: any = '';
  @ViewChild(RouterOutlet) routerOutlet!: RouterOutlet;
  constructor(
    public layout: LayoutService,
    public navServices: NavService,
    private bonusCodeService: CodeBonusService,
    private _toastr: ToastrService,
    @Inject(DOCUMENT) private document: any,
    private store: Store,
    private activedRouter: ActivatedRoute,
    private _userService: UserService,
    private router: Router
  ) {
    const fullUrl = this.router.url;
    const [baseUrl] = fullUrl.split('?'); // Divide la URL y toma la primera parte
    const urlSegments = baseUrl.split('/');
    this.token = urlSegments[urlSegments.length - 1];
    this._userService.getUserByTokenApp(this.token).subscribe({
      next: (res) => {
        this.roleId = res.data.id_rol;
        this.agentId = res.data.idusuario;
        this.country = res.data.id_pais;
        this.getBalanceBonus();
      },
      error: (err) => {},
    });
    // this.loadTheme();
  }
  async ngOnInit(): Promise<void> {
    await this.loadTheme();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.routerOutletState = this.getRouterOutletState(this.routerOutlet);
      });
  }
  public getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
  async loadTheme(): Promise<void> {
    this.activedRouter.queryParams.subscribe(
      (params: { [x: string]: any }) => {
        if (params.isModeDark === 'true') {
          document.body.classList.add('dark-only');
          this.store.dispatch(
            ThemeActions['[Theme]Change']({
              settings: {
                layout_version: 'dark-only',
              },
            })
          );
        }
      },
      (error: any) => {}
    );
  }

  getBalanceBonus(): void {
    this.isLoading = true;
    const subscription = this.bonusCodeService
      .getAgentBalance({ agentId: this.agentId })
      .subscribe({
        next: (res) => {
          const response = res.data;
          this.bonusBalanceData = response.bonusBalance;
          this.isLoading = false;
        },
        error: (error) => {
          this._toastr.error('Internal server error', 'Error');
          this.isLoading = false;
        },
      });

    this.subscriptions.push(subscription);
  }
  reloadTable() {
    this.isLoading = true;
    this.getBalanceBonus();
  }
}
