import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private secretKey = environment.SECRET_KEY;

  constructor() { }

  encrypt(data: string): string {
    let dataToEncrypt = data + '|' + new Date().getTime();
    return CryptoJS.AES.encrypt(dataToEncrypt, this.secretKey).toString();
  }

  decrypt(encryptedText: string): string {
    if (!encryptedText) return encryptedText;
    try {
      const decrypted = CryptoJS.AES.decrypt(encryptedText, this.secretKey);
      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      return decryptedText;
    } catch (error) {
      console.error('Error al desencriptar:', error);
      return '';
    }
  }
}
