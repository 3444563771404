import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ThemeActions } from '../../../../core/state/theme/theme.actions';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class FullComponent implements OnInit {
  constructor(private activedRouter: ActivatedRoute, private store: Store) {}

  async ngOnInit(): Promise<void> {
    await this.loadTheme();
  }
  async loadTheme(): Promise<void> {
    this.activedRouter.queryParams.subscribe(
      (params: { [x: string]: any }) => {
        if (params.isModeDark === 'true') {
          document.body.classList.add('dark-only');
          this.store.dispatch(
            ThemeActions['[Theme]Change']({
              settings: {
                layout_version: 'dark-only',
              },
            })
          );
        }
      },
      (error: any) => {}
    );
  }
}
