import { createReducer, on } from '@ngrx/store';
import { LanguageActions } from './language.actions';
import { AVAILABLE_LANGUAGES } from '../../constants/constants';

const initialState = {
  language: AVAILABLE_LANGUAGES.ES,
};

export const languageReducer = createReducer(
  initialState,
  on(LanguageActions['[Language]Change'], (state, payload) => {
    return { ...state, ...payload };
  })
);
