import { Injectable } from '@angular/core';
import { Config } from '../../core/interfaces/theme.interface';
import { Store } from '@ngrx/store';
import { selectConfig } from '../../core/state/theme/theme.selectors';
import { ThemeActions } from '../../core/state/theme/theme.actions';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  // public config: Config = {
  //   settings: {
  //     layout: 'Dubai',
  //     layout_type: 'ltr',
  //     layout_version: 'light-only',
  //     icon: 'stroke-svg',
  //   },
  //   color: {
  //     primary_color: '#7366ff',
  //     secondary_color: '#f73164',
  //   },
  // };
  public config!: Config;
  constructor(private store: Store) {
    this.store.select(selectConfig).subscribe((configs) => {
      this.config = configs;
      if (this.config.settings.layout_type == 'rtl')
        document
          .getElementsByTagName('html')[0]
          .setAttribute('dir', this.config.settings.layout_type);

      document.documentElement.style.setProperty(
        '--theme-deafult',
        localStorage.getItem('primary_color')
      );
      document.documentElement.style.setProperty(
        '--theme-secondary',
        localStorage.getItem('secondary_color')
      );
    });
    var primary =
      localStorage.getItem('primary_color') || this.config.color.primary_color;
    var secondary =
      localStorage.getItem('secondary_color') ||
      this.config.color.secondary_color;
    this.store.dispatch(
      ThemeActions['[Theme]Change']({
        color: {
          primary_color: primary,
          secondary_color: secondary,
        },
      })
    );
  }

  setColor(primary_color: string, secondary_color: string) {
    this.store.dispatch(
      ThemeActions['[Theme]Change']({
        color: {
          primary_color: primary_color,
          secondary_color: secondary_color,
        },
      })
    );
    localStorage.setItem('primary_color', this.config.color.primary_color);
    localStorage.setItem('secondary_color', this.config.color.secondary_color);
    window.location.reload();
  }

  resetColor() {
    document.documentElement.style.setProperty('--theme-deafult', '#7366ff');
    document.documentElement.style.setProperty('--theme-secondary', '#f73164');
    localStorage.setItem('primary_color', '#7366ff');
    localStorage.setItem('secondary_color', '#f73164');
    window.location.reload();
  }
}
