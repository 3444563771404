<div class="row">
  <div class="col-sm-12 col-xl-12">
    <div class="card">
      <div class="card-header">
        <h5
          class="card-title d-flex justify-content-between align-items-center"
        >
          Filtro
          <button
            class="btn btn-link"
            data-bs-toggle="collapse"
            data-bs-target="#filterCardContent"
            aria-expanded="false"
            aria-controls="filterCardContent"
            (click)="toggleChart()"
          >
            <i
              class="fas"
              [ngClass]="{
                'fa-arrow-up': showFilter,
                'fa-arrow-down': !showFilter
              }"
            ></i>
          </button>
        </h5>
      </div>
      <div id="filterCardContent" class="collapse">
        <div class="card-body">
          <div class="col-xl-12 col-md-12">
            <div
              [ngClass]="{ open: showFilter }"
              id="filter"
              [ngStyle]="getShowFilterStyle()"
              class="row row-mobile"
            >
              <div class="col-sm-12 col-xl-12">
                <div class="row">
                  <div class="col-sm-12">
                    <form
                      [formGroup]="listLwFormFilter"
                      style="padding-right: 2%"
                    >
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <label class="col-form-label">Fecha</label>
                          <input
                            bsDaterangepicker
                            [bsConfig]="bsConfig"
                            [minDate]="bsConfig.minDate"
                            [maxDate]="bsConfig.maxDate"
                            type="text"
                            class="form-control"
                            formControlName="dateRange"
                            placeholder="Ingrese la fecha"
                            [bsValue]="initialRange"
                          />
                        </div>
                        <div class="col-sm-3">
                          <label class="col-form-label">Monto</label>
                          <input
                            type="text"
                            formControlName="amount"
                            class="form-control custom-input"
                            placeholder="Ingresa un monto"
                          />
                        </div>
                        <div class="col-sm-3">
                          <label class="col-form-label">País</label>
                          <ng-select
                            [items]="optionsCountries$ | async"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="country"
                            class="mb-2"
                            placeholder="Selecciona un país"
                          ></ng-select>
                        </div>
                        <div class="col-sm-3">
                          <label class="col-form-label">Estado</label>
                          <ng-select
                            [items]="optionsState$ | async"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="state"
                            class="mb-2"
                            placeholder="Selecciona un estado"
                          ></ng-select>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <label class="col-form-label">Categoria</label>
                          <ng-select
                            [items]="optionsCategory$ | async"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="category"
                            class="mb-2"
                            placeholder="Selecciona una categoria"
                          ></ng-select>
                        </div>
                        @if (this.profile?.id_rol !== ROLES.AGENT) {
                        <div class="col-sm-3">
                          <label class="col-form-label">Titular</label>
                          <ng-select
                            [items]="allHoldersData"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="holder"
                            class="mb-2"
                            placeholder="Selecciona una opción"
                            (scrollToEnd)="onScrollToEnd()"
                            (search)="onSearch($event.term)"
                            (clear)="onSearch('')"
                            (open)="onSearch('')"
                          ></ng-select>
                        </div>
                        <div class="col-sm-3">
                          <label class="col-form-label">Agente</label>
                          <ng-select
                            [items]="allAgentsData"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="id_agente"
                            class="mb-2"
                            placeholder="Selecciona una opción"
                            (scrollToEnd)="onScrollToEnd()"
                            (search)="onSearch($event.term)"
                            (clear)="onSearch('')"
                            (open)="onSearch('')"
                          ></ng-select>
                        </div>
                        }
                        <div class="col-sm-3">
                          <label class="col-form-label">Comentario</label>
                          <input
                            type="text"
                            formControlName="comentary"
                            class="form-control custom-input"
                            placeholder="Ingresa un comentario"
                          />
                        </div>
                      </div>
                    </form>
                    <div class="text-end" style="padding-right: 2%">
                      <button
                        type="button"
                        class="btn btn-sm btn-restore me-1"
                        (click)="resetForm()"
                      >
                        Restablecer
                      </button>
                      <button
                        class="btn btn-sm btn-head me-1"
                        (click)="sendFilter()"
                      >
                        Filtrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
