import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicModalService } from '../../../../../../core/services/dynamic-modal.service';
import { ChargesWithdrawalsRequestService } from '../../../../../../core/services/charges-withdrawals-request.service';
import { environment } from '../../../../../../../environments/environment';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
})
export class ImageViewComponent implements OnInit {
  @ViewChild('imageContainer', { static: false }) imageContainer!: ElementRef;
  public cwId: any;
  public imgSrc!: any;
  public urlResponse: any;
  public file: any;
  public numero_comprobante: any;
  public baseUrl: string = environment.API_FILES;
  public isLoading: boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
    private customModalService: DynamicModalService,
    private _cwService: ChargesWithdrawalsRequestService
  ) {}

  ngOnInit(): void {
    this.cwId = this.customModalService.getModalData();
    this.getImgCw();
  }

  getImgCw() {
    this.isLoading = true;
    this._cwService.getCw(this.cwId).subscribe((response) => {
      this.numero_comprobante =
        response.data.comprobante || response.data.comprobante_empresa;
      this.urlResponse = response.data.foto_comprobante;
      this.urlResponse = this.urlResponse.replace(/ /g, '%20');
      this.file = this.urlResponse.replace(/\\/g, '/');
      this.imgSrc = this.baseUrl + '/' + this.file;
      this.isLoading = false;
    });
  }

  downloadImage() {
    const options = {
      backgroundColor: null,
      useCORS: true,
      scale: 1,
    };
    html2canvas(this.imageContainer.nativeElement, options).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `comprobante-${this.numero_comprobante}.png`;
      link.click();
    });
  }

  closeModal() {
    this.activeModal.close();
  }
}
