import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TIME_OUT } from '../../core/constants/constants';
import { AuthService } from '../../core/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class InactivityService implements OnDestroy {
  private userActivity: EventListenerOrEventListenerObject;
  private userInactive: number | null = null;
  private readonly INACTIVITY_TIME: number = TIME_OUT.TIME;
  private routeSubscription: Subscription;

  constructor(private router: Router, private ngZone: NgZone, private authService: AuthService,
    private modalService: NgbModal) {
    this.userActivity = this.resetTimer.bind(this);

    // Escuchar cambios de ruta
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.isExemptedRoute(event.urlAfterRedirects)) {
          this.clearTimeout();
          this.removeActivityListeners();
        } else {
          this.addActivityListeners();
          this.setTimeout();
        }
      }
    });
  }

  private isExemptedRoute(url: string): boolean {

    const exemptedRoutes = ['/auth/unlock-user', '/auth/two-factory-authentication', '/auth/login'];
    const isMobileRoute = url.includes('/mobile');
    return exemptedRoutes.includes(url) || isMobileRoute;
  }

  private addActivityListeners(): void {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('mousemove', this.userActivity);
      window.addEventListener('keydown', this.userActivity);
      window.addEventListener('mousedown', this.userActivity);
      window.addEventListener('touchstart', this.userActivity);
      window.addEventListener('click', this.userActivity);
      window.addEventListener('scroll', this.userActivity);
    });
  }

  private removeActivityListeners(): void {
    window.removeEventListener('mousemove', this.userActivity);
    window.removeEventListener('keydown', this.userActivity);
    window.removeEventListener('mousedown', this.userActivity);
    window.removeEventListener('touchstart', this.userActivity);
    window.removeEventListener('click', this.userActivity);
    window.removeEventListener('scroll', this.userActivity);
  }

  private setTimeout(): void {
    this.clearTimeout();
    this.userInactive = window.setTimeout(() => this.logout(), this.INACTIVITY_TIME);
  }

  private resetTimer(): void {
    if (this.userInactive !== null) {
      clearTimeout(this.userInactive);
    }
    this.setTimeout();
  }

  private logout(): void {
    this.ngZone.run(() => {
      this.modalService.dismissAll();
      this.authService.lock();
      this.router.navigate(['auth/unlock-user']);
    });
  }

  private clearTimeout(): void {
    if (this.userInactive !== null) {
      clearTimeout(this.userInactive);
      this.userInactive = null;
    }
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.clearTimeout();
    this.removeActivityListeners();
  }
}
