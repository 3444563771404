import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  map,
  tap,
  switchMap,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';
import { combineLatest, interval, of } from 'rxjs';
import { AuthActions } from './auth.actions';
import { StorageService } from '../../services/storage.service';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ToastService } from 'angular-toastify';
import { NgxSpinnerService } from 'ngx-spinner';
import { select, Store } from '@ngrx/store';
import { selectLanguage } from '../language/language.selectors';
const USER_SESSION = 'USER_SESSION';
const USER_SESSION_PRE = 'USER_SESSION_PRE';

export const loadSessionEffect = createEffect(
  (
    actions$ = inject(Actions),
    storageService = inject(StorageService),
    userService = inject(UserService)
  ) =>
    combineLatest([
      actions$.pipe(
        ofType(AuthActions['[Auth]LoadPreviewSession']),
        startWith(AuthActions['[Auth]LoadPreviewSession']())
      ),
      // interval(2000).pipe(startWith(0)),
    ]).pipe(
      exhaustMap((action) => {
        const id = JSON.parse(
          storageService.secureStorage.getItem(USER_SESSION)
        );
        if (!id) {
          return of(
            AuthActions['[Auth]LoginFailed']({
              message: '',
            })
          );
        }
        return userService.getUser(id.data.idusuario).pipe(
          map((response) => {
            return AuthActions['[Auth]LoginSuccess'](response.data);
          }),
          catchError((error) => {
            return of(
              AuthActions['[Auth]LoginFailed']({
                message: '',
              })
            );
          })
        );
      })
    ),
  { functional: true }
);

export const preLoadSessionEffect = createEffect(
  (
    actions$ = inject(Actions),
    storageService = inject(StorageService),
    userService = inject(UserService)
  ) =>
    combineLatest([
      actions$.pipe(
        ofType(AuthActions['[Auth]LoadPrePreviewSession']),
        startWith(AuthActions['[Auth]LoadPrePreviewSession']())
      ),
      // interval(2000).pipe(startWith(0)),
    ]).pipe(
      exhaustMap((action) => {
        const id = JSON.parse(
          storageService.secureStorage.getItem(USER_SESSION_PRE)
        );
        if (!id) {
          return of(
            AuthActions['[Auth]LoginPre-Failed']({
              message: '',
            })
          );
        }
        return userService.getUser(id.data.idusuario).pipe(
          map((response) => {
            return AuthActions['[Auth]LoginPre-Success'](response.data);
          }),
          catchError((error) => {
            return of(
              AuthActions['[Auth]LoginPre-Failed']({
                message: '',
              })
            );
          })
        );
      })
    ),
  { functional: true }
);

export const signInEffect = createEffect(
  (
    actions$ = inject(Actions),
    authService = inject(AuthService),
    toast = inject(ToastService),
    storageService = inject(StorageService),
    userService = inject(UserService),
    routerService = inject(Router),
    spinner = inject(NgxSpinnerService),
store = inject(Store)
  ) =>
    actions$.pipe(
      ofType(AuthActions['[Auth]RequestLogin']),
      withLatestFrom(store.pipe(select(selectLanguage))),
      switchMap(([action, language]) => {
        setTimeout(() => {
          spinner.show();
        }, 100);
        return authService.signIn(action.username, action.password).pipe(
          map((response) => {
            setTimeout(() => {
              spinner.hide();
            }, 200);
            if (response.data.TFA === 1) {
              routerService.navigate([`/auth/two-factory-authentication`]);
              return AuthActions['[Auth]LoginPre-Success'](response.data);
            } else {
              return AuthActions['[Auth]LoginSuccess'](response.data);
            }
          }),
          catchError((error) => {
            setTimeout(() => {
              spinner.hide();
            }, 200);
            // toast.error(error.error.message);
            return of(
              AuthActions['[Auth]LoginFailed']({
                message: error.error.message[language],
              })
            );
          })
        );
      }),
      switchMap(() => {
        const id =
          JSON.parse(storageService.secureStorage.getItem(USER_SESSION)) ||
          JSON.parse(storageService.secureStorage.getItem(USER_SESSION_PRE));
        if (!id) {
          spinner.hide();
          return of(
            AuthActions['[Auth]LoginFailed']({
              message: '',
            })
          );
        }
        spinner.hide();
        return userService.getUser(id.data.idusuario).pipe(
          map((response) => {
            if (response.data.TFA === 1) {
              routerService.navigate([`/auth/two-factory-authentication`]);
              spinner.hide();
              return AuthActions['[Auth]LoginPre-Success'](response.data);
            } else {
              spinner.hide();
              toast.default(`Bienvenido, ${response.data.usuario}`);
              routerService.navigate([``]);
              return AuthActions['[Auth]LoginSuccess'](response.data);
            }
          }),
          catchError((error) => {
            spinner.hide();
            toast.error('Error al iniciar sesion');
            return of(
              AuthActions['[Auth]LoginFailed']({
                message: error,
              })
            );
          })
        );
      })
    ),

  { functional: true }
);

export const signOutEffect = createEffect(
  (actions$ = inject(Actions), authService = inject(AuthService)) =>
    actions$.pipe(
      ofType(AuthActions['[Auth]Logout']),
      tap(() => authService.logOut())
    ),

  { functional: true, dispatch: false }
);

export const activeTwoFactorAuth = createEffect(
  (
    actions$ = inject(Actions),
    twoFactorAuthService = inject(UserService),
    toast = inject(ToastService)
  ) =>
    actions$.pipe(
      ofType(AuthActions['[Auth]TwoFactorAuth']),
      exhaustMap((action) => {
        return twoFactorAuthService
          .twoFactorAuth(action._id, action.twoFactorAuth)
          .pipe(
            map((response) => {
              toast.default('Autenticación de 2 pasos activada!');
              return AuthActions['[Auth]UpdateUser'](response.data);
            }),
            catchError((error) => {
              toast.error('Código Incorrecto');
              return of(
                AuthActions['[Auth]LoginFailed']({
                  message: '',
                })
              );
            })
          );
      })
    ),
  { functional: true }
);

export const desactivetwoFactorAuth = createEffect(
  (
    actions$ = inject(Actions),
    twoDesactiveFactorAuthService = inject(UserService),
    toast = inject(ToastService)
  ) =>
    actions$.pipe(
      ofType(AuthActions['[Auth]DesactiveTwoFactorAuth']),
      exhaustMap((action) => {
        return twoDesactiveFactorAuthService
          .twoDesactiveFactorAuth(action._id)
          .pipe(
            map((response) => {
              toast.default('Autenticación de 2 pasos desactivada!');
              return AuthActions['[Auth]UpdateUser'](response.data);
            }),
            catchError((error) => {
              toast.error('Error al configurar');
              return of(
                AuthActions['[Auth]LoginFailed']({
                  message: '',
                })
              );
            })
          );
      })
    ),
  { functional: true }
);

export const updateUser = createEffect(
  (
    actions$ = inject(Actions),
    userService = inject(UserService),
    toastr = inject(ToastrService)
  ) =>
    actions$.pipe(
      ofType(AuthActions['[Auth]RequestUpdateUser']),
      exhaustMap((action) =>
        userService.updateProfile(action.updatedUser).pipe(
          map((response) => {
            toastr.success(`Actualizado Con Éxito`);
            return AuthActions['[Auth]UpdateUser'](response.data);
          }),
          catchError((error) => {
            toastr.error('Error Al Actualizar');
            return of(
              AuthActions['[Auth]UpdateFailed']({
                message: error.error.message,
              })
            );
          })
        )
      )
    ),

  { functional: true }
);
