import { Injectable } from '@angular/core';
// import { IUserSession } from "../../core/models/UserSession.interface";
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SecureSessionService {
  public API_URL = environment.API_URL;
  keySsoSession = 'SESSIONID';
  keyUser: string = 'USER_SESSION';
  constructor(
    private storageService: StorageService,
    private store: Store<any>
  ) {}
  setSession(value: string) {
    this.storageService.secureStorage.setItem(this.keyUser, value),
      this.API_URL;
  }
  getSession(): null | any {
    try {
      return JSON.parse(
        this.storageService.secureStorage.getItem(this.keyUser)
      );
    } catch (error) {
      return null;
    }
  }
  clearSession() {
    this.storageService.secureStorage.removeItem(this.keyUser);
  }
  setObjectSession(keySession: string, value: string) {
    this.storageService.secureStorage.setItem(keySession, value);
  }
  deleteObjectSession(key: string) {
    this.storageService.secureStorage.removeItem(key);
  }
  parseJwt = (session: any) => {
    let base64Url = session.token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  };
  getObjectSession = (keySesion: string) => {
    try {
      return JSON.parse(this.storageService.secureStorage.getItem(keySesion));
    } catch (error) {
      return null;
    }
  };
}
