import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  ApiPathEnum,
  ChargesWithdrawalsEnum,
} from '../constants/endpoints.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChargesWithdrawalsService {
  public API_URL = `${environment.API_URL}${ApiPathEnum.ChargesWithdrawals}`;
  public API_URL_CATEGORY = `${environment.API_URL}${ApiPathEnum.Category}`;
  constructor(private http: HttpClient) {}

  getAllLw(page: number, limit: number, filters?: any): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params = params.set(key, filters[key]);
      }
    });

    return this.http.get<any>(
      `${this.API_URL}${ChargesWithdrawalsEnum.GetAllLw}`,
      { params }
    );
  }
  getUserEditLw(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    return this.http.get<any>(
      `${this.API_URL}${ChargesWithdrawalsEnum.GetUserEdiLw}`,
      { params }
    );
  }
  downloadLwData(): Observable<any> {
    return this.http.get(
      `${this.API_URL}${ChargesWithdrawalsEnum.DownloadLwData}`
    );
  }
  setState(idcargas_retiros: number, id_estado: number): Observable<any> {
    const body = {
      idcargas_retiros: idcargas_retiros,
      id_estado: id_estado,
    };
    return this.http.patch(
      `${this.API_URL}${ChargesWithdrawalsEnum.SetState}`,
      body
    );
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL_CATEGORY}${ChargesWithdrawalsEnum.GetCategories}`
    );
  }
}
