import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastService } from 'angular-toastify';
import { Validators } from 'ngx-editor';
import { UserService } from '../../../../../../../core/services/user.service';
import { AuthActions } from '../../../../../../../core/state/auth/auth.actions';
import { selectPreAuth } from '../../../../../../../core/state/auth/auth.selector';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapModalService } from '../../../../../../../core/services/boostrap-modal.service';

@Component({
  selector: 'app-tfa-form',
  templateUrl: './tfa-form.component.html',
  styleUrls: ['./tfa-form.component.scss']
})
export class TfaFormComponent implements OnInit {
  public profile$ = this.store.select(selectPreAuth);
  public tfaForm: FormGroup;
  public profile: any;
  public id: string = '';

  constructor(
    private toast: ToastService,
    private fb: FormBuilder,
    private store: Store,
    private _userService: UserService,
    public activeModal: NgbActiveModal,
    private _bsService: BootstrapModalService
  ) {
    this.tfaForm = this.fb.group({
      TFA: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.profile$.subscribe((profile) => {
      this.id = profile.idusuario ? profile.idusuario : this.id;
    });
  }

  onSubmit() {
    const twoFactorAuth = this.tfaForm.value;
    this._userService.verifyLogin(this.id, twoFactorAuth.TFA).subscribe({
      next: (response) => {
        this._userService.getUser(response.data.idusuario).subscribe({
          next: (response) => {
            this._bsService.updateDataIssued({isVerify: true})
            this._bsService.updateModalClosed(true)
          },
          error: (error) => { },
        });
      },
      error: (error) => {
        this.toast.error('Código incorrecto');
        this._bsService.updateDataIssued({ isVerify: false })
      }
    });
  }
}
