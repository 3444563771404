import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import SwiperCore from 'swiper';
import { Store } from '@ngrx/store';
import { ThemeActions } from '../../../core/state/theme/theme.actions';
import { selectSettings } from '../../../core/state/theme/theme.selectors';
import { CodeBonusService } from '../../../core/services/code-bonus.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { selectUser } from '../../../core/state/auth/auth.selector';
import { AuthService } from '../../../core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public elem: any;
  public bonusBalanceData: any[] = [];
  public isLoading: boolean = true;
  private subscriptions: Subscription[] = [];
  public countryId = 1;
  public codeBonus$ = this.store.select(selectUser);
  public country: number | null = null;
  public agentId: any = null;
  public roleId: number | null = null;

  constructor(
    public layout: LayoutService,
    public navServices: NavService,
    private bonusCodeService: CodeBonusService,
    private _toastr: ToastrService,
    @Inject(DOCUMENT) private document: any,
    private store: Store,
    private router: Router,
    private authService: AuthService
  ) {
    this.codeBonus$.subscribe((profileData) => {
      this.roleId = profileData.id_rol;
      this.agentId = profileData.idusuario;
      this.country = profileData.id_pais;
      this.getBalanceBonus();
    });
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.loadTheme();
    this.getBalanceBonus();
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  // layoutToggle() {
  //   if ((this.layout.config.settings.layout_version = 'dark-only')) {
  //     document.body.classList.toggle('dark-only');
  //   }
  //   document.body.remove;
  // }
  getBalanceBonus(): void {
    this.isLoading = true;
    const subscription = this.bonusCodeService
      .getAgentBalance({ agentId: this.agentId })
      .subscribe({
        next: (res) => {
          const response = res.data;

          this.bonusBalanceData = response.bonusBalance;
          this.isLoading = false;
        },
        error: (error) => {
          this._toastr.error('Internal server error', 'Error');
          this.isLoading = false;
        },
      });

    this.subscriptions.push(subscription);
  }
  reloadTable() {
    this.isLoading = true;
    this.getBalanceBonus();
  }
  layoutToggle() {
    document.body.classList.toggle('dark-only');
    const layoutVersion = document.body.classList.contains('dark-only')
      ? 'dark-only'
      : '';
    this.store.dispatch(
      ThemeActions['[Theme]Change']({
        settings: {
          layout_version: layoutVersion,
        },
      })
    );
    // Save the layout version to localStorage
    localStorage.setItem('layoutVersion', layoutVersion);
  }
  loadTheme(): void {
    const layoutVersion = localStorage.getItem('layoutVersion');
    if (layoutVersion === 'dark-only') {
      document.body.classList.add('dark-only');
    }
  }
  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  lockSystem() {
    this.authService.lock();
    this.router.navigate(['/auth/unlock-user']);
  }
}
