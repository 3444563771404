<div class="container-fluid">
  <div class="row">
    <div class="col-xxl-12 col-xl-12 box-cSol-12">
      <div class="col-sm-12">
        <div class="row mt-3">
          <div class="col-12">
            <div class="mb-sm-0">
              <app-list-lw-filters></app-list-lw-filters>
            </div>
          </div>
        </div>
        <app-list-lw-table></app-list-lw-table>
      </div>
    </div>
  </div>
</div>
