// TABLES
export const columnsTable = [
  {
    name: 'Acciones',
  },
  {
    name: 'Fecha',
  },
  {
    name: 'Monto',
  },
  {
    name: 'Comentario',
  },
  {
    name: 'Comprobante',
  },
  {
    name: 'Tipo de Bono',
  },
  {
    name: 'Agente',
  },
  {
    name: 'Creado Por',
  },
];

export const columnsTableList = [
  {
    name: 'Acciones',
    isVisible: true,
  },
  {
    name: 'Estado',
    isVisible: true,
    value: 'id_estado',
  },
  {
    name: 'Fecha',
    isVisible: true,
    value: 'fecha',
  },
  {
    name: 'Agente / Cuenta Cont.',
    isVisible: true,
    value: 'agent',
  },
  {
    name: 'Comprobante',
    isVisible: true,
    value: 'n_comprobante',
  },
  {
    name: 'Monto',
    isVisible: true,
    value: 'monto',
  },
  {
    name: 'Tipo Movimiento',
    isVisible: true,
    value: 'movementTypeLabel',
  },
  {
    name: 'Banco',
    isVisible: true,
    value: 'spBankPerIdBanco',
  },
  {
    name: 'Titular',
    value: 'spUserPerIdUsuTitular',
  },
  {
    name: 'Asigno',
    value: 'updated_by',
  },
  {
    name: 'Observacion',
  },
  {
    name: 'Fecha de la transacción Bancaria',
    value: 'fecha_bancaria',
  },
];

export const bonusTypes = [
  { value: '0', label: '2x1', multiplier: 2 },
  { value: '1', label: '10%', multiplier: 0.1 },
];
export const bonusState = [
  { value: '0', label: 'Desactivado' },
  { value: '1', label: 'Activado' },
];

export const tableData = [
  {
    fecha: '2024-06-05',
    estado: 5, // Procesado
    agente: 'Agente 1',
    comprobante: '123456',
    monto: 1000,
    tipo_movimiento: 'Depósito',
    banco: 'Banco 1',
    titular: 'Titular 1',
    asigno: 'Asigno 1',
    observacion: 'No',
  },
  {
    fecha: '2024-06-04',
    estado: 5, // Procesado
    agente: 'Agente 2',
    comprobante: '123457',
    monto: 500,
    tipo_movimiento: 'Retiro',
    banco: 'Banco 2',
    titular: 'Titular 2',
    asigno: 'Asigno 2',
    observacion: 'Sí',
  },
  {
    fecha: '2024-06-03',
    estado: 5, // Procesado
    agente: 'Agente 3',
    comprobante: '123458',
    monto: 750,
    tipo_movimiento: 'Transferencia',
    banco: 'Banco 3',
    titular: 'Titular 3',
    asigno: 'Asigno 3',
    observacion: 'No',
  },
];

//ESTADOS
interface StateClass {
  text: string;
  classes: { [key: string]: boolean };
}
export const STATE_CLASSES: { [key: number]: StateClass } = {
  0: { text: 'Pendiente', classes: { 'bg-pending': true } },
  1: { text: 'Solicitud<br>Bot', classes: { 'bg-request-bot': true } },
  2: {
    text: 'Procesado<br>(Prevalidado)',
    classes: { 'bg-request-prevalidado': true },
  },
  3: { text: 'Rechazado', classes: { 'bg-reject': true } },
  4: {
    text: 'Solicitud BOT<br>(Pendiente Boleta)',
    classes: { 'bg-request-bot-pending-slip': true },
  },
  5: { text: 'Procesado', classes: { 'bg-processing': true } },
  6: { text: 'Problema<br>Bot', classes: { 'bg-problem-bot': true } },
  7: {
    text: 'Procesado<br>(Validado)',
    classes: { 'bg-processing-validate': true },
  },
  8: { text: 'Corrección', classes: { 'bg-correction': true } },
  9: {
    text: 'Cargado<br>no ingresado',
    classes: { 'bg-correction-charge-not-entry': true },
  },
  10: { text: 'Reverso', classes: { 'bg-reversal': true } },
  11: { text: 'Dividido', classes: { 'bg-divide': true } },
  12: {
    text: 'Rechazado<br>(Por comentario)',
    classes: { 'bg-reject-comment': true },
  },
  13: {
    text: 'Solicitud BOT<br>(Reverso)',
    classes: { 'bg-bot-request-reversal': true, 'text-rejected': true },
  },
};

export const headersExcel: string[] = [
  'Fecha', 'Monto', 'Comentario', 'Comprobante', 'Tipo de Bono',
  'Agente'
];
