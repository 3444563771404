export interface ApiResponse<T> {
  message: MsgTranslate;
  data: T;
  status: string;
  statusCode: number;
  totalCount?: number;
}
export interface ApiDataResponse<T> {
  result?: T;
  data: T;
  totalCount: number;
}
interface MsgTranslate {
  es: string;
  en: string;
}
