// TABLE
export const columnsTable = [
  {
    name: 'Acciones',
  },
  {
    name: 'Estado',
    value: 'estado',
  },
  {
    name: 'Monto',
    value: 'monto',
  },
  {
    name: 'Tipo Transacción',
    value: 'tipo_transaccion',
  },
  {
    name: 'Creado',
    value: 'creado',
  },
  {
    name: 'Cliente',
  },
  {
    name: 'Comprobante',
  },
  {
    name: 'Detalle Cliente',
  },
  {
    name: 'Detalle Empresa',
  },
  {
    name: 'Actualizado',
  },
  {
    name: 'Creado Por',
  },
];

// TRANSACTIONS TYPES
export const TRANSACTION_TYPES = [
  { value: '0', label: 'Cargar Saldo' },
  { value: '1', label: 'Retirar Saldo' },
  { value: '2', label: 'Cargar Bono Saldo' },
  { value: '3', label: 'Abonar a Deuda' },
  { value: '4', label: 'Cobrar Comisión' },
  { value: '5', label: 'Financiero' },
  { value: '6', label: 'Credito Depósito' },
  { value: '7', label: 'Credito Retiro' },
  { value: '8', label: 'Boleta Riesgosa Carga' },
  { value: '9', label: 'Boleta Riesgosa Retiro' },
];

export const TRANSACTION_TYPE_LABELS = {
  LOAD_BALANCE: 'Cargar Saldo',
  WITHDRAW_BALANCE: 'Retirar Saldo',
  LOAD_BONUS_BALANCE: 'Cargar Bono Saldo',
  PAY_TO_DEBIT: 'Abonar a Deuda',
  COLLECT_COMMISSION: 'Cobrar Comisión',
  FINANCIAL: 'Financiero',
  CREDIT_DEPOSIT: 'Credito Depósito',
  CREDIT_WITHDRAWAL: 'Credito Retiro',
  LOAD_RISK_VOUCHER: 'Boleta Riesgosa Carga',
  WITHDRAW_RISK_VOUCHER: 'Boleta Riesgosa Retiro',
};

export const TRANSACTION_TYPE_VALUES = {
  LOAD_BALANCE: 0,
  WITHDRAW_BALANCE: 1,
  LOAD_BONUS_BALANCE: 2,
  PAY_TO_DEBIT: 3,
  COLLECT_COMMISSION: 4,
  FINANCIAL: 5,
  CREDIT_DEPOSIT: 6,
  CREDIT_WITHDRAWAL: 7,
  LOAD_RISK_VOUCHER: 8,
  WITHDRAW_RISK_VOUCHER: 9,
};

// COUNTRY CODES
export const COUNTRIES: { [key: string]: number } = {
  ECUADOR: 1,
  PERU: 2,
  CHILE: 3,
};

//ALLOWED TYPES
export const ALLOWED_TYPES_FOR_BANK = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.FINANCIAL,
];

export const ALLOWED_TYPES_FOR_AGENT_READONLY = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
];

export const ALLOWED_TYPES_FOR_PLAYER_READONLY = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
];

export const ALLOWED_TYPES_FOR_AGENT_READONLY_SPECIAL = [
  TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT,
  TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL,
];

export const ALLOWED_TYPES_FOR_PLAYER_READONLY_SPECIAL = [
  TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT,
  TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL,
];

export const ALLOWED_TYPES_FOR_AGENT_PLAYER = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
];

export const ALLOWED_TYPES_FOR_HOLDER = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
];

export const ALLOWED_TYPES_FOR_HOLDER_BY_EDIT = [
  ...ALLOWED_TYPES_FOR_HOLDER,
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.FINANCIAL,
];

export const ALLOWED_TYPES_FOR_RECEIPT = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
];

export const ALLOWED_TYPES_FOR_IDENTIFICATION = [
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.FINANCIAL,
];

export const ALLOWED_TYPES_FOR_BENEFICIARY = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.FINANCIAL,
];

export const ALLOWED_TYPES_FOR_ENTERPRISE_BANK = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.FINANCIAL,
];

export const ALLOWED_TYPES_FOR_CHECK_RECEIPT = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
];

export const ALLOWED_TYPES_FOR_UPLOAD_WITHDRAWAL_DIRECT = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
];

export const ALLOWED_TYPES_FOR_STATUS_RISKY_RECEIPT = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.LOAD_RISK_VOUCHER,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
];

// TRANACTION TYPES ALLOWED FOR VALIDATORS
export const ALLOWED_TYPES_FOR_MONTO = [
  TRANSACTION_TYPE_VALUES.WITHDRAW_BALANCE,
  TRANSACTION_TYPE_VALUES.COLLECT_COMMISSION,
  TRANSACTION_TYPE_VALUES.WITHDRAW_RISK_VOUCHER,
];

export const ALLOWED_TYPES_FOR_MONTO_BANK_ID_ACCOUNT = [
  TRANSACTION_TYPE_VALUES.CREDIT_DEPOSIT,
  TRANSACTION_TYPE_VALUES.CREDIT_WITHDRAWAL,
];

export const ALLOWED_TYPES_FOR_MONTO_BANK_RECEIPT = [
  TRANSACTION_TYPE_VALUES.LOAD_BALANCE,
  TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
  TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT,
];

export const minimum_amount_ec = 5;
export const minimum_amount_pe = 1;

//AGENTS / PLAYERS
export const AGENT_OPTIONS = [
  { value: 'agent1', label: 'Agente 1' },
  { value: 'agent2', label: 'Agente 2' },
  { value: 'agent3', label: 'Agente 3' },
];

export const PLAYER_OPTIONS = [
  { value: 'player1', label: 'Jugador 1' },
  { value: 'player2', label: 'Jugador 2' },
  { value: 'player3', label: 'Jugador 3' },
];

export const INTERBANK_TRANSDFER_OPTIONS = [
  { value: '0', label: 'No' },
  { value: '1', label: 'Si' },
  { value: '2', label: 'Otros' },
];

export const INTERBANK_TRANSDFER_OPTIONS_VALUES = {
  NO: '0',
  YES: '1',
  OTHERS: '2',
};

// BANKS
export const BANKS = [
  'Banco Pichincha C.A.',
  'Banco Pacifico',
  'Banco Guayaquil S.A.',
  'Cooperativa de Ahorro y Crédito JEP',
  'Produbanco',
  'Banco Machala',
  'Banco Internacional',
  'Banco Bolivariano',
  'Banco General Ruminahui S.A.',
  'Efectivo',
  'BBVA',
  'YAPE',
  'BCP',
  'Interbank',
  'Bono Sorti',
  'Bitcoin',
  'SafetyPay',
  'USDT',
  'ScotiaBank',
  'Banco del Austro',
];

// TYPES USERS
export const TYPE_USER_OPTION = [
  { value: '0', label: 'Jugadores' },
  { value: '1', label: 'Agentes' },
];

// KEYWORDS
export const WORDS = ['Recarga', 'Sorti', 'Apuesta', 'Recarga', 'Sorti365'];

// CHART

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ApexResponsive,
  ApexNonAxisChartSeries,
  ApexTitleSubtitle,
  ApexTheme,
  ApexMarkers,
  ApexAnnotations,
  ApexGrid,
} from 'ng-apexcharts';

let primary_color = localStorage.getItem('primary_color') || '#7366ff';

export type ChartOptions = {
  series?: ApexAxisChartSeries;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  stroke?: ApexStroke;
  tooltip?: any;
  dataLabels?: ApexDataLabels;
  yaxis?: ApexYAxis;
  legend?: ApexLegend;
  labels?: string[];
  plotOptions?: ApexPlotOptions;
  fill?: ApexFill;
  responsive?: ApexResponsive[];
  pieseries?: ApexNonAxisChartSeries;
  title?: ApexTitleSubtitle;
  theme?: ApexTheme;
  colors?: string[];
  markers?: ApexMarkers;
  annotations?: ApexAnnotations;
  grid?: ApexGrid;
};

export let bar: ChartOptions | any = {
  colors: [primary_color],
  chart: {
    type: 'bar',
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: ['Pendiente', 'Pendiente Boleta', 'Pre Validado', 'Validado'],
  },
};

//ESTADOS
interface StateClass {
  text: string;
  classes: { [key: string]: boolean };
}
export const STATE_CLASSES: { [key: number]: StateClass } = {
  0: { text: 'Pendiente', classes: { 'bg-pending': true } },
  1: { text: 'Solicitud<br>Bot', classes: { 'bg-request-bot': true } },
  2: {
    text: 'Procesado<br>(Prevalidado)',
    classes: { 'bg-request-prevalidado': true },
  },
  3: { text: 'Rechazado', classes: { 'bg-reject': true } },
  4: {
    text: 'Solicitud BOT<br>(Pendiente Boleta)',
    classes: { 'bg-request-bot-pending-slip': true },
  },
  5: { text: 'Procesado', classes: { 'bg-processing': true } },
  6: { text: 'Problema<br>Bot', classes: { 'bg-problem-bot': true } },
  7: {
    text: 'Procesado<br>(Validado)',
    classes: { 'bg-processing-validate': true },
  },
  8: { text: 'Corrección', classes: { 'bg-correction': true } },
  9: {
    text: 'Cargado<br>no ingresado',
    classes: { 'bg-correction-charge-not-entry': true },
  },
  10: { text: 'Reverso', classes: { 'bg-reversal': true } },
  11: { text: 'Dividido', classes: { 'bg-divide': true } },
  12: {
    text: 'Rechazado<br>(Por comentario)',
    classes: { 'bg-reject-comment': true },
  },
  13: {
    text: 'Solicitud BOT<br>(Reverso)',
    classes: { 'bg-bot-request-reversal': true, 'text-rejected': true },
  },
};

//TRANSACTION TYPES
// Define las clases de íconos para los tipos de transacción
export const TRANSACTION_ICON_CLASSES: { [key: number]: string } = {
  0: 'fas fa-cash-register',
  1: 'fas fa-money-check-alt',
  2: 'fas fa-gift',
  3: 'fas fa-hand-holding-usd',
  4: 'fas fa-money-bill-wave',
  5: 'fas fa-chart-line',
  6: 'fas fa-plus-square',
  7: 'fas fa-minus-square',
  8: 'fas fa-file-invoice-dollar',
  9: 'fas fa-file-invoice-dollar',
};

// Define las clases de transacciones para los tipos de transacción
export const TRANSACTION_CLASSES: { [key: number]: string } = {
  0: 'bg-positive',
  1: 'bg-negative',
  2: 'bg-positive',
  3: 'bg-positive',
  4: 'bg-negative',
  5: 'bg-negative',
  6: 'bg-positive',
  7: 'bg-negative',
  8: 'bg-positive',
  9: 'bg-negative',
};

//Tipos de Movimiento
export const movementType = [
  { value: '1', label: 'Credito' },
  { value: '2', label: 'Debito' },
];

export const MOVEMENT_CLASSES: { [key: number]: string } = {
  1: 'bg-positive',
  2: 'bg-negative',
};

export const MOVEMENT_ICON_CLASSES: { [key: number]: string } = {
  1: 'fas fa-credit-card',
  2: 'fas fa-money-bill',
};

export const STATE_SP_CLASSES: { [key: number]: StateClass } = {
  1: { text: 'Pendiente', classes: { 'bg-pending': true } },
  2: { text: 'Procesado', classes: { 'bg-processing': true } },
  3: { text: 'Reboto', classes: { 'bg-problem-bot': true } },
  4: { text: 'Auspicios', classes: { 'bg-auspicious': true } },
  5: { text: 'Cancelados', classes: { 'bg-problem-bot': true } },
  6: { text: 'Bono', classes: { 'bg-bonus': true } },
  7: { text: 'Comision', classes: { 'bg-comision': true } },
  8: { text: 'Abono a<br>deuda', classes: { 'bg-debt': true } },
  9: { text: 'Adelanto de<br>Comision', classes: { 'bg-comision': true } },
  10: { text: 'Pago Doble', classes: { 'bg-charge-double': true } },
  11: { text: 'Pago<br>Punto Físico', classes: { 'bg-processing': true } },
  12: {
    text: 'Comisión Perú',
    classes: { 'bg-correction-charge-not-entry': true },
  },
};

export const STATE_NAMES = {
  PENDING: 0,
  BOT_REQUEST: 1,
  PREVALIDATED_PROCESSED: 2,
  REJECTED: 3,
  BOT_PENDING_SLIP: 4,
  PROCESSED: 5,
  BOT_PROBLEM: 6,
  VALIDATED_PROCESSED: 7,
  CORRECTION: 8,
  NOT_ENTERED: 9,
  REVERSED: 10,
  DIVIDED: 11,
  REJECTED_COMMENT: 12,
  BOT_REVERSED: 13,
};

export const CURRENCY_BY_COUNTRY: { [key: string]: string } = {
  ECU: 'USD',
  PER: 'PEN',
  CHL: 'CLP',
};

export const CURRENCY_VALUES = {
  ECU: 1,
  PER: 2,
};

export const COUNTRY_CODE_MAP: { [key: string]: string } = {
  EC: 'ECU',
  PE: 'PER',
  CHL: 'CHL',
};

export function getCurrencyByCountry(countryCode: string): string {
  return CURRENCY_BY_COUNTRY[countryCode];
}

export function convertCountryCode(countryCode: string): string {
  return COUNTRY_CODE_MAP[countryCode];
}

export const DOCUMENT_TYPES: { [key: string]: number } = {
  ID: 1,
  PASSPORT: 2,
  RUC: 3,
};

export const ACCOUNT_TYPES: { [key: string]: number } = {
  CURRENT: 1,
  SAVINGS: 2,
};

export const ACCOUNT_TYPES_TEXT = {
  CURRENT: 'Corriente',
  SAVINGS: 'Ahorros',
};

export const DEFAULT_VALUE = 0;
export const TRUE_CHECK_VALUE = 1;
export const PAGE_SIZE_VALUE = 1;
export const UNKNOWN_VALUE_TEXT = 'Tipo desconocido';
export const NULL_VALUE_TEXT = 'Null';
export const ALERTS_TYPES = {
  DANGER: 'danger',
};
export const PROCESSING_TEXT = 'Procesando...';
export const DATE_TEXT = {
  DATE_START: 'date_star',
  DATE_END: 'date_end',
};

export const ECUADOR_BANK_CODES: { [key: string]: string } = {
  'Banco Pichincha C.A.': '010',
  'Banco de Guayaquil S.A': '017',
  'Banco City Bank': '024',
  'Banco Machala': '025',
  'Banco de Loja': '029',
  'Banco del Pacífico': '030',
  'Banco Internacional': '032',
  'Banco Amazonas': '034',
  'Banco del Austro': '035',
  'Produbanco/Promerica': '036',
  'Banco Bolivariano': '037',
  'Comercial de Manabi': '039',
  'Banco General Rumiñahui S.A.': '042',
  'Banco del Litoral S.A.': '043',
  'Banco Solidario': '059',
  'Banco Procredit S.A.': '060',
  'Banco Capital': '061',
  'Banco Desarrollo de Los Pueblos S.A.': '065',
  'Banecuador B.P.': '066',
  'Banco Delbank S.A.': '201',
  'Cooperativa de Ahorro y Crédito JEP': '213',
};

export const PERU_BANK_CODES: { [key: string]: string } = {
  'Banco BBVA': '001',
  'Banco de Credito': '002',
  Interbank: '003',
  Scotiabank: '004',
  'Banco de Comercio': '005',
  'Banco Interamericano de Finanzas (BanBif)': '006',
  'Banco Pichincha': '007',
  Citibank: '008',
  'Banco GNB': '011',
  'Banco Santander': '014',
  'Banco Cencosud': '016',
  'ICBC PERU BANK': '017',
  'Banco de la Nación': '018',
  'Caja Arequipa': '019',
  'Caja Cusco': '020',
  'Caja Huancayo': '021',
  'Caja Maynas': '022',
  'Caja Metropolitana': '023',
  'Caja Municipal Ica': '024',
  'Caja Sullana': '026',
  'Caja Tacna': '027',
  'Caja Trujillo': '028',
};

export const PAYOUT_STAGES: { [key: string]: string } = {
  INPROGRESS: 'IN_PROGRESS',
};

export const ERROR_MESSAGES = {
  LOAD_BALANCE:
    'La foto del comprobante es obligatoria para la transacción de Cargar Saldo.',
  LOAD_BONUS_BALANCE:
    'La foto del comprobante es obligatoria para la transacción de Cargar Bono Saldo.',
  WITHDRAW_BALANCE:
    'La foto del comprobante es obligatoria para la transacción de Retirar Saldo.',
  LOAD_RISK_VOUCHER:
    'La foto del comprobante es obligatoria para la transacción de Boleta Riesgosa Carga.',
  WITHDRAW_RISK_VOUCHER:
    'La foto del comprobante es obligatoria para la transacción de Boleta Riesgosa Retiro.',
};

export const headersExcel: string[] = [
  'Estado', 'Monto', 'Tipo Transacción', 'Creado', 'Cliente',
  'Comprobante', 'Comprobante Empresa', 'Comprobante Reptido', 'Titular', 'Beneficiario', 'Banco Cliente',
  'Identificación', 'Número de Cuenta', 'Banco Empresa', 'Actualizado'
];
