import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectAuth } from '../state/auth/auth.selector';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  private profileSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  profileSubscription!: Subscription;
  profileSubject$: Observable<any> = this.profileSubject.asObservable();

  constructor(private _router: Router, private store: Store) {
    this.profileInitializer();
  }

  profileInitializer() {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario !== -1) {
        this.profileSubject.next(profile);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot): any {
    const requiredPermission = route.data.permission;
    let value = (this.profileSubscription = this.profileSubject$.subscribe(
      (profile) => {
        this.profile = profile;
        if (profile.idusuario !== -1) {
          if (this.profile.rol) {
            const permisosPrincipalesSet = new Set(
              this.profile.rol.permisos_principales.map((pp: { id: any }) => pp.id)
            );

            const subPermissionsSet = new Set(
              this.profile.rol.permisos_principales.flatMap((pp: { permisos: any[] }) => pp.permisos.map((p: { id: any }) => p.id))
            );

            if (
              !requiredPermission ||
              permisosPrincipalesSet.has(requiredPermission) ||
              subPermissionsSet.has(requiredPermission)
            ) {
              if (this.profileSubscription) {
                this.profileSubscription.unsubscribe();
              }
              return true;
            } else {
              this._router.navigateByUrl('/');
              if (this.profileSubscription) {
                this.profileSubscription.unsubscribe();
              }
              return false;
            }

          } else {
            if (this.profileSubscription) {
              this.profileSubscription.unsubscribe();
            }
            return true;
          }
        } else {
          this._router.navigateByUrl('/');
          if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
          }
          return false;
        }
      }
    ));
    return value;
  }
}
