import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import {
  CODE_BONUS_CONSTANS,
  CONFIG_BASE_DATE_RANGEPICKER,
} from '../../../../../../core/constants/constants';
import { CodeBonusInterface } from '../../../../../../core/interfaces/bonus-code.interface';
import { BootstrapModalConfig } from '../../../../../../core/interfaces/IBootstrapModal.interface';
import { BootstrapModalService } from '../../../../../../core/services/boostrap-modal.service';
import { CodeBonusService } from '../../../../../../core/services/code-bonus.service';
import { DynamicModalService } from '../../../../../../core/services/dynamic-modal.service';
import { FilterCommunicationService } from '../../../../../../core/services/filter-communication.service';
import { SocketService } from '../../../../../../core/services/socket.service';
import { UserService } from '../../../../../../core/services/user.service';
import { selectUser } from '../../../../../../core/state/auth/auth.selector';
import { selectScreen } from '../../../../../../core/state/screen/screen.selectors';
import { formatAmount } from '../../../../../../core/utils/format-numeric.utils';
import {
  CODE_BONUS_STATUS,
  columnsTableCodeBonus,
  COUNTRY,
} from '../../../../../../shared/data/bonus/code-bonus/constants';
import {
  MAIN_PERMISSIONS,
  ROLES,
} from '../../../../../../shared/data/config/constants';
import { REQUEST_BONUS_BUTTON_PERMISSIONS } from '../../../../../../shared/data/config/constants-buttons';
import { CreateFormsBcComponent } from '../../forms/create-forms-bc/create-forms-bc.component';
import { ModalEditStateBcComponent } from '../../forms/modal-edit-state-bc/modal-edit-state-bc.component';
import { ModalViewDetailsBonusCodeComponent } from '../../forms/modal-view-details-bonus-code/modal-view-details-bonus-code.component';
import { SelectCountryBcComponent } from '../../forms/select-country-bc/select-country-bc.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bonus-code-table',
  templateUrl: './bonus-code-table.component.html',
  styleUrl: './bonus-code-table.component.scss',
})
export class BonusCodeTableComponent implements OnInit, OnDestroy {
  // LOADER
  public isLoading: boolean = true;
  private subscriptions: Subscription[] = [];

  // TABLE
  public bonusCodeData: any[] = [];
  public columnsTable = columnsTableCodeBonus;
  private dataSelect: CodeBonusInterface | null = null;
  messageSubscription: Subscription;
  dataSocket: { action: string; data: any } = { action: '', data: {} };
  public codeBonus$ = this.store.select(selectUser);
  public pais: number | null = null;
  public selectedFilter: any;
  public defaultFilter: any;
  public total: string = '';
  public isMobile$ = this.store.select(selectScreen);
  public isMobileRedux!: boolean;
  public isMobile: boolean = false;
  public actualIndex!: number;
  public actualItem: any;
  public subscribe: Subscription = new Subscription();
  public stateBonus: number = 3;

  originalComment: string[] = [];
  typeof(arg0: any) {
    return typeof arg0;
  }
  editMode: boolean[] = [];
  public cardExpanded: boolean[] = [];
  subMenuItem: any;
  public bonoIlimitado: number | null = null;
  // PAGINATION
  public collectionSize: number = 0;
  public page: number = CODE_BONUS_CONSTANS.PAGE;
  public limit: number = CODE_BONUS_CONSTANS.LIMIT_TABLE;
  public id_agente: any = null;
  public roleId: number | null = null;
  public startDate = CONFIG_BASE_DATE_RANGEPICKER.minDate;
  public endDate = CONFIG_BASE_DATE_RANGEPICKER.initialRange[1];
  public totalDolares: string = '';
  public totalSoles: string = '';
  public token: string = '';
  public profile: any;
  public ROLES = ROLES;
  public CODE_BONUS_STATUS = CODE_BONUS_STATUS;
  public COUNTRY = COUNTRY;
  public buttonAllowed: boolean = false;
  public deleteButtonAllowed: boolean = false;
  public verifyButtonAllowed: boolean = false;
  public copyButtonAllowed: boolean = false;
  public sendWsButtonAllowed: boolean = false;
  public excelButtonAllowed: boolean = false;

  constructor(
    private bonusCodeService: CodeBonusService,
    private _toastr: ToastrService,
    private _bsModalService: BootstrapModalService,
    private socketService: SocketService,
    private dynamicModal: DynamicModalService,
    private store: Store,
    private _filterCommunicationService: FilterCommunicationService,
    private router: Router,
    private activedRouter: ActivatedRoute,
    private _userService: UserService,
    private datePipe: DatePipe
  ) {
    this.socketService.joinRoom('bonos');
    this.messageSubscription = this.socketService
      .getMessageRoomSubject()
      .subscribe((msg) => {
        this.dataSocket = msg;
        if (this.dataSocket?.action) {
          if (
            this.dataSocket?.action === 'U' ||
            this.dataSocket?.action === 'A'
          ) {
            this.updateItemList(this.dataSocket.data);
          }
        }
      });
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      this.activedRouter.params.subscribe((params) => {
        this.token = params['token'];
      });
      this._userService.getUserByTokenApp(this.token).subscribe({
        next: (res) => {
          if (res.data.idusuario) {
            this._userService.getUser(res.data.idusuario).subscribe({
              next: (res) => {
                const profile = res.data;
                this.profile = profile;
                this.roleId = profile.id_rol;
                this.id_agente = profile.idusuario;
                this.pais = profile.id_pais;
                this.bonoIlimitado = profile.bono_ilimitado;
                if (this.roleId != ROLES.UNDEFINED) {
                  this.getData();
                  this.getDataFromFilter();
                  this.mainButton();
                }
              },
              error: (err) => {
                console.error('Error fetching user data', err);
              },
            });
          }
        },
        error: (err) => {
          console.error('Error fetching user by token', err);
        },
      });
    } else {
      this.codeBonus$.subscribe((profileData) => {
        this.profile = profileData;
        this.roleId = profileData.id_rol;
        this.id_agente = profileData.idusuario;
        this.pais = profileData.id_pais;
        if (this.roleId != ROLES.UNDEFINED) {
          this.getData();
          this.getDataFromFilter();
          this.mainButton();
        }
      });
    }
  }

  ngOnInit(): void {
    this.isMobile$.subscribe((isMobile) => {
      this.isMobileRedux = isMobile;
    });
  }
  updateItemList(newItem: any): void {
    const index = this.bonusCodeData.findIndex(
      (item: any) => item.idbonos === newItem.idbonos
    );
    if (index !== -1) {
      Object.keys(newItem).forEach((key) => {
        if (this.bonusCodeData[index][key] !== newItem[key]) {
          this.bonusCodeData[index][key] = newItem[key];
        }
      });
    } else {
      if (this.page === 1) {
        this.bonusCodeData.unshift(newItem);
      }
    }
  }
  getDataByAgent(agentId: number, page: number, limit: number): void {
    this.isLoading = true;
    this.defaultFilter = this.selectedFilter
      ? this.selectedFilter
      : {
          date_star: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
          date_end: moment(this.endDate).format('YYYY-MM-DD 23:59:59'),
        };
    const subscription = this.bonusCodeService
      .getCodeBonusByAgent(agentId, page, limit, this.defaultFilter)
      .subscribe({
        next: (response) => {
          this.bonusCodeData = response.data.rows;
          this.collectionSize = response.data.count;
          this.calculateTotal();
          this.isLoading = false;
        },
        error: (error) => {
          this._toastr.error('Internal server error', 'Error');
          this.isLoading = false;
        },
      });

    this.subscriptions.push(subscription);
  }

  getAllData(page: number, limit: number): void {
    this.isLoading = true;
    this.defaultFilter = this.selectedFilter
      ? this.selectedFilter
      : {
          date_star: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
          date_end: moment(this.endDate).format('YYYY-MM-DD 23:59:59'),
        };
    const subscription = this.bonusCodeService
      .getAllCodeBonus(page, limit, this.defaultFilter)
      .subscribe({
        next: (response) => {
          this.bonusCodeData = response.data.rows;
          this.collectionSize = response.data.count;
          this.totalDolares = response.data.totalMontoSummary.Ecuador
            ? formatAmount(
                Number(response.data.totalMontoSummary.Ecuador),
                'EC'
              )
            : '$ 0';
          this.totalSoles = response.data.totalMontoSummary.Peru
            ? formatAmount(Number(response.data.totalMontoSummary.Peru), 'PE')
            : 'S/ 0';
          this.isLoading = false;
        },
        error: (error) => {
          this._toastr.error('Internal server error', 'Error');
          this.isLoading = false;
        },
      });

    this.subscriptions.push(subscription);
  }

  getData(): void {
    if (this.roleId === ROLES.AGENT) {
      this.getDataByAgent(this.id_agente, this.page, this.limit);
    } else {
      this.getAllData(this.page, this.limit);
    }
  }

  getDataFromFilter() {
    this.subscribe = this._filterCommunicationService.currentFilter.subscribe(
      (filter) => {
        this.selectedFilter = filter;
        this.getData();
      }
    );
  }

  mainButton() {
    const mainPermission = this.profile?.rol?.permisos_principales;
    const subscriptionsPayments = mainPermission.find(
      (permission: { id: number }) => permission.id === MAIN_PERMISSIONS.Bonos
    );

    if (subscriptionsPayments) {
      this.buttonAllowed = subscriptionsPayments.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id ===
          REQUEST_BONUS_BUTTON_PERMISSIONS.NEW_BONUS_CODE
      );
      this.deleteButtonAllowed = subscriptionsPayments.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id ===
          REQUEST_BONUS_BUTTON_PERMISSIONS.DELETE_BONUS_CODE
      );
      this.copyButtonAllowed = subscriptionsPayments.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id ===
          REQUEST_BONUS_BUTTON_PERMISSIONS.COPY_BONUS_CODE
      );
      this.sendWsButtonAllowed = subscriptionsPayments.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id ===
          REQUEST_BONUS_BUTTON_PERMISSIONS.SEND_WS_BONUS_CODE
      );
      this.verifyButtonAllowed = subscriptionsPayments.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id ===
          REQUEST_BONUS_BUTTON_PERMISSIONS.VERIFY_BONUS_CODE
      );
      this.excelButtonAllowed = subscriptionsPayments.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id ===
          REQUEST_BONUS_BUTTON_PERMISSIONS.EXCEL_BONUS_CODE
      );
    }
  }

  formatFecha(fecha: string): string {
    const fechaISO = new Date(fecha);
    return formatDate(fechaISO, 'dd/MM/yyyy HH:mm:ss', 'en-US');
  }

  onPageChange(page: number): void {
    this.page = page;
    this.getData();
  }

  onLimitChange(limit: number) {
    this.limit = limit;
    // this.pageSize = limit
    this.getData();
  }
  getStatusText(state: number): string {
    switch (state) {
      case CODE_BONUS_STATUS.PENDING:
        return 'Bono Pendiente';
      case CODE_BONUS_STATUS.CREATED:
        return 'Bono Creado';
      case CODE_BONUS_STATUS.SENT:
        return 'Bono Enviado';
      case CODE_BONUS_STATUS.DELETED:
        return 'Bono ELIMINADO';
      default:
        return 'Estado Desconocido';
    }
  }

  getCurrencySymbol(countryId: number): string {
    switch (countryId) {
      case COUNTRY.ECUADOR:
        return '$';
      case COUNTRY.PERU:
        return 'S/';
      default:
        return '';
    }
  }

  copyPromotionalCode(monto: number, promotionalCode: string) {
    const tempInput = document.createElement('input');
    tempInput.value =
      'Saludos, tienes un bono pendiente por activar de ' +
      monto +
      ', utiliza el código: ' +
      '*' +
      promotionalCode +
      '*';
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.editStateBonusCode();
    this._toastr.success(
      'Saludos, tienes un bono pendiente por activar de ' +
        monto +
        ', utiliza el código: ' +
        '*' +
        promotionalCode +
        '*',
      'Mensaje copiado con éxito:'
    );
  }
  onRowSelected(data: CodeBonusInterface): void {
    setTimeout(() => {
      this.dataSelect = data;
    }, 0);
  }
  editStateBonusCode(): void {
    setTimeout(() => {
      if (!this.dataSelect || this.dataSelect.estado === 2) return;
      const modalConfig: BootstrapModalConfig = {
        title: 'Cambiar a Enviados?',
        options: { size: 's', centered: true },
        component: ModalEditStateBcComponent,
        data: {
          bgHeader: 'text-danger',
          bonusCode: this.dataSelect,
        },
      };
      this._bsModalService.openModal(modalConfig);
    }, 1);
  }
  openCreateBcFormModal() {
    if (this.roleId === ROLES.AGENT) {
      this.dynamicModal.openModal(
        CreateFormsBcComponent,
        'Crear Código De Bono',
        'xl',
        false,
        {
          id_country: this.pais,
          idRol: this.roleId,
          bonoIlimitado: this.bonoIlimitado,
          id_agente: this.id_agente,
        }
      );
    } else {
      this.dynamicModal.openModal(
        SelectCountryBcComponent,
        'Crear Código De Bono',
        'md',
        true
      );
    }
  }

  viewDetailsBonusCode(): void {
    const modalConfig: BootstrapModalConfig = {
      title: 'Detalles De Código De Bono',
      options: { size: 'l', centered: true },
      component: ModalViewDetailsBonusCodeComponent,
      data: {
        bonusCode: this.bonusCodeData,
        bgHeader: 'text-danger',
      },
    };
    this._bsModalService.openModal(modalConfig);
  }
  exportToExcel() {
    const fileName = 'bonus_code.xlsx';
    const worksheetName = 'Codigos De Bonos';
    const excelData = this.bonusCodeData.map((item: any) => ({
      Fecha: this.datePipe.transform(item.fecha, 'dd/MM/yyyy HH:mm:ss', 'UTC'),
      Monto: item.monto,
      Comentario: item.comentario,
      Creado: this.datePipe.transform(
        item.fecha_creacion,
        'dd/MM/yyyy HH:mm:ss',
        'UTC'
      ),
      Agente: item.agente,
      CreadoPor: item.usuario,
      Estado: this.getStatusText(item.estado),
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
    XLSX.writeFile(workbook, fileName);
  }
  reloadTable() {
    this.isLoading = true;
    this.getData();
  }
  ngOnDestroy(): void {
    this.socketService.leaveRoom('bonos');
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscribe.unsubscribe();
    this._filterCommunicationService.resetFilter();
  }
  calculateTotal() {
    let total = 0;
    for (const item of this.bonusCodeData) {
      total += parseFloat(item.monto);
    }
    this.total = total.toFixed(2); // Convert to string with 2 decimal places
  }

  showMenu: boolean = false;
  showSubMenu: boolean = false;
  toggleMenu(index: number, item: any) {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.actualIndex = index;
      this.actualItem = item;
      this.onRowSelected(item); // Ensure the item is selected
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }

  expandCard(index: number) {
    this.cardExpanded[index] = !this.cardExpanded[index];
  }
  closeMenu() {
    this.showMenu = false;
    this.showSubMenu = false;
    document.body.style.overflowY = 'auto';
  }
  openSubMenu(item: any) {
    this.subMenuItem = item;
    this.showMenu = false;
    this.showSubMenu = true;
  }
  closeSubMenu() {
    this.showMenu = true;
    this.showSubMenu = false;
  }
  cancelEdit(index: number) {
    this.editMode[index] = false;
  }
  onCommentChange(comment: any, index: number) {
    this.originalComment[index] = comment.target.value;
  }
  deleteBonusCode() {
    Swal.fire({
      title: '¿Estás seguro que  quieres eliminar el código de bono?',
      text: 'No podrás revertir esto!',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const bonus = {
          idbonos: this.dataSelect?.idbonos,
          estado: this.stateBonus,
        };
        this.bonusCodeService
          .updateStateBonusCode(bonus)
          .subscribe((response) => {
            Swal.fire({
              title: 'Código de bono eliminado!',
              text: 'El código de bono fue eliminado correctamente.',
              icon: 'success',
            }).then(() => {
              this.reloadTable();
            });
          });
      }
    });
  }
}
