import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import {
  CONFIG_BASE_DATE_RANGEPICKER,
  SORT_DIRECTION,
} from '../../../../../../../core/constants/constants';
import { ChargesWithdrawalsService } from '../../../../../../../core/services/charges-withdrawals.service';
import { FilterCommunicationService } from '../../../../../../../core/services/filter-communication.service';
import { StateService } from '../../../../../../../core/services/state.service';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import { formatAmount } from '../../../../../../../core/utils/format-numeric.utils';
import {
  MOVEMENT_CLASSES,
  MOVEMENT_TYPE,
  STATE_LW_CLASSES,
  columnsTableLw,
} from '../../../../../../../shared/data/loads-withdrawal/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { selectScreen } from '../../../../../../../core/state/screen/screen.selectors';
import { UserService } from '../../../../../../../core/services/user.service';
import { Subscription } from 'rxjs';
import { LOADS_WITHDRAWAL_BUTTON_PERMISSIONS } from '../../../../../../../shared/data/config/constants-buttons';
import {
  Permission,
  MainPermission,
} from '../../../../../../../core/interfaces/permision.interface';

@Component({
  selector: 'app-list-lw-table',
  templateUrl: './list-lw-table.component.html',
  styleUrl: './list-lw-table.component.scss',
})
export class ListLwTableComponent implements OnInit, OnDestroy {
  // LOADER
  public isLoading: boolean = false;

  // TABLE
  public lwData: any;
  public columnsTable!: any[];
  public totalDolares: string = '';
  public totalSoles: string = '';
  public states: any;
  public showMenu: boolean = false;
  public showSubMenu: boolean = false;
  public subMenuItem: any;
  public actualIndex!: number;
  public actualItem: any;
  public detailComment: string[] = [];
  public SORT_DIRECTION = SORT_DIRECTION;
  public LOADS_WITHDRAWAL_BUTTON_PERMISSIONS =
    LOADS_WITHDRAWAL_BUTTON_PERMISSIONS;

  // PAGINATION
  public collectionSize: number = 0;
  public page: number = 1;
  public limit: number = 10;
  public sortColumn: string | null = null;
  public sortDirection: string | null = null;

  //FILTER
  public selectedFilter: any;
  public defaultFilter: any;
  public startDate = CONFIG_BASE_DATE_RANGEPICKER.minDate;
  public endDate = CONFIG_BASE_DATE_RANGEPICKER.initialRange[1];
  public isMobile: boolean = false;
  public token!: string;
  public subscribe: Subscription = new Subscription();

  //LOGGEDSUSER
  public profile$ = this.store.select(selectAuth);
  public profile: any;

  // REDUX
  public isMobile$ = this.store.select(selectScreen);
  public isMobileRedux!: boolean;

  constructor(
    private _filterCommunicationService: FilterCommunicationService,
    private stateService: StateService,
    private store: Store,
    private lwService: ChargesWithdrawalsService,
    private router: Router,
    private activedRouter: ActivatedRoute,
    private _userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    this.isMobile$.subscribe((isMobile) => {
      this.isMobileRedux = isMobile;
    });
    this.columnsTable = this.isMobile
      ? columnsTableLw.filter((column) => column.name !== 'Acciones')
      : columnsTableLw;
    if (this.isMobile) {
      await this.loadTokenLogged();
    } else {
      await this.loadProfileLogged();
    }
    this.getDataFromFilter();
    this.getStatesLw();
  }

  //? LOAD DATA
  async loadProfileLogged() {
    await new Promise<void>((resolve, reject) => {
      this.profile$.subscribe({
        next: (res) => {
          if (res.idusuario !== -1) {
            this.profile = res;
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
  async loadTokenLogged() {
    await new Promise<void>((resolve, reject) => {
      this.activedRouter.params.subscribe((params) => {
        this.token = params['token'];
        this._userService.getUserByTokenApp(this.token).subscribe({
          next: (res) => {
            if (res.data.idusuario) {
              this.profile = res.data;
              resolve();
            } else {
              reject(new Error('No user id found'));
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    });
  }
  getData(): void {
    this.isLoading = true;

    this.defaultFilter = this.selectedFilter
      ? this.selectedFilter
      : {
        date_star: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
        date_end: moment(this.endDate).format('YYYY-MM-DD 23:59:59'),
      };
    const filterWithSorting = {
      ...this.defaultFilter,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
    };

    this.lwService
      .getAllLw(this.page, this.limit, filterWithSorting)
      .subscribe((res) => {
        const response = res.data;
        this.isLoading = false;
        this.totalDolares = response.totalMontoSummary.Ecuador
          ? formatAmount(Number(response.totalMontoSummary.Ecuador), 'EC')
          : '$ 0';
        this.totalSoles = response.totalMontoSummary.Peru
          ? formatAmount(Number(response.totalMontoSummary.Peru), 'PE')
          : 'S/ 0';
        this.lwData = response.data.map((item: any) => ({
          ...this.formatLwData(item),
        }));
        this.collectionSize = response.totalCount;
      });
  }

  onSort(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection =
        this.sortDirection === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC;
    } else {
      this.sortColumn = column;
      this.sortDirection = SORT_DIRECTION.ASC;
    }
    this.getData();
  }

  getStatesLw() {
    this.stateService.geatAllDataLw().subscribe((response) => {
      this.states = response.data;
    });
  }
  formatLwData(item: any) {
    const monto = formatAmount(
      Number(item.monto),
      item.id_pais === 1 ? 'EC' : 'PE'
    );

    return {
      ...item,
      monto: monto,
    };
  }
  toggleMenu(index: number, item: any) {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.actualIndex = index;
      this.actualItem = item.comentario;
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }

  //?FILTER
  getDataFromFilter() {
    this.subscribe = this._filterCommunicationService.currentFilter.subscribe(
      (filter) => {
        if (this.isMobile) {
          if (filter) {
            this.selectedFilter = filter;
            this.getData();
          }
        } else {
          this.selectedFilter = filter;
          this.getData();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  //*BUTTONS
  reloadTable() {
    this.isLoading = true;
    this.sortColumn = null;
    this.sortDirection = null;
    this.getData();
  }
  setStatus(idcargas_retiros: number, idestado: number) {
    Swal.fire({
      title: '¿Estás seguro que  quieres cambiar el estado?',
      text: 'No podrás revertir esto!',
      icon: 'warning',
      iconColor: '#8C98A1',
      showCancelButton: true,
      confirmButtonColor: '#54BA4A',
      cancelButtonColor: '#5A6268',
      confirmButtonText: 'Si, cambiar!',
      cancelButtonText: 'No, cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.lwService
          .setState(idcargas_retiros, idestado)
          .subscribe((response) => {
            Swal.fire({
              title: 'Estado actualizado 👌!',
              text: 'El estado fue actualizado correctamente.',
              icon: 'success',
            });
          });
      }
    });
  }

  exportToExcel() {
    const fileName = 'cargas_retiros_data.xlsx';
    const worksheetName = 'Cargas Y Retiros Data';
    const excelData = this.lwData.map((item: any) => {
      const montoOriginal = item.monto.trim();
      const montoConFormato = montoOriginal
        .replace(/[$S/]/g, '')
        .replace(/−/g, '-')
        .replace(/\s/g, '');

      const montoSinMoneda = parseFloat(montoConFormato.replace(',', '.'));
      const moneda = item.monto.includes('$')
        ? 'USD'
        : item.monto.includes('S/')
          ? 'PEN'
          : 'Desconocido';

      return {
        Estado: STATE_LW_CLASSES[item.id_estado]?.text || 'Desconocido',
        Creado: item.fecha,
        Monto: montoSinMoneda,
        Moneda: moneda,
        categoria:
          MOVEMENT_TYPE.find((type) => type.value === String(item.id_categoria))
            ?.label || 'Desconocido',
        Titular: item.titular?.usuario,
        Agente: item.agente?.usuario,
        Comentario: item.comentario,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
    XLSX.writeFile(workbook, fileName);
  }

  //*PAGINATION
  // Métodos para manejar el cambio de página y el cambio de límite
  onPageChange(page: number): void {
    this.page = page;
    this.getData();
  }

  onLimitChange(limit: number): void {
    this.limit = limit;
    this.getData();
  }

  //? STATE CLASSES
  getStateClasses(state: number) {
    return STATE_LW_CLASSES[state] || { text: '', classes: {} };
  }

  getMovementClass(type: number): string {
    return MOVEMENT_CLASSES[type] || '';
  }

  getMovementIconClass(type: number): string {
    return this.getMovementIconClassFromTypes(type) || '';
  }

  getMovementIconClassFromTypes(type: number): string {
    // Aquí puedes definir los íconos específicos para cada tipo de movimiento, similar a TRANSACTION_ICON_CLASSES
    const MOVEMENT_ICON_CLASSES: { [key: number]: string } = {
      1: 'fas fa-credit-card', // Ejemplo de ícono para 'Credito'
      2: 'fas fa-money-bill', // Ejemplo de ícono para 'Debito'
    };
    return MOVEMENT_ICON_CLASSES[type] || '';
  }

  getMovementTypeLabel(type: number): string {
    const typeObj = MOVEMENT_TYPE.find((t) => Number(t.value) === type);
    return typeObj ? typeObj.label : 'Desconocido';
  }

  //*CARD
  closeMenu() {
    this.showMenu = false;
    this.showSubMenu = false;
    document.body.style.overflowY = 'auto';
  }
  openSubMenu(item: any) {
    this.subMenuItem = item;
    this.showMenu = false;
    this.showSubMenu = true;
  }
  closeSubMenu() {
    this.showMenu = true;
    this.showSubMenu = false;
  }

  hasPermission(permissionId: number): boolean {
    return this.profile?.rol?.permisos_principales.some(
      (mainPermission: MainPermission) =>
        mainPermission.permisos.some(
          (permission: Permission) => permission.id === permissionId
        )
    );
  }
}
