import { Injectable, inject } from '@angular/core';
import { StorageService } from '../services/storage.service';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
const USER_SESSION = 'USER_SESSION';
const USER_SESSION_PRE = 'USER_SESSION_PRE';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor {
  private storageService = inject(StorageService);
  constructor(private router: Router) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const data =
      JSON.parse(this.storageService.secureStorage.getItem(USER_SESSION)) ??
      JSON.parse(this.storageService.secureStorage.getItem(USER_SESSION_PRE));
    let token;
    if (!data) {
      const fullUrl = this.router.url;
      const [baseUrl] = fullUrl.split('?');
      const urlSegments = baseUrl.split('/');
      token = urlSegments[urlSegments.length - 1];
    } else {
      token = data?.data?.token;
    }

    if (req.url.includes('create-withdrawal')) {
      return next.handle(req)
    }

    const tokenReq = req.clone({
      setHeaders: {
        'X-API-KEY': `${token}`,
      },
    });
    return next.handle(tokenReq);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
