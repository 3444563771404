import { Component, HostListener } from '@angular/core';
import {
  columnsTableResult,
  columnsTableSumary,
} from '../../../shared/data/reports/constants';
import { Observable, Subject, Subscription, debounceTime, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from '../../../core/services/user.service';
import { FinancialService } from '../../../core/services/financial.service';
import { ToastService } from 'angular-toastify';
import { ClosureService } from '../../../core/services/closure.service';
import { formatAmount } from '../../../core/utils/format-numeric.utils';
import { Store } from '@ngrx/store';
import { selectAuth } from '../../../core/state/auth/auth.selector';
import { User } from '../../../core/interfaces/user.interface';
import Swal from 'sweetalert2';
import moment, { Moment } from 'moment';
import { selectScreen } from '../../../core/state/screen/screen.selectors';
import { defaultInitialDateRangepickerV2 } from '../../../core/constants/constants';
import { USERS_TYPES } from '../../../shared/data/users/agents/constants';
import { MAIN_PERMISSIONS, ROLES } from '../../../shared/data/config/constants';
import { UserMainService } from '../../user/services/user-main.service';
import { ScreenSizeService } from '../../../core/services/screen-size.service';
import { REPORT_PERMISSIONS } from '../../../shared/data/config/constants-buttons';
@Component({
  selector: 'app-closure',
  templateUrl: './closure.component.html',
  styleUrl: './closure.component.scss',
})
export class ClosureComponent {
  form: FormGroup;
  public optionsCountries$!: Observable<string[]>;
  private profileRedux: Subscription = new Subscription();
  private filterSubject: Subject<string> = new Subject<string>();
  private agentsSubject: Subject<string> = new Subject<string>();
  public criteria!: string;
  private values!: any;
  public allAgentsData: any[] = [];
  public columnsTableResult = columnsTableResult;
  public columnsTableSumary = columnsTableSumary;
  public currentPageAgents: number = 1;
  public id_pais: number = 1;
  public pageSizeAgents: number = 10;
  public totalTable: number = 0;
  public totalTableString: string = '';
  public totalBody: number = 0;
  public totalBodyString: string = '';
  public totaldebtAdjustment: number = 0;
  public totalClosureString: string = '';
  public totalClosure: number = 0;
  public countryId: number = 0;
  public selectedItem!: number | null;
  public initialRange: Date[] = defaultInitialDateRangepickerV2;
  private startDate: Date = this.initialRange[0];
  private endDate: Date = this.initialRange[1];
  public country: any;
  isSmallScreen: boolean = false;
  isLoading: boolean = false;
  token!: string;
  public closureData!: any;
  public profile$ = this.store.select(selectAuth);
  public profile!: User;
  public rol!: Number;
  showColumn1: boolean = false;
  showColumn2: boolean = false;
  showColumn3: boolean = false;
  showRefers: boolean = true;
  wasConsulted: boolean = false;
  public cardExpanded: boolean[] = [];
  public USERS_TYPES = USERS_TYPES;
  public ROLES = ROLES;
  private screenSizeSub: Subscription = new Subscription();
  hasMoreThanTwentyItems: boolean = false;
  showScrollUpButton: boolean = false;
  showScrollDownButton: boolean = true;
  public sendClosureAllow: boolean = false;
  public consultClosureAllow: boolean = false;
  constructor(
    private store: Store,
    private fb: FormBuilder,
    private activedRouter: ActivatedRoute,
    private _userService: UserService,
    private _financialService: FinancialService,
    private toastService: ToastService,
    private _closureService: ClosureService,
    private router: Router,
    private _userMainService: UserMainService,
    private screenSizeService: ScreenSizeService
  ) {
    this.form = this.fb.group({
      agent: new FormControl([], [Validators.required]),
      countryId: new FormControl([]),
    });
    this.activedRouter.params.subscribe((params) => {
      this.token = params['token'];
    });
    this.agentsSubject
      .pipe(
        debounceTime(250) // Adjust debounce time as needed (e.g., 300 milliseconds)
      )
      .subscribe((term: any) => {
        this.criteria = term;
        this.allAgentsData = [];
        this.currentPageAgents = 1;
        this.loadAgents(term);
      });
  }

  ngOnInit(): void {
    let executed = true;
    if (this.token) {
      if (executed) {
        executed = false;
        this.onSubmit();
      }
    }
    this.profileRedux = this.profile$.subscribe({
      next: (res) => {
        if (res.usuario !== '') {
          this.profile = res;
          this.rol = res.id_rol;
          if ([3, 4].includes(res.tipo_usuario)) {
            if (executed) {
              executed = false;
              this.onSubmit();
            }
          }
          this.allowPermission();
        }
      },
      error: (err) => {},
    });
    this.getCountriesData();
    this.loadAgents();
    this.updateScrollButtons();
    this.screenSizeSub = this.screenSizeService.isBelow900$.subscribe({
      next: (isBelow900) => {
        if (isBelow900) {
          this.showColumn1 = false;
          this.showColumn2 = false;
          this.showColumn3 = false;
        } else {
          this.showColumn1 = true;
          this.showColumn2 = true;
          this.showColumn3 = true;
        }
      },
      error: (err) => {
        console.error('Error detecting screen size:', err);
      },
    });
  }

  ngOnDestroy(): void {
    this.profileRedux.unsubscribe();
    this.screenSizeSub.unsubscribe();
  }

  onSubmit() {
    if (this.form.valid) {
      this.getClosure();
      // this.formSubmit.emit(this.form.value); // Emitir los datos del formulario cuando sea válido
    } else if (this.token) {
      this._userService.getUserByTokenApp(this.token).subscribe({
        next: (res) => {
          this.selectedItem = res.data.idusuario;
          this.getClosure();
        },
        error: (err) => {},
      });
    } else if ([3, 4].includes(this.profile.tipo_usuario)) {
      this.selectedItem = this.profile.idusuario;
      this.getClosure();
    } else {
      this.toastService.error('Por favor seleccione un agente');
    }
  }

  onSearch(term: string) {
    this.allAgentsData = [];
    this.agentsSubject.next(term);
  }

  onScrollToEnd() {
    this.currentPageAgents++;
    this.loadAgents(this.criteria);
  }

  loadAgents(searchTerm?: string) {
    this._userMainService
      .getAllReferredAndAgents(
        this.currentPageAgents,
        this.pageSizeAgents,
        searchTerm,
        this.id_pais
      )
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: any; idusuario: any }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.allAgentsData = [...this.allAgentsData, ...transformedData];
      });
  }

  getCountriesData() {
    this._financialService.getAllCountries().subscribe((response) => {
      const transformedData = response.data.map(
        (item: { pais: any; id_pais: any }) => {
          return { label: item.pais, value: item.id_pais };
        }
      );

      this.optionsCountries$ = of(transformedData);
    });
  }

  allowPermission() {
    const permission = this.profile?.rol?.permisos_principales;
    const reportPermission = permission.find(
      (permission: { id: number }) => permission.id === MAIN_PERMISSIONS.Reporte
    );

    if (reportPermission) {
      this.sendClosureAllow = reportPermission.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id === REPORT_PERMISSIONS.SEND_CLOSURE
      );
      this.consultClosureAllow = reportPermission.permisos.some(
        (permissionButton: { id: number; activar: number }) =>
          permissionButton.id === REPORT_PERMISSIONS.CONSULT_CLOSURE
      );
    }
  }

  public onDateSelected({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.wasConsulted = false;
  }

  getClosure() {
    if (this.selectedItem) {
      this.wasConsulted = true;
      this.isLoading = true;
      this._userService.getUser(JSON.stringify(this.selectedItem)).subscribe({
        next: (res) => {
          this.countryId = res.data?.pais?.id_pais;
          this.country = res.data?.pais?.codigo;
        },
        error: (err) => {
          console.log(err);
        },
      });
      this._closureService
        .getClosure(this.startDate, this.endDate, this.selectedItem)
        .subscribe({
          next: (res) => {
            this.totalBody = 0;
            this.totaldebtAdjustment = 0;
            res.data.table = res.data.table.map((item: any) => ({
              ...item,
              pagos: Math.abs(item.pagos),
              retiros: Math.abs(item.retiros),
            }));
            res.data.tableString = JSON.parse(JSON.stringify(res.data.table));
            res.data.tableString = res.data.tableString.map((item: any) => ({
              ...item,
              cargas: item.cargas,
              pagos: item.pagos,
              punto_fisico: item.punto_fisico,
              bono_cargado: item.bono_cargado,
            }));
            res.data.headerString = JSON.parse(JSON.stringify(res.data.header));
            if (res.data.header.length <= 0) {
              res.data.headerString = [{ valor_cierre: '0.00' }];
            }
            res.data.bodyString = JSON.parse(JSON.stringify(res.data.body));
            res.data.debtAdjustmentString = JSON.parse(
              JSON.stringify(res.data.debtAdjustment)
            );
            if (res.data.headerString[0]?.valor_cierre) {
              res.data.headerString[0].valor_cierre = formatAmount(
                Number(res.data?.headerString[0]?.valor_cierre),
                this.country
              );
            }
            res.data.bodyString = res.data.bodyString.map((item: any) => {
              this.totalBody += Number(item.comision);
              return {
                ...item,
                balance_actual: formatAmount(
                  Number(item.balance_actual),
                  this.country
                ),
                balance_anterior: formatAmount(
                  Number(item.balance_anterior),
                  this.country
                ),
                balance_final: formatAmount(
                  Number(item.balance_final),
                  this.country
                ),
                comision: formatAmount(Number(item.comision), this.country),
              };
            });
            res.data.debtAdjustmentString = res.data.debtAdjustmentString.map(
              (item: any) => {
                this.totaldebtAdjustment += Number(item.monto);
                return {
                  ...item,
                  monto: formatAmount(Number(item.monto), this.country),
                };
              }
            );
            this.formatData(res.data.tableString[0]);
            this.totalTable = Number(
              (
                (+res.data?.header[0]?.valor_cierre || 0) +
                (+res.data?.table[0]?.abonos || 0) +
                (-res.data?.table[0]?.pagos || 0) +
                (-res.data?.table[0]?.cargas || 0) +
                (+res.data?.table[0]?.retiros || 0) +
                (+res.data?.table[0]?.punto_fisico || 0) +
                (+res.data?.table[0]?.bono_comprado || 0) +
                (+res.data?.table[0]?.bono_comprado || 0) +
                (-res.data?.table[0]?.bono_cargado || 0)
              ).toFixed(2)
            );
            this.totalTableString = formatAmount(this.totalTable, this.country);
            this.totalBodyString = formatAmount(
              this.totalBody + this.totaldebtAdjustment,
              this.country
            );
            this.totalClosure =
              this.totalTable + this.totalBody + this.totaldebtAdjustment;
            this.totalClosureString = formatAmount(
              this.totalTable + this.totalBody + this.totaldebtAdjustment,
              this.country
            );
            this.isLoading = false;
            if (!this.token) {
              this.toastService.success('Cierre obtenido con exito');
            }
            this.closureData = res.data;
            this.checkItemsCount();
          },
          error: (error) => {
            this.toastService.error('Hubo un error al obtener el cierre');
            console.error(`Error: ${error}`);
          },
        });
    }
  }

  selectedCountry() {
    this.id_pais = this.form.value.countryId?.value;
    this.selectedItem = null;
  }

  selectedAgent() {
    this.selectedItem = this.form.value.agent;
  }

  formatData(array: any) {
    const object = array;
    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        let value = Number(object[key]);
        if (typeof value === 'number') {
          object[key] = formatAmount(value, this.country);
        }
      }
    }
  }
  expandCard(index: number) {
    this.cardExpanded[index] = !this.cardExpanded[index];
  }
  sendClosure() {
    if (
      this.wasConsulted &&
      this.selectedItem &&
      ![3, 4].includes(this.profile.tipo_usuario)
    ) {
      Swal.fire({
        title: 'De que modo deseas enviar el cierre?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Enviar App',
        denyButtonText: `Envio Directo`,
        customClass: {
          actions: 'swal2-custom-actions',
          cancelButton: 'swal2-cancel-btn',
          confirmButton: 'swal2-confirm-btn',
          denyButton: 'swal2-deny-btn',
        },
      }).then((result) => {
        let date = moment().format('DD/MM/YYYY HH:mm:ss');
        let dateSent: Moment;
        let mes_cierre: Number;
        let anio_cierre: Number;
        let enviado = 0;
        let origen_msj = '';
        if (result.isConfirmed) {
          enviado = 1;
          origen_msj = 'app';
        } else if (result.isDenied) {
          enviado = 2;
          origen_msj = 'Envio Directo sin cargar';
        }
        if (result.isConfirmed || result.isDenied) {
          let creado;
          let actualizado;
          dateSent = moment.utc();
          if (this.closureData.header[0]?.actualizado) {
            actualizado = date;
          } else {
            actualizado = undefined;
          }
          if (dateSent.month() === 0) {
            mes_cierre = 12;
            anio_cierre = dateSent.year() - 1;
          } else {
            mes_cierre = dateSent.month();
            anio_cierre = dateSent.year();
          }
          const obj = {
            id_usuario: Number(
              this.closureData.header[0]?.idUsuario?.idusuario ||
                this.selectedItem
            ),
            id_pais: Number(
              this.closureData.header[0]?.idUsuario?.id_pais || this.countryId
            ),
            fecha_envio: date,
            creado: date,
            actualizado: actualizado,
            enviado,
            origen_msj,
            mes_cierre,
            anio_cierre,
            valor_cierre: this.totalClosure,
            id_usuario_add: this.profile.idusuario,
          };
          // //esta sera para cuando exista cierre anterior
          this._closureService.sendClosure(obj).subscribe({
            next: (response) => {
              Swal.fire('Saved!', '', 'success');
            },
            error: (err) => {
              Swal.fire('Changes are not saved', '', 'info');
            },
          });
        }
      });
    } else {
      this.toastService.error('Error al enviar Cierre');
    }
  }
  redirectToDaily() {
    if (this.token) {
      this.router.navigate(['/mobile/closure/daily/' + this.token]);
    } else {
      this.router.navigate(['/closure/daily']);
    }
  }

  checkItemsCount() {
    this.hasMoreThanTwentyItems = this.closureData.body.length > 20;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  scrollToBottom() {
    window.scrollTo({
      top: document.documentElement.scrollHeight - window.innerHeight,
      behavior: 'smooth',
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateScrollButtons();
  }

  updateScrollButtons() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    this.showScrollUpButton = scrollTop > windowHeight;
    this.showScrollDownButton = scrollTop + windowHeight < documentHeight;
  }
}
