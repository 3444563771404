import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IProfileState, User } from '../../interfaces/user.interface';

export const selectAuth =
  createFeatureSelector<Readonly<IProfileState>>('auth');
export const selectPreAuth = createFeatureSelector<Readonly<any>>('pre_auth');

export const selectErrorMessage = createSelector(
  selectAuth,
  (state) => state.message
);

export const selectId = createSelector(selectAuth, (state) => state.idusuario);

export const selectLoading = createSelector(
  selectAuth,
  (state) => state.loading
);

export const selectUser = createSelector(selectAuth, (state) => state);
