<div class="container-fluid">
  @if (isLoading) {
  <div class="loader-box">
    <div class="loader-16"></div>
  </div>
  }@if (!isLoading) {
  <form
    class="theme-form mega-form"
    [formGroup]="spEditForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="row">
      <div class="col-sm-12 col-xl-12">
        <div class="row">
          <div class="col-md-6">
            <label for="fecha">Fecha</label>
            <input
              type="datetime-local"
              class="form-control custom-input"
              id="fecha"
              placeholder="fecha"
              (change)="updateIsModified()"
              formControlName="fecha"
            />
          </div>
          <div class="col-md-6">
            <label for="id_movimiento">Tipo Movimiento</label>
            <ng-select
              [items]="optionsMovementType$ | async"
              bindLabel="label"
              bindValue="value"
              formControlName="id_movimiento"
              class="mb-2"
              placeholder="Selecciona un estado"
            ></ng-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="id_pais">Pais</label>
            <input
              type="text"
              class="form-control custom-input"
              id="id_pais"
              placeholder="id_pais"
              formControlName="id_pais"
            />
          </div>
          <div class="col-md-6">
            <div class="media">
              <label class="col-form-label m-r-10">
                {{ isAgent ? "Agente" : "Cuenta Contable" }}
              </label>
              <div class="media-body text-end switch-sm icon-state">
                <label class="switch">
                  <input
                    type="checkbox"
                    formControlName="isAgent"
                    (change)="toggleSwitch()"
                  />
                  <span class="switch-state"></span>
                </label>
              </div>
            </div>
            @if (isAgent) {
            <ng-select
              [items]="allAgentsData"
              bindLabel="label"
              bindValue="value"
              formControlName="id_usu_agente"
              (change)="updateIsModified()"
              placeholder="Selecciona un agente"
              (scrollToEnd)="onScrollToEnd(NG_SELECT_TYPE.AGENT)"
              (search)="onSearch($event.term, NG_SELECT_TYPE.AGENT)"
              (clear)="onSearch('', NG_SELECT_TYPE.AGENT)"
              (open)="onSearch('', NG_SELECT_TYPE.AGENT)"
            ></ng-select>
            }@else {
            <ng-select
              bindLabel="label"
              bindValue="value"
              [items]="optionsAccountingAccount$ | async"
              required=""
              (change)="updateIsModified()"
              formControlName="id_codigo"
              placeholder="Selecciona una cuenta contable"
            >
            </ng-select>
            }
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="n_comprobante">Comprobante</label>
            <input
              type="text"
              class="form-control custom-input"
              id="n_comprobante"
              placeholder="n_comprobante"
              formControlName="n_comprobante"
            />
          </div>
          <div class="col-md-6">
            <label for="monto">Monto</label>
            <input
              type="text"
              class="form-control custom-input"
              id="monto"
              placeholder="monto"
              formControlName="monto"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="id_banco">Banco</label>
            <ng-select
              [items]="allBankData"
              bindLabel="label"
              bindValue="value"
              formControlName="id_banco"
              (change)="updateIsModified()"
              placeholder="Selecciona un banco"
              (scrollToEnd)="onScrollToEnd(NG_SELECT_TYPE.BANK)"
              (search)="onSearch($event.term, NG_SELECT_TYPE.BANK)"
              (clear)="onSearch('', NG_SELECT_TYPE.BANK)"
              (open)="onSearch('', NG_SELECT_TYPE.BANK)"
              (change)="onBankSelected($event)"
            >
            </ng-select>
          </div>
          <div class="col-md-6">
            <label for="id_usu_titular">Titular</label>
            <ng-select
              [items]="allHoldersData"
              [bindLabel]="'label'"
              [bindValue]="'value'"
              (change)="updateIsModified()"
              formControlName="id_usu_titular"
              placeholder="Selecciona un titular"
              (scrollToEnd)="onScrollToEnd(NG_SELECT_TYPE.HOLDER)"
              (search)="onSearch($event.term, NG_SELECT_TYPE.HOLDER)"
              (clear)="onSearch('', NG_SELECT_TYPE.HOLDER)"
              (open)="onSearch('', NG_SELECT_TYPE.HOLDER)"
            ></ng-select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <label for="observacion"> Observacion </label>
            <textarea
              rows="5"
              id="observacion"
              class="form-control"
              (input)="updateIsModified()"
              formControlName="observacion"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-4 mb-4" />
    <div class="text-end">
      <button type="button" class="btn btn-cancel me-1" (click)="closeModal()">
        Cancelar
      </button>
      @if(isLoadingButton){
      <button class="btn btn-create" type="submit" disabled>
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </button>
      }@else {
      <button
        type="submit"
        class="btn btn-create me-1"
        [disabled]="!isModified || !spEditForm.valid"
      >
        Editar
      </button>
      }
    </div>
  </form>
  }
</div>
