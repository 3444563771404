<div ngbAccordion #accordion="ngbAccordion">
  <div ngbAccordionItem="first">
    <div ngbAccordionHeader>
      <button ngbAccordionButton>{{ headerTitle | translate }}</button>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-container
          *ngTemplateOutlet="contentBodyTemplate ?? null"
        ></ng-container>
      </div>
    </div>
  </div>
</div>
