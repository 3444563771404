import { Component } from '@angular/core';
import { columnsTableDaily } from '../../../shared/data/reports/constants';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subject, debounceTime, of } from 'rxjs';
import { UserService } from '../../../core/services/user.service';
import { ChargesWithdrawalsService } from '../../../core/services/charges-withdrawals.service';
import { ToastService } from 'angular-toastify';
import moment from 'moment';
import { FinancialService } from '../../../core/services/financial.service';
import { ClosureService } from '../../../core/services/closure.service';
import { selectAuth } from '../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { selectScreen } from '../../../core/state/screen/screen.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultInitialDateRangepickerV2 } from '../../../core/constants/constants';
import { ROLES } from '../../../shared/data/config/constants';
import { UserMainService } from '../../user/services/user-main.service';
@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrl: './daily.component.scss',
})
export class DailyComponent {
  typeof(arg0: any) {
    return typeof arg0;
  }
  form: FormGroup;
  public columnsTableDaily = columnsTableDaily;
  public currentPageAgents = 1;
  public pageSizeAgents = 10;
  public currentPage: number = 1;
  public pageSize: number = 10;
  public recordsRange: any;
  public visiblePages: number[] = [];
  public totalPages: any;
  public totalPagesVisible: any;
  public totalRecords: number = 100;
  public id_usuari_add!: number;
  public id_pais!: number;
  public id_pais_filter!: number;
  public allAgentsData: any[] = [];
  public reportDaily: any[] = [];
  public criteria: any;
  public showFilterForm!: boolean;
  public isLoading: boolean = false;

  public optionsCountries$!: Observable<string[]>;
  private filterSubject: Subject<string> = new Subject<string>();
  private agentsSubject: Subject<string> = new Subject<string>();
  private startDate!: Date;
  private endDate!: Date;
  private values!: {};
  public profile$ = this.store.select(selectAuth);
  public totalClosure: number = 0;
  public totalSubscriptions: number = 0;
  public totalPayments: number = 0;
  public totalLoads: number = 0;
  public totalWithdrawals: number = 0;
  public totalPhysicalPoints: number = 0;
  public totalPurchasedBonus: number = 0;
  public totalLoadedBonus: number = 0;
  public actualIndex!: number;
  public actualItem: any;
  public initialRange: Date[] = defaultInitialDateRangepickerV2;
  agentsId: number[] = [];
  showDetail: boolean[] = [];
  public cardExpanded: boolean[] = [];
  editMode: boolean[] = [];
  detailComment: string[] = [];
  originalComment: string[] = [];
  subMenuItem: any;
  public selectedsItem!: number | null;
  public isMobile$ = this.store.select(selectScreen);
  public isMobileRedux!: boolean;
  public isMobile: boolean = false;
  public token!: string;
  public id_rol!: number;
  public ROLES = ROLES;
  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private store: Store,
    private _userService: UserService,
    private _closureService: ClosureService,
    private _financialService: FinancialService,
    private router: Router,
    private activedRouter: ActivatedRoute,
    private _userMainService: UserMainService
  ) {
    this.form = this.fb.group({
      agents: new FormControl([], [Validators.required]),
      countryId: new FormControl([]),
    });
    this.filterSubject
      .pipe(
        debounceTime(500) // Adjust debounce time as needed (e.g., 300 milliseconds)
      )
      .subscribe((value: any) => {
        this.currentPage = 1;
        this.selectedsItem = value.agents;
        this.values = value.agents;
        //this.getReportDaily(this.values);
      });
    this.agentsSubject
      .pipe(
        debounceTime(250) // Adjust debounce time as needed (e.g., 300 milliseconds)
      )
      .subscribe((term: any) => {
        this.criteria = term;
        this.allAgentsData = [];
        this.currentPageAgents = 1;
        this.loadAgents(term);
      });
  }
  async ngOnInit(): Promise<void> {
    this.isMobile$.subscribe((isMobile) => {
      this.isMobileRedux = isMobile;
    });
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      this.activedRouter.params.subscribe((params) => {
        this.token = params['token'];
      });
      await this.getUserByTokenApp(this.token);
    } else {
      await this.loadProfileLogged();
      this.loadAgents();
      this.getCountriesData();
    }
  }

  async loadProfileLogged() {
    await new Promise<void>((resolve, reject) => {
      this.profile$.subscribe({
        next: (res) => {
          if (res.idusuario !== -1) {
            if (res.id_rol === 4 || res.id_rol === 5) {
              this.values = [res.idusuario];
              this.form.controls.agents.setValue([res.idusuario]);
            }
            this.id_rol = res.id_rol;
            this.id_usuari_add = res.idusuario;
            this.id_pais = res.id_pais;
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
  getCountriesData() {
    this._financialService.getAllCountries().subscribe((response) => {
      const transformedData = response.data.map(
        (item: { pais: any; id_pais: any }) => {
          return { label: item.pais, value: item.id_pais };
        }
      );

      this.optionsCountries$ = of(transformedData);
    });
  }
  handleFormSubmit(event: any) {
    console.log(event);
  }
  async getUserByTokenApp(token: string): Promise<void> {
    try {
      const res = await new Promise<any>((resolve, reject) => {
        this._userService.getUserByTokenApp(token).subscribe({
          next: (res) => resolve(res),
          error: (err) => reject(err),
        });
      });
      this.id_rol = res.data.id_rol;
      this.values = [res.data.idusuario];
      this.form.controls.agents.setValue([res.data.idusuario]);
      this.id_usuari_add = res.data.idusuario;
      this.id_pais = res.data.id_pais;
    } catch (error) {
      console.error('Error fetching user by token:', error);
    }
  }
  onScrollToEnd() {
    this.currentPageAgents++;
    this.loadAgents(this.criteria);
  }
  onSearch(term: string) {
    this.allAgentsData = [];
    this.agentsSubject.next(term);
  }
  loadAgents(searchTerm?: string) {
    this._userMainService
      .getAllReferredAndAgents(
        this.currentPageAgents,
        this.pageSizeAgents,
        searchTerm,
        this.id_pais_filter
      )
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: any; idusuario: any }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.allAgentsData = [...this.allAgentsData, ...transformedData];
      });
  }
  toggleFilterForm() {
    this.showFilterForm = !this.showFilterForm;
  }
  getFilterFormStyle(): { [key: string]: string } {
    return this.showFilterForm
      ? { overflow: 'visible', maxHeight: '600px' }
      : { overflow: 'hidden', maxHeight: '0' };
  }
  onSubmit() {
    if (this.form.valid) {
      this.getReportDaily(this.form.value.agents);
    } else {
      this.toastService.error('Por favor completa los campos requeridos.');
    }
  }
  updateFilter() {
    this.filterSubject.next(this.form.value);
  }
  getReportDaily(
    agents: any = [],
    countryId: number | null = this.id_pais_filter
  ) {
    this.isLoading = true;
    this.agentsId = [];
    this.showDetail = [];
    this.editMode = [];
    this.detailComment = [];
    this.originalComment = [];
    this._closureService
      .reportDailySpecific(
        this.startDate,
        this.endDate,
        this.currentPage,
        this.pageSize,
        agents,
        countryId
      )
      .subscribe((res) => {
        const response = res.data;
        this.isLoading = false;
        response.data = response.data.map((item: any) => ({
          ...item, // Mantén todas las propiedades existentes
          pagos: Math.abs(item.pagos), // Convierte pagos a positivo
          retiros: Math.abs(item.retiros),
          // Añade más propiedades si es necesario
        }));
        this.reportDaily = response.data;
        this.totalRecords = response.totalCount;
        // Calcular el total de páginas

        this.loadsTotals(response);
      });
  }
  loadsTotals(response: any) {
    this.totalClosure = response.data
      .reduce((total: number, item: any) => {
        return total + Number(item.cierre_mes_pasado);
      }, 0)
      .toFixed(2);
    this.totalSubscriptions = response.data
      .reduce((total: number, item: any) => {
        return total + Number(item.abonos);
      }, 0)
      .toFixed(2);
    this.totalPayments = response.data
      .reduce((total: number, item: any) => {
        return total + Number(item.pagos);
      }, 0)
      .toFixed(2);
    this.totalLoads = response.data
      .reduce((total: number, item: any) => {
        return total + Number(item.cargas);
      }, 0)
      .toFixed(2);
    this.totalWithdrawals = response.data
      .reduce((total: number, item: any) => {
        return total + Number(item.retiros);
      }, 0)
      .toFixed(2);
    this.totalPhysicalPoints = response.data
      .reduce((total: number, item: any) => {
        return total + Number(item.punto_fisico);
      }, 0)
      .toFixed(2);
    this.totalPurchasedBonus = response.data
      .reduce((total: number, item: any) => {
        return total + Number(item.bono_comprado);
      }, 0)
      .toFixed(2);
    this.totalLoadedBonus = response.data
      .reduce((total: number, item: any) => {
        return total + Number(item.bono_cargado);
      }, 0)
      .toFixed(2);
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    this.getReportDaily(this.values);
  }

  onLimitChange(limit: number) {
    this.pageSize = limit;
    this.currentPage = 1;
    this.getReportDaily(this.values);
    this.updateFilter();
  }
  public onDateSelected({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) {
    this.startDate = startDate;
    this.endDate = endDate;
    /*  if (!this.isLoading) {
      this.getReportDaily(this.values);
    } */
  }
  getNote(index: number, item: any) {
    this._userService
      .getAgentIdByUsername(item.agente)
      .subscribe((response) => {
        this.agentsId[index] = response.data.idusuario;
        this._closureService
          .getNote(response.data.idusuario, item.fecha)
          .subscribe((res) => {
            this.detailComment[index] = res.data?.nota;
            this.originalComment[index] = res.data?.nota + ' ';
          });
      });
  }
  toggleDetail(index: number, item: any) {
    this.showDetail[index] = !this.showDetail[index];
    if (this.showDetail[index]) {
      this.getNote(index, item);
    }
  }
  toggleEditMode(index: number, item: any) {
    if (!this.detailComment[index]) {
      this.editMode[index] = !this.editMode[index];
      if (!this.editMode[index]) {
        this._closureService
          .createNote(
            this.agentsId[index],
            item.fecha,
            this.originalComment[index],
            this.id_usuari_add,
            this.id_pais
          )
          .subscribe({
            next: (res) => {
              this.toastService.success('Nota Creada con Exito');
              this.getNote(index, item);
              this.subMenuItem = this.originalComment[index];
            },
            error: (error) => {
              this.toastService.error('Hubo un error al crear la Nota');
              console.error(`Error: ${error}`);
            },
          });
      }
    } else {
      this.editMode[index] = !this.editMode[index];
      if (!this.editMode[index]) {
        this._closureService
          .updateNote(
            this.agentsId[index],
            item.fecha,
            this.originalComment[index]
          )
          .subscribe({
            next: (res) => {
              this.toastService.success('Nota Actualizada con Exito');
              this.getNote(index, item);
            },
            error: (error) => {
              this.toastService.error('Hubo un error al actualizar la Nota');
              console.error(`Error: ${error}`);
            },
          });
      }
    }
  }
  cancelEdit(index: number) {
    this.editMode[index] = false;
  }
  onCommentChange(comment: any, index: number) {
    this.originalComment[index] = comment.target.value;
  }
  showMenu: boolean = false;
  showSubMenu: boolean = false;

  toggleMenu(index: number, item: any) {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.getNote(index, item);
      this.actualIndex = index;
      this.actualItem = item;
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }

  closeMenu() {
    this.showMenu = false;
    this.showSubMenu = false;
    document.body.style.overflowY = 'auto';
  }
  openSubMenu(item: any) {
    this.subMenuItem = item;
    this.showMenu = false;
    this.showSubMenu = true;
  }
  closeSubMenu() {
    this.showMenu = true;
    this.showSubMenu = false;
  }
  expandCard(index: number) {
    this.cardExpanded[index] = !this.cardExpanded[index];
  }
  selectedCountry() {
    this.form.get('agents')?.setValue(undefined);
    this.id_pais_filter = this.form.value.countryId?.value;
    this.selectedsItem = null;
    //this.updateFilter();
  }
}
