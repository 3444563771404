import { Component, Input } from '@angular/core';
import { CardLightDescription } from '../../../../core/interfaces/components.interface';

@Component({
  selector: 'shared-card-light-description',
  templateUrl: './light-description.component.html',
})
export class LightDescriptionComponent {
  @Input({ required: true })
  public tag!: CardLightDescription;

  @Input()
  public isLoading: boolean = true;
}
