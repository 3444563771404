import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiPathEnum, StateEndpointEnum } from '../constants/endpoints.enum';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  public API_URL = `${environment.API_URL}${ApiPathEnum.State}`;

  constructor(private http: HttpClient) { }

  geatAllData(): Observable<any>{
    return this.http.get<any>(
      `${this.API_URL}${StateEndpointEnum.GetStateData}`
    );
  }

  geatAllDataSp(): Observable<any>{
    return this.http.get<any>(
      `${this.API_URL}${StateEndpointEnum.GetStateSpData}`
    );
  }

  geatAllDataLw(): Observable<any>{
    return this.http.get<any>(
      `${this.API_URL}${StateEndpointEnum.GetStateLwData}`
    );
  }
}
