<div class="container-fluid">
  <div class="row">
    <div class="col-xxl-12 col-xl-12 box-cSol-12">
      <div class="row mt-2">
        <div class="col-sm-3 mt-3">
          <div class="card small-widget mb-3">
            <app-count-card
              [data]="players"
              [totalPlayers]="totalPlayers"
              [loading]="loadingSate"
            ></app-count-card>
          </div>
        </div>
        <div class="col-sm-3 mt-3">
          <div class="card small-widget mb-3">
            <app-count-card
              [data]="registeredPlayers"
              [totalRegisteredPlayers]="totalRegisteredPlayers"
              [loading]="loadingSate"
            ></app-count-card>
          </div>
        </div>
        <div class="col-sm-3  mt-3">
          <div class="card small-widget mb-3">
            <app-count-card
              [data]="visitTraffic"
              [totalvisitTraffic]="totalvisitTraffic"
              [loading]="loadingSate"
            ></app-count-card>
          </div>
        </div>
        <div class="col-sm-3  mt-3">
          <div class="card small-widget mb-3">
            <app-count-card
              [data]="conversionRate"
              [totalconversionRate]="totalconversionRate"
              [loading]="loadingSate"
            ></app-count-card>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <app-card-statistics
            (totalPlayersChange)="onTotalPlayersChange($event)"
            (totalRegisteredPlayersChange)="
              onTotalRegisteredPlayersChange($event)
            "
            (totalvisitTrafficChange)="onTotalvisitTrafficChange($event)"
            (percentajeRegisterClicChange)="onTotalconversionRate($event)"
            (loading)="loading($event)"
          ></app-card-statistics>
        </div>
        <div class="col-md-6">
          <app-card-qr></app-card-qr>
        </div>
      </div>
    </div>
  </div>
</div>
