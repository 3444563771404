// TABLE
export const columnsTableCodeBonus = [
  {
    name: 'Acciones',
    value: 'acciones',
  },
  {
    name: 'Fecha',
    value: 'fecha',
  },
  {
    name: 'Código Promocional',
    value: 'codigo_promocional',
  },
  {
    name: 'Monto',
    value: 'monto',
  },
  {
    name: 'Comentario',
    value: 'comentario',
  },
  {
    name: 'Detalle',
    value: 'detalle_tipo_bono',
  },
  {
    name: 'Creado',
    value: 'fecha_creacion',
  },
  {
    name: 'Agente',
    value: 'agente',
  },
  {
    name: 'Creado Por',
    value: 'usuario',
  },
  {
    name: 'Estado Bono',
    value: 'estado',
  },

];

export const stateBonus = [
  { value: '0', label: 'Bono Pendiente' },
  { value: '1', label: 'Bono Creado' },
  { value: '2', label: 'Bono Enviado' },
  { value: '3', label: 'Bono Eliminado' },
];

export const CODE_BONUS_STATUS = {
  PENDING: 0,
  CREATED: 1,
  SENT: 2,
  DELETED: 3,
};

export const COUNTRY = {
  ECUADOR: 1,
  PERU: 2,
  CHILE: 3,
  VENEZUELA: 4,
};
