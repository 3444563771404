import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
// import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SecureSessionService } from './secure-session.service';
import { User } from '../interfaces/user.interface';
import { StorageService } from './storage.service';
import {
  ApiPathEnum,
  AuthEndpointEnum,
  AuthMobileEndpointEnum,
  UserEndpointEnum,
} from '../constants/endpoints.enum';
import { UserService } from './user.service';

const USER_SESSION = 'USER_SESSION';
const USER_SESSION_PRE = 'USER_SESSION_PRE';
const DATA_USER = 'DATA_USER';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public API_URL = `${environment.API_URL}${ApiPathEnum.Auth}`;
  private lockedKey = 'isLocked';
  private locked: boolean = true;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private _secureSession: SecureSessionService,
    private _userService: UserService
  ) {
    const locked = localStorage.getItem(this.lockedKey);
    this.locked = locked === 'true';
  }

  public signIn(
    username: string,
    password: string
  ): Observable<{ data: User }> {
    return this.http
      .post<{ data: User }>(`${this.API_URL}${AuthEndpointEnum.Login}`, {
        username,
        password,
      })
      .pipe(
        tap((response) => {
          if (response.data.TFA === 0) {
            this.storageService.secureStorage.setItem(
              USER_SESSION,
              JSON.stringify(response)
            );
          } else {
            this.storageService.secureStorage.setItem(
              USER_SESSION_PRE,
              JSON.stringify(response)
            );
          }
        })
      );
  }

  logOut() {
    this._secureSession.deleteObjectSession(USER_SESSION);
    this._secureSession.deleteObjectSession(USER_SESSION_PRE);
    this._secureSession.deleteObjectSession(DATA_USER);
  }

  public isLoggedIn(): boolean {
    return moment().isBefore(this.getExpiration()) ?? false;
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  getExpiration = () => {
    const session = this._secureSession.getObjectSession(USER_SESSION);
    if (!session) {
      return null;
    }
    const { exp } = this._secureSession.parseJwt(session.data);
    return moment(exp * 1000);
  };

  getUsuarioSesion = (): any | null => {
    const session = this._secureSession.getObjectSession(DATA_USER);
    if (!session) {
      return null;
    }
    return session as any;
  };

  invalidateToken() {
    this._secureSession.deleteObjectSession(USER_SESSION);
  }

  performLogout() {
    this.invalidateToken();
    this._secureSession.deleteObjectSession(DATA_USER);
  }

  public verifyLogin(_id: string, twoFactorSecret: string): Observable<any> {
    return this.http
      .post<any>(`${this.API_URL}${UserEndpointEnum.VerifyLogin}`, {
        _id,
        twoFactorSecret,
      })
      .pipe(
        tap((response) => {
          this.storageService.secureStorage.setItem(
            'jwt-token',
            response.token
          );
          this.storageService.secureStorage.setItem(
            'logged-user',
            response._id
          );
        })
      );
  }

  public isMobileLoggedIn(token: string): Observable<boolean> {
    return this._userService.getUserByTokenApp(token).pipe(
      map((user) => {
        return !!user;
      }),
      catchError((err) => {
        console.error('Error fetching user by token:', err);
        return of(false);
      })
    );
  }

  public isLocked(): boolean {
    return this.locked;
  }

  public unlock(): void {
    this.locked = false;
    localStorage.setItem(this.lockedKey, 'false');
  }

  public lock(): void {
    this.locked = true;
    localStorage.setItem(this.lockedKey, 'true');
  }
}
