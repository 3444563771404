import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../../../../core/services/user.service';
import { environment } from '../../../../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { ImageCompressionService } from '../../../../../../../shared/services/image-compression.service';
import { ToastrService } from 'ngx-toastr';
import { AuthActions } from '../../../../../../../core/state/auth/auth.actions';
interface FilePreview {
  file: File;
  urlFile: string;
}

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit, AfterViewInit {

  public isMobile: boolean = false;
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  public token!: string;
  public baseUrl: string = environment.API_FILES;
  public previewImage: any;
  public showCameraIcon: boolean = false;
  public selectedFiles: FilePreview[] = [];


  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    private store: Store,
    private activedRouter: ActivatedRoute,
    private router: Router,
    private _userService: UserService,
    private sanitizer: DomSanitizer,
    private _imageCompressionService: ImageCompressionService,
    private _toastrSvc: ToastrService,

  ) { }

  ngOnInit(): void {
    const fullUrl = this.router.url;

    // Verifica si la URL contiene 'mobile'
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      this.loadUserProfileForMobile();
    } else {
      this.loadUserProfile();
    }
  }

  loadUserProfileForMobile(): void {
    this.activedRouter.params.subscribe((params) => {
      this.token = params['token'];
      this._userService.getUserByTokenApp(this.token).subscribe({
        next: (res) => {
          if (res.data.idusuario) {
            this._userService.getUser(res.data.idusuario).subscribe({
              next: (res) => {
                const profile = res.data;
                this.profile = profile;
              },
              error: (err) => {
                // Manejo de errores
              },
            });
          }
        },
        error: (err) => {
          // Manejo de errores
        },
      });
    });
  }

  loadUserProfile(): void {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario !== -1) {
        this.profile = profile;
        this.previewImage = this.formatUrl(this.profile?.foto_selfie);
      }
    });
  }


  ngAfterViewInit(): void {
    // Este método está aquí para asegurar que @ViewChild se inicializa correctamente.
  }

  //IMG
  formatUrl(url: string) {
    return this.baseUrl + '/' + url.replace(/ /g, '%20').replace(/\\/g, '/');
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  fileUrl(file: File): any {
    const url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (!this.isImageFile(file)) {
        this._toastrSvc.error('Solo se permiten imágenes en formato JPG o PNG');
        return;
      }
      const compressedFile = await this.compressImage(file);
      const url = this.fileUrl(compressedFile);
      this.selectedFiles = [
        {
          file: compressedFile,
          urlFile: url,
        },
      ];
      this.showImagePreview(file);
    }
  }

  isImageFile(file: File): boolean {
    return file.type.startsWith('image/');
  }

  compressImage(file: File): Promise<File> {
    return this._imageCompressionService.compressImage(file);
  }

  showImagePreview(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      const newImagePreview = reader.result;

      Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Deseas cambiar tu foto de perfil?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, cambiar',
        cancelButtonText: 'No, cancelar',
        iconColor: '#8C98A1',
        confirmButtonColor: '#54BA4A',
        cancelButtonColor: '#5A6268'
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateUserProfilePicture();
        } else {
          this._toastrSvc.info('Se canceló el cambio de foto de perfil');
        }
        this.fileInput.nativeElement.value = '';
      });
    };
    reader.readAsDataURL(file);
  }

  updateUserProfilePicture(): void {
    const formData = new FormData();
    formData.append('userId', this.profile?.idusuario);
    formData.append('username', this.profile?.usuario);
    formData.append('selfiePhoto', this.selectedFiles[0].file);
    formData.append('identification', this.profile?.identificacion);
    formData.append('externalId', this.profile?.id_externo);

    this.store.dispatch(
      AuthActions['[Auth]RequestUpdateUser']({
        updatedUser: formData,
      })
    );
  }

  hasExtension(url: string): boolean {
    return /\.(jpg|jpeg|png|gif|bmp)$/.test(url);
  }
}
