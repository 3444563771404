import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from '../../../../../../shared/services/event.service';
import * as chartData from '../../../../../../shared/data/suscriptions-payments/constants';

@Component({
  selector: 'app-card-progress-request',
  templateUrl: './card-progress-request.component.html',
  styleUrls: ['./card-progress-request.component.scss'],
})
export class CardProgressRequestComponent implements OnInit {
  @Input() pendingCount: number = 0;
  @Input() processedCount: number = 0;
  @Input() correctionCount: number = 0;
  @Input() botProblemCount: number = 0;
  @Input() reverseCount: number = 0;
  @Input() chargeNotIncomeCount: number = 0;
  @Input() totalCount: number = 0;
  @Input() isLoadingData: boolean = true;
  private eventSubscription!: Subscription;
  public statesChart: any[] = [];
  public chartDataState: any[] = [];
  public bar = chartData.bar;
  public showChart: boolean = false;
  public data: any;

  // public selectedFilter: any;

  constructor(
    private eventService: EventService,
  ) { }

  ngOnInit(): void {
    this.getSuscription();
    this.loadData();
    // this.getDataFromFilter();
  }

  getSuscription() {
    this.eventSubscription = this.eventService.getEvent().subscribe((event) => {
      if (event.action === 'totalPendingChange') {
        this.pendingCount = event.value;
      } else if (event.action === 'totalProcessedChange') {
        this.processedCount = event.value;
      } else if (event.action === 'totalChargeNotIncomeChange') {
        this.chargeNotIncomeCount = event.value;
      } else if (event.action === 'totalCorrectionChange') {
        this.correctionCount = event.value;
      } else if (event.action === 'totalBotProblemChange') {
        this.botProblemCount = event.value;
      } else if (event.action === 'totalReverseChange') {
        this.reverseCount = event.value;
      } else if (event.action === 'totalCountChange') {
        this.totalCount = event.value;
      } else if (event.action === 'loading') {
        this.isLoadingData = event.value;
      }

      const customColors = {
        pendiente: { background: '#F9C100' },
        procesado: { background: '#40D933' },
        cargadoNoIngresado: { background: '#4ABA90' },
        correccion: { background: '#BAB04A' },
        problemaBot: { background: '#ff0000' },
        reverse: { background: '#A0A0A0' },
      };

      this.statesChart = [
        {
          title: 'Pendiente',
          count: this.pendingCount,
          customColor: customColors.pendiente,
        },
        {
          title: 'Procesado',
          count: this.processedCount,
          customColor: customColors.procesado,
        },
        {
          title: 'Cargado No Ingresado',
          count: this.chargeNotIncomeCount,
          customColor: customColors.cargadoNoIngresado,
        },
        {
          title: 'Corrección',
          count: this.correctionCount,
          customColor: customColors.correccion,
        },
        {
          title: 'Problema Bot',
          count: this.botProblemCount,
          customColor: customColors.problemaBot,
        },
        {
          title: 'Reverso',
          count: this.reverseCount,
          customColor: customColors.reverse,
        },
      ];
    });
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  toggleChart() {
    this.showChart = !this.showChart;
  }

  getShowChartStyle(): { [key: string]: string } {
    return this.showChart
      ? { overflow: 'visible', maxHeight: '740px' }
      : { overflow: 'hidden', maxHeight: '0' };
  }

  loadData() {
    this.data = [
      {
        style: 'row',
        columnsPerRow: 3,
        formData: [
          {
            label: 'Comprobante',
            id: 'comprobante',
            control: 'input',
            type: 'text',
          },
          { label: 'Monto', id: 'monto', control: 'input', type: 'number' },
          { label: 'Agente', id: 'agent', control: 'select', options: [1, 2] },
          {
            label: 'Transferencia Interbancaria',
            id: 'tipo_transferencia',
            control: 'select',
            options: [1, 2],
          },
          {
            label: 'Tipo transacción',
            id: 'tipo_transferencia',
            control: 'select',
            options: [1, 2],
          },
          { label: 'Estado', id: 'estado', control: 'select', options: [1, 2] },
          { label: 'Creado', id: 'dateRange', control: 'dateInput' },
          { label: 'Actualizado', id: 'dateRange', control: 'dateInput' },
          {
            label: 'Banco',
            id: 'banco',
            control: 'select',
            options: [1, 2],
          },
          {
            label: 'Identifiación',
            id: 'identifiaccion',
            control: 'input',
            type: 'text',
          },
          {
            label: 'Beneficiario',
            id: 'beneficiario',
            control: 'input',
            type: 'text',
          },
          {
            label: 'N° de cuenta',
            id: 'numero_cuenta',
            control: 'input',
            type: 'text',
          },
          {
            label: 'Creado por',
            id: 'crado_por',
            control: 'select',
            options: [1, 2],
          },
          {
            label: 'Depositado',
            id: 'depositado',
            control: 'select',
            options: [1, 2],
          },
          {
            label: 'Titular',
            id: 'titular',
            control: 'select',
            options: [1, 2],
          },
        ],
      },
    ];
  }
}
