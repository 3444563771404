import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  ApiPathEnum,
  BankEndpointEnum,
  GroupBankAccountEndPointEnum,
  UserEndpointEnum,
  UserMainEndpointEnum,
} from '../../../core/constants/endpoints.enum';
import { StorageService } from '../../../core/services/storage.service';
import {
  Filters,
  GroupBankAccount,
  Role,
  UserMainResponse,
} from '../interface/user-main.interface';
import { ApiResponse } from '../interface/api-response.inteface';
import {
  Banks,
  ReferredAndAgents,
} from '../../accounting/banking/interface/bank-accounts.interface';
import { ApiDataResponse } from '../../../core/interfaces/api-response.inteface';

@Injectable({
  providedIn: 'root',
})
export class UserMainService {
  public API_URL = `${environment.API_URL}${ApiPathEnum.User}`;
  public API_URL_ROLES = `${environment.API_URL}${ApiPathEnum.Roles}`;
  public API_URL_GROUP_BANKS_ACCOUTN = `${environment.API_URL}${ApiPathEnum.GroupBankAccount}`;
  public API_URL_BANK = `${environment.API_URL}${ApiPathEnum.Bank}`;
  private API_FILES: string = environment.API_FILES;
  public API_URL_USER_LEVEL = `${environment.API_URL}${ApiPathEnum.UserLevel}`;
  USER_SESSION = 'USER_SESSION';
  USER_SESSION_PRE = 'USER_SESSION_PRE';

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}
  public getAllAgents(
    page: number,
    limit: number,
    filters?: Filters
  ): Observable<ApiResponse<UserMainResponse>> {
    const url = `${this.API_URL}${UserMainEndpointEnum.GetAllAgentsByUsers}`;
    const params = { page: page.toString(), limit: limit.toString() };
    return this.http.post<ApiResponse<UserMainResponse>>(url, {
      params,
      filters,
    });
  }
  public getAllHolders(
    page: number,
    limit: number,
    filters?: Filters
  ): Observable<ApiResponse<UserMainResponse>> {
    const url = `${this.API_URL}${UserMainEndpointEnum.GetAllHoldersByUsers}`;
    const params = { page: page.toString(), limit: limit.toString() };
    return this.http.post<ApiResponse<UserMainResponse>>(url, {
      params,
      filters,
    });
  }
  public getAllAdministratives(
    page: number,
    limit: number,
    filters?: Filters
  ): Observable<ApiResponse<UserMainResponse>> {
    const url = `${this.API_URL}${UserMainEndpointEnum.GetAllAdministrativesByUsers}`;
    const params = { page: page.toString(), limit: limit.toString() };
    return this.http.post<ApiResponse<UserMainResponse>>(url, {
      params,
      filters,
    });
  }
  getAllGroupBankAccount(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<ApiResponse<GroupBankAccount[]>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    return this.http.get<ApiResponse<GroupBankAccount[]>>(
      `${this.API_URL_GROUP_BANKS_ACCOUTN}${GroupBankAccountEndPointEnum.GetGroupBankAccountData}`,
      { params }
    );
  }
  getGroupBankAccountById(
    id: number
  ): Observable<ApiResponse<UserMainResponse>> {
    return this.http.post<ApiResponse<UserMainResponse>>(
      `${this.API_URL}${GroupBankAccountEndPointEnum.GetGroupBankAccountById}`,
      { id }
    );
  }
  updateUserObservation(data: {
    userId: number;
    observation: string;
    activate: number;
    userSetId?: number;
  }): Observable<ApiResponse<UserMainResponse>> {
    return this.http.put<ApiResponse<UserMainResponse>>(
      `${this.API_URL}/update-observation`,
      data
    );
  }
  updateUserpassword(data: {
    userId: number;
    password: string;
    userSetId?: number;
  }): Observable<ApiResponse<UserMainResponse>> {
    return this.http.put<ApiResponse<UserMainResponse>>(
      `${this.API_URL}/update-password`,
      data
    );
  }
  createUser(formData: FormData): Observable<ApiResponse<UserMainResponse>> {
    return this.http.post<ApiResponse<UserMainResponse>>(
      `${this.API_URL}/create-user`,
      formData
    );
  }

  updateUser(formData: FormData): Observable<ApiResponse<UserMainResponse>> {
    return this.http.patch<ApiResponse<UserMainResponse>>(
      `${this.API_URL}/update-user`,
      formData
    );
  }
  getAllRoles(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<ApiResponse<ApiDataResponse<Role[]>>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    return this.http.get<ApiResponse<ApiDataResponse<Role[]>>>(
      `${this.API_URL_ROLES}${UserMainEndpointEnum.GetAllRoles}`,
      { params }
    );
  }

  getAllBanksBySelect(
    page: number,
    limit: number,
    searchTerm?: string,
    countryId?: number
  ): Observable<ApiResponse<Banks[]>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    if (countryId) {
      params = params.set('countryId', countryId);
    }
    return this.http.get<ApiResponse<Banks[]>>(
      `${this.API_URL_BANK}${BankEndpointEnum.GetAllBanksBySelect}`,
      { params }
    );
  }

  getAllReferredAndAgents(
    page: number,
    limit: number,
    searchTerm?: string,
    id_pais?: number,
    userOnlyActive?: number,
    userType?: number[]
  ): Observable<ApiResponse<ReferredAndAgents[]>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    if (id_pais) {
      params = params.set('id_pais', id_pais);
    }
    if (userOnlyActive) {
      params = params.set('userOnlyActive', userOnlyActive);
    }
    if (userType && userType.length > 0) {
      params = params.set('userType', userType.join(',')); // Convertir el array a cadena separada por comas
    }
    return this.http.get<ApiResponse<ReferredAndAgents[]>>(
      `${this.API_URL}${UserEndpointEnum.GetAllReferredAndAgents}`,
      { params }
    );
  }
}
