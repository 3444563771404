import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as chartData from '../../../../../shared/data/affiliate/constants';
import { AffiliateService } from '../../../../../core/services/affiliate.service';
import { FilterCommunicationService } from '../../../../../core/services/filter-communication.service';
import { CONFIG_BASE_DATE_RANGEPICKER } from '../../../../../core/constants/constants';
import moment from 'moment';
import { selectAuth } from '../../../../../core/state/auth/auth.selector';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserService } from '../../../../../core/services/user.service';

@Component({
  selector: 'app-card-statistics',
  templateUrl: './card-statistics.component.html',
  styleUrls: ['./card-statistics.component.scss'],
})
export class CardStatisticsComponent implements OnInit {
  @Output() totalPlayersChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() totalRegisteredPlayersChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() totalvisitTrafficChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() percentajeRegisterClicChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  public splineArea1 = chartData.splineArea1;
  public isLoading: boolean = true;
  public registerClic: number = 0;
  isMobile: boolean = false;
  token!: string;
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  public subscribe: Subscription = new Subscription();

  // FILTER
  public selectedFilter: any;
  public defaultFilter: any;
  public startDate = CONFIG_BASE_DATE_RANGEPICKER.minDate;
  public endDate = CONFIG_BASE_DATE_RANGEPICKER.initialRange[1];

  constructor(
    private _affliateService: AffiliateService,
    private _filterCommunicationService: FilterCommunicationService,
    private activedRouter: ActivatedRoute,
    private store: Store,
    private router: Router,
    private _userService: UserService
  ) {}

  async ngOnInit(): Promise<void> {
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      await new Promise<void>((resolve, reject) => {
        this.activedRouter.params.subscribe((params) => {
          this.token = params['token'];
          this._userService.getUserByTokenApp(this.token).subscribe({
            next: (res) => {
              if (res.data.idusuario) {
                this.profile = res.data;
                this.getDataFromFilter();
                resolve();
              } else {
                reject(new Error('No user id found'));
              }
            },
            error: (err) => {
              reject(err);
            },
          });
        });
      });
    } else {
      await new Promise<void>((resolve, reject) => {
        this.profile$.subscribe({
          next: (res) => {
            if (res.idusuario !== -1) {
              this.profile = res;
              this.getDataFromFilter();
              resolve();
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    }
  }

  getAffiliateInfo() {
    this.isLoading = true;
    this.loading.emit(true);
    this.defaultFilter = this.selectedFilter
      ? this.selectedFilter
      : {
          startDate: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.endDate).format('YYYY-MM-DD 23:59:59'),
        };
    this._affliateService
      .getAffiliateInfo(this.defaultFilter)
      .subscribe((res) => {
        const response = res.data;
        this.isLoading = true;
        this.totalPlayersChange.emit(response.totalPlayers);
        this.totalRegisteredPlayersChange.emit(
          response.playersInDateRange.total
        );
        this.totalvisitTrafficChange.emit(response.totalClics);
        //CALCULO PORCENTAJE TASA DE CONVERSION
        this.registerClic =
          (response.playersInDateRange.total * 100) / response.totalClics;
        this.percentajeRegisterClicChange.emit(this.registerClic);
        //GRAFICO
        this.splineArea1 = {
          ...this.splineArea1,
          series: [
            {
              name: 'Clicks',
              data: response.clicsInDateRange.clicsPerDate,
            },
            {
              name: 'Players',
              data: response.playersInDateRange.playersPerDate,
            },
          ],
          xaxis: {
            categories: response.clicsInDateRange.dates,
          },
        };
        this.loading.emit(false);
        this.isLoading = false;
      });
  }

  getDataFromFilter() {
    this.subscribe = this._filterCommunicationService.currentFilter.subscribe(
      (filter) => {
        if (this.profile.id_rol !== 5) {
          this.selectedFilter = filter;
          this.getAffiliateInfo();
        } else if (filter) {
          this.selectedFilter = filter;
          this.getAffiliateInfo();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }
}
