@if (!token) {
<app-breadcrumb
  [title]="'Closure'"
  [items]="['Reports']"
  [active_item]="'Closure'"
></app-breadcrumb>
}
<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-sm-12">
      <div class="card mt-3">
        <div class="card-header">
          <div class="row">
            @if (!token) {
            <div class="card-header">
              <h4
                class="card-title d-flex justify-content-between align-items-center"
              >
                Cierre
              </h4>
            </div>
            }
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label for="field.id" class="col-form-label">Fecha</label>
                    <shared-datepicker-v1
                      [initialRange]="initialRange"
                      (onRangeChange)="onDateSelected($event)"
                    />
                  </div>
                  @if (profile) { @if (!token&&![USERS_TYPES.PLAYER_BD,
                  USERS_TYPES.AGENTS_BD].includes(profile.tipo_usuario)) {
                  <div class="col-md-4">
                    <label class="col-form-label">Pais</label>
                    <ng-select
                      [items]="optionsCountries$ | async"
                      formControlName="countryId"
                      placeholder="Selecciona un país"
                      (change)="selectedCountry()"
                    >
                    </ng-select>
                  </div>
                  <div class="col-md-4">
                    <label for="field.id" class="col-form-label">Agente</label>
                    <ng-select
                      [ngModel]="selectedItem"
                      (change)="selectedAgent()"
                      (open)="onSearch('')"
                      [items]="allAgentsData"
                      bindLabel="label"
                      bindValue="value"
                      formControlName="agent"
                      placeholder="Select one agente"
                      class="mb-2"
                      (scrollToEnd)="onScrollToEnd()"
                      (search)="onSearch($event.term)"
                      (clear)="onSearch('')"
                    ></ng-select>
                  </div>
                  } }
                </div>
              </div>
            </form>
          </div>
          @if (!token&& closureData && ![USERS_TYPES.PLAYER_BD,
          USERS_TYPES.AGENTS_BD].includes(profile.tipo_usuario)) {
          <div class="card-block row justify-content-center">
            <div class="col-sm-6 col-lg-6 col-xl-6">
              <div class="card-body custom-datatable noscroll">
                <div class="custom-datatable table-responsive card">
                  <ul class="schedule-list" style="gap: 10px">
                    <li class="success">
                      <div class="w-100">
                        <ul>
                          <li class="mb-1">
                            <i class="fas fa-shipping-fast"></i> Estado Enviado:
                          </li>
                          <li class="f-light">
                            <span>{{
                              closureData?.header[0]?.estado_envio
                            }}</span>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="warning">
                      <div class="w-100">
                        <ul>
                          <li class="mb-1">
                            <i class="fas fa-boxes"></i> Cantidad Enviados:
                          </li>
                          <li class="f-light">
                            <span>{{
                              closureData?.header[0]?.cantidad_enviados
                            }}</span>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="primary">
                      <div class="w-100">
                        <ul>
                          <li class="mb-1">
                            <i class="fas fa-calendar-alt"></i> Fecha Enviado:
                          </li>
                          <li class="f-light">
                            <span>{{
                              closureData?.header[0]?.fecha_enviado
                            }}</span>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="danger">
                      <div class="w-100">
                        <ul>
                          <li class="mb-1">
                            <i class="fas fa-user"></i> Usuario Envio:
                          </li>
                          <li class="f-light">
                            <span>{{
                              closureData?.header[0]?.usuario_envio
                            }}</span>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-6 col-xl-6">
              <div class="card-body custom-datatable noscroll">
                <div
                  class="custom-datatable table-responsive style-table table-bordered-left"
                >
                  <table class="table">
                    <tbody class="text-start">
                      <tr>
                        <td>
                          <div class="row">
                            <div class="col-6">
                              <span class="alert-span">
                                <i class="fas fa-exclamation-circle"></i> Alerta
                                del cierre:
                              </span>
                            </div>
                            <div class="col-6">
                              <span
                                class="d-flex justify-content-end align-items-center"
                              >
                                <i
                                  class="fas fa-check-circle"
                                  style="margin-right: 5px"
                                ></i>
                                {{ closureData?.closingAlert?.length }}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            @for (item of closureData?.closingAlert; track
                            $index) {
                            <li>{{ item.mensaje }}</li>
                            }
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          }
          <div class="row justify-content-end">
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
              <div class="mb-0">
                <div class="card-body" style="padding: 11px">
                  <div class="row justify-content-end">
                    <div class="col text-center">
                      <div
                        class="mb-3 d-flex justify-content-end align-items-center"
                        style="height: 100%"
                      >
                        @if (profile) { @if (!token && ![USERS_TYPES.PLAYER_BD,
                        USERS_TYPES.AGENTS_BD].includes(profile.tipo_usuario)) {
                        <button
                          type="button"
                          class="btn btn-sm btn-restore me-1"
                          (click)="sendClosure()"
                          [disabled]="
                            isLoading || !closureData || !this.wasConsulted
                          "
                        >
                          Enviar Cierre
                        </button>
                        }}
                        <button
                          adiButton
                          class="btn btn-sm btn-head"
                          style="
                            background-color: var(
                              --primary-button-color
                            ) !important;
                            border-color: var(
                              --primary-button-color
                            ) !important;
                          "
                          (click)="onSubmit()"
                          [loading]="isLoading"
                          [disabled]="isLoading"
                        >
                          Consultar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        @if (closureData) {
        <!-- <div class="card-body custom-datatable noscroll"> -->
        <div class="container-table">
          <table class="table table-custom">
            <thead class="text-start">
              <tr>
                <th>Detalle Cargas y Pagos</th>
                <th class="text-end">Monto</th>
              </tr>
            </thead>
            <tbody class="text-start">
              <tr>
                <td>CIERRE MES PASADO</td>
                <td style="font-weight: bold" class="text-end">
                  {{
                    closureData?.headerString[0]?.valor_cierre || "Sin Cierre"
                  }}
                </td>
              </tr>
              <tr>
                <td>CARGAS DE LA PAGINA AL AGENTE</td>
                <td class="text-end">
                  {{ closureData?.tableString[0]?.cargas }}
                </td>
              </tr>
              <tr>
                <td>ABONOS REALIZADOS POR EL AGENTE</td>
                <td class="text-end">
                  {{ closureData?.tableString[0]?.abonos }}
                </td>
              </tr>
              <tr>
                <td>RETIROS DE LA PAGINA SOLICITADOS POR AGENTE</td>
                <td class="text-end">
                  {{ closureData?.tableString[0]?.retiros }}
                </td>
              </tr>
              <tr>
                <td>PAGOS REALIZADOS POR LA PAGINA DEL AGENTE</td>
                <td class="text-end">
                  {{ closureData?.tableString[0]?.pagos }}
                </td>
              </tr>
              <tr>
                <td>POR PAGAR DEL PUNTO FISICO</td>
                <td class="text-end">
                  {{ closureData?.tableString[0]?.punto_fisico }}
                </td>
              </tr>
              <tr>
                <td>BONO COMPRADO</td>
                <td class="text-end">
                  {{ closureData?.tableString[0]?.bono_comprado }}
                </td>
              </tr>
              <tr>
                <td>BONO SORTI</td>
                <td class="text-end">
                  {{ closureData?.tableString[0]?.bono_sorti }}
                </td>
              </tr>
              <tr>
                <td>BONO CARGADO</td>
                <td class="text-end">
                  {{ closureData?.tableString[0]?.bono_cargado }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Detalle</td>
                <td class="text-end">{{ totalTableString }}</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <!--   </div> -->
        <div class="card-footer custom-datatable no scroll">
          <!-- <div class="custom-datatable table-responsive">

            </div> -->
          @if ( closureData?.header[0]?.cantidad_enviados>=1) {
          <ng-template *ngTemplateOutlet="tableFotter"></ng-template>

          }@else if(!token&&![USERS_TYPES.PLAYER_BD,
          USERS_TYPES.AGENTS_BD].includes(profile.tipo_usuario)) {
          <ng-template *ngTemplateOutlet="tableFotter"></ng-template>
          }@else {
          <ng-template *ngTemplateOutlet="image"></ng-template>
          }
        </div>
        }
      </div>
    </div>
  </div>
</div>

<ng-template #image>
  <div class="row justify-content-center">
    <div class="col-md-6 text-center">
      <img
        src="../../../../assets/images/notification/undraw_transfer_money_re_6o1h.webp"
        alt="Estamos trabajando en tu cierre"
        width="60%"
      />
      <h3 class="mt-2">Estamos trabajando en tu cierre.</h3>
      <span>
        Tus cargas, retiros y comisiones se están procesando, para darte a
        conocer el resultado del cierre del mes. Te notificaremos cuando este
        listo.
      </span>
    </div>
  </div>
</ng-template>

<ng-template #tableFotter>
  <!-- isForMobile -->
  @if (false) {
  <div class="card-body">
    @if (isLoading) {
    <div class="loader-box">
      <div class="loader-16"></div>
    </div>
    }@else { @for (item of closureData?.bodyString; track $index) {
    <div class="card mb-3">
      <div class="card-body custom-card">
        <div class="card-more-button-holder" (click)="expandCard($index)">
          <i
            class="fa-solid"
            [ngClass]="
              cardExpanded[$index] ? 'fa-chevron-up' : 'fa-chevron-down'
            "
          ></i>
        </div>
        <div
          class="card-c-holder"
          [ngClass]="{ openCard: cardExpanded[$index] }"
        >
          <div class="geneKeyValue card-list-col">
            <p
              role="heading"
              aria-level="1"
              class="geneKeyValue__label ellipsis-text"
            >
              {{ columnsTableSumary[0].name }}
            </p>
            <br />
            <p class="geneKeyValue__value ellipsis-text">
              {{ item[columnsTableSumary[0].value] }}
            </p>
          </div>
          @if (cardExpanded[$index]) { @for (column of
          columnsTableSumary.slice(1, 3); track $index) { @if (column.value) {
          <div class="geneKeyValue card-list-col">
            <p
              role="heading"
              aria-level="1"
              class="geneKeyValue__label ellipsis-text"
            >
              {{ column.name }}
            </p>
            <br />
            <p class="geneKeyValue__value ellipsis-text">
              {{ item[column.value] }}
            </p>
          </div>
          }} } @for (column of columnsTableSumary.slice(3, 6); track $index) {
          @if (column.value) {
          <div class="geneKeyValue card-list-col">
            <p
              role="heading"
              aria-level="1"
              class="geneKeyValue__label ellipsis-text"
            >
              {{ column.name }}
            </p>
            <br />
            <p class="geneKeyValue__value ellipsis-text">
              {{ item[column.value] }}
            </p>
          </div>
          } }
        </div>
      </div>
    </div>
    } @if (closureData.debtAdjustment ) { @for (item of
    closureData?.debtAdjustment; track $index) {
    <div class="card mb-3">
      <div class="card-body custom-card">
        <div class="card-more-button-holder" (click)="expandCard($index)">
          <i
            class="fa-solid"
            [ngClass]="
              cardExpanded[$index] ? 'fa-chevron-up' : 'fa-chevron-down'
            "
          ></i>
        </div>
        <div
          class="card-c-holder"
          [ngClass]="{ openCard: cardExpanded[$index] }"
        >
          @for (column of columnsTableSumary.slice(6,8); track $index) { @if
          (column.value) {
          <div class="geneKeyValue card-list-col">
            <p
              role="heading"
              aria-level="1"
              class="geneKeyValue__label ellipsis-text"
            >
              {{ column.name }}
            </p>
            <br />
            <p class="geneKeyValue__value ellipsis-text">
              {{ item[column.value] }}
            </p>
          </div>
          }}
        </div>
      </div>
    </div>
    } } }
  </div>
  <div class="card-fotter text-center">
    <div class="row">
      <div class="col-6">
        <span>TOTAL COMISIÓN</span>
      </div>
      <div class="col-6">
        <span style="font-weight: bold">{{ totalBodyString }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <span>CIERRE</span>
      </div>
      <div class="col-6">
        <span style="font-weight: bold">{{ totalClosureString }}</span>
      </div>
    </div>
  </div>
  }@else {
  <div class="d-flex justify-content-between align-items-center">
    <div ngbDropdown class="d-inline-block">
      <button
        class="btn btn-primary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        ngbDropdownToggle
        style="width: 17vh"
      >
        Mostrar/Ocultar
      </button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <a
          class="dropdown-item"
          ngbDropdownItem
          (click)="showColumn1 = !showColumn1"
        >
          <i
            class="fa-solid"
            [ngClass]="!showColumn1 ? 'fa-eye' : 'fa-eye-slash'"
          ></i>
          {{
            showColumn1
              ? "Ocultar Balance Mes Anterior"
              : "Balance Mes Anterior"
          }}
        </a>
        <a
          class="dropdown-item"
          ngbDropdownItem
          (click)="showColumn2 = !showColumn2"
        >
          <i
            class="fa-solid"
            [ngClass]="!showColumn2 ? 'fa-eye' : 'fa-eye-slash'"
          ></i>
          {{
            showColumn2
              ? "Ocultar Balance Mes Actual"
              : "Mostrar Balance Mes Actual"
          }}</a
        >
        <a
          class="dropdown-item"
          ngbDropdownItem
          (click)="showColumn3 = !showColumn3"
        >
          <i
            class="fa-solid"
            [ngClass]="!showColumn3 ? 'fa-eye' : 'fa-eye-slash'"
          ></i>
          {{ showColumn3 ? "Ocultar Porcentaje" : "Mostrar Porcentaje" }}</a
        >
        <a
          class="dropdown-item"
          ngbDropdownItem
          (click)="showRefers = !showRefers"
        >
          <i
            class="fa-solid"
            [ngClass]="!showRefers ? 'fa-eye' : 'fa-eye-slash'"
          ></i>
          {{ showRefers ? "Ocultar Referidos" : "Mostrar Referidos" }}</a
        >
      </div>
    </div>
    <button
      style="cursor: pointer"
      (click)="redirectToDaily()"
      class="primary-button"
    >
      Reporte Diario
    </button>
  </div>
  <div
    class="card-body custom-datatable"
    [ngClass]="
      showColumn1 || showColumn2 || showColumn3
        ? 'table-responsive'
        : 'no-scroll'
    "
  >
    <!-- <div class="custom-datatable no-scroll"> -->
    <table class="table mt-3">
      <thead class="text-start">
        @for (column of columnsTableSumary.slice(0,6); track $index) { @switch
        (column.name) { @case ("Balance Anterior") { @if (showColumn1) {
        <th class="text-end">{{ column.name }}</th>
        } } @case ("Balance Actual") { @if (showColumn2) {
        <th class="text-end">{{ column.name }}</th>
        } } @case ("%") { @if (showColumn3) {
        <th class="text-end">{{ column.name }}</th>
        } } @case ("Balance Final") {
        <th class="text-end">{{ column.name }}</th>
        } @case ("Comisión") {
        <th class="text-end">{{ column.name }}</th>
        } @default {
        <th>{{ column.name }}</th>
        } } }
      </thead>
      <tbody class="text-start">
        @for (item of closureData?.bodyString; track $index) { @if ($index === 0
        || showRefers) {
        <tr>
          <td>{{ item.agente }}</td>
          @if (showColumn1) {
          <td class="text-end">{{ item.balance_anterior }}</td>
          } @if (showColumn2) {
          <td class="text-end">{{ item.balance_actual }}</td>
          }
          <td class="text-end">{{ item.balance_final }}</td>
          @if (showColumn3) {
          <td class="text-end">{{ item.porcentaje_comision }}%</td>
          }
          <td style="font-weight: bold" class="text-end">
            {{ item.comision }}
          </td>
        </tr>
        } } @if (closureData.debtAdjustment) { @for (item of
        closureData?.debtAdjustment; track $index) {
        <tr>
          <td>{{ item.titulo }}</td>
          @if (showColumn1) {
          <td class="text-end"></td>
          } @if (showColumn2) {
          <td class="text-end"></td>
          }
          <td class="text-end">{{ item.monto }}</td>
          @if (showColumn3) {
          <td class="text-end"></td>
          }
          <td style="font-weight: bold" class="text-end">{{ item.monto }}</td>
        </tr>
        } }
      </tbody>
      <tfoot>
        <tr>
          <td>TOTAL COMISIÓN</td>
          @if (showColumn1) {
          <td class="text-end"></td>
          } @if (showColumn2) {
          <td class="text-end"></td>
          }
          <td class="text-end"></td>
          @if (showColumn3) {
          <td class="text-end"></td>
          }
          <td class="text-end">{{ this.totalBodyString }}</td>
        </tr>
        <tr>
          <td>CIERRE</td>
          @if (showColumn1) {
          <td class="text-end"></td>
          } @if (showColumn2) {
          <td class="text-end"></td>
          }
          <td class="text-end"></td>
          @if (showColumn3) {
          <td class="text-end"></td>
          }
          <td class="text-end">{{ totalClosureString }}</td>
        </tr>
      </tfoot>
    </table>
  </div>

  @if(hasMoreThanTwentyItems) { @if(showScrollUpButton) {
  <div id="go-to-top" class="floating-button" (click)="scrollToTop()">
    <div class="arrow up"></div>
    <div class="arrow up"></div>
    <div class="arrow up"></div>
  </div>
  } @if(showScrollDownButton) {
  <div id="go-to-bottom" class="floating-button" (click)="scrollToBottom()">
    <div class="arrow down"></div>
    <div class="arrow down"></div>
    <div class="arrow down"></div>
  </div>
  } }
  <!-- </div> -->
  }
</ng-template>
