import { Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { ClosureComponent } from '../../pages/reports/closure/closure.component';
import { ProfileDetailComponent } from '../../pages/profile/pages/profile-detail/profile-detail.component';
import { BonusCodeComponent } from '../../pages/bonus/pages/bonus-code/bonus-code.component';
import { ListLwComponent } from '../../pages/loads-withdrawals/pages/list-lw/list-lw.component';
import { ListSpComponent } from '../../pages/suscriptions-payments/pages/list-sp/list-sp.component';
import { RequestCwComponent } from '../../pages/suscriptions-payments/pages/request-cw/request-cw.component';
import { DailyComponent } from '../../pages/reports/daily/daily.component';
import { AffiliateToolsComponent } from '../../pages/affiliate/pages/affiliate-tools/affiliate-tools.component';

export const mobile: Routes = [
  {
    path: 'user/profile/:token', //
    component: ProfileDetailComponent,
  },
  {
    path: 'closure/:token', //
    component: ClosureComponent,
  },
  {
    path: 'bonus/bonus-code/:token', //
    component: BonusCodeComponent,
  },
  {
    path: 'loads-withdrawals/list-lw/:token', //
    component: ListLwComponent,
  },
  {
    path: 'suscriptions-payments/list-sp/:token', //
    component: ListSpComponent,
  },
  {
    path: 'suscriptions-payments/request-cw/:token', //
    component: RequestCwComponent,
  },
  {
    path: 'closure/daily/:token', //not found for the moment
    component: DailyComponent,
  },
  {
    path: 'affiliate/:token',
    component: AffiliateToolsComponent,
  },
];
