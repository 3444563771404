import { createReducer, on } from '@ngrx/store';
import { AVAILABLE_THEME } from '../../constants/constants';
import { ThemeActions } from './theme.actions';

// const initialState = {
//   theme: AVAILABLE_THEME.WHITE,
// };
// const initialState = {
//   settings: {
//     layout: '',
//     layout_type: '',
//     layout_version: '',
//     icon: '',
//   },
//   color: {
//     primary_color: '',
//     secondary_color: '',
//   },
// };
const initialState = {
  settings: {
    layout: 'Dubai',
    layout_type: 'ltr',
    layout_version: 'light-only',
    icon: 'stroke-svg',
  },
  color: {
    primary_color: '#7366ff',
    secondary_color: '#f73164',
  },
};

export const themeReducer = createReducer(
  initialState,
  on(ThemeActions['[Theme]Change'], (state, { settings, color }) => ({
    ...state,
    settings: settings
      ? { ...state.settings, ...settings }
      : { ...state.settings }, // Usa settings solo si está definido
    color: color ? { ...state.color, ...color } : { ...state.color }, // Usa color solo si está definido
  }))
);
