import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiPathEnum, PlayerEndpointEnum } from '../constants/endpoints.enum';
import {
  ApiDataResponse,
  ApiResponse,
} from '../interfaces/api-response.inteface';
import { RegisteredPlayersByDate } from '../interfaces/dashboard.interface';
import { NewPlayer, NewPlayerFilters } from '../interfaces/player.interface';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  private API_URL = `${environment.API_URL}${ApiPathEnum.Player}`;

  constructor(private readonly _httpClient: HttpClient) {}

  public getPlayers(
    id_pais: any,
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    if (id_pais) {
      params = params.set('id_pais', id_pais);
    }
    return this._httpClient.get<any>(
      `${this.API_URL}${PlayerEndpointEnum.GetPlayersData}`,
      { params }
    );
  }

  public getRegisteredPlayersByDate({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }): Observable<ApiResponse<RegisteredPlayersByDate[]>> {
    const endpoint = `${PlayerEndpointEnum.GetRegisteredPlayersByDate}`;
    const url = `${this.API_URL}${endpoint}`;
    return this._httpClient.post<ApiResponse<RegisteredPlayersByDate[]>>(url, {
      startDate,
      endDate,
    });
  }

  getAllData(
    page: number,
    limit: number,
    filters: NewPlayerFilters
  ): Observable<ApiResponse<ApiDataResponse<NewPlayer[]>>> {
    const url = `${this.API_URL}${PlayerEndpointEnum.GetAllData}`;
    return this._httpClient.post<ApiResponse<ApiDataResponse<NewPlayer[]>>>(
      url,
      { page, limit, filters }
    );
  }
  createPlayer(playerData: NewPlayer): Observable<ApiResponse<NewPlayer>> {
    const url = `${this.API_URL}${PlayerEndpointEnum.CreatePlayer}`;
    return this._httpClient.post<ApiResponse<NewPlayer>>(url, playerData);
  }

  deactivatePlayer(playerId: number): Observable<ApiResponse<void>> {
    const url = `${this.API_URL}${PlayerEndpointEnum.DeactivatePlayer}`;
    let params = new HttpParams().set('id', playerId.toString());
    return this._httpClient.patch<ApiResponse<void>>(url, {}, { params });
  }
  updatePlayer(
    playerId: number,
    playerData: NewPlayer
  ): Observable<ApiResponse<NewPlayer>> {
    const url = `${this.API_URL}${PlayerEndpointEnum.UpdatePlayer}/${playerId}`;
    return this._httpClient.patch<ApiResponse<NewPlayer>>(url, playerData);
  }
}
