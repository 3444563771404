import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private _auth: AuthService, private _router: Router,) {}
  canActivate(): boolean {
    if (this._auth.isLoggedOut()) {
      this._router.navigate(['/auth/login']);
      return false;
    }
    return true;
  }
}
