import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventService } from '../../../../../shared/services/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-count-card',
  templateUrl: './count-card.component.html',
  styleUrls: ['./count-card.component.scss']
})
export class CountCardComponent implements OnInit, OnDestroy {

  @Input() totalPlayers: number = 0;
  @Input() totalRegisteredPlayers: number = 0;
  @Input() totalvisitTraffic: number = 0;
  @Input() totalconversionRate: number = 0
  @Input() loading!: boolean;
  @Input() data: any;;
  private eventSubscription!: Subscription;

  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.getSuscription();
  }

  getSuscription() {
    this.eventSubscription = this.eventService.getEvent().subscribe((event) => {
      if (event.action === 'totalPlayersChange') {
        this.totalPlayers = event.value;
      } else if (event.action === 'totalRegisteredPlayersChange') {
        this.totalRegisteredPlayers = event.value;
      } else if (event.action === 'totalvisitTrafficChange') {
        this.totalvisitTraffic = event.value;
      } else if (event.action === 'totalconversionRateChange') {
        this.totalconversionRate = event.value;
      } else if (event.action === 'loading') {
        this.loading = event.value;
      }
    });
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }
}
