import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject, Subscription, debounceTime, of } from 'rxjs';
import { UserService } from '../../../../../../core/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DynamicModalService } from '../../../../../../core/services/dynamic-modal.service';
import {
  selectAuth,
  selectUser,
} from '../../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { CodeBonusService } from '../../../../../../core/services/code-bonus.service';
import { PlayerService } from '../../../../../../core/services/player.service';
import { CODE_BONUS_CONSTANS } from '../../../../../../core/constants/constants';

@Component({
  selector: 'app-create-forms-bc',
  templateUrl: './create-forms-bc.component.html',
  styleUrls: ['./create-forms-bc.component.scss'],
})
export class CreateFormsBcComponent implements OnInit {
  public optionsBonusTypes$: any;
  public bonoIlimitado$ = this.store.select(selectUser);
  public allTypeBonusData: any[] = [];
  public optionsAgents$: any;
  public optionsPlayers$: any;
  public id_country!: any;
  public agente = CODE_BONUS_CONSTANS.AGENTE;
  public pagos = CODE_BONUS_CONSTANS.PAGOS;
  public atc = CODE_BONUS_CONSTANS.ATC;
  public administrador = CODE_BONUS_CONSTANS.ADMINISTRADOR;
  public afiliados = CODE_BONUS_CONSTANS.AFILIADO;
  public currentPageAgents = 1;
  public currentPagePlayers = 1;
  public bonoIlimitado: number | null = null;
  public allPlayersData: any[] = [];
  public allAgentsData: any[] = [];
  public isAgent = true;
  public pageSize = 10;
  private bonusSubject: Subject<string> = new Subject<string>();
  public criteria: any;
  public bonusForm!: FormGroup;
  public isLoadingButton = false;
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  public idRol: number | null = null;
  private selectedOption: any = null;
  private id_agente: any = null;
  private agenteBalance: any = null;
  public bonusBalanceData: number = 0;
  private subscriptions: Subscription[] = [];
  public codeBonus$ = this.store.select(selectUser);

  constructor(
    private _userService: UserService,
    private bonusCodeService: CodeBonusService,
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private _toastrSvc: ToastrService,
    private dynamicModal: DynamicModalService,
    private store: Store,
    private cd: ChangeDetectorRef,
    private _playerService: PlayerService
  ) {
    this.initForm();
    this.bonusSubject
      .pipe(
        debounceTime(400) // Adjust debounce time as needed (e.g., 300 milliseconds)
      )
      .subscribe((term: any) => {
        this.getBalanceBonus();
        this.allAgentsData = [];
        this.allPlayersData = [];
        if (this.isAgent) {
          this.loadAgents(term);
        } else {
          this.loadPlayers(term);
        }
      });
    this.isLoadingButton = false;
  }
  async ngOnInit(): Promise<void> {
    const data = this.dynamicModal.getModalData();
    this.id_country = this.dynamicModal.getModalData();
    this.cd.detectChanges();
    this.loadPlayers();
    this.loadTypeBonus();
    if (data.id_agente) {
      this.idRol = data.idRol;
      this.bonoIlimitado = data.bonoIlimitado;
      this.id_agente = data.id_agente;
      this.id_country = data.id_country;
    } else {
      await new Promise((resolve, reject) => {
        this.bonoIlimitado$.subscribe({
          next: (profileData) => {
            this.idRol = profileData.id_rol;
            this.bonoIlimitado = profileData.bono_ilimitado;
            this.id_agente = profileData.idusuario;
            resolve(true);
          },
          error: (err) => {
            reject(false);
          },
        });
      });
    }
    if (this.idRol === this.agente) {
      this.bonusForm.patchValue({ id_agente: this.id_agente });
      this.bonusForm.get('id_agente')?.clearValidators();
    } else {
      this.bonusForm.get('id_agente')?.setValidators(Validators.required);
    }
    this.bonusForm.get('id_agente')?.updateValueAndValidity();
    this.getBalanceBonus();
  }

  loadAgents(searchTerm?: string) {
    this._userService
      .getAgents(
        this.id_country,
        this.currentPageAgents,
        this.pageSize,
        searchTerm
      )
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: any; idusuario: any }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.optionsAgents$ = of(transformedData);
        this.allAgentsData = [...this.allAgentsData, ...transformedData];
      });
  }

  loadTypeBonus(searchTerm?: string) {
    this.bonusCodeService.getAllTypeBonus().subscribe((response) => {
      let filteredData = response.data;
      filteredData = filteredData.filter((item: any) => item.activar === 1);

      if (this.idRol === this.agente) {
        if (this.bonoIlimitado === 1) {
          this.allTypeBonusData = filteredData.map((item: any) => {
            return {
              label: item.detalle,
              value: item.idbono_tipos,
              porcentaje: item.porcentaje,
              detalle: item.detalle,
              nombre: item.nonbre,
            };
          });
        } else {
          this.allTypeBonusData = filteredData
            .filter((item: any) => item.permiso_agente === 1)
            .map((item: any) => {
              return {
                label: item.detalle,
                value: item.idbono_tipos,
                porcentaje: item.porcentaje,
                detalle: item.detalle,
                nombre: item.nombre,
              };
            });
        }
      } else {
        this.allTypeBonusData = filteredData.map((item: any) => {
          return {
            label: item.detalle,
            value: item.idbono_tipos,
            porcentaje: item.porcentaje,
            detalle: item.detalle,
            nombre: item.nombre,
          };
        });
      }

      if (this.allTypeBonusData.length > 0) {
        this.bonusForm.patchValue({
          tipo_bono: this.allTypeBonusData[0].value,
          detalle_tipo_bono: this.allTypeBonusData[0].detalle,
          nombre: this.allTypeBonusData[0].detalle,
        });
        this.selectedOption = this.allTypeBonusData[0];
        this.calculateResultAmountBonus();
      }
    });
  }
  onSelectChange(event: any) {
    const selectedValue = parseInt(event.target.value, 10);
    this.selectedOption = this.allTypeBonusData.find(
      (option) => option.value === selectedValue
    );
    if (this.selectedOption) {
      this.calculateResultAmountBonus();
    }
  }

  onRechargeAmountChange(event: any) {
    this.calculateResultAmountBonus();
  }
  calculateResultAmountBonus() {
    const rechargeAmount = this.bonusForm.get('monto_recarga')?.value;
    const fractions = this.bonusForm.get('fracciones')?.value || 1;
    if (this.selectedOption && rechargeAmount) {
      const multiplyingPercentage =
        parseFloat(this.selectedOption.porcentaje) * parseFloat(rechargeAmount);
      const amountPerBonus = multiplyingPercentage / parseFloat(fractions);
      this.bonusForm.patchValue({ monto: amountPerBonus.toFixed(2) });
    }
  }

  loadPlayers(searchTerm?: string) {
    this._playerService
      .getPlayers(
        this.id_country,
        this.currentPagePlayers,
        this.pageSize,
        searchTerm
      )
      .subscribe((response) => {
        const transformedData = response.data.map((item: any) => {
          return {
            label: item.usuario_jugador,
            value: item.id_jugador,
            whiteList: item.jugador_white_list?.lista_negra,
            id_usuario: item.id_usuario,
          };
        });
        this.optionsPlayers$ = of(transformedData);
        this.allPlayersData = [...this.allPlayersData, ...transformedData];
      });
  }
  //*NG-SELECT
  onScrollToEnd() {
    if (this.isAgent) {
      this.currentPageAgents++;
      this.loadAgents(this.criteria);
    } else {
      this.currentPagePlayers++;
      this.loadPlayers(this.criteria);
    }
  }
  onSearch(term: string) {
    this.criteria = term;
    if (this.isAgent) {
      this.allAgentsData = [];
      this.currentPageAgents = 1;
      this.bonusSubject.next(term);
    } else {
      this.allPlayersData = [];
      this.currentPagePlayers = 1;
      this.bonusSubject.next(term);
    }
  }
  toggleUserType() {
    this.isAgent = !this.isAgent;
    this.criteria = '';
    if (this.isAgent) {
      this.loadAgents();
    } else {
      this.loadPlayers();
    }
  }

  //?MODAL
  closeModal() {
    this.activeModal.close();
    this.bonusForm.reset();
  }
  //*FORM
  initForm(): void {
    this.bonusForm = this.fb.group({
      monto_recarga: [null],
      monto: [null, Validators.min(0.1)],
      tipo_bono: [null],
      id_agente: [null],
      comentario: [null],
      fracciones: [1],
      id_usu_creabono: [null],
      detalle_tipo_bono: [null],
      id_pais: [null],
    });
  }
  onKeyPressSpecial(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  isFormValid(): boolean {
    if (this.idRol === this.agente) {
      return (
        this.bonusForm.valid && this.bonusForm.get('fracciones')?.value > 0
      );
    } else {
      return (
        this.bonusForm.valid && this.bonusForm.get('fracciones')?.value > 0
      );
    }
  }

  getBalanceBonus(): void {
    const subscription = this.bonusCodeService
      .getAgentBalance({ agentId: this.id_agente })
      .subscribe({
        next: (response) => {
          this.bonusBalanceData = response.data.bonusBalance;
        },
        error: (error) => {
          this._toastrSvc.error('Internal server error', 'Error');
        },
      });
    this.subscriptions.push(subscription);
  }

  sendFormData() {
    this.isLoadingButton = true;
    if (this.idRol === this.agente) {
      this.bonusForm.patchValue({ id_agente: this.id_agente });
    }

    this.bonusForm.patchValue({
      id_usu_creabono: this.id_agente,
      detalle_tipo_bono: this.selectedOption?.detalle,
      tipo_bono: this.selectedOption?.nombre,
      id_pais: this.id_country,
    });

    const formData = this.bonusForm.value;
    const fractions = formData.fracciones;
    const totalAmount =
      parseFloat(this.bonusForm.get('monto')?.value || '0') * fractions;

    if (this.idRol === this.agente && totalAmount > this.bonusBalanceData) {
      this.isLoadingButton = false;
      this._toastrSvc.error(
        'Saldo insuficiente para crear los bonos, Recargue',
        'Error'
      );
      this.bonusForm.reset();
      return;
    }

    this._toastrSvc.info('Procesando... ⌛', '', {
      timeOut: 500,
    });

    const request = {
      data: formData,
      fracciones: fractions,
    };

    this.bonusCodeService.createCodeBonus(request).subscribe(
      (response) => {
        this.isLoadingButton = false;
        this._toastrSvc.success('Bonos creados con éxito!', '');
        this.closeModal();
      },
      (err) => {
        this.isLoadingButton = false;
        this._toastrSvc.error('Error al crear los bonos', '');
        this.bonusForm.reset();
        console.error('Error:', err);
      }
    );
  }

  onInputChange(event: any) {
    let value = Math.round(event.target.value);
    if (value < 0) {
      value = 0;
    }
    event.target.value = value;
    this.bonusForm.patchValue({ fracciones: value });
    this.calculateResultAmountBonus();
  }
}
