<div class="container-fluid">
  <div class="row">
    <div class="col-xl-7 order-1 login-background">
      <img
        class="bg-img-cover bg-center d-none"
        src="assets/images/login/login.png"
        alt="looginpage"
      />
    </div>
    <div class="col-xl-5 p-0">
      <div class="login-card login-dark">
        <div>
          <div>
            <a class="logo text-start" routerLink="/">
              <img
                class="img-fluid for-light"
                src="assets/images/logo/logo_sorti.png"
                alt="looginpage"
              />
              <img
                class="img-fluid for-dark"
                src="assets/images/logo/logo_sorti_dark.png"
                alt="looginpage"
              />
            </a>
          </div>
          <div class="login-main">
            <form
              class="theme-form needs-validation"
              [class.was-validated]="!loginForm.valid"
              novalidate=""
              [formGroup]="loginForm"
              (ngSubmit)="onSubmit()"
            >
              <h4 class="primary-text">Ingresa en tu cuenta</h4>
              <p class="secondary-text">Ingresa tu usuario y contraseña para iniciar sesión</p>
              <div class="form-group">
                <label class="col-form-label">Username</label>
                <input
                  class="form-control"
                  type="input"
                  required=""
                  placeholder="example26"
                  formControlName="username"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    loginForm.controls.username.touched &&
                    loginForm.controls.username.errors?.['required']
                  "
                >
                  El usuario es requerido.
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    loginForm.controls.username.touched &&
                    loginForm.controls.username.errors?.username
                  "
                >
                  Usuario inválido.
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input
                  class="form-control"
                  [type]="show ? 'text' : 'password'"
                  name="login[password]"
                  required=""
                  placeholder="*********"
                  formControlName="password"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors?.required
                  "
                >
                  La contraseña es requerida.
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <span class="show"></span>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <span class="Hide"></span>
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input
                    id="checkbox1"
                    type="checkbox"
                    [checked]="rememberMeChecked"
                    (change)="onRememberMeChange()"
                  />
                  <label for="checkbox1">Remember password</label>
                </div>
                <button
                  class="primary-button d-block w-100"
                  type="submit"
                  [disabled]="!loginForm.valid"
                >
                  Iniciar Sesión
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
