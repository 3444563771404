<div class="container-fluid basic_table">
  <div class="row">
    <div class="card">
      <div class="card-header">
        <div class="text-end">
          <button
            class="btn btn-sm btn-refresh me-3"
            [disabled]="isLoading"
            ngbTooltip="Recargar Tabla"
            tooltipClass="custom-tooltip"
            (click)="reloadTable()"
          >
            <i class="fas fa-sync-alt"></i>
          </button>
          <button
            class="btn btn-sm btn-head me-3"
            [disabled]="isLoading"
            (click)="exportToExcel()"
            ngbTooltip="Descargar Información en .xls"
            tooltipClass="custom-tooltip"
          >
            <i class="far fa-file-excel"></i>
          </button>
          <!-- <button
            class="btn btn-sm btn-head me-3"
            [disabled]="isLoading"
            ngbTooltip="Descargar Información en .pdf"
            tooltipClass="custom-tooltip"
          >
            <i class="far fa-file-pdf"></i>
          </button> -->
        </div>
      </div>
      @if (!(isMobile$ | async)) {
      <div class="card-body custom-datatable noscroll">
        <div class="table-responsive signal-table custom-datatable">
          <table class="table table-hover table-responsive uppercase-table">
            <thead class="mx-auto text-center">
              <tr>
                @for (column of columnsTable; track $index) {
                <th (click)="onSort(column.value)">
                  {{ column.name }}
                  <i
                    *ngIf="
                      sortColumn === column.value &&
                      sortDirection === SORT_DIRECTION.ASC
                    "
                    class="fas fa-sort-up"
                  ></i>
                  <i
                    *ngIf="
                      sortColumn === column.value &&
                      sortDirection === SORT_DIRECTION.DESC
                    "
                    class="fas fa-sort-down"
                  ></i>
                  <i
                    *ngIf="sortColumn !== column.value"
                    class="fas fa-sort"
                  ></i>
                </th>
                }
              </tr>
            </thead>

            @if (isLoading) {
            <tbody class="text-center">
              <tr>
                <td colspan="11">
                  <!-- Ajusta el colspan según el número de columnas -->
                  <div class="loader-box">
                    <div class="loader-16"></div>
                  </div>
                </td>
              </tr>
            </tbody>
            } @if (!isLoading) { @for (item of lwData; track $index) {
            <tbody class="text-center">
              <tr>
                @if (!isMobile) {
                <td class="mx-auto text-center">
                  <div
                    class="d-flex justify-content-center align-items-cejustify-content-center"
                  >
                    <div class="btn-group mb-2">
                      <!-- <button
                          class="btn btn-sm btn-head btn-mode mt-2"
                          type="button"
                          ngbTooltip="Cambiar Estado"
                          tooltipClass="custom-tooltip"
                        >
                          <i class="fas fa-exchange-alt"></i>
                        </button> -->
                      @if(hasPermission(LOADS_WITHDRAWAL_BUTTON_PERMISSIONS.ACCTIONS)){

                      <div ngbDropdown class="btn-group" role="group">
                        <button
                          type="button"
                          class="btn btn-sm btn-head btn-mode mt-2"
                          id="dropdownBasic1"
                          ngbDropdownToggle
                        >
                          <i class="fas fa-exchange-alt"></i>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                          @for (state of states; track $index) {
                          <button
                            class="dropdown-item text-black"
                            (click)="
                              setStatus(item.idcargas_retiros, state.n_estado)
                            "
                          >
                            {{ state.estado }}
                          </button>
                          }
                        </div>
                      </div>

                      } @else { No hay Acciones Disponibles }
                    </div>
                  </div>
                </td>
                }
                <td class="mx-auto text-start">
                  <div
                    class="d-flex d-flex justify-content-start align-items-center"
                    style="padding-left: 24%"
                  >
                    <span
                      [ngClass]="getStateClasses(item.id_estado).classes"
                      [innerHTML]="getStateClasses(item.id_estado).text"
                      class="status-span"
                    ></span>
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div
                    class="d-flex d-flex justify-content-start align-items-center"
                    style="padding-left: 21%"
                  >
                    <i class="fas fa-clock fa-sm me-2 informative-icon"></i>
                    <span>{{
                      item.fecha | date : "dd/MM/yyyy HH:mm:ss" : "UTC"
                    }}</span>
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div
                    class="d-flex d-flex justify-content-start align-items-center"
                    style="padding-left: 35%"
                  >
                    <span
                      ><strong>{{ item.monto }}</strong></span
                    >
                  </div>
                </td>
                <td class="mx-auto text-center">
                  <div
                    class="d-flex d-flex justify-content-center align-items-center"
                    [ngClass]="getMovementClass(item.id_categoria)"
                  >
                    <i
                      [ngClass]="getMovementIconClass(item.id_categoria)"
                      class="me-2"
                    ></i>
                    {{ getMovementTypeLabel(item.id_categoria) }}
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div
                    class="d-flex d-flex justify-content-start align-items-center"
                    style="padding-left: 23%"
                  >
                    @if (item.titular) {
                    <i class="fas fa-user-tie fa-sm me-2 sorti-2"></i>
                    {{ item.titular?.usuario }}
                    }@else { Sin Titular }
                  </div>
                </td>
                <td class="mx-auto text-start">
                  <div
                    class="d-flex d-flex justify-content-start align-items-center"
                    style="padding-left: 35%"
                  >
                    @if (item.agente) {
                    <strong>{{ item.agente?.usuario }}</strong>
                    }@else { Sin Agente }
                  </div>
                </td>
                <td
                  class="mx-auto text-start observation-column"
                  [ngbTooltip]="item.comentario"
                  tooltipClass="custom-tooltip"
                  container="body"
                >
                  {{ item.comentario }}
                </td>
              </tr>
            </tbody>
            } }
          </table>
          @if(isLoading){
          <div
            class="d-flex justify-content-between align-items-center mb-3 me-3 ms-3"
          >
            <p class="card-text placeholder-glow text-end">
              <span class="placeholder col-7 mb-2"></span>
            </p>
            <p class="card-text placeholder-glow text-end">
              <span class="placeholder col-7 mb-2"></span>
            </p>
          </div>
          } @else {
          <div
            class="d-flex justify-content-between align-items-center mb-3 me-3 ms-3"
          >
            <div class="total-monto-container mt-3">
              Total en Dólares: <strong>{{ totalDolares }}</strong>
            </div>
            <div class="total-monto-container text-end mt-3">
              Total en Soles: <strong>{{ totalSoles }}</strong>
            </div>
          </div>
          }
        </div>
      </div>
      }@else { @if (isLoading) {
      <!-- Ajusta el colspan según el número de columnas -->
      <div class="loader-box">
        <div class="loader-16"></div>
      </div>
      }@else {@for (item of lwData; track $index) {
      <div class="card mb-3 mt-2">
        <div class="card-body custom-card">
          <div
            class="card-more-button-holder"
            (click)="toggleMenu($index, item)"
          >
            <i class="fa-solid fa-ellipsis"></i>
          </div>
          <div class="card-c-holder">
            @for (column of columnsTable; track $index) { @if (column.value) {
            <div class="geneKeyValue card-list-col">
              <p
                role="heading"
                aria-level="1"
                class="geneKeyValue__label ellipsis-text"
              >
                {{ column.name }}
              </p>
              <br />
              @switch (column.value) { @case ('id_categoria') {

              <p
                class="geneKeyValue__value ellipsis-text"
                [ngClass]="getMovementClass(item.id_categoria)"
              >
                <span>
                  <i
                    [ngClass]="getMovementIconClass(item.id_categoria)"
                    class="me-2"
                  ></i>
                  {{ getMovementTypeLabel(item.id_categoria) }}
                </span>
              </p>
              }@case ('id_estado') {
              <p class="geneKeyValue__value ellipsis-text">
                <span
                  [ngClass]="getStateClasses(item.id_estado).classes"
                  [innerHTML]="getStateClasses(item.id_estado).text"
                ></span>
              </p>
              }@case ('titular') { @if (item.titular) {
              <p class="geneKeyValue__value ellipsis-text">
                {{ item[column.value].usuario }}
              </p>
              }@else {
              <p class="geneKeyValue__value ellipsis-text">Sin Titular</p>
              } }@case ('agente') { @if (item.agente) {
              <p class="geneKeyValue__value ellipsis-text">
                {{ item[column.value].usuario }}
              </p>
              }@else {
              <p class="geneKeyValue__value ellipsis-text">Sin Agente</p>
              } } @default {
              <p class="geneKeyValue__value ellipsis-text">
                {{ item[column.value] }}
              </p>
              } }
            </div>
            } }
          </div>
        </div>
      </div>
      } }}
      <div class="card-footer">
        <shared-custom-pagination
          [collectionSize]="collectionSize"
          (pageChange)="onPageChange($event)"
          (pageSizeChange)="onLimitChange($event)"
        ></shared-custom-pagination>
      </div>
    </div>
  </div>
</div>

<!-- COMENTARY -->
<div
  class="overlay"
  *ngIf="showMenu || showSubMenu"
  (click)="closeMenu()"
></div>
<div class="popover1" *ngIf="showMenu">
  <ul>
    <li
      (click)="openSubMenu(actualItem)"
      style="border-top: 1px solid var(--chart-border)"
    >
      <i class="fas fa-comment-dots informative-icon me-2"></i> Ver Comentario
    </li>
    <li (click)="closeMenu()" style="border-top: 1px solid var(--chart-border)">
      <i class="fas fa-times informative-icon me-2"></i> Close
    </li>
  </ul>
</div>
<div class="popover1" *ngIf="showSubMenu">
  <div class="submenu">
    <ul>
      <li (click)="closeSubMenu()">
        <i class="fas fa-reply informative-icon me-2"></i> Regresar
      </li>
    </ul>
    <div class="submenu-content">
      <div class="row" style="max-width: 98%">
        <div [innerHTML]="actualItem"></div>
      </div>
    </div>
  </div>
</div>
