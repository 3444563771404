import { FormArray } from '@angular/forms';
import { CURRENCY_BY_COUNTRY_CODE } from '../constants/constants';

export interface IvaCalculationResult {
  ivas: { value: number; amount: number; subtotal: number }[];
  totalWithIva: number;
}

export function formatAmount(
  amount: number,
  countryCode: string = 'EC'
): string {
  const { symbol } = CURRENCY_BY_COUNTRY_CODE[countryCode!];
  return `${symbol} ${amount.toLocaleString('fi-FI')}`;
}
export function formatQuantity(quantity: number): string {
  return quantity.toLocaleString('fi-FI');
}
