import { createActionGroup, props } from '@ngrx/store';
import { AuthState, User } from '../../interfaces/user.interface';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    '[Auth] Load Preview Session': props<any>,
    '[Auth] Load Pre Preview Session': props<any>,
    '[Auth] Request Login': props<AuthState>(),
    '[Auth] Login Success': props<User>(),
    '[Auth] Login Pre-Success': props<User>(),
    '[Auth] Login Failed': props<{ message: string }>(),
    '[Auth] Login Pre-Failed': props<{ message: string }>(),
    '[Auth] Logout': props,
    '[Auth] Update User': props<User>(),
    '[Auth] Request Update User': props<{ updatedUser: FormData }>(),
    '[Auth] Request Update Balance': props<{ _id: string; updatedUser: any }>(),
    '[Auth] Two Factor Auth': props<{ _id: string; twoFactorAuth: string }>(),
    '[Auth] Desactive Two Factor Auth': props<{ _id: string }>(),
    '[Auth] Account State': props<{ _id: string; password: string }>(),
    '[Auth] Update Data': props<{ _id: string; balance: number }>(),
    '[Auth] Update Failed': props<{ message: string }>(),
  },
});
