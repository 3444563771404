import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { throwError, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { AVAILABLE_LANGUAGES } from '../constants/constants';
import { selectLanguage } from '../state/language/language.selectors';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor, OnDestroy {
  private selectedLanguage: string = AVAILABLE_LANGUAGES.ES;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private messageError: string = 'common.internalServerError';

  constructor(
    private readonly _toastrSvc: ToastrService,
    private readonly _store: Store,
    private readonly _translateSvc: TranslateService
  ) {
    this._store
      .select(selectLanguage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => (this.selectedLanguage = res),
        error: (error) => {
          this.selectedLanguage = AVAILABLE_LANGUAGES.ES;
          console.error(`Error selecting language: ${error}`);
        },
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.url.includes('/get-user-token-app')) {
          return throwError(() => error);
        }
        let errorMessage = this.getErrorMessage(error);
        this._toastrSvc.error(errorMessage, '', {
          progressBar: true,
          positionClass: 'custom-toastr-top',
        });
        return throwError(() => error);
      })
    );
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    let errorMessage: string = '';

    try {
      if (typeof error.error.message === 'string') {
        // Attempt to parse the error message as JSON
        const parsedMessage = JSON.parse(error.error.message);
        if (typeof parsedMessage === 'object' && parsedMessage !== null) {
          errorMessage = parsedMessage[this.selectedLanguage] || parsedMessage[AVAILABLE_LANGUAGES.ES];
        } else {
          errorMessage = parsedMessage;
        }
      } else if (typeof error.error.message === 'object') {
        errorMessage = error.error.message[this.selectedLanguage] || error.error.message[AVAILABLE_LANGUAGES.ES];
      } else {
        errorMessage = this._translateSvc.instant(this.messageError);
      }
    } catch (e) {
      errorMessage = this._translateSvc.instant(this.messageError);
    }

    return errorMessage;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export const errorInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];
