 <!-- error-404 start-->
 <div class="error-wrapper">
    <div class="container"><img class="img-100" src="assets/images/other-images/sad.png" alt="">
      <div class="error-heading">
        <h2 class="headline font-danger">404</h2>
      </div>
      <div class="col-md-8 offset-md-2">
        <p class="sub-content">La página a la que intenta acceder no está disponible actualmente. Esto puede deberse a que la página no existe o ha sido movida.</p>
      </div>
      <div><a class="btn btn-danger-gradien btn-lg" routerLink='/dashboard/default'><i class="fas fa-home fa-lg" style="color: #ffffff;"></i></a></div>
    </div>
  </div>
  <!-- error-404 end      -->
