import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import moment from 'moment';
import { Subject, debounceTime, of } from 'rxjs';
import { CONFIG_BASE_DATE_RANGEPICKER } from '../../../../../../../core/constants/constants';
import { ChargesWithdrawalsRequestService } from '../../../../../../../core/services/charges-withdrawals-request.service';
import { FilterCommunicationService } from '../../../../../../../core/services/filter-communication.service';
import { FinancialService } from '../../../../../../../core/services/financial.service';
import { HolderAccountBankService } from '../../../../../../../core/services/holder-account-bank.service';
import { StateService } from '../../../../../../../core/services/state.service';
import { UserService } from '../../../../../../../core/services/user.service';
import { movementType } from '../../../../../../../shared/data/suscriptions-payments/constants';
import { ChargesWithdrawalsService } from '../../../../../../../core/services/charges-withdrawals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { CountryService } from '../../../../../../../core/services/country.service';
import { ROLES } from '../../../../../../../shared/data/config/constants';
import { UserMainService } from '../../../../../../user/services/user-main.service';

@Component({
  selector: 'app-list-lw-filters',
  templateUrl: './list-lw-filters.component.html',
  styleUrl: './list-lw-filters.component.scss',
})
export class ListLwFiltersComponent implements OnInit {
  public listLwFormFilter!: FormGroup;
  public optionsHolder$: any;
  public optionsAgents$: any;
  public optionsBanks$: any;
  public optionsState$: any;
  public optionsCountries$: any;
  public optionsCategory$: any;
  public optionsMovementType$: any;
  public createUser$: any;
  public id_country!: any;
  public currentPageAgents = 1;
  public currentPageCreatedBy = 1;
  public currentPageHolders = 1;
  public allAgentsData: any[] = [];
  public allHoldersData: any[] = [];
  public allCreatedBy: any[] = [];
  public pageSize = 10;
  public criteria: any;
  private filterSubject: Subject<string> = new Subject<string>();
  public showFilter: boolean = false;
  public selectedBankId: any;
  public transformedDataBank: any;
  public responseCreateUser: any;
  public id_agente: any;
  isMobile: boolean = false;
  token!: string;

  //LOGGEDSUSER
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  public ROLES = ROLES;
  //DATE
  //DATE
  public bsConfig = CONFIG_BASE_DATE_RANGEPICKER.bsConfig;
  public initialRange: Date[] = CONFIG_BASE_DATE_RANGEPICKER.initialRange;


  constructor(
    private fb: FormBuilder,
    private _userService: UserService,
    private _filterCommunicationService: FilterCommunicationService,
    private _holderAccountBank: HolderAccountBankService,
    private stateSevice: StateService,
    private lwService: ChargesWithdrawalsService,
    private router: Router,
    private activedRouter: ActivatedRoute,
    private store: Store,
    private _countryService: CountryService,
    private _userMainService: UserMainService
  ) {
    this.initForm();
    this.filterSubject
      .pipe(
        debounceTime(400) // Adjust debounce time as needed (e.g., 300 milliseconds)
      )
      .subscribe((term: any) => {
        this.allAgentsData = [];
        this.allCreatedBy = [];
        this.allHoldersData = [];
        this.loadAgents(term);
        this.getHoldersData(term);
      });
  }

  async ngOnInit(): Promise<void> {
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      await new Promise<void>((resolve, reject) => {
        this.activedRouter.params.subscribe((params) => {
          this.token = params['token'];
          this._userService.getUserByTokenApp(this.token).subscribe({
            next: (res) => {
              if (res.data.idusuario) {
                this.profile = res.data;
                this.id_agente = this.profile.idusuario;
                this.listLwFormFilter
                  .get('id_agente')
                  ?.setValue(this.profile.idusuario);
                resolve();
              } else {
                reject(new Error('No user id found'));
              }
            },
            error: (err) => {
              reject(err);
            },
          });
        });
      });
    } else {
      await new Promise<void>((resolve, reject) => {
        this.profile$.subscribe({
          next: (res) => {
            if (res.idusuario !== -1) {
              this.profile = res;
              if (this.profile.id_rol === 4) {
                this.listLwFormFilter
                  .get('id_agente')
                  ?.setValue(this.profile?.idusuario);
              } else {
                this.loadAgents();
              }
              resolve();
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    }
    this.getHoldersData();
    this.getStatesLw();
    this.getCategoriesLw();
    this.getCountries();
    this.sendFilter();
  }

  //?LOAD DATA
  loadAgents(searchTerm?: string) {
    this._userMainService
      .getAllReferredAndAgents(
        this.currentPageAgents,
        this.pageSize,
        searchTerm,
        this.id_country
      )
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: any; idusuario: any }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.optionsAgents$ = of(transformedData);
        this.allAgentsData = [...this.allAgentsData, ...transformedData];
      });
  }
  getCountries() {
    this._countryService.getAllCountries().subscribe((res) => {
      const transformedData = res.data.map(
        (item: { pais: number; id_pais: string }) => {
          return {
            label: item.pais,
            value: item.id_pais,
          };
        }
      );
      this.optionsCountries$ = of(transformedData);
    });
  }
  getHoldersData(searchTerm?: string) {
    this._userService
      .getHolders(this.currentPageHolders, this.pageSize, searchTerm)
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: string; idusuario: number }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.optionsHolder$ = of(transformedData);
        this.allHoldersData = [...this.allHoldersData, ...transformedData];
      });
  }
  getCategoriesLw() {
    this.lwService.getCategories().subscribe((response) => {
      const transformedData = response.data.map(
        (item: { categoria: any; idcare_categoria: any }) => {
          return { label: item.categoria, value: item.idcare_categoria };
        }
      );
      this.optionsCategory$ = of(transformedData);
    });
  }
  getStatesLw() {
    this.stateSevice.geatAllDataLw().subscribe((response) => {
      const transformedData = response.data.map(
        (item: { estado: any; n_estado: any }) => {
          return { label: item.estado, value: item.n_estado };
        }
      );
      this.optionsState$ = of(transformedData);
    });
  }

  //*NG-SELECT
  onScrollToEnd() {
    this.currentPageAgents++;
    this.loadAgents(this.criteria);
    this.currentPageHolders++;
    this.getHoldersData(this.criteria);
  }
  onSearch(term: string) {
    this.criteria = term;
    this.allAgentsData = [];
    this.allHoldersData = [];
    this.currentPageAgents = 1;
    this.currentPageHolders = 1;
    this.filterSubject.next(term);
  }

  //*TOGGLE
  toggleChart() {
    this.showFilter = !this.showFilter;
  }
  getShowFilterStyle(): { [key: string]: string } {
    return this.showFilter
      ? { overflow: 'visible', maxHeight: '600px' }
      : { overflow: 'hidden', maxHeight: '0' };
  }

  //*FORM
  initForm() {
    this.listLwFormFilter = this.fb.group({
      id_agente: [null],
      holder: [null],
      state: [null],
      dateRange: [this.initialRange],
      category: [null],
      amount: [null],
      country: [null],
      comentary: [null],
    });
  }

  sendFilter(): void {
    if (!this.listLwFormFilter.valid) return;

    const filterValues = this.listLwFormFilter?.value;
    const dateRange = filterValues?.dateRange;

    const formattedFilter = {
      date_start:
        dateRange && dateRange[0]
          ? moment(dateRange[0]).format('YYYY-MM-DD 00:00:00')
          : null,
      date_end:
        dateRange && dateRange[1]
          ? moment(dateRange[1]).format('YYYY-MM-DD 23:59:59')
          : null,
    };

    let filters = {
      id_agente:
        this.profile?.id_rol === ROLES.AGENT
          ? this.profile.idusuario
          : filterValues.id_agente,
      holder: filterValues.holder,
      state: filterValues.state,
      datestart: formattedFilter.date_start,
      dateend: formattedFilter.date_end,
      category: filterValues.category,
      amount: filterValues.amount,
      country: filterValues.country,
      comentary: filterValues.comentary,
    };
    this._filterCommunicationService.changeFilter(filters);
  }

  resetForm(): void {
    let clearFilter: any;
    this.listLwFormFilter.reset({
      id_agente:
        this.isMobile || this.profile?.id_rol === ROLES.AGENT
          ? this.profile?.idusuario
          : null,
      holder: null,
      state: null,
      dateRange: this.initialRange,
      category: null,
      amount: null,
      country: null,
      comentary: null,
    });

    clearFilter = {
      id_agente:
        this.isMobile || this.profile?.id_rol === ROLES.AGENT
          ? this.profile?.idusuario
          : null,
      holder: null,
      state: null,
      datestart: this.initialRange[0],
      dateend: this.initialRange[1],
      category: null,
      amount: null,
      country: null,
      comentary: null,
    };

    this._filterCommunicationService.changeFilter(clearFilter);
  }

  //*UTILS
  capitalizeName(name: string): string {
    return name
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
