<div class="card mb-2">
  <div class="card-header">
    <app-affiliate-filter></app-affiliate-filter>
  </div>
  <div class="card-body">
    <div id="area-spaline">
      <apx-chart
        [series]="splineArea1.series"
        [chart]="splineArea1.chart"
        [xaxis]="splineArea1.xaxis"
        [stroke]="splineArea1.stroke"
        [tooltip]="splineArea1.tooltip"
        [dataLabels]="splineArea1.dataLabels"
        [colors]="splineArea1.colors"
      >
      </apx-chart>
    </div>
  </div>
</div>
