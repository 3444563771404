import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiPathEnum, UserEndpointEnum } from '../constants/endpoints.enum';
import {
  User,
  UserResponse,
  VerifyResponse,
} from '../interfaces/user.interface';
import { ApiResponse } from '../interfaces/api-response.inteface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public API_URL = `${environment.API_URL}${ApiPathEnum.User}`;
  public API_URL_USER_LEVEL = `${environment.API_URL}${ApiPathEnum.UserLevel}`;
  API_URL_CODE_PHONE = `${environment.API_URL}${ApiPathEnum.CodePhone}`;
  USER_SESSION = 'USER_SESSION';
  USER_SESSION_PRE = 'USER_SESSION_PRE';

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  public getUsers(page: number, limit: number): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    return this.http.get<any>(`${this.API_URL}${UserEndpointEnum.GetAllUser}`, {
      params,
    });
  }

  public getAgents(
    id_pais: any,
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    if (id_pais) {
      params = params.set('id_pais', id_pais);
    }
    return this.http.get<any>(
      `${this.API_URL}${UserEndpointEnum.GetAllAgents}`,
      { params }
    );
  }

  public getRrhhUsers(
    page: number,
    limit: number,
    paymentType: string | null,
    salaryGroup: string | null,
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())

    if (paymentType) {
      params = params.set('paymentType', paymentType);
    }

    if (salaryGroup) {
      params = params.set('salaryGroup', salaryGroup);
    }

    return this.http.get<any>(
      `${this.API_URL}${UserEndpointEnum.GetRrhhUsers}`,
      { params }
    );
  }


  public getAgentName(filters?: any): Observable<any> {
    let params = new HttpParams();

    if (filters) {
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          params = params.set(key, filters[key]);
        }
      });
    }
    return this.http.get<any>(
      `${this.API_URL}${UserEndpointEnum.GetAllAgentNames}`,
      { params }
    );
  }

  public updateUserArrastre(
    userId: number,
    dragStatus: number
  ): Observable<any> {
    return this.http.patch<any>(
      `${this.API_URL}${UserEndpointEnum.ChangeDragStatus}`,
      {
        userId,
        dragStatus,
      }
    );
  }

  public getAffiliates(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    return this.http.get<any>(
      `${this.API_URL}${UserEndpointEnum.GetAllAffiliates}`,
      { params }
    );
  }

  public getHolders(
    page: number,
    limit: number,
    id_pais: any,
    searchTerm?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    if (id_pais) {
      params = params.set('id_pais', id_pais);
    }
    return this.http.get<any>(
      `${this.API_URL}${UserEndpointEnum.GetAllHolders}`,
      { params }
    );
  }

  public getUser(id: string): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL}${UserEndpointEnum.GetUserById}`,
      { id }
    );
  }
  public getUserByTokenApp(tokenApp: string): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL}${UserEndpointEnum.GetUserByTokenApp}`,
      { tokenApp }
    );
  }

  public getUserType(idusuario: number): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL_USER_LEVEL}${UserEndpointEnum.GetUserType}`,
      { idusuario }
    );
  }

  public twoFactorData(idusuario: string): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL}${UserEndpointEnum.GenerateTwoFactorData}`,
      { idusuario }
    );
  }

  public twoFactorAuth(idusuario: string, code: string): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL}${UserEndpointEnum.ActivateTwoFactorAuth}`,
      { idusuario, code }
    );
  }
  public twoDesactiveFactorAuth(idusuario: string): Observable<any> {
    return this.http.post<any>(
      `${this.API_URL}${UserEndpointEnum.DesactivateTwoFactorAuth}`,
      { idusuario }
    );
  }

  public verifyLogin(
    idusuario: number | string,
    code: string
  ): Observable<any> {
    return this.http
      .post<VerifyResponse>(`${this.API_URL}${UserEndpointEnum.VerifyLogin}`, {
        idusuario,
        code,
      })
      .pipe(
        tap((response) => {
          this.storageService.secureStorage.setItem(
            'jwt-token',
            response.data.token
          );
          this.storageService.secureStorage.setItem(
            this.USER_SESSION,
            JSON.stringify(response)
          );
        })
      );
  }

  public updateProfile(body: FormData): Observable<any> {
    return this.http.patch<User>(
      `${this.API_URL}${UserEndpointEnum.UpdateProfile}`,
      body
    );
  }

  public updatePassword(
    idusuario: string,
    newPassword: string
  ): Observable<User> {
    return this.http.put<User>(
      `${this.API_URL}${UserEndpointEnum.ChangePassword}`,
      {
        idusuario,
        newPassword,
      }
    );
  }

  public getAuthorizeUsers(financialId: string): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL}${UserEndpointEnum.GetAuthorizeUsers}/${financialId}`
    );
  }
  public getAgentIdByUsername(username: string): Observable<any> {
    return this.http.get<any>(
      `${this.API_URL}${UserEndpointEnum.GetAgentId}/${username}`
    );
  }

  public getUsersByType(
    type: number
  ): Observable<ApiResponse<Partial<User>[]>> {
    const endpoint = `${UserEndpointEnum.GetUsersByType}`;
    const url = `${this.API_URL}${endpoint}`;
    const params = new HttpParams().set('type', type.toString());
    return this.http.get<ApiResponse<Partial<User>[]>>(url, { params });
  }
  getAllReferredAndAgents(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    return this.http.get<any>(
      `${this.API_URL}${UserEndpointEnum.GetAllReferredAndAgents}`,
      { params }
    );
  }
  getAllCellPhoneCodes(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    return this.http.get<any>(
      `${this.API_URL_CODE_PHONE}${UserEndpointEnum.GetAllCellPhoneCodes}`,
      { params }
    );
  }
}
