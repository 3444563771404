import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiPathEnum, ClosureEnum } from '../constants/endpoints.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ClosureService {
  public API_URL = `${environment.API_URL}${ApiPathEnum.Closure}`;
  constructor(private http: HttpClient) {}
  reportDaily(
    startDate: Date,
    endDate: Date,
    page: number,
    limit: number,
    agents: []
  ): Observable<any> {
    return this.http.post(`${this.API_URL}${ClosureEnum.ReportDaily}`, {
      startDate,
      endDate,
      agents,
      page,
      limit,
    });
  }
  reportDailySpecific(
    startDate: Date,
    endDate: Date,
    page: number,
    limit: number,
    agents: [],
    countryId: number | null
  ): Observable<any> {
    return this.http.post(`${this.API_URL}${ClosureEnum.ReportDailySpecific}`, {
      startDate,
      endDate,
      agents,
      page,
      limit,
      countryId,
    });
  }
  getNote(id_usuario: string, fecha_asignado: string): Observable<any> {
    let params = new HttpParams()
      .set('id_usuario', id_usuario.toString())
      .set('fecha_asignado', fecha_asignado.toString());
    return this.http.get(`${this.API_URL}${ClosureEnum.GetNote}`, {
      params,
    });
  }
  updateNote(
    id_usuario: number,
    fecha_asignado: string,
    nota: string
  ): Observable<any> {
    return this.http.put(`${this.API_URL}${ClosureEnum.UpdateNote}`, {
      id_usuario,
      fecha_asignado,
      nota,
    });
  }
  createNote(
    id_usuario: number,
    fecha_asignado: string,
    nota: string,
    id_usuario_add: number,
    id_pais: number
  ): Observable<any> {
    return this.http.post(`${this.API_URL}${ClosureEnum.CreateNote}`, {
      id_usuario,
      fecha_asignado,
      nota,
      id_usuario_add,
      id_pais,
    });
  }
  getClosure(
    startDate: Date,
    endDate: Date,
    id_usuario: number
  ): Observable<any> {
    return this.http.post(`${this.API_URL}${ClosureEnum.GetClosure}`, {
      startDate,
      endDate,
      id_usuario,
    });
  }
  sendClosure(obj: {
    id_usuario: Number;
    id_pais: Number;
    fecha_envio: any;
    creado?: any;
    actualizado?: any;
    enviado: Number;
    origen_msj: string;
    mes_cierre: Number;
    anio_cierre: Number;
    valor_cierre: Number;
    id_usuario_add: Number;
    id_usuario_set?: Number;
  }): Observable<any> {
    return this.http.post(`${this.API_URL}${ClosureEnum.SendClosure}`, {
      ...obj,
    });
  }
}
