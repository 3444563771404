<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img
            class="img-fluid for-light"
            src="assets/images/logo/logo.png"
            alt=""
          />
          <img
            class="img-fluid for-dark"
            src="assets/images/logo/logo_dark.png"
            alt=""
          />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons
          [icon]="'align-center'"
          class="status_toggle middle"
        ></app-feather-icons>
      </div>
    </div>
    <div
      class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto"
    >
      <ul class="nav-menus">
        <li class="balance-nav" *ngIf="roleId == 4">
          <div class="balance-info">
            <button
              class="btn btn-sm btn-refresh me-3"
              [disabled]="isLoading"
              ngbTooltip="Refrescar Saldo"
              tooltipClass="custom-tooltip"
              (click)="reloadTable()"
            >
              <i class="fas fa-sync-alt"></i>
            </button>
            <div style="width: 100%; min-width: 75px">
              <span *ngIf="isLoading">
                <i class="fas fa-spinner fa-spin"></i>
              </span>
              <span
                *ngIf="!isLoading"
                tooltipClass="custom-tooltip"
                ngbTooltip="Bono Saldo"
              >
                {{ country === 1 ? "$" : "S/" }}
                {{
                  bonusBalanceData !== null && bonusBalanceData !== undefined
                    ? bonusBalanceData
                    : "0"
                }}
              </span>
            </div>
          </div>
        </li>
        <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>
        <li
          (click)="lockSystem()"
          ngbTooltip="Bloquear Sistema"
          tooltipClass="custom-tooltip"
          style="cursor: pointer;"
        >
          <i class="fas fa-lock" style="color: #53803d"></i>
        </li>
        <li>
          <div class="mode" (click)="layoutToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li>
        <!-- <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li> -->
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
