import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-font-awesome-icon',
  templateUrl: './font-awesome-icon.component.html',
  styleUrl: './font-awesome-icon.component.scss',
})
export class FontAwesomeIconComponent {
  @Input() icon!: string;
}
