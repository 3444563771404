import { Injectable } from '@angular/core';
declare const require: any;
const SecureStorage = require('secure-web-storage');

import CryptoJS from 'crypto-js';
const SECRET_KEY = `S0RT1365`;

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key: any) {
      key = CryptoJS.SHA256(key, { SECRET_KEY });
      return key.toString();
    },
    encrypt: function encrypt(data: any) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);
      data = data.toString();
      return data;
    },
    decrypt: function decrypt(data: any) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    },
  });
}
