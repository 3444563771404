<div class="container-fluid">
  <form
    class="theme-form mega-form"
    [formGroup]="bonusForm"
  >
    <div class="row g-3">
      <div class="col-md-6 mb-3 mt-2">
        <label class="col-form-label">Tipo de Bono *</label>
        <span class="text-muted">Selecciona un tipo de bono:</span>
        <div class="custom-select">
          <select class="form-control" formControlName="tipo_bono"  (change)="onSelectChange($event)">
            <option value="" disabled  selected>Seleccione una Opción</option>
            <option *ngFor="let option of allTypeBonusData" [value]="option.value">{{ option.label }}</option>
          </select>
          <div class="arrow"></div>
        </div>
        <div class="text-danger font-error mt-2" *ngIf="bonusForm.controls.tipo_bono!.touched && bonusForm.controls.tipo_bono.errors?.required">
          Selecciona un tipo de bono!.
        </div>
      </div>

      <div class="col-md-6">
        <label class="form-label" for="validationCustom01"
          >Monto Recarga *</label
        >
        <div class="input-group">
          <span class="input-group-text" id="inputGroupPrepend2"
              > {{ id_country === 1 ? '$' : 'S/.' }}</span
            >
          <input
            class="form-control"
            id="validationCustom01"
            type="text"
            placeholder="0.00"
            required=""
            (keypress)="onKeyPressSpecial($event)"
            (input)="onRechargeAmountChange($event)"
            formControlName="monto_recarga"
          />
        </div>
        @if (bonusForm.controls.monto_recarga!.touched &&
        bonusForm.controls.monto_recarga.errors?.required) {
        <div class="text-danger font-error mt-2">
          Ingresa un valor para crear el bono.
        </div>
        }
      </div>
    </div>
    <div class="row g-3">
      <div class="col-md-4">
        <label class="form-label" for="validationCustom03">Cuantos Bonos Deseas Generar *</label>
        <div class="input-group">
            <input
                class="form-control"
                type="number"
                formControlName="fracciones"
                required=""
                step="1"
                (input)="onInputChange($event)"
            />
        </div>
    </div>
    <div class="col-md-4">
      <label class="form-label" for="validationCustom03">Monto Por Bono Generado</label>
      <div class="input-group">
        <span class="input-group-text" id="inputGroupPrepend2">{{ id_country === 1 ? '$' : 'S/.' }}</span>
        <input
          class="form-control"
          type="text"
          placeholder="0.00"
          formControlName="monto"
          (keypress)="onKeyPressSpecial($event)"
          required
          readonly
        />
      </div>
    </div>
      <div class="col-md-4" *ngIf="idRol !== 4">
        <!-- <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="userTypeSwitch"
            (change)="toggleUserType()"
            [checked]="isAgent"
          />
        </div> -->
        <label class="form-check-label" for="userTypeSwitch">
          {{ isAgent ? 'Agente' : 'Jugador' }}
        </label>
        <ng-select
          [items]="isAgent ? allAgentsData : allPlayersData"
          bindLabel="label"
          bindValue="value"
          required
          formControlName="id_agente"
          class="mb-2"
          placeholder="Selecciona una opción"
          (scrollToEnd)="onScrollToEnd()"
          (search)="onSearch($event.term)"
          (clear)="onSearch('')"
          (open)="onSearch('')"
        ></ng-select>
        <div *ngIf="bonusForm.controls.id_agente.touched && bonusForm.controls.id_agente.errors?.required" class="text-danger font-error mt-2">
          Selecciona un {{ isAgent ? 'agente' : 'jugador' }}!.
        </div>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-md-12 mb-3">
        <label class="form-label" for="validationCustom04">Comentario</label>
        <textarea
          class="form-control"
          formControlName="comentario"
          rows="4"
        ></textarea>
        @if (bonusForm.controls.comentario!.touched &&
        bonusForm.controls.comentario.errors?.required) {
        <div class="text-danger font-error mt-2">
          Selecciona un comentario!.
        </div>
        }
      </div>
    </div>
    <div class="text-end">
      <button class="btn btn-cancel me-2" type="button" (click)="closeModal()">
        Cancelar
      </button>
      @if(isLoadingButton){
      <button class="btn btn-create" type="submit" disabled>
        <i class="fas fa-ticket-alt fa-spin fa-xs me-1"></i>Creando Bono
      </button>
      }@else {
      <button
        type="submit"
        class="btn btn-create"
        [disabled]="!isFormValid()"
        (click)=" sendFormData()"
      >
        Crear
      </button>
      }
    </div>
  </form>
</div>
