<div class="card">
  <div class="card-header">
    <h4 class="card-title mb-0">Configuración de Seguridad</h4>
  </div>
  <div class="card-body">
    <div class="row">
      @if (!isMobile) {
      <div class="col-md-6">
        <h5>Autenticación de dos factores</h5>
        <div class="mt-3">
          <p>
            Aumenta la seguridad de tu cuenta activando la Autenticación de Dos
            Factores
          </p>
          @if ((profile$ | async)?.TFA === 0) {
          <button class="primary-button" (click)="toggleQRCode()">
            Activar 2FA
          </button>
          }@else {
          <button
            class="mt-2 primary-button"
            (click)="twoDesactiveFactorAuth()"
          >
            Desactivar 2FA
          </button>
          }
        </div>
        @if(showQRCode){
        <div>
          <p class="mt-3 description">
            Escanea el código QR en tu aplicación e ingresa el código
          </p>
          @if (qrCodeImage) {
          <img [src]="qrCodeImage" alt="Código QR" class="img-qr" />
          }
          <form
            autocomplete="off"
            class="mt-3 form-qr needs-validation"
            novalidate=""
            [formGroup]="qrCodeForm"
            [class.was-validated]="!qrCodeForm.valid"
            (ngSubmit)="twoFactorAuth()"
          >
            <span class="label-form"> Ingresa el Código </span>
            <div class="form-group mt-2">
              <input
                type="text"
                class="form-control-sm text-center"
                required=""
                formControlName="twoFactorAuth"
                style="background-color: #eeeeee; border: none"
              />
              @if (qrCodeForm.controls.twoFactorAuth.touched &&
              qrCodeForm.controls.twoFactorAuth.errors?.['required']) {
              <div class="invalid-feedback">El código es requerido.</div>
              }
            </div>
            <button
              type="submit"
              class="primary-button btn-form mt-3"
              [disabled]="!qrCodeForm.valid"
            >
              <span>Habilitar</span>
            </button>
          </form>
        </div>
        }
      </div>
      }
      <div class="col-md-6">
        <h5>Cambio de contraseña</h5>
        <div class="change-pass-form">
          @if (!showNewPasswordInputs) {
          <form
            [formGroup]="autorizationForm"
            (ngSubmit)="onSubmitAutorization()"
          >
            <div class="mb-3">
              <label class="form-label">Ingrese su contraseña actual</label>
              <input
                class="form-control"
                type="password"
                [type]="showCurrentPassword ? 'text' : 'password'"
                required=""
                placeholder="********"
                formControlName="passwordVerify"
              />
              <button
                class="primary-button mt-2"
                [disabled]="!autorizationForm.controls.passwordVerify.valid"
              >
                Autorizar
              </button>
              @if (autorizationForm.controls.passwordVerify.touched &&
              autorizationForm.controls.passwordVerify.errors?.required) {
              <div class="invalid-feedback">Password is required.</div>
              }@if (!showCurrentPassword) {
              <div class="verify" (click)="showPasswordVerify()">
                <span><i class="fa-regular fa-eye-slash"></i></span>
              </div>
              } @if (showCurrentPassword) {
              <div class="verify" (click)="showPasswordVerify()">
                <span><i class="fa-regular fa-eye"></i></span>
              </div>
              }
            </div>
          </form>
          }@else {
          <form
            [formGroup]="changePassword"
            (ngSubmit)="onSubmitChangePass()"
            class="needs-validation"
            [class.passwords-not-matching]="!arePasswordsMatching()"
          >
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">Nueva Contraseña</label>
                  <input
                    class="form-control"
                    [type]="showNewPassword ? 'text' : 'password'"
                    type="password"
                    required=""
                    placeholder="********"
                    formControlName="password"
                  />
                  @if (changePassword.controls.password.touched &&
                  changePassword.controls.password.errors?.required) {
                  <div class="invalid-feedback">Password is required.</div>
                  } @if (!showNewPassword) {
                  <div class="verify-pass-new" (click)="showPassword()">
                    <span><i class="fa-regular fa-eye-slash"></i></span>
                  </div>
                  } @if (showNewPassword) {
                  <div class="verify-pass-new" (click)="showPassword()">
                    <span><i class="fa-regular fa-eye"></i></span>
                  </div>
                  }
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">Confirmar Contraseña</label>
                  <input
                    class="form-control"
                    [type]="showConfirmPassword ? 'text' : 'password'"
                    type="password"
                    required=""
                    placeholder="********"
                    formControlName="passwordConfirm"
                  />
                  @if (changePassword.controls.passwordConfirm.touched &&
                  changePassword.controls.passwordConfirm.errors?.required) {
                  <div class="invalid-feedback">Password is required.</div>
                  } @if (!showConfirmPassword) {
                  <div class="verify-pass-new" (click)="showPasswordConfirm()">
                    <span><i class="fa-regular fa-eye-slash"></i></span>
                  </div>
                  } @if (showConfirmPassword) {
                  <div class="verify-pass-new" (click)="showPasswordConfirm()">
                    <span><i class="fa-regular fa-eye"></i></span>
                  </div>
                  }
                </div>
              </div>
            </div>
            <div class="text-end">
              <button
                class="primary-button-cancel"
                style="margin-right: 10px"
                (click)="resetFormPass(changePassword)"
              >
                Cancelar
              </button>
              <button
                class="primary-button"
                title="Actualizar"
                type="submit"
                [disabled]="!changePassword.valid || !arePasswordsMatching()"
              >
                Actualizar
              </button>
            </div>
          </form>
          }
        </div>
      </div>
    </div>
  </div>
</div>
