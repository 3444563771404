<div class="container-fluid">
  @if (isLoading) {
  <div class="loader-box">
    <div class="loader-16"></div>
  </div>
  } @else {
  <div class="row">
    <div class="text-start mb-3">
      <img
        src="../../../../../../../../assets/images/logo/logo_sorti.png"
        alt=""
        srcset=""
      />
    </div>

    <div class="row">
      <!-- Columna izquierda con los detalles -->
      <div class="col-md-6">
        <div class="box-details">
          <div class="mb-4 text-center">
            <h6>Detalles de la Transacción</h6>
          </div>
          <div class="d-flex justify-content-between">
            <p>Fecha</p>
            <span
              ><p class="mb-0 special-text">
                {{ cwData.creado | date : "dd/MM/yyyy" }}
              </p></span
            >
          </div>
          <div class="d-flex justify-content-between">
            <p>Tipo</p>
            <span
              class="d-flex justify-content-center align-items-center bg-processing"
            >
              <i class="fa-solid fa-cash-register me-2"></i>
              {{ transactionTypes[cwData.tipo_transaccion].label }}
            </span>
          </div>
          <div class="d-flex justify-content-between">
            <p>Estado</p>
            <span
              class="text-success"
              [innerHTML]="getStateClasses(cwData.estado).text"
            ></span>
          </div>
          <div class="d-flex justify-content-between">
            <p>Monto</p>
            <span
              ><p class="mb-0 special-text">{{ cwData?.monto }}</p></span
            >
          </div>
        </div>

        <div class="box-details">
          <div class="mb-4 text-center">
            <h6>Detalles del Cliente</h6>
          </div>
          <div class="d-flex justify-content-between">
            <p>Cliente</p>
            <span
              ><p class="special-text">
                {{ cwData?.agent || cwData?.player }}
              </p></span
            >
          </div>
          <div class="d-flex justify-content-between">
            <p>País</p>
            <span
              ><p class="special-text">{{ cwData?.pais.pais }}</p></span
            >
          </div>
          <div class="d-flex justify-content-between">
            <p>Banco</p>
            <span
              ><p class="special-text">{{ cwData?.bank }}</p></span
            >
          </div>
          <div class="d-flex justify-content-between">
            <p>Comprobante</p>
            <span
              ><p class="special-text">
                {{ cwData?.comprobante }}
              </p></span
            >
          </div>
        </div>

        <div class="box-details">
          <div class="mb-4 text-center">
            <h6>Detalles de la Empresa</h6>
          </div>
          @if (cwData.comprobante) {
          <div class="d-flex justify-content-between">
            <p>Banco</p>
            <span
              ><p class="special-text">{{ cwData?.bank_enterprise }}</p></span
            >
          </div>
          <div class="d-flex justify-content-between">
            <p>Titular</p>
            <span
              ><p class="special-text">{{ cwData?.holder?.usuario }}</p></span
            >
          </div>
          @if (cwData?.comprobante_empresa) {
          <div class="d-flex justify-content-between">
            <p>Comprobante Empresa</p>
            <span
              ><p class="special-text">
                {{ cwData?.comprobante_empresa }}
              </p></span
            >
          </div>
          } }
        </div>
      </div>

      <!-- Columna derecha con la imagen -->
      <div class="col-md-6 text-center">
        @if (isLoading) {
        <div class="loader-box">
          <div class="loader-16"></div>
        </div>
        }@if (!isLoading) {
        <form class="theme-form mega-form">
          <div class="col-12 content-right">
            <div class="row">
              <div class="col-sm-12">
                <div class="drop-area-container">
                  <div
                    class="col-12 d-flex align-items-center justify-content-center"
                  >
                    <img
                      [src]="imgSrc"
                      alt="Imagen de solicitud"
                      class="img-fluid mt-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        }
      </div>
    </div>

    <div class="text-end mt-4">
      @if ([TRANSACTION_TYPE_VALUES.LOAD_BONUS_BALANCE,
      TRANSACTION_TYPE_VALUES.PAY_TO_DEBIT].includes(cwData.tipo_transaccion)) {
      <button
        type="button"
        class="btn btn-success me-2"
        (click)="
          setState(cwId, STATE_NAMES.PREVALIDATED_PROCESSED, userData.idusuario)
        "
        ngbTooltip="Procesado Prevalidado"
        tooltipClass="custom-tooltip"
      >
        <i class="fa-solid fa-check-double"></i>
      </button>
      <button
        type="button"
        class="btn btn-success"
        (click)="setState(cwId, STATE_NAMES.PROCESSED, userData.idusuario)"
        ngbTooltip="Procesado"
        tooltipClass="custom-tooltip"
      >
        <i class="fa-solid fa-check"></i>
      </button>
      }@else {
      <button
        type="button"
        class="btn btn-success me-2"
        (click)="setState(cwId, STATE_NAMES.BOT_REQUEST, userData.idusuario)"
        ngbTooltip="Solicitud Bot"
        tooltipClass="custom-tooltip"
      >
        <i class="fa-solid fa-check-double"></i>
      </button>
      <button
        type="button"
        class="btn btn-success"
        (click)="
          setState(cwId, STATE_NAMES.BOT_PENDING_SLIP, userData.idusuario)
        "
        ngbTooltip="Solicitud Bot (Pendiente Boleta)"
        tooltipClass="custom-tooltip"
      >
        <i class="fa-solid fa-check"></i>
      </button>
      }
    </div>
  </div>
  }
</div>
