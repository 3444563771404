<ul class="nav nav-tabs custom-tab">
  @for (tab of tabs; track $index) {
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      type="button"
      [ngClass]="{
        'active show': openTab == tab
      }"
      (click)="onClickTab(tab)"
    >
      {{ tab }}
    </button>
  </li>
  }
</ul>
