import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChargesWithdrawalsRequestService } from '../../../../../../../core/services/charges-withdrawals-request.service';
import { DynamicModalService } from '../../../../../../../core/services/dynamic-modal.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-divide-cw-request',
  templateUrl: './divide-cw-request.component.html',
  styleUrls: ['./divide-cw-request.component.scss']
})
export class DivideCwRequestComponent implements OnInit {

  public isLoading: boolean = false;
  public id: any;
  public cwDivideForm!: FormGroup;
  public cwData: any;
  public isLoadingButton = false;
  public amountAvailable: number = 0;
  public amountRemaining: number = 0;
  public isSubmitDisabled: boolean = true;

  constructor(
    private fb: FormBuilder,
    private _cwService: ChargesWithdrawalsRequestService,
    private _dynamicModal: DynamicModalService,
    private _toastrSvc: ToastrService,
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.id = this._dynamicModal.getModalData();
    this.initForm();
    this.getCwData();
  }

  getCwData() {
    this.isLoading = true;
    this._cwService.getCw(this.id).subscribe((response) => {
      this.cwData = response.data;
      this.amountAvailable = this.cwData.monto;
      this.amountRemaining = this.amountAvailable;
      this.isSubmitDisabled = this.amountRemaining !== 0 || !this.areInputsValid();
      this.isLoading = false;
    });
  }

  initForm(): void {
    this.cwDivideForm = this.fb.group({
      divisions: this.fb.array([])
    });
  }

  get divisionFormArray(): FormArray {
    return this.cwDivideForm.get('divisions') as FormArray;
  }

  addDivision(): void {
    if (this.amountRemaining <= 0) {
      this._toastrSvc.error('Limite de divisiones máximo alcanzado!')
      return;
    }

    const divisionGroup = this.fb.group({
      cantidad: [null, [Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]{1,2})?$')]]
    });
    this.divisionFormArray.push(divisionGroup);
    this.updateAmountRemaining();
  }

  deleteDivision(index: number): void {
    this.divisionFormArray.removeAt(index);
    this.updateAmountRemaining();
  }

  onDivisionInputChange(): void {
    this.updateAmountRemaining();
  }

  updateAmountRemaining(): void {
    let totalDivisionsAmount = 0;
    let inputsAreValid = true;

    this.divisionFormArray.controls.forEach((group) => {
      const amount = group.get('cantidad')?.value;
      if (amount && !isNaN(amount)) {
        const parsedAmount = parseFloat(amount);

        if (parsedAmount < 5) {
          inputsAreValid = false;
          this._toastrSvc.info('Cada cantidad debe de ser mínimo de $5.');
        }

        totalDivisionsAmount += parsedAmount;
      }
    });

    let calculatedRemaining = this.amountAvailable - totalDivisionsAmount;

    const precisionTolerance = 0.0000001;

    if (calculatedRemaining < -precisionTolerance) {
      inputsAreValid = false;
      this.amountRemaining = 0;
      this._toastrSvc.error('Una de las cantidades a dividir es incorrecta.');
    } else {
      if (Math.abs(calculatedRemaining) < precisionTolerance) {
        calculatedRemaining = 0;
      }
      this.amountRemaining = parseFloat(calculatedRemaining.toFixed(2));
    }
    this.isSubmitDisabled = this.amountRemaining !== 0 || !inputsAreValid;
  }

  areInputsValid(): boolean {
    return this.divisionFormArray.controls.every(group => {
      const cantidad = group.get('cantidad')?.value;
      return group.valid && cantidad && parseFloat(cantidad) <= this.amountAvailable;
    });
  }


  onSubmit(): void {
    this.isLoadingButton = true;
    if (this.cwDivideForm.valid) {
      const divisions = this.divisionFormArray.controls.map(control => parseFloat(control.value.cantidad));
      const requestData = {
        id: this.id,
        monto: divisions
      };

      this._cwService.createDivision(requestData).subscribe(
        (response) => {
          this.activeModal.close();
          this.isLoadingButton = false;
          // Aquí puedes añadir cualquier lógica adicional después de una respuesta exitosa
        },
        (error) => {
          this._toastrSvc.error('Error al crear la división');
          this.isLoadingButton = false;
          console.error(error);
        }
      );
    } else {
      this.isLoadingButton = false;
      this._toastrSvc.error('El formulario no es válido');
    }
  }
}
