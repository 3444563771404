<div class="modal-header" [ngClass]="options ? options.headerClass : ''">
  <h4 class="modal-title">
    {{ title | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <ng-container *ngComponentOutlet="component"></ng-container>
</div>
