import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../services/nav.service';
import { Store } from '@ngrx/store';
import { LanguageActions } from '../../../../../core/state/language/language.actions';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  public language: boolean = false;

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us',
    },
    {
      language: 'Español',
      code: 'es',
      icon: 'es',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr',
    },
    {
      language: 'Português',
      code: 'pt',
      type: 'BR',
      icon: 'pt',
    },
  ];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us',
  };

  constructor(
    public navServices: NavService,
    private translate: TranslateService,
    private store: Store
  ) {}
  //
  ngOnInit() {
    this.detectAndSetLanguage();
  }
  detectAndSetLanguage() {
    const userLang = navigator.language.split('-')[0];
    const userLanguage = this.languages.find((lang) => lang.code === userLang);
    if (userLanguage) {
      this.selectedLanguage = userLanguage;
      this.store.dispatch(
        LanguageActions['[Language]Change']({ language: userLanguage.code })
      );
    }
    this.translate.setDefaultLang(userLang);
    this.translate.use(userLang);
  }

  onchangeLanguage(lang: { code: string }) {
    const selectedCode = lang.code;
    const selectedLanguage = this.languages.find(
      (lang) => lang.code === selectedCode
    );
    if (selectedLanguage) {
      this.changeLanguage(selectedLanguage);
    }
  }
  changeLanguage(lang: any) {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
    this.store.dispatch(
      LanguageActions['[Language]Change']({ language: lang.code })
    );
  }
}
