export const columnsTableResult = [
  {
    name: 'Detalle Cargas y Pagos',
  },
  {
    name: 'Monto',
  },
];
export const columnsTableSumary = [
  {
    name: 'Comision Del Mes Actual',
    value: 'agente',
  },
  {
    name: 'Balance Anterior',
    value: 'balance_anterior',
  },
  {
    name: 'Balance Actual',
    value: 'balance_actual',
  },
  {
    name: 'Balance Final',
    value: 'balance_final',
  },
  {
    name: '%',
    value: 'porcentaje_comision',
  },
  {
    name: 'Comisión',
    value: 'comision',
  },
  {
    name: 'Comision Del Mes Actual',
    value: 'titulo',
  },
  {
    name: 'Comisión',
    value: 'monto',
  },
];
export const columnsTableDaily = [
  {
    name: 'COMENTARIO',
    value: '',
  },
  {
    name: 'FECHA',
    value: 'fecha',
  },
  {
    name: 'AGENTE',
    value: 'agente',
  },
  {
    name: 'CIERRE MES PASADO',
    value: 'cierre_mes_pasado',
  },
  {
    name: 'ABONOS',
    value: 'abonos',
  },
  {
    name: 'PAGOS',
    value: 'pagos',
  },
  {
    name: 'CARGAS',
    value: 'cargas',
  },
  {
    name: 'RETIROS',
    value: 'retiros',
  },
  {
    name: 'PUNTO fISICO',
    value: 'punto_fisico',
  },
  {
    name: 'BONO COMPRADO',
    value: 'bono_comprado',
  },
  {
    name: 'BONO SORTI',
    value: 'bono_comprado',
  },
  {
    name: 'BONO CARGADO',
    value: 'bono_cargado',
  },
  {
    name: 'TOTAL',
    value: '',
  },
];
