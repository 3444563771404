<form
  class="card needs-validation"
  [formGroup]="editProfile"
  [class.was-validated]="!editProfile.valid"
  novalidate=""
  (ngSubmit)="onSubmitProfile()"
>
  <div class="card-header">
    <h4 class="card-title mb-0">Edición de Perfil</h4>
    <div class="card-options">
      <a class="card-options-collapse" data-bs-toggle="card-collapse"
        ><i class="fe fe-chevron-up"></i></a
      ><a
        class="card-options-remove"
        href="javascript:void(0)"
        data-bs-toggle="card-remove"
        ><i class="fe fe-x"></i
      ></a>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6 col-md-12">
        <div class="mb-3">
          <label class="form-label">Nombres y Apellidos</label>
          <input
            class="form-control"
            type="text"
            required=""
            placeholder="First name and Last name"
            formControlName="names"
            onlyAlphabets
          />
          @if (editProfile.controls.names.touched &&
          editProfile.controls.names.errors?.['required']) {
          <div class="invalid-feedback">Nombres es requerido.</div>
          }
        </div>
      </div>
      <div class="col-sm-6 col-md-6">
        <div class="mb-3">
          <label class="form-label">Usuario</label>
          <input
            class="form-control"
            type="text"
            required=""
            placeholder="Username"
            formControlName="username"
          />
          @if (editProfile.controls.username.touched &&
          editProfile.controls.username.errors?.['required']) {
          <div class="invalid-feedback">Usuario es requerido.</div>
          }
        </div>
      </div>
      <div class="col-sm-6 col-md-6">
        <div class="mb-3">
          <label class="form-label">Dirección</label>
          <input
            class="form-control"
            type="text"
            required=""
            placeholder="Address"
            formControlName="address"
          />
          @if (editProfile.controls.address.touched &&
          editProfile.controls.address.errors?.['required']) {
          <div class="invalid-feedback">direccion es requerido.</div>
          }
        </div>
      </div>
      <div class="col-sm-6 col-md-6">
        <div class="mb-3">
          <label class="form-label">Celular</label>
          <input
            class="form-control"
            type="tel"
            placeholder="Phone"
            required=""
            formControlName="cellphone"
            inputmode="numeric"
            pattern="[0-9]*"
            (keypress)="onKeyPress($event)"
          />
          @if (editProfile.controls.cellphone.touched &&
          editProfile.controls.cellphone.errors?.['required']) {
          <div class="invalid-feedback">celular es requerido.</div>
          }
        </div>
      </div>
      <div class="col-sm-6 col-md-6">
        <div class="mb-3">
          <label class="form-label">Correo</label>
          <input
            class="form-control"
            type="email"
            required=""
            placeholder="app@yourmail.com"
            formControlName="email"
          />
          @if (editProfile.controls.email.touched &&
          editProfile.controls.email.errors?.['required']) {
          <div class="invalid-feedback">Correo es requerido.</div>
          } @if (editProfile.controls.email.touched &&
          editProfile.controls.email.errors?.email) {
          <div class="invalid-feedback">Invalid correo.</div>
          }
        </div>
      </div>
    </div>
  </div>
  <div
    class="card-footer text-end"
    style="padding-bottom: 28px; padding-top: 15px"
  >
    @if (profile?.id_rol === roles.MASTER) { @if (editProfile.enabled) {
    <button
      class="primary-button-cancel"
      style="margin-right: 10px"
      (click)="resetForm(editProfile)"
    >
      Cancelar
    </button>
    <button
      class="primary-button"
      title="Actualizar"
      type="submit"
      [disabled]="!editProfile.valid"
    >
      Actualizar
    </button>
    } @else {
    <button
      class="primary-button"
      title="Habilitar formulario"
      (click)="enableFormProfile()"
    >
      Actualizar
    </button>
    } }@else {
    <span
      >* Si deseas cambiar información personal contacta con
      <strong>Recursos Humanos</strong>.</span
    >
    }
  </div>
</form>
