import { Component } from '@angular/core';
import { SocketService } from '../../../../core/services/socket.service';

@Component({
  selector: 'app-list-sp',
  templateUrl: './list-sp.component.html',
  styleUrl: './list-sp.component.scss',
})
export class ListSpComponent {
  constructor(private socketService: SocketService) {
    this.socketService.joinRoom('abonos y pagos');
  }
  ngOnDestroy(): void {
    this.socketService.leaveRoom('abonos y pagos');
  }
}
