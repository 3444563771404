<form class="theme-form" [formGroup]="tfaForm" (ngSubmit)="onSubmit()">
  <h5>Autenticación De Dos Factores</h5>
  <div class="form-group">
    <label class="col-form-label">Ingresa tu código de seguridad</label>
    <input
      class="form-control"
      input
      id="TFA"
      autofocus
      class="form-control"
      placeholder="Code"
      formControlName="TFA"
      placeholder="*********"
    />
  </div>
  <div class="form-group mb-0 mt-2 d-flex justify-content-center">
    <button
      class="primary-button d-block"
      [disabled]="!tfaForm.valid"
      type="submit"
    >
      Enviar Código
    </button>
  </div>
</form>
