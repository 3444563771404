import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  ApiPathEnum,
  SubscriptionPaymentEndpointEnum,
} from '../constants/endpoints.enum';
import {
  ApiDataResponse,
  ApiResponse,
} from '../interfaces/api-response.inteface';
import {
  BankAccounts,
  ExpensesPerAccount,
  LatestExpenseMovements,
  ProfitPerAgent,
} from '../interfaces/dashboard.interface';
import { DateRange, PaginationParams } from '../interfaces/filters.interface';
import { SubscriptionPayment } from '../interfaces/subscription-payment.interface';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPaymentService {
  private API_URL = `${environment.API_URL}${ApiPathEnum.SubscriptionPayment}`;

  constructor(private readonly _httpClient: HttpClient) {}

  private mapApiResponseToSubscriptionPayment(
    res: ApiResponse<SubscriptionPayment[]>
  ): ApiResponse<SubscriptionPayment[]> {
    return {
      ...res,
      data: res.data.map((item) => ({
        ...item,
        monto:
          typeof item.monto === 'string' ? parseFloat(item.monto) : item.monto,
        parseMonto: Math.abs(item.monto),
      })),
    };
  }

  public getFinancialMovementSummary(
    dateRange: DateRange
  ): Observable<ApiResponse<SubscriptionPayment[]>> {
    const endpoint = `${SubscriptionPaymentEndpointEnum.GetFinancialMovementSummary}`;
    const url = `${this.API_URL}${endpoint}`;
    return this._httpClient
      .post<ApiResponse<SubscriptionPayment[]>>(url, { ...dateRange })
      .pipe(map(this.mapApiResponseToSubscriptionPayment));
  }

  public getIncomeAndExpenses(
    dateRange: DateRange
  ): Observable<ApiResponse<SubscriptionPayment[]>> {
    const endpoint = `${SubscriptionPaymentEndpointEnum.GetIncomeAndExpenses}`;
    const url = `${this.API_URL}${endpoint}`;
    return this._httpClient
      .post<ApiResponse<SubscriptionPayment[]>>(url, { ...dateRange })
      .pipe(map(this.mapApiResponseToSubscriptionPayment));
  }

  public getBankAccounts(): Observable<ApiResponse<BankAccounts[]>> {
    const endpoint = `${SubscriptionPaymentEndpointEnum.GetBankAccounts}`;
    const url = `${this.API_URL}${endpoint}`;
    return this._httpClient.post<ApiResponse<BankAccounts[]>>(url, {});
  }

  public getAgentTransactionsSummary(
    filters: PaginationParams & DateRange & { [key: string]: any }
  ): Observable<ApiResponse<{ data: ProfitPerAgent[]; totalCount: number }>> {
    const endpoint = `${SubscriptionPaymentEndpointEnum.GetAgentTransactionsSummary}`;
    const url = `${this.API_URL}${endpoint}`;
    return this._httpClient.post<
      ApiResponse<{ data: ProfitPerAgent[]; totalCount: number }>
    >(url, {
      ...filters,
    });
  }

  public getExpensesPerAccount(
    dateRange: DateRange
  ): Observable<ApiResponse<ExpensesPerAccount[]>> {
    const endpoint = `${SubscriptionPaymentEndpointEnum.GetExpensesByAccount}`;
    const url = `${this.API_URL}${endpoint}`;
    return this._httpClient.post<ApiResponse<ExpensesPerAccount[]>>(url, {
      ...dateRange,
    });
  }

  public getLatestExpenseMovements(
    filters: { [key: string]: any } = { limit: 10, page: 1 }
  ): Observable<
    ApiResponse<{ data: LatestExpenseMovements[]; totalCount: number }>
  > {
    const endpoint = `${SubscriptionPaymentEndpointEnum.GetLatestExpenseMovements}`;
    const url = `${this.API_URL}${endpoint}`;
    return this._httpClient
      .post<
        ApiResponse<{ data: LatestExpenseMovements[]; totalCount: number }>
      >(url, {
        ...filters,
      })
      .pipe(map(this.mapDates));
  }

  private mapDates(
    res: ApiResponse<{ data: LatestExpenseMovements[]; totalCount: number }>
  ): ApiResponse<{ data: LatestExpenseMovements[]; totalCount: number }> {
    const { data } = res;

    return {
      ...res,
      data: {
        totalCount: data.totalCount,
        data: data.data.map((item) => ({
          ...item,
          fecha: moment.utc(item.fecha).format('DD-MM-YYYY HH:mm:ss'),
        })),
      },
    };
  }

  getAllSp(page: number, limit: number, filters?: any): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params = params.set(key, filters[key]);
      }
    });

    return this._httpClient.get<any>(
      `${this.API_URL}${SubscriptionPaymentEndpointEnum.GetAllSp}`,
      { params }
    );
  }

  getSp(spId: number): Observable<any> {
    return this._httpClient.get<any>(
      `${this.API_URL}${SubscriptionPaymentEndpointEnum.GetOneSp}/${spId}`
    );
  }

  getUserEditSp(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    return this._httpClient.get<any>(
      `${this.API_URL}${SubscriptionPaymentEndpointEnum.GetUserEditSp}`,
      { params }
    );
  }

  downloadBalanceBonusData(): Observable<any> {
    return this._httpClient.get(
      `${this.API_URL}${SubscriptionPaymentEndpointEnum.DownloadSpData}`
    );
  }
  setState(
    idabonos_pagos: number,
    id_estado: number,
    usuario_updated_id: number
  ): Observable<any> {
    const body = {
      idabonos_pagos: idabonos_pagos,
      id_estado: id_estado,
      usuario_updated_id: usuario_updated_id,
    };
    return this._httpClient.patch(
      `${this.API_URL}${SubscriptionPaymentEndpointEnum.SetState}`,
      body
    );
  }

  updateItem(object: object): Observable<any> {
    return this._httpClient.patch(
      `${this.API_URL}${SubscriptionPaymentEndpointEnum.updateItem}`,
      object
    );
  }
}
