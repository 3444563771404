import { Directive, HostListener, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appCopyToClipboard]'
})
export class CopyToClipboardDirective {
  @Input('appCopyToClipboard') textToCopy: string = '';

  constructor(private toastr: ToastrService) {}

  @HostListener('click', ['$event'])
  onClick() {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData?.setData('text/plain', this.textToCopy);
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.toastr.success('Número copiado al portapapeles', 'Copiado');
  }
}
