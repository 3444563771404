<div class="card-body" [ngClass]="data?.colorClass">
  @if (loading) {
  <div class="loader-box">
    <div class="loader-16"></div>
  </div>
  }@else {
  <div>
    <div class="mb-3">
      <div
        ngbTooltip="{{ data?.title_1 }}"
        tooltipClass="custom-tooltip"
        class="d-flex align-items-center"
      >
        <span class="f-light">{{ data?.title_1 }}</span>
      </div>
      <div class="d-flex align-items-end gap-1">
        <h4>{{ data?.value_1 }}</h4>
      </div>
    </div>
    <div class="bg-gradient">
      <i class="fas fa-{{ data?.icon }} me-2"></i>
    </div>
  </div>
  }
</div>
