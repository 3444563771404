import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, Subject, debounceTime, of, map } from 'rxjs';
import { StateService } from '../../../../../../core/services/state.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../../../core/services/user.service';
import { PlayerService } from '../../../../../../core/services/player.service';
import { ChargesWithdrawalsRequestService } from '../../../../../../core/services/charges-withdrawals-request.service';
import { FilterCommunicationService } from '../../../../../../core/services/filter-communication.service';
import moment from 'moment';
import { CONFIG_BASE_DATE_RANGEPICKER } from '../../../../../../core/constants/constants';
import { CustomNgSelect } from '../../../../../../core/interfaces/components.interface';
import {
  CODE_BONUS_STATUS,
  stateBonus,
} from '../../../../../../shared/data/bonus/code-bonus/constants';
import { selectUser } from '../../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeBonusService } from '../../../../../../core/services/code-bonus.service';
import { CountryService } from '../../../../../../core/services/country.service';
import { ROLES } from '../../../../../../shared/data/config/constants';

@Component({
  selector: 'app-filter-bonus-code',
  templateUrl: './filter-bonus-code.component.html',
  styleUrl: './filter-bonus-code.component.scss',
})
export class FilterBonusCodeComponent implements OnInit {
  public bcFormFilter!: FormGroup;
  public isExpanded = false;
  public optionsAgents$: any;
  public optionsPlayers$: any;
  public optionsBonusState$: any;
  public optionsTypeBonus$: any;
  public optionsCountries$: any;
  public optionsCreateUser$: any;
  public countryId!: number;
  public allAgentsData: any[] = [];
  public allPlayersData: any[] = [];
  public allCountriesData: any[] = [];
  public allTypeBonusData: any[] = [];
  public allCreateUserData: any[] = [];
  public profile: any[] = [];
  public criteria: any;
  public currentPageAgents = 1;
  public currentPagePlayers = 1;
  public pageSize = 10;
  private holderSubject: Subject<string> = new Subject<string>();
  public isAgent = true;
  public country: number | null = null;
  public agentId!: number;
  public roleId: number | null = null;
  public isMobile: boolean = false;
  public token: string = '';
  public ROLES = ROLES;
  public CODE_BONUS_STATUS = CODE_BONUS_STATUS;

  // data picker
  @Output()
  public onFilter: EventEmitter<Record<string, unknown>> = new EventEmitter<
    Record<string, unknown>
  >();
  public accounts$: Observable<CustomNgSelect<number>[]> = of([]);
  @Output() clearFilter: EventEmitter<void> = new EventEmitter<void>();

  public minDate: Date = CONFIG_BASE_DATE_RANGEPICKER.minDate;
  public maxDate: Date = CONFIG_BASE_DATE_RANGEPICKER.maxDate;
  public bsConfig = CONFIG_BASE_DATE_RANGEPICKER.bsConfig;
  public initialRange: Date[] = [
    CONFIG_BASE_DATE_RANGEPICKER.minDate,
    CONFIG_BASE_DATE_RANGEPICKER.maxDate,
  ];
  public codeBonus$ = this.store.select(selectUser);

  constructor(
    private _stateService: StateService,
    private fb: FormBuilder,
    private _userService: UserService,
    private _playerService: PlayerService,
    private _filterCommunicationService: FilterCommunicationService,
    private cwService: ChargesWithdrawalsRequestService,
    private store: Store,
    private router: Router,
    private activedRouter: ActivatedRoute,
    private _bonusCodeService: CodeBonusService,
    private _countryService: CountryService
  ) {
    this.holderSubject
      .pipe(
        debounceTime(400) // Adjust debounce time as needed (e.g., 300 milliseconds)
      )
      .subscribe((term: any) => {
        this.allAgentsData = [];
        this.allPlayersData = [];
        if (this.isAgent) {
          this.loadAgents(term);
        } else {
          this.loadPlayers(term);
        }
      });
    // Verificación de si es móvil o no basado en la URL
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      this.activedRouter.params.subscribe((params) => {
        this.token = params['token'];
      });
      this._userService.getUserByTokenApp(this.token).subscribe({
        next: (res) => {
          if (res.data.idusuario) {
            this._userService.getUser(res.data.idusuario).subscribe({
              next: (res) => {
                const profile = res.data;
                this.profile = profile;
                this.roleId = profile.id_rol;
                this.agentId = profile.idusuario;
                this.country = profile.id_pais;
                this.loadDataBasedOnRole();
                this.getStateBonus();
              },
              error: (err) => {
                console.error('Error fetching user data', err);
              },
            });
          }
        },
        error: (err) => {
          console.error('Error fetching user by token', err);
        },
      });
    } else {
      this.codeBonus$.subscribe((profileData) => {
        this.roleId = profileData.id_rol;
        this.agentId = profileData.idusuario;
        this.country = profileData.id_pais;
        this.loadDataBasedOnRole();
        this.getStateBonus();
      });
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.getStateBonus();
    this.toggleExpand();
  }

  private loadDataBasedOnRole(): void {
    if (this.roleId !== ROLES.AGENT && this.roleId !== ROLES.UNDEFINED) {
      this.loadAgents();
      this.loadPlayers();
      this.getUserCreatedRequestCharge();
      this.getAllTypeBonus();
      this.getAllCountries();
    }
  }

  getStateBonus() {
    const filteredStateBonus =
      this.roleId !== ROLES.PERSONAL_SERVICE &&
      this.roleId !== ROLES.AGENT &&
      this.roleId !== ROLES.AFFILIATE &&
      this.roleId !== ROLES.SERVICE_BOSS
        ? stateBonus
        : stateBonus.filter(
            (state) => state.value !== CODE_BONUS_STATUS.DELETED.toString()
          );
    this.optionsBonusState$ = of(filteredStateBonus);
  }

  loadAgents(searchTerm?: string) {
    this._userService
      .getAgents(
        this.countryId,
        this.currentPageAgents,
        this.pageSize,
        searchTerm
      )
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: any; idusuario: any }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.optionsAgents$ = of(transformedData);
        this.allAgentsData = [...this.allAgentsData, ...transformedData];
      });
  }

  loadPlayers(searchTerm?: string) {
    this._playerService
      .getPlayers(
        this.countryId,
        this.currentPagePlayers,
        this.pageSize,
        searchTerm
      )
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario_jugador: any; id_jugador: any }) => {
            return { label: item.usuario_jugador, value: item.id_jugador };
          }
        );
        this.optionsPlayers$ = of(transformedData);
        this.allPlayersData = [...this.allPlayersData, ...transformedData];
      });
  }

  getUserCreatedRequestCharge(): void {
    this.cwService.getUserCreatedRegister().subscribe((response) => {
      this.allCreateUserData = response.data.map(
        (item: { usuario: any; idusuario: any }) => {
          return { label: item.usuario, value: item.idusuario };
        }
      );

      this.optionsCreateUser$ = of(this.allCreateUserData);
    });
  }

  getAllTypeBonus(): void {
    this._bonusCodeService.getAllTypeBonus().subscribe((response) => {
      this.allTypeBonusData = response.data.map((item: { detalle: string }) => {
        return { label: item.detalle, value: item.detalle };
      });

      this.optionsTypeBonus$ = of(this.allTypeBonusData);
    });
  }

  getAllCountries(searchTerm?: string): void {
    this._countryService
      .getAllCountriesByfilters(
        this.currentPageAgents,
        this.pageSize,
        searchTerm
      )
      .subscribe((response) => {
        this.allCountriesData = response.data.map(
          (item: { pais: any; id_pais: any }) => {
            return { label: item.pais, value: item.id_pais };
          }
        );

        this.optionsCountries$ = of(this.allCountriesData);
      });
  }

  //*UTILS
  capitalizeName(name: string): string {
    return name
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  //*NG-SELECT
  onScrollToEnd() {
    if (this.isAgent) {
      this.currentPageAgents++;
      if (this.isAgent) {
        this.loadAgents(this.criteria);
      } else {
        this.loadAgents();
      }
    } else {
      this.currentPagePlayers++;
      if (!this.isAgent) {
        this.loadPlayers(this.criteria);
      } else {
        this.loadPlayers();
      }
    }
  }

  onSearch(term: string) {
    this.criteria = term;
    this.allAgentsData = [];
    this.allPlayersData = [];
    this.currentPageAgents = 1;
    this.currentPagePlayers = 1;
    this.holderSubject.next(term);
  }

  onSelectionChange() {
    const controlName = this.isAgent ? 'agentId' : 'playerId';
    const oppositeControlName = this.isAgent ? 'playerId' : 'agentId';
    const selectedValue = this.bcFormFilter.get(controlName)?.value;

    // Establecer el valor del control seleccionado
    this.bcFormFilter.removeControl('personId');
    this.bcFormFilter.addControl(
      controlName,
      this.fb.control(selectedValue, Validators.required)
    );

    this.bcFormFilter.get(oppositeControlName)?.setValue(0);
  }

  //SWITCHES
  toggleSwitch() {
    const currentControlName = this.isAgent ? 'agentId' : 'playerId';
    const oppositeControlName = this.isAgent ? 'playerId' : 'agentId';

    this.bcFormFilter.get(currentControlName)?.reset();
    this.bcFormFilter.get(oppositeControlName)?.setValue(null);

    this.isAgent = !this.isAgent;
  }

  //*FORM
  initForm() {
    this.bcFormFilter = this.fb.group({
      dateRange: [this.initialRange],
      bonusState: [null],
      amount: [null],
      createdByUserId: [null],
      agentId: [null],
      playerId: [null],
      promotionalCode: [null],
      bonusType: [null],
      countryId: [null],
    });
  }

  sendFilter(): void {
    if (!this.bcFormFilter.valid) return;
    const filterValues = this.bcFormFilter?.value;
    const dateRange = filterValues?.dateRange;

    const formattedFilter = {
      date_star:
        dateRange && dateRange[0]
          ? moment(dateRange[0]).format('YYYY-MM-DD 00:00:00')
          : null,
      date_end:
        dateRange && dateRange[1]
          ? moment(dateRange[1]).format('YYYY-MM-DD 23:59:59')
          : null,
    };

    let filters = {
      bonusState: filterValues.bonusState,
      amount: filterValues.amount,
      createdByUserId: filterValues.createdByUserId,
      agentId: filterValues.agentId,
      playerId: filterValues.playerId,
      promotionalCode: filterValues.promotionalCode,
      bonusType: filterValues.bonusType,
      countryId: filterValues.countryId,
      date_star: formattedFilter.date_star,
      date_end: formattedFilter.date_end,
    };

    this._filterCommunicationService.changeFilter(filters);
  }

  formatDate(date: any, endOfDay: boolean): string | null {
    if (!date) return null;
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return endOfDay ? formattedDate + ' 23:59:59' : formattedDate + ' 00:00:00';
  }

  resetForm(): void {
    const clearFilter = this.bcFormFilter.reset({
      bonusState: null,
      amount: null,
      createdByUserId: null,
      agentId: null,
      playerId: null,
      promotionalCode: null,
      bonusType: null,
      countryId: null,
      dateRange: this.initialRange,
      dateUpdate: null,
    });
    this._filterCommunicationService.changeFilter(clearFilter);
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }
}
