import { Component, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAuth } from '../../../core/state/auth/auth.selector';
import { User } from '../../../core/interfaces/user.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../core/services/auth.service';
import { Router } from '@angular/router';
import { selectLanguage } from '../../../core/state/language/language.selectors';
import { ToastService } from 'angular-toastify';

@Component({
  selector: 'app-unlock-user',
  templateUrl: './unlock-user.component.html',
  styleUrls: ['./unlock-user.component.scss']
})
export class UnlockUserComponent implements OnInit {

  public show: boolean = false;
  public profile$ = this.store.select(selectAuth);
  public profile!: User;
  public username: string = '';
  public tfa!: number;
  public unlockForm!: FormGroup;
  public currentLanguage$ = this.store.select(selectLanguage);
  public errorMessage: string = '';

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    this.unlockForm = this.fb.group({
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario) {
        this.profile = profile;
        this.username = profile.usuario
        this.tfa = profile.TFA
      }
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    if (this.authService.isLocked()) {
      this.router.navigate(['/auth/unlock-user']);
    }
  }

  showPassword() {
    this.show = !this.show;
  }

  onSubmit() {
    const { password } = this.unlockForm.value;
    if (this.username && password) {
      this.authService.signIn(this.username, password).subscribe({
        next: (response) => {
          if (this.tfa === 1) {
            this.router.navigate([`/auth/two-factory-authentication`])
          } else {
            this.authService.unlock(); // Desbloquea al usuario
            this.router.navigate(['/']);
          }
        },
        error: (error) => {
          // Manejar el error
          console.error('Error during unlock:', error);
        }
      });
    }
  }
}
