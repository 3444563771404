import { Routes } from '@angular/router';
import { ComingSoonComponent } from '../components/coming-soon/coming-soon.component';
import { RoleGuard } from '../../core/guards/role.guard';
import { MAIN_PERMISSIONS } from '../data/config/constants';

export const content: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/default',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    // data: { permission: MAIN_PERMISSIONS.Dashboard },
    // canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'accounting',
    data: { permission: MAIN_PERMISSIONS.Contabilidad },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/accounting/accounting.module').then(
        (m) => m.AccountingModule
      ),
  },
    {
    path: 'accounting/account',
    data: { permission: MAIN_PERMISSIONS.Contabilidad },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/accounting/accounting.module').then(
        (m) => m.AccountingModule
      ),
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('../../pages/billing/billing.module').then((m) => m.BillingModule),
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('../../pages/customers/customers.module').then(
        (m) => m.CustomersModule
      ),
  },
  {
    path: 'suppliers',
    loadChildren: () =>
      import('../../pages/suppliers/suppliers.module').then(
        (m) => m.SuppliersModule
      ),
  },
  {
    path: 'products',
    loadChildren: () =>
      import('../../pages/products/products.module').then(
        (m) => m.ProductsModule
      ),
  },
  {
    path: 'audit',
    data: { permission: MAIN_PERMISSIONS.Auditoria },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/audit/audit.module').then((m) => m.AuditModule),
  },
  {
    path: 'debt-list',
    data: { permission: MAIN_PERMISSIONS.Deudas },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/debt-list/debt.module').then((m) => m.DebtModule),
  },
  {
    path: 'user',
    data: { permission: MAIN_PERMISSIONS.Perfil },
    // canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'rrhh',
    data: { permission: MAIN_PERMISSIONS.RRHH },
    // canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/rrhh/rrhh.module').then((m) => m.RRHHModule),
  },
  {
    path: 'balance',
    data: { permission: MAIN_PERMISSIONS.Balance },
    // canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/balance/balance.module').then((m) => m.BalanceModule),
  },
  {
    path: 'loads-withdrawals',
    data: { permission: MAIN_PERMISSIONS['Cargas y Retiros'] },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/loads-withdrawals/loads-withdrawals.module').then(
        (m) => m.LoadsWithdrawalsModule
      ),
  },
  {
    path: 'affiliate',
    data: { permission: MAIN_PERMISSIONS.Marketing },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/affiliate/affiliate.module').then(
        (m) => m.AffiliateModule
      ),
  },
  {
    path: 'bot',
    data: { permission: MAIN_PERMISSIONS.Bot },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/bot/bot.module').then((m) => m.BotModule),
  },
  {
    path: 'suscriptions-payments',
    data: { permission: MAIN_PERMISSIONS['Abonos y Pagos'] },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import(
        '../../pages/suscriptions-payments/suscriptions-payments.module'
      ).then((m) => m.SuscriptionsPaymentsModule),
  },
  {
    path: 'bonus',
    data: { permission: MAIN_PERMISSIONS.Bonos },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/bonus/bonus.module').then((m) => m.BonusModule),
  },
  {
    path: 'users',
    data: { permission: MAIN_PERMISSIONS.Usuarios },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'closure',
    data: { permission: MAIN_PERMISSIONS.Reporte },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'roles-and-permissions',
    data: { permission: MAIN_PERMISSIONS['Roles y Permisos'] },
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('../../pages/rules/rules.module').then((m) => m.RulesModule),
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
  },
];
