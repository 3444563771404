import { Component, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicModalService } from '../../../../../core/services/dynamic-modal.service';

@Component({
  selector: 'app-change-audit-modal',
  templateUrl: './change-audit-modal.component.html',
  styleUrls: ['./change-audit-modal.component.scss'],
})
export class ChangeAuditModalComponent implements OnInit {
  data: any;
  oldValueIsObject: boolean = false;
  newValueIsObject: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private dynamicModalService: DynamicModalService
  ) {}

  ngOnInit(): void {
    this.data = this.dynamicModalService.getModalData().data;
    this.showData();
    //this.dynamicModalService.closeModal();
  }

  showData() {
    try {
      this.data.newvalue = JSON.parse(this.data.newvalue);
    } catch (error) {}
    try {
      this.data.oldvalue = JSON.parse(this.data.oldvalue);
    } catch (error) {}
    this.newValueIsObject =
      typeof this.data.newvalue === 'object' && this.data.newvalue !== null;
    this.oldValueIsObject =
      typeof this.data.oldvalue === 'object' && this.data.oldvalue !== null;
  }
}
