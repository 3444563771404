import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlError]',
})
export class FormControlErrorDirective implements OnInit {
  @Input() errorMessage: string = 'Invalid input';
  @Input() invalidClass: string = 'is-invalid';
  @Input() validClass: string = 'is-valid';

  private control!: FormControl;
  private errorElement!: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private ngControl: NgControl
  ) {}

  ngOnInit() {
    this.control = this.ngControl.control as FormControl;

    // Create error message element
    this.errorElement = this.renderer.createElement('span');
    this.renderer.setStyle(this.errorElement, 'color', 'red');
    this.renderer.setStyle(this.errorElement, 'display', 'none');
    this.renderer.setProperty(
      this.errorElement,
      'textContent',
      this.errorMessage
    );
    this.renderer.appendChild(
      this.el.nativeElement.parentNode,
      this.errorElement
    );

    // Subscribe to control value changes to toggle error message and classes
    this.control.statusChanges.subscribe((value) => {
      this.updateControlClassesAndError();
    });

    // Initial class and error update
    this.updateControlClassesAndError();
  }

  private updateControlClassesAndError() {
    if (this.control.invalid && (this.control.dirty || this.control.touched)) {
      this.renderer.addClass(this.el.nativeElement, this.invalidClass);
      this.renderer.removeClass(this.el.nativeElement, this.validClass);
      this.renderer.setStyle(this.errorElement, 'display', 'block');
    } else if (
      this.control.valid &&
      (this.control.dirty || this.control.touched)
    ) {
      this.renderer.removeClass(this.el.nativeElement, this.invalidClass);
      this.renderer.addClass(this.el.nativeElement, this.validClass);
      this.renderer.setStyle(this.errorElement, 'display', 'none');
    } else {
      this.renderer.removeClass(this.el.nativeElement, this.invalidClass);
      this.renderer.removeClass(this.el.nativeElement, this.validClass);
      this.renderer.setStyle(this.errorElement, 'display', 'none');
    }
  }
}
