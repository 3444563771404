<div class="container-fluid">
  @if (isLoading) {
  <div class="loader-box">
    <div class="loader-16"></div>
  </div>
  }@if (!isLoading) {
  <form class="theme-form mega-form">
    <div class="col-12 content-right">
      <div class="row">
        <div class="col-sm-12">
          <div class="drop-area-container mt-3">
            <div
              #imageContainer
              class="col-12 d-flex align-items-center justify-content-center"
            >
              <img
                [src]="imgSrc"
                alt="Imagen de solicitud"
                class="img-fluid mt-3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-4 mb-4" />
    <div class="text-end">
      <button type="button" class="btn btn-cancel me-1" (click)="closeModal()">
        Cerrar
      </button>
      <button type="submit" class="btn btn-create" (click)="downloadImage()">
        Descargar
      </button>
    </div>
  </form>
  }
</div>
