<div class="container-fluid">
  @if (isLoading) {
  <div class="loader-box">
    <div class="loader-16"></div>
  </div>
  }@else {
  <div class="row">
    <div class="text-end mb-3">
      <img
        src="../../../../../../../../assets/images/logo/logo_sorti.png"
        alt=""
        srcset=""
      />
    </div>

    <div class="box-details">
      <div class="mb-4 text-center">
        <h6>Detalles de la Transacción</h6>
      </div>
      <div class="d-flex justify-content-between">
        <p>Fecha</p>
        <span
          ><p class="mb-0 special-text">{{ cwData.creado }}</p></span
        >
      </div>
      <div class="d-flex justify-content-between">
        <p>Tipo</p>
        <span
          class="d-flex justify-content-center align-items-center"
          [ngClass]="getTransactionClass(cwData.tipo_transaccion)"
          ><i
            [ngClass]="getTransactionIconClass(cwData.tipo_transaccion)"
            class="me-2"
          ></i
          >{{ transactionTypes[cwData.tipo_transaccion].label }}</span
        >
      </div>
      <div class="d-flex justify-content-between">
        <p>Estado</p>
        <span
          [ngClass]="getStateClasses(cwData.estado).classes"
          [innerHTML]="getStateClasses(cwData.estado).text"
        ></span>
      </div>
      <div class="d-flex justify-content-between">
        <p>Monto</p>
        <span>
          <p class="mb-0 special-text">{{ cwData?.monto }}</p>
        </span>
      </div>
    </div>

    <div class="box-details">
      <div class="mb-4 text-center">
        <h6>Detalles del Cliente</h6>
      </div>
      <div class="d-flex justify-content-between">
        <p>Cliente</p>
        <span>
          <p class="special-text">{{ cwData?.agent || cwData?.player }}</p>
        </span>
      </div>
      <div class="d-flex justify-content-between">
        <p>Identificación</p>
        <span>
          <p class="special-text">{{ cwData?.identificacion }}</p>
        </span>
      </div>
      <div class="d-flex justify-content-between">
        <p>País</p>
        <span>
          <p class="special-text">{{ cwData?.pais.pais }}</p>
        </span>
      </div>
      <div class="d-flex justify-content-between">
        <p>Banco</p>
        <span>
          <p class="special-text">{{ cwData?.bank }}</p>
        </span>
      </div>
      <div class="d-flex justify-content-between">
        <p>Tipo de Cuenta</p>
        <span>
          <p class="special-text">
            {{ getAccountTypeLabel(cwData?.tipo_cuenta) }}
          </p>
        </span>
      </div>
      <div class="d-flex justify-content-between">
        <p>Cuenta Bancaria</p>
        <span>
          <p class="special-text">{{ cwData?.numero_cuenta }}</p>
        </span>
      </div>
    </div>

    <div class="box-details">
      <div class="mb-4 text-center">
        <h6>Detalles de la empresa</h6>
      </div>
      <div class="d-flex justify-content-between">
        <p>Responsable</p>
        <span class="text-end">
          <div class="d-flex align-items-center">
            <img
              class="img-20 me-2 rounded-circle"
              [src]="previewImage"
              alt=""
              data-original-title=""
              title=""
            />
            <div>
              <p class="mb-0">{{ userData.usuario }}</p>
            </div>
          </div>
        </span>
      </div>
      @if (cwData.comprobante) {
      <div class="d-flex justify-content-between">
        <p>Banco</p>
        <span>
          <p class="special-text">{{ cwData?.bank_enterprise }}</p>
        </span>
      </div>
      <div class="d-flex justify-content-between">
        <p>Comprobante</p>
        <span>
          <p class="special-text">{{ cwData?.comprobante_empresa }}</p>
        </span>
      </div>
      }
    </div>

    <div class="text-end">
      <button
        type="button"
        class="primary-button-cancel me-1"
        (click)="closeModal()"
      >
        Cancelar
      </button>
      <button type="button" class="primary-button" (click)="validatePayment()" [disabled]="isPaymentButtonDisabled">
        Pagar
      </button>
    </div>
  </div>
  }
</div>
