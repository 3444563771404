<div class="card mt-5">
  @if (!isMobile) {
  <div class="card-header">
    <h4 class="card-title mb-0">Mi Perfil</h4>
    <div class="card-options">
      <a
        class="card-options-collapse"
        href="javascript:void(0)"
        data-bs-toggle="card-collapse"
      >
        <i class="fe fe-chevron-up"></i>
      </a>
      <a
        class="card-options-remove"
        href="javascript:void(0)"
        data-bs-toggle="card-remove"
      >
        <i class="fe fe-x"></i>
      </a>
    </div>
  </div>
  }
  <div class="card-body">
    <div class="row mb-2">
      <div
        class="col-auto position-relative"
        (mouseenter)="showCameraIcon = true"
        (mouseleave)="showCameraIcon = false"
      >
        @if (profile?.foto_selfie) {
        <img
          [src]="previewImage"
          alt="Vista Previa"
          class="img-70 rounded-circle fixed-size"
        />
        } @else {
        <img
          class="img-70 rounded-circle"
          alt=""
          src="assets/images/no-image.jpg"
        />
        } @if (showCameraIcon) {
        <i class="fas fa-camera camera-icon" (click)="triggerFileInput()"></i>
        }
        <input
          type="file"
          #fileInput
          class="d-none"
          (change)="onFileSelected($event)"
        />
      </div>
      <div class="col">
        <h3 class="mb-1">{{ profile?.usuario }}</h3>
        <p class="mb-4">{{ profile?.rol.nombre_rol }}</p>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label"><strong>Nombre y Apellido</strong></label>
      <p>{{ profile?.nombres }}</p>
    </div>
    <div class="mb-3">
      <label class="form-label"><strong>Correo</strong></label>
      <p>{{ profile?.correo }}</p>
    </div>
    <div class="mb-3">
      <label class="form-label"><strong>Teléfono</strong></label>
      <p>{{ profile?.celular }}</p>
    </div>
  </div>
</div>
