import { Component, OnInit } from '@angular/core';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../../../../core/services/auth.service';
import { UserService } from '../../../../../../../core/services/user.service';
import { AuthActions } from '../../../../../../../core/state/auth/auth.actions';
import Swal from 'sweetalert2';
import { DynamicModalService } from '../../../../../../../core/services/dynamic-modal.service';
import { TfaFormComponent } from '../../forms/tfa-form/tfa-form.component';
import { BootstrapModalService } from '../../../../../../../core/services/boostrap-modal.service';
import { BootstrapModalConfig } from '../../../../../../../core/interfaces/IBootstrapModal.interface';
import { ROLES } from '../../../../../../../shared/data/config/constants';

@Component({
  selector: 'app-security-settings',
  templateUrl: './security-settings.component.html',
  styleUrl: './security-settings.component.scss'
})
export class SecuritySettingsComponent implements OnInit {

  public profile$ = this.store.select(selectAuth);
  public profile: any;
  public autorizationForm!: FormGroup;
  public changePassword!: FormGroup;
  public qrCodeForm!: FormGroup;
  public qrCodeImage!: string;
  public showCurrentPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public showNewPasswordInputs: boolean = false;
  public showQRCode = false;
  public isMobile: boolean = false;
  public tokenExists!: string;
  public roles = ROLES;

  constructor(
    private store: Store,
    private _userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private activedRouter: ActivatedRoute,
    private router: Router,
    private _bsService: BootstrapModalService
  ) {
    this.changePassword = this.formBuilder.group({
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required],
    });
    this.autorizationForm = this.formBuilder.group({
      passwordVerify: ['', Validators.required],
    });
    this.qrCodeForm = this.formBuilder.group({
      twoFactorAuth: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const fullUrl = this.router.url;

    // Verifica si la URL contiene 'mobile'
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      this.loadUserProfileForMobile();
    } else {
      this.loadUserProfile();
    }
  }

  loadUserProfileForMobile(): void {
    this.activedRouter.params.subscribe((params) => {
      this.tokenExists = params['token'];
      this._userService.getUserByTokenApp(this.tokenExists).subscribe({
        next: (res) => {
          if (res.data.idusuario) {
            this._userService.getUser(res.data.idusuario).subscribe({
              next: (res) => {
                const profile = res.data;
                this.profile = profile;
              },
              error: (err) => {
                // Manejo de errores
              },
            });
          }
        },
        error: (err) => {
          // Manejo de errores
        },
      });
    });
  }

  loadUserProfile(): void {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario !== -1) {
        this.profile = profile;
      }
    });
  }


  showPasswordVerify() {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  showPassword() {
    this.showNewPassword = !this.showNewPassword;
  }

  showPasswordConfirm() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  resetFormPass(changePassword: FormGroup) {
    this.showNewPasswordInputs = false;
    this.autorizationForm.reset();
    changePassword.reset();
  }

  arePasswordsMatching(): boolean {
    const newPassword = this.changePassword.get('password')?.value;
    const confirmNewPassword =
      this.changePassword.get('passwordConfirm')?.value;
    return newPassword === confirmNewPassword;
  }

  onSubmitAutorization() {
    const username = this.profile.usuario;
    const password = this.autorizationForm.controls.passwordVerify.value;
    this.authService.signIn(username, password).subscribe(
      (response) => {
        this.showNewPasswordInputs = true;
      },
      (error) => {
        this.toastr.error('Contraseña incorrecta');
      }
    );
  }

  tfaModal() {
    const modalConfig: BootstrapModalConfig = {
      title: 'Verificación de dos factores',
      options: { size: 'lg', centered: false },
      component: TfaFormComponent,
    };
    this._bsService.openModal(
      modalConfig
    );

    const subscription = this._bsService.getModalClosedListener().subscribe(() => {
      this._bsService.getDataIssued().subscribe((data) => {
        const isCorrect = data.isVerify;
        if (isCorrect) {
          this.updatePassword();
        } else {
          this.toastr.error('El código de verificación debe ser ingresado para realizar el cambio de contraseña!');
        }
        subscription.unsubscribe();
      });
    });
  }

  onSubmitChangePass() {
    if (this.arePasswordsMatching()) {
      this.updatePassword();
      // if (this.profile.id_rol === ROLES.AGENT){
      //   this.updatePassword();
      // }
      // if(this.profile.TFA === 1){
      //   this.tfaModal();
      // }else{
      //   this.toastr.error('La autenticacion de dos factores es obligatoria para realizar el cambio de contraseña');
      // }
    } else {
      this.toastr.error('Las contraseñas no coinciden');
    }
  }

  updatePassword() {
    const idusuario = this.profile.idusuario;
    const newPassword = this.changePassword.controls.password.value;
    this._userService.updatePassword(idusuario, newPassword).subscribe(
      (response) => {
        this.showNewPasswordInputs = false;
        this.toastr.success('Constraseña cambiada correctamente, vuelva a ingresar!');
        setTimeout(() => {
          this.store.dispatch(AuthActions['[Auth]Logout']());
          window.location.reload();
        }, 1000);
      },
      (error) => {
        this.toastr.error(error.error.message.es);
      }
    );
  }

  //*2FA
  toggleQRCode() {
    this.showQRCode = !this.showQRCode;
    this.showQRCode ? this.generateQRCode() : this.qrCodeImage = '';
  }

  generateQRCode() {
    const idusuario = this.profile.idusuario;
    this._userService.twoFactorData(idusuario).subscribe({
      next: (response) => {
        this.qrCodeImage = response.data.qrCodeImage;
      },
      error: (error) => {
        console.error('Error al obtener el código QR:', error);
      },
    });
  }

  twoDesactiveFactorAuth() {
    Swal.fire({
      title: 'Autenticación de dos factores',
      text: ' ¿Estás seguro de que quieres desactivarlo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8DB555',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.store.dispatch(
          AuthActions['[Auth]DesactiveTwoFactorAuth']({
            _id: this.profile.idusuario,
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    });
  }

  twoFactorAuth() {
    Swal.fire({
      title: 'Autenticación de dos factores',
      text: ' ¿Estás seguro de que quieres activarlo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8DB555',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.store.dispatch(
          AuthActions['[Auth]TwoFactorAuth']({
            _id: this.profile.idusuario,
            twoFactorAuth: this.qrCodeForm.value.twoFactorAuth,
          })
        );
        setTimeout(() => {
          this.store.dispatch(AuthActions['[Auth]Logout']());
          window.location.reload();
        }, 1000);
      }
    });
  }

}
