import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BootstrapModalService } from '../../../../../../core/services/boostrap-modal.service';
import { CodeBonusInterface } from '../../../../../../core/interfaces/bonus-code.interface';
import { CodeBonusService } from '../../../../../../core/services/code-bonus.service';
import { SocketService } from '../../../../../../core/services/socket.service';

@Component({
  selector: 'app-modal-edit-state-bc',
  template: `
    <p class="my-0">¿Quiere cambiar el estado a bono enviado?</p>
    <br />
    <div class="d-flex justify-content-end align-items-center gap-2 mt-2">
      <button
        type="button"
        class="btn btn-primary text-white"
        (click)="stateBonusCode()"
      >
        Si
      </button>
      <button
        type="button"
        class="btn btn-danger text-white"
        (click)="closeModal()"
      >
        No
      </button>
    </div>
  `,
})
export class ModalEditStateBcComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  private _selectedBonusCode: CodeBonusInterface | undefined = undefined;
  public stateBonus: number = 2;

  constructor(
    private _toastr: ToastrService,
    private _bsModalService: BootstrapModalService,
    private bonusCodeService: CodeBonusService
  ) {}

  ngOnInit(): void {
    this.getModalData();
  }

  getModalData(): void {
    this._subscriptions.push(
      this._bsModalService.getDataIssued().subscribe(
        (response) => (this._selectedBonusCode = response.bonusCode),
        (error) => this.handleError(error)
      )
    );
  }

  stateBonusCode(): void {
    if (!this._selectedBonusCode || !this._selectedBonusCode.idbonos) return;
    const bonus = {
      idbonos: this._selectedBonusCode.idbonos,
      estado: this.stateBonus,
    };
    this._subscriptions.push(
      this.bonusCodeService.updateStateBonusCode(bonus).subscribe({
        next: (response) => {
          this.handleSuccess(response.message);
          this.closeModal();
        },
        error: (error) => this.handleError(error),
      })
    );
  }

  closeModal(): void {
    this._bsModalService.updateModalClosed(true);
  }

  private handleError(error: any): void {
    this._toastr.error('Internal server error', 'Error');
  }

  private handleSuccess(message: { en: string; es: string }): void {
    this._toastr.success(message.en, 'Success');
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
