import {
  AfterViewInit,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { ContentBodyBasicAccordionDirective } from '../../../directives/content-body-basic-accordion.directive';

@Component({
  selector: 'shared-basic-accordion',
  templateUrl: './basic-accordion.component.html',
  styleUrl: './basic-accordion.component.scss',
})
export class BasicAccordionComponent implements AfterViewInit {
  @ContentChild(ContentBodyBasicAccordionDirective, { read: TemplateRef })
  public contentBodyTemplate?: TemplateRef<unknown>;

  @Input()
  public headerTitle: string = 'Accordion';

  @Input()
  public appearOpen: boolean = true;

  @ViewChild('accordion') accordion: NgbAccordionDirective | undefined;

  ngAfterViewInit(): void {
    if (this.accordion && this.appearOpen) {
      this.accordion.toggle('first');
    }
  }
}
