import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastService } from 'angular-toastify';
import { UserService } from '../../../core/services/user.service';
import { AuthActions } from '../../../core/state/auth/auth.actions';
import { selectPreAuth } from '../../../core/state/auth/auth.selector';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-two-factory-authentication',
  templateUrl: './two-factory-authentication.component.html',
  styleUrl: './two-factory-authentication.component.scss',
})
export class TwoFactoryAuthenticationComponent implements OnInit {
  public profile$ = this.store.select(selectPreAuth);
  public tfaForm: FormGroup;
  public profile: any;
  public id: string = '';
  public isgood: boolean = false;

  constructor(
    private toast: ToastService,
    private fb: FormBuilder,
    private store: Store,
    private _userService: UserService,
    private router: Router,
    private _authService: AuthService,
  ) {
    this.tfaForm = this.fb.group({
      TFA: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario) {
        this.id = profile.idusuario;
      }
    });
  }

  onSubmit() {
    const twoFactorAuth = this.tfaForm.value;
    this._userService.verifyLogin(this.id, twoFactorAuth.TFA).subscribe({
      next: (response) => {
        this.isgood = true;
        this._userService.getUser(response.data.idusuario).subscribe({
          next: (response) => {
            this._authService.unlock();
            this.store.dispatch(
              AuthActions['[Auth]LoginSuccess'](response.data)
            );
            this.router.navigate(['']);
            this.toast.default(`Bienvenido, ${response.data.usuario}`);
          },
          error: (error) => {},
        });
      }
    });
  }

  backLogin() {
    this._authService.logOut();
  }
}
