import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import moment from 'moment';
import { FormObject } from '../../../core/interfaces/dynamic-form.interface';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
})
export class FilterComponent implements OnChanges {
  @Input() data: FormObject[] = [];
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() scrollEnd: EventEmitter<any> = new EventEmitter<any>();
  showFilterForm: boolean = false;
  public today = moment();
  public tomorrow = moment().startOf('day').add(1, 'day');
  public startDate = moment().subtract(1, 'month').startOf('month');
  public endDate = moment().startOf('month');
  public dateSelected = `${this.startDate.format(
    'YYYY/MM/DD'
  )} - ${this.endDate.format('YYYY/MM/DD')}`;

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('asd');
    // console.log(this.data);
  }
  toggleFilterForm() {
    this.showFilterForm = !this.showFilterForm;
  }
  getFilterFormStyle(): { [key: string]: string } {
    return this.showFilterForm
      ? { overflow: 'visible', maxHeight: '600px' }
      : { overflow: 'hidden', maxHeight: '0' };
  }

  onFormSubmit(data: any) {
    this.formSubmit.emit(data);
  }
  onScrollEnd(even: any) {
    this.scrollEnd.emit('scrollEnd');
  }
}
