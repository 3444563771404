<div class="custom-pagination-container">
  <select
    class="form-select"
    style="width: auto"
    name="pageSize"
    [(ngModel)]="pageSize"
    (ngModelChange)="onPageSizeChange($event)"
  >
    @for (option of paginationOptions; track $index) {
    <option [value]="option">{{ option }} items por página</option>
    }
  </select>

  <ngb-pagination
    class="d-flex justify-content-center"
    [(page)]="page"
    [collectionSize]="collectionSize"
    [pageSize]="pageSize"
    [boundaryLinks]="true"
    (pageChange)="onPageChange($event)"
  >
    <ng-template ngbPaginationPages let-page let-pages="pages">
      @if (pages.length > 0) {
      <li class="ngb-custom-pages-item">
        <div class="d-flex flex-nowrap px-2">
          <label
            id="paginationInputLabel"
            for="paginationInput"
            class="col-form-label me-2 ms-1"
            >Página</label
          >
          <input
            #i
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            class="form-control custom-pages-input"
            id="paginationInput"
            [value]="page"
            (keyup.enter)="selectPage(i.value)"
            (blur)="selectPage(i.value)"
            (input)="formatInput($any($event).target)"
            aria-labelledby="paginationInputLabel paginationDescription"
            style="width: 2.5rem"
          />
          <span
            id="paginationDescription"
            class="col-form-label text-nowrap px-2"
          >
            de {{ pages.length }}</span
          >
        </div>
      </li>
      }
    </ng-template>
  </ngb-pagination>
</div>
<div class="d-flex justify-content-center align-items-center mt-3">
  <p>
    Mostrando
    {{ collectionSize ? (page - 1) * pageSize + 1 : "0" }}
    -
    {{ page * pageSize > collectionSize ? collectionSize : page * pageSize }}
    de {{ collectionSize }} registros
  </p>
</div>

<!-- <pre>Current page: {{ page }}</pre> -->
<!-- <pre>Page size: {{ pageSize }}</pre> -->
