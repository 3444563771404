import { Component, OnInit } from '@angular/core';
import { Subject, debounceTime, of } from 'rxjs';
import { selectAuth } from '../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { UserService } from '../../../../../core/services/user.service';
import { CONFIG_BASE_DATE_RANGEPICKER } from '../../../../../core/constants/constants';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { FilterCommunicationService } from '../../../../../core/services/filter-communication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ROLES } from '../../../../../shared/data/config/constants';

@Component({
  selector: 'app-affiliate-filter',
  templateUrl: './affiliate-filter.component.html',
  styleUrl: './affiliate-filter.component.scss',
})
export class AffiliateFilterComponent implements OnInit {
  public isCollapsed = true;
  public affiliateFilterForm!: FormGroup;
  //NG-SELECT
  public allAffiliatesData: any[] = [];
  public optionsAffiliates$: any;
  public criteria: any;
  private filterSubject: Subject<string> = new Subject<string>();
  public currentPage = 1;
  public pageSize = 10;
  public ROLES = ROLES;
  //LOGGEDSUSER
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  isMobile: boolean = false;
  token!: string;

  public minDate: Date = CONFIG_BASE_DATE_RANGEPICKER.minDate;
  public maxDate: Date = CONFIG_BASE_DATE_RANGEPICKER.maxDate;
  public bsConfig = CONFIG_BASE_DATE_RANGEPICKER.bsConfig;
  public initialRange: Date[] = [
    CONFIG_BASE_DATE_RANGEPICKER.minDate,
    CONFIG_BASE_DATE_RANGEPICKER.maxDate,
  ];

  constructor(
    private store: Store,
    private _userService: UserService,
    private fb: FormBuilder,
    private _filterCommunicationService: FilterCommunicationService,
    private activedRouter: ActivatedRoute,
    private router: Router
  ) {
    this.initForm();
    this.filterSubject
      .pipe(
        debounceTime(400) // Adjust debounce time as needed (e.g., 300 milliseconds)
      )
      .subscribe((term: any) => {
        this.allAffiliatesData = [];
        this.loadAffiliates(term);
      });
  }

  async ngOnInit(): Promise<void> {
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      await new Promise<void>((resolve, reject) => {
        this.activedRouter.params.subscribe((params) => {
          this.token = params['token'];
          this._userService.getUserByTokenApp(this.token).subscribe({
            next: (res) => {
              if (res.data.idusuario) {
                this.profile = res.data;
                this.affiliateFilterForm.controls.id_usuario_agente.setValue(
                  res.data.idusuario
                );
                resolve();
              } else {
                reject(new Error('No user id found'));
              }
            },
            error: (err) => {
              reject(err);
            },
          });
        });
      });
      this.sendFilter();
    } else {
      await new Promise<void>((resolve, reject) => {
        this.profile$.subscribe({
          next: (res) => {
            if (res.idusuario !== -1) {
              this.profile = res;
              if (this.profile.id_rol === ROLES.AFFILIATE) {
                this.affiliateFilterForm.controls.id_usuario_agente.setValue(
                  this.profile.idusuario
                );
                this.sendFilter();
              } else {
                this.loadAffiliates();
              }
              resolve();
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    }
  }

  loadAffiliates(searchTerm?: string) {
    this._userService
      .getAffiliates(this.currentPage, this.pageSize, searchTerm)
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: any; idusuario: any }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.optionsAffiliates$ = of(transformedData);
        this.allAffiliatesData = [
          ...this.allAffiliatesData,
          ...transformedData,
        ];
      });
  }

  //*NG-SELECT
  onScrollToEnd() {
    this.currentPage++;
    this.loadAffiliates(this.criteria);
  }
  onSearch(term: string) {
    this.criteria = term;
    this.allAffiliatesData = [];
    this.currentPage = 1;
    this.filterSubject.next(term);
  }

  //*FORM
  initForm() {
    this.affiliateFilterForm = this.fb.group({
      id_usuario_agente: [null],
      dateRange: [this.initialRange],
    });
  }
  public onDateSelected(dateRange: { startDate: Date; endDate: Date }) {
    this.affiliateFilterForm.controls.dateRange.setValue([
      dateRange.startDate,
      dateRange.endDate,
    ]);
  }
  sendFilter(): void {
    if (!this.affiliateFilterForm.valid) return;
    const filterValues = this.affiliateFilterForm?.value;
    const dateRange = filterValues?.dateRange;

    const formattedFilter = {
      startDate:
        dateRange && dateRange[0]
          ? moment(dateRange[0]).format('YYYY-MM-DD 00:00:00')
          : null,
      endDate:
        dateRange && dateRange[1]
          ? moment(dateRange[1]).format('YYYY-MM-DD 23:59:59')
          : null,
    };

    let filters = {
      id_usuario_agente:
        this.profile?.id_rol === ROLES.AFFILIATE
          ? this.profile.idusuario
          : filterValues.id_usuario_agente,
      datestart: formattedFilter.startDate,
      dateend: formattedFilter.endDate,
    };
    this._filterCommunicationService.changeFilter(filters);
    this.isCollapsed = true;
  }

  resetForm(): void {
    let clearFilter: any;
    this.affiliateFilterForm.reset({
      id_usuario_agente:
        this.profile?.id_rol === ROLES.AFFILIATE
          ? this.profile.idusuario
          : null,
      dateRange: this.initialRange,
    });

    clearFilter = {
      id_usuario_agente:
        this.profile?.id_rol === ROLES.AFFILIATE
          ? this.profile.idusuario
          : null,
      dateRange: this.initialRange,
    };

    this._filterCommunicationService.changeFilter(clearFilter);
    this.isCollapsed = true;
  }
}
