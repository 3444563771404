import { Component, OnInit } from '@angular/core';
import { selectAuth } from '../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { User } from '../../../../core/interfaces/user.interface';
import { ROLES } from '../../../../shared/data/config/constants';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrl: './profile-detail.component.scss',
})
export class ProfileDetailComponent implements OnInit {
  // OBSERVABLES
  public profile$ = this.store.select(selectAuth);
  public profile: User = {} as User;
  public rolId: number = 0;

  // VARIABLES
  public roles = ROLES

  constructor(
    private store: Store
  ) { }

  async ngOnInit(): Promise<void> {
    await this.loadProfileLogged();
  }

  //? LOAD DATA

  async loadProfileLogged() {
    await new Promise<void>((resolve, reject) => {
      this.profile$.subscribe({
        next: (res) => {
          if (res.idusuario !== -1) {
            this.profile = res;
            this.rolId = this.profile.id_rol;
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

}
