import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Screen } from '../../interfaces/screen.interface';

export const featuredSelector =
  createFeatureSelector<Readonly<Screen>>('screen');

export const selectScreen = createSelector(
  featuredSelector,
  (state) => state.isMobile
);
