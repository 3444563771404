<div class="card">
  <div class="card-header">
    <h4 class="card-title d-flex justify-content-between align-items-center">
      Filters
      <button class="btn btn-link" (click)="toggleFilterForm()">
        <i
          [class]="'fas fa-arrow-' + (showFilterForm ? 'up' : 'down')"
          style="font-size: 14px"
        ></i>
      </button>
    </h4>
  </div>
  <div class="card-body row">
    <div
      [ngClass]="{ open: showFilterForm }"
      id="filterForm"
      [ngStyle]="getFilterFormStyle()"
    >
      <app-dynamic-form
        [data]="data"
        (formSubmit)="onFormSubmit($event)"
        (scrollEnd)="onScrollEnd($event)"
      ></app-dynamic-form>
    </div>
  </div>
</div>
