<div class="container-fluid p-0 m-0">
  <div class="comingsoon">
    <div class="comingsoon-inner text-center">
      <img src="../../../../assets/images/other-images/zg.png" class="comingsoon-img" alt="">
      <h5>WE ARE COMING SOON</h5>
      <div class="countdown" id="clockdiv">
        <ul>
          <li><span class="time digits">{{ days }}</span><span class="title">days</span></li>
          <li><span class="time digits">{{ hours }}</span><span class="title">Hours</span></li>
          <li><span class="time digits">{{ minutes }}</span><span class="title">Minutes</span></li>
          <li><span class="time digits">{{ seconds }}</span><span class="title">Seconds</span></li>
        </ul>
      </div>
    </div>
  </div>
</div>
