<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="authentication-main mt-0">
      <div class="row">
        <div class="col-12">
          <div class="login-card login-dark">
            <div>
              <div>
                <a class="logo"  routerLink='/'>
                  <img class="img-fluid for-light" src="assets/images/logo/logo_sorti.png" alt="looginpage">
                  <img class="img-fluid for-dark" src="assets/images/logo/logo_sorti_dark.png" alt="looginpage">
                </a>
              </div>
              <div class="login-main">
                <form class="theme-form" [formGroup]="unlockForm"
                      (ngSubmit)="onSubmit()">
                  <h5>Bienvenido de vuelta, {{ username | uppercase }}</h5>
                  <div class="form-group">
                    <label class="col-form-label">Ingresa tu contraseña.</label>
                    <input class="form-control" formControlName="password" [type]="show ? 'text' : 'password'" required="" placeholder="*********">
                    @if (!show) {
                      <div class="show-hide" (click)="showPassword()"><span class="show"></span></div>
                    }@else if (show) {
                      <div class="show-hide" (click)="showPassword()"><span class="Hide"></span></div>
                    }
                  </div>
                  <div class="form-group mb-0">
                    <button class="primary-button d-block w-100" type="submit" [disabled]="!unlockForm.valid">Unlock</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
