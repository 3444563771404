import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MAIN_PERMISSIONS, SUB_PERMISSIONS } from '../data/config/constants';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  mainPermissionId?: number | number[];
  subPermissionsIds?: number | number[];
  requiresAllPermissions?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  // Permisos principales
  public MAIN_PERMISSIONS = MAIN_PERMISSIONS;
  public SUB_PERMISSIONS = SUB_PERMISSIONS;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      headTitle1: 'General',
      mainPermissionId: [MAIN_PERMISSIONS.Dashboard, MAIN_PERMISSIONS.Perfil],
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.Dashboard,
      title: 'Dashboard',
      icon: 'home',
      type: 'link',
      badgeType: 'light-primary',
      path: '/dashboard/default',
    },
    {
      mainPermissionId: [MAIN_PERMISSIONS.Dashboard, MAIN_PERMISSIONS.Perfil],
      requiresAllPermissions: true,
      title: 'Perfil',
      icon: 'user',
      type: 'link',
      badgeType: 'light-primary',
      path: '/user/profile',
    },
    {
      title: 'Suppliers',
      icon: 'button',
      type: 'link',
      badgeType: 'light-primary',
      path: '/suppliers/all-suppliers',
    },
    {
      title: 'Products',
      icon: 'ecommerce',
      type: 'link',
      badgeType: 'light-primary',
      path: '/products/all-products',
    },
    {
      headTitle1: 'Users',
      mainPermissionId: MAIN_PERMISSIONS.Usuarios,
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.Usuarios,
      title: 'Users',
      icon: 'user',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/users/agents',
          title: 'Agents',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Agentes],
        },
        {
          path: '/users/holders',
          title: 'Holders',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Titulares],
        },
        {
          path: '/users/administratives',
          title: 'Administratives',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Administrativos],
        },
        {
          path: '/users/players',
          title: `Players`,
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Jugadores],
        },
      ],
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.RRHH,
      title: 'RRHH',
      icon: 'user',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/rrhh/payroll',
          title: 'Nominas',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Nominas],
        },
        {
          path: '/rrhh/permit',
          title: 'Permit',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Permisos_Empleados],
        },
      ],
    },
    {
      headTitle1: 'Accounting and Finance',
      mainPermissionId: MAIN_PERMISSIONS.Contabilidad,
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.Contabilidad,
      title: 'Accounting',
      icon: 'charts',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/accounting/account/',
          title: 'Account',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Cuentas],
        },
        {
          path: '/accounting/financial',
          title: 'Financial',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Financiero],
        },
        {
          path: '/accounting/accounting-accounts',
          title: 'Accounting Accounts',
          type: 'link',
        },
        {
          title: 'Accounting docs',
          type: 'sub',
          badgeType: 'light-secondary',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Documentos_Contables],
          children: [
            {
              path: '/accounting/diary',
              title: 'Diary',
              type: 'link',
              subPermissionsIds: [
                SUB_PERMISSIONS.Sub_Modulo_Documentos_Contables_Diario,
              ],
            },
            { path: '/accounting/major', title: 'Major', type: 'link' },
          ],
        },
        {
          path: '/accounting/financial-statement',
          title: 'Financial Statement',
          type: 'link',
        },
        {
          path: '/accounting/result-statement',
          title: 'Result Statement',
          type: 'link',
        },
        { path: '/accounting/bank-report', title: 'Bank Report', type: 'link' },
        { path: '/accounting/banking', title: 'Banking', type: 'link' },
        { path: '/accounting/financial', title: 'Financial', type: 'link' },
        {
          title: 'Banking',
          type: 'sub',
          badgeType: 'light-secondary',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Bancario],
          children: [
            {
              path: '/accounting/banking/bank-report',
              title: 'Bank Report',
              type: 'link',
              subPermissionsIds: [
                SUB_PERMISSIONS.Sub_Modulo_Bancario_Reporte_Bancario,
              ],
            },
            {
              path: '/accounting/banking/bank-accounts',
              title: 'Accounts Banks',
              type: 'link',
              subPermissionsIds: [
                SUB_PERMISSIONS.Sub_Modulo_Bancario_Cuentas_Bancarias,
              ],
            },
            {
              path: '/accounting/bank',
              title: 'Bancos',
              type: 'link',
              subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Bancario_Bancos],
            },
          ],
        },
        {
          path: '/accounting/accounting-accounts',
          title: 'Accounting Accounts',
          type: 'link',
        },
        {
          path: '/accounting/diary',
          title: 'Diary',
          type: 'link',
        },
        {
          path: '/accounting/major',
          title: 'Major',
          type: 'link',
        },
        {
          path: '/accounting/financial-statement',
          title: 'Financial Statement',
          type: 'link',
        },
        {
          path: '/accounting/result-statement',
          title: 'Result Statement',
          type: 'link',
        },
        {
          path: '/accounting/bank-report',
          title: 'Bank Report',
          type: 'link',
        },
        {
          path: '/accounting/banking',
          title: 'Banking',
          type: 'link',
        },
      ],
    },
    {
      title: 'Billing',
      icon: 'sample-page',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        { path: '/billing/bill-entry', title: 'Bill Entry', type: 'link' },
        { path: '/billing/bill-invoice', title: 'Bill Invoice', type: 'link' },
        { path: '/billing/credit-note', title: 'Credit Note', type: 'link' },
        {
          path: '/billing/referral-guide',
          title: 'Referral Guide',
          type: 'link',
        },
        {
          path: '/billing/proof-withholding',
          title: 'Proof Withholding',
          type: 'link',
        },
        {
          path: '/billing/liquidation-purchase-assets-provision-service',
          title: 'Liquidation of purchase of goods and provision of service',
          type: 'link',
        },
      ],
    },
    {
      headTitle1: 'Transactions',
      mainPermissionId: [
        MAIN_PERMISSIONS['Abonos y Pagos'],
        MAIN_PERMISSIONS['Cargas y Retiros'],
      ],
    },
    {
      mainPermissionId: MAIN_PERMISSIONS['Cargas y Retiros'],
      title: 'Loads and Withdrawals',
      icon: 'social',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/loads-withdrawals/list-lw',
          title: 'List',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Lista_CR],
        },
      ],
    },
    {
      mainPermissionId: MAIN_PERMISSIONS['Abonos y Pagos'],
      title: 'Subscriptions and Payments',
      icon: 'others',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/suscriptions-payments/list-sp',
          title: 'List',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Lista_AP],
        },
        {
          path: '/suscriptions-payments/bank-transaction-bot',
          title: 'Transaction Bot',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Transaccion_Banco],
        },
        {
          path: '/suscriptions-payments/request-cw',
          title: 'Request Loading or Withdrawal',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Solicitar_CR],
        },
      ],
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.Bonos,
      title: 'Bonus',
      icon: 'bonus-kit',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/bonus/balance-bonus',
          title: 'Balance Bonus',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Bono_Saldos],
        },
        {
          path: '/bonus/bonus-code',
          title: 'Bonus Code',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Codigo_Bonos],
          type: 'link',
        },
      ],
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.Balance,
      title: 'Balance',
      icon: 'search',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/balance/page-balance',
          title: 'Balance página',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_PageBalance],
          type: 'link',
        },
      ],
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.Debt,
      title: 'List of debts',
      icon: 'search',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/debt-list/list',
          title: 'Debt Adjustment',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Lista_Deuda],
          type: 'link',
        },
      ],
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.Marketing,
      headTitle1: 'Affiliate Tools',
    },
    {
      subPermissionsIds: [SUB_PERMISSIONS.Sub_Module_Marketing],
      title: 'Marketing',
      icon: 'user',
      type: 'link',
      badgeType: 'light-primary',
      path: '/affiliate/marketing',
    },
    {
      subPermissionsIds: [SUB_PERMISSIONS.Sub_Module_Promotional_Material],
      title: 'Material Promocional',
      icon: 'file',
      type: 'link',
      badgeType: 'light-primary',
      path: '/affiliate/promotional-material',
    },
    {
      subPermissionsIds: [SUB_PERMISSIONS.Sub_Module_Referrals_Link],
      title: 'Enlace de Referencia',
      icon: 'social',
      type: 'link',
      badgeType: 'light-primary',
      path: '/affiliate/referrals-link',
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.Marketing,
      subPermissionsIds: [SUB_PERMISSIONS.Sub_Module_Prediction],
      title: 'Prediction',
      icon: 'file',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/affiliate/template',
          title: 'Template',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Plantilla],
        },
        {
          path: '/affiliate/questions',
          title: 'Questions',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Pregunta],
        },
        {
          path: '/affiliate/options',
          title: 'Options',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Opciones],
        },
        {
          path: '/affiliate/report',
          title: 'Reports',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Reporte],
        },
      ],
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.Bot,
      headTitle1: 'Bot Tools',
    },
    {
      mainPermissionId: MAIN_PERMISSIONS.Bot,
      title: 'Bot',
      icon: 'bonus-kit',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/bot/actions-menu',
          title: 'Actions',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Bot],
        },
      ],
    },
    {
      headTitle1: 'Management tools',
      mainPermissionId: MAIN_PERMISSIONS.Auditoria,
    },
    {
      title: 'Audit',
      mainPermissionId: MAIN_PERMISSIONS.Auditoria,
      icon: 'search',
      type: 'link',
      badgeType: 'light-primary',
      path: '/audit',
    },
    {
      mainPermissionId: MAIN_PERMISSIONS['Roles y Permisos'],
      title: 'Roles and Permissions',
      icon: 'user',
      type: 'sub',
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/roles-and-permissions/modules',
          title: 'Modules and Permission',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Consultar_Modulos_Permisos],
        },
        {
          path: '/roles-and-permissions/roles',
          title: 'Roles and Modules',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Roles],
        },
      ],
    },
    {
      headTitle1: 'Reports',
      mainPermissionId: MAIN_PERMISSIONS.Reporte,
    },
    {
      title: 'Reports',
      icon: 'social',
      type: 'sub',
      mainPermissionId: MAIN_PERMISSIONS.Reporte,
      badgeType: 'light-secondary',
      active: false,
      children: [
        {
          path: '/closure',
          title: 'Closure',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Cierre],
        },
        {
          path: '/closure/daily',
          title: 'Daily',
          type: 'link',
          subPermissionsIds: [SUB_PERMISSIONS.Sub_Modulo_Diario],
        },
      ],
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  // Método para filtrar ítems de menú basado en permisos del usuario
  filterRoutesByPermissions = (routes: any, rol: any) => {
    const permisosPrincipalesSet = new Set(
      rol.permisos_principales.map((pp: { id: any }) => pp.id)
    );

    const subPermissionsSet = new Set(
      rol.permisos_principales.flatMap((pp: { permisos: any[] }) =>
        pp.permisos.map((p: { id: any }) => p.id)
      )
    );

    const filterRoutes = (routes: any) => {
      return routes.reduce((acc: any, route: any) => {
        const mainPermissionIds = Array.isArray(route.mainPermissionId)
          ? route.mainPermissionId
          : [route.mainPermissionId];

        const subPermissionIds = Array.isArray(route.subPermissionsIds)
          ? route.subPermissionsIds
          : [route.subPermissionsIds];

        const requiresAllPermissions = route.requiresAllPermissions || false;

        let hasPermission;
        if (requiresAllPermissions) {
          hasPermission =
            mainPermissionIds.every((id: any) =>
              permisosPrincipalesSet.has(id)
            ) && subPermissionIds.every((id: any) => subPermissionsSet.has(id));
        } else {
          hasPermission =
            mainPermissionIds.some((id: any) =>
              permisosPrincipalesSet.has(id)
            ) || subPermissionIds.some((id: any) => subPermissionsSet.has(id));
        }

        if (hasPermission) {
          if (route.children) {
            route.children = filterRoutes(route.children).filter(
              (child: any) => {
                const childMainPermissionIds = Array.isArray(
                  child.mainPermissionId
                )
                  ? child.mainPermissionId
                  : [child.mainPermissionId];

                const childSubPermissionIds = Array.isArray(
                  child.subPermissionsIds
                )
                  ? child.subPermissionsIds
                  : [child.subPermissionsIds];

                const childHasPermission =
                  childMainPermissionIds.some((id: any) =>
                    permisosPrincipalesSet.has(id)
                  ) ||
                  childSubPermissionIds.some((id: any) =>
                    subPermissionsSet.has(id)
                  );

                return childHasPermission;
              }
            );
          }
          acc.push(route);
        }
        return acc;
      }, []);
    };

    return filterRoutes(routes);
  };

  // Método para actualizar los ítems del menú basado en permisos del usuario
  updateMenuItems(rol: any) {
    const permisosPrincipales = rol.permisos_principales.map(
      (permiso: any) => ({
        id: permiso.id,
        permisos: permiso.permisos,
      })
    );

    const transformedRole = {
      permisos_principales: permisosPrincipales,
    };

    const filteredMenuItems = this.filterRoutesByPermissions(
      this.MENUITEMS,
      transformedRole
    );
    this.items.next(filteredMenuItems);
  }
}
