<div class="light-card balance-card widget-hover">
  <div class="svg-box">
    <svg class="svg-fill">
      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ tag.icon }}"></use>
    </svg>
  </div>
  <div>
    <span class="f-light">{{ tag.title | translate }}</span>
    <h6 class="mt-1 mb-0">{{ !isLoading ? tag.price : "NA" }}</h6>
  </div>
  <div class="ms-auto text-end">
    <span class="font-{{ tag.colorClass }}">{{ tag.growth }}</span>
  </div>
</div>
