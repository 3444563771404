import { Component, OnInit } from '@angular/core';
import { EventService } from '../../../../shared/services/event.service';

@Component({
  selector: 'app-affiliate-tools',
  templateUrl: './affiliate-tools.component.html',
  styleUrls: ['./affiliate-tools.component.scss'],
})
export class AffiliateToolsComponent implements OnInit {
  public totalPlayers: number = 0;
  public totalRegisteredPlayers: number = 0;
  public totalvisitTraffic: number = 0;
  public totalconversionRate: number = 0;
  public players: any = {};
  public registeredPlayers: any = {};
  public visitTraffic: any = {};
  public conversionRate: any = {};
  public loadingSate!: boolean;

  constructor(private eventService: EventService) {}

  ngOnInit(): void {}

  onTotalPlayersChange(total: number) {
    this.totalPlayers = total;
    this.updatePlayers();
    this.emitEvent({ action: 'totalPlayersChange', value: total });
  }

  onTotalRegisteredPlayersChange(total: number) {
    this.totalRegisteredPlayers = total;
    this.updateRegisteredPlayers();
    this.emitEvent({ action: 'totalRegisteredPlayersChange', value: total });
  }

  onTotalvisitTrafficChange(total: number) {
    this.totalvisitTraffic = total;
    this.updateVisitTraffic();
    this.emitEvent({ action: 'totalvisitTrafficChange', value: total });
  }

  onTotalconversionRate(total: number) {
    this.totalconversionRate = total;
    this.updateConversionRate();
    this.emitEvent({ action: 'totalconversionRateChange', value: 40 });
  }

  updatePlayers() {
    this.players = {
      title_1: 'Jugadores Totales',
      colorClass: 'success',
      icon: 'users',
      value_1: this.totalPlayers,
    };
  }

  updateRegisteredPlayers() {
    this.registeredPlayers = {
      title_1: 'Jugadores Registrados',
      colorClass: 'success',
      icon: 'user-plus',
      value_1: this.totalRegisteredPlayers,
    };
  }

  updateVisitTraffic() {
    this.visitTraffic = {
      title_1: 'Tráfico de visitas',
      colorClass: 'success',
      icon: 'signal',
      value_1: this.totalvisitTraffic,
    };
  }

  updateConversionRate() {
    this.conversionRate = {
      title_1: 'Tasa de conversión (Registro/Click)',
      colorClass: 'success',
      icon: 'percent',
      value_1: this.totalconversionRate.toFixed(2) + '%',
    };
    this.conversionRate.value_1 === 'NaN%' ||
    this.conversionRate.value_1 === 'Infinity%'
      ? (this.conversionRate.value_1 = '0%')
      : this.conversionRate.value_1;
  }

  loading(loadings: boolean) {
    this.loadingSate = loadings;
    this.emitEvent({ action: 'loading', value: loadings });
  }

  emitEvent(event: any) {
    this.eventService.sendEvent(event);
  }
}
