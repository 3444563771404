<div class="row">
  <div class="col-sm-12 col-xl-12">
    <div class="card" style="margin-bottom: 0px">
      <div class="card-header">
        <h5 class="card-title">Filtros De Búsqueda</h5>
        <button
          class="btn btn-link"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#filterCardContent"
          aria-expanded="false"
          aria-controls="filterCardContent"
          (click)="toggleExpand()"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-chevron-up': !isExpanded,
              'fa-chevron-down': isExpanded
            }"
          ></i>
        </button>
      </div>
      <div id="filterCardContent" class="collapse">
        <div class="card-body">
          <form [formGroup]="bcFormFilter">
            <div class="row mb-3"  > 
              <div class="col-sm-3">
                <label class="col-form-label">Fecha de creación</label>
                <input
                  bsDaterangepicker
                  [bsConfig]="bsConfig"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  type="text"
                  class="form-control"
                  formControlName="dateRange"
                  placeholder="Ingrese la fecha"
                  [bsValue]="initialRange"
                />
              </div>
              <div class="col-sm-3">
                <label class="col-form-label">Código</label>
                <input
                  type="text"
                  formControlName="promotionalCode"
                  class="form-control custom-input"
                  placeholder="Ingresa un comprobante"
                />
              </div>
              <div class="col-sm-3">
                <label class="col-form-label">Monto</label>
                <input
                  type="text"
                  formControlName="amount"
                  class="form-control custom-input"
                  placeholder="Ingresa un monto"
                />
              </div>
              @if (roleId !== ROLES.AGENT) {
              <div class="col-sm-3">
                <label class="col-form-label">País</label>
                <ng-select
                  [items]="allCountriesData"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="countryId"
                  class="mb-2"
                  placeholder="Selecciona una opción"
                  (scrollToEnd)="onScrollToEnd()"
                  (search)="onSearch($event.term)"
                  (clear)="onSearch('')"
                  (open)="onSearch('')"
                ></ng-select>
              </div>
              <div class="col-sm-3">
                <div class="media">
                  <label class="col-form-label m-r-10">
                    {{ isAgent ? "Agente" : "Jugador" }}
                  </label>
                  <!-- <div class="media-body text-end switch-sm icon-state">
                    <label class="switch">
                      <input type="checkbox" (change)="toggleSwitch()" />
                      <span class="switch-state"></span>
                    </label>
                  </div> -->
                </div>
                <ng-select
                  [items]="isAgent ? allAgentsData : allPlayersData"
                  bindLabel="label"
                  bindValue="value"
                  [formControlName]="isAgent ? 'agentId' : 'playerId'"
                  placeholder="{{
                    isAgent ? 'Selecciona un agente' : 'Selecciona un jugador'
                  }}"
                  (scrollToEnd)="onScrollToEnd()"
                  (search)="onSearch($event.term)"
                  (clear)="onSearch('')"
                  (open)="onSearch('')"
                ></ng-select>
                <!-- (change)="onSelectionChange()" -->
              </div>
              <div class="col-sm-3">
                <label class="col-form-label">Creado Por</label>
                <ng-select
                  [items]="allCreateUserData"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="createdByUserId"
                  class="mb-2"
                  placeholder="Selecciona una opción"
                  (scrollToEnd)="onScrollToEnd()"
                  (search)="onSearch($event.term)"
                  (clear)="onSearch('')"
                  (open)="onSearch('')"
                ></ng-select>
              </div>
              }
              <div class="col-sm-3">
                <label class="col-form-label">Estado de Bono</label>
                <ng-select
                  [items]="optionsBonusState$ | async"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="bonusState"
                  class="mb-2"
                  placeholder="Selecciona un tipo de transacción"
                ></ng-select>
              </div>
              @if (roleId !== ROLES.AGENT) {
              <div class="col-sm-3">
                <label class="col-form-label">Tipo de Bono</label>
                <ng-select
                  [items]="allTypeBonusData"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="bonusType"
                  class="mb-2"
                  placeholder="Selecciona una opción"
                  (scrollToEnd)="onScrollToEnd()"
                  (search)="onSearch($event.term)"
                  (clear)="onSearch('')"
                  (open)="onSearch('')"
                ></ng-select>
              </div>
              }
            </div>
          </form>
          <div class="text-end">
            <button
              type="button"
              class="btn btn-sm btn-restore me-1"
              (click)="resetForm()"
            >
              Restablecer
            </button>
            <button (click)="sendFilter()" class="btn btn-sm btn-head me-1">
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
