import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FilterCommunicationService } from '../../../../../core/services/filter-communication.service';
import { CONFIG_BASE_DATE_RANGEPICKER } from '../../../../../core/constants/constants';
import { UserService } from '../../../../../core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router';
import { selectAuth } from '../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ROLES } from '../../../../../shared/data/config/constants';
import { User } from '../../../../../core/interfaces/user.interface';
import { QRCodeComponent } from 'angularx-qrcode';
@Component({
  selector: 'app-card-qr',
  templateUrl: './card-qr.component.html',
  styleUrl: './card-qr.component.scss',
})
export class CardQrComponent implements OnInit, OnDestroy {
  @ViewChild('qrContainer', { static: false }) qrContainer!: ElementRef;
  @ViewChild('qrCode', { static: false }) qrCode!: QRCodeComponent;
  public isMobile: boolean = false;
  public token!: string;
  public profile$ = this.store.select(selectAuth);
  public profile!: User;
  public subscribe: Subscription = new Subscription();
  public profileSubscription: Subscription = new Subscription();

  // FILTER
  public selectedFilter!: { id_usuario_agente: string };
  public defaultFilter: any;
  public startDate = CONFIG_BASE_DATE_RANGEPICKER.minDate;
  public endDate = CONFIG_BASE_DATE_RANGEPICKER.initialRange[1];
  public ROLES = ROLES;
  //QR
  public url: string = '';
  public usuario: string = '';
  public inputUrl: string = 'Selecciona un afiliado';

  constructor(
    private _filterCommunicationService: FilterCommunicationService,
    private _userService: UserService,
    private _toastrSvc: ToastrService,
    private activedRouter: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      await new Promise<void>((resolve, reject) => {
        this.activedRouter.params.subscribe((params) => {
          this.token = params['token'];
          this._userService.getUserByTokenApp(this.token).subscribe({
            next: (res) => {
              if (res.data.idusuario) {
                this.profile = res.data;
                this.getDataFromFilter();
                resolve();
              } else {
                reject(new Error('No user id found'));
              }
            },
            error: (err) => {
              reject(err);
            },
          });
        });
      });
    } else {
      await new Promise<void>((resolve, reject) => {
        this.profileSubscription = this.profile$.subscribe({
          next: (res) => {
            if (res.idusuario !== -1) {
              this.profile = res;
              this.getDataFromFilter();
              resolve();
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
      this.profileSubscription.unsubscribe();
    }
  }

  getUser() {
    if (this.selectedFilter?.id_usuario_agente) {
      this._userService
        .getUser(this.selectedFilter.id_usuario_agente)
        .subscribe((response) => {
          this.usuario = response.data.usuario;
          this.url = `https://sortiecuador.com/jugador/${this.usuario}`;
          this.inputUrl = this.url;
        });
    } else {
      this.inputUrl = 'Selecciona un afiliado';
      this._toastrSvc.info('Seleccione un afiliado para mostrar sus datos');
    }
  }

  copyInputMessage(inputElement: {
    select: () => void;
    setSelectionRange: (arg0: number, arg1: number) => void;
  }) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  getDataFromFilter() {
    this.subscribe = this._filterCommunicationService.currentFilter.subscribe(
      (filter) => {
        if (this.profile.id_rol !== ROLES.AFFILIATE) {
          this.selectedFilter = filter;
          this.getUser();
        } else if (filter) {
          this.selectedFilter = filter;
          this.getUser();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this._filterCommunicationService.resetFilter();
  }

  downloadImage() {
    const options = {
      backgroundColor: null,
      useCORS: true,
      scale: 1.5,
    };
    html2canvas(this.qrContainer.nativeElement, options).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'imagen-qr.png';
      link.click();
    });
  }
  downloadQrCode() {
    if (this.qrCode.context) {
      const qrCanvas = this.qrCode.context.canvas;
      const options = {
        backgroundColor: null,
        useCORS: true,
        scale: 1,
      };
      html2canvas(qrCanvas, options).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'qr-code.png';
        link.click();
      });
    }
  }
}
