<div class="customizer-links" [ngClass]="{ open: customizer != '' }">
  <div
    class="nav flex-column nac-pills"
    id="c-pills-tab"
    role="tablist"
    aria-orientation="vertical"
  >
    <a
      href="javascript:void(0)"
      class="nav-link"
      [ngClass]="{ 'active show': customizer == 'layout' }"
      (click)="Customizer('layout')"
    >
      <div class="settings"><i class="icon-paint-bucket"></i></div>
      <span>Check layouts</span>
    </a>
    <a
      href="javascript:void(0)"
      class="nav-link"
      [ngClass]="{ 'active show': customizer == 'option' }"
      id="c-pills-home-tab"
      (click)="Customizer('option')"
    >
      <div class="settings"><i class="icon-settings"></i></div>
      <span>Quick option</span>
    </a>
  </div>
</div>
<div class="customizer-contain" [ngClass]="{ open: customizer != '' }">
  <div class="tab-content" id="c-pills-tabContent">
    <div class="customizer-header">
      <i class="icofont-close icon-close" (click)="Customizer('')"></i>
      <h5>Preview Settings</h5>
      <p class="mb-0">
        Try It Real Time <i class="fa fa-thumbs-o-up txt-primary"></i>
      </p>
    </div>
    <div class="customizer-body custom-scrollbar">
      <div
        class="tab-pane fade"
        [ngClass]="{ 'active show': customizer == 'layout' }"
        id="c-pills-layouts"
        role="tabpanel"
      >
        <ul class="sidebar-type layout-grid layout-types">
          <li data-attr="compact-sidebar">
            <div class="layout-img">
              <a (click)="dispatch('Dubai')">
                <img
                  src="assets/images/layout-images/dubai.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>Dubai</h6>
            </div>
          </li>
          <li class="only-body" data-attr="default-body">
            <div class="layout-img">
              <a (click)="dispatch('London')">
                <img
                  src="assets/images/layout-images/london.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>London</h6>
            </div>
          </li>
          <li data-attr="modern-layout">
            <div class="layout-img">
              <a (click)="dispatch('Seoul')">
                <img
                  src="assets/images/layout-images/seoul.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>Seoul</h6>
            </div>
          </li>
          <li data-attr="material-layout">
            <div class="layout-img">
              <a (click)="dispatch('LosAngeles')">
                <img
                  src="assets/images/layout-images/los.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>Los Angeles</h6>
            </div>
          </li>
          <li data-attr="dark-sidebar">
            <div class="layout-img">
              <a (click)="dispatch('Paris')">
                <img
                  src="assets/images/layout-images/paris.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>Paris</h6>
            </div>
          </li>
          <li data-attr="compact-wrap">
            <div class="layout-img">
              <a (click)="dispatch('Tokyo')">
                <img
                  src="assets/images/layout-images/tokyo.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>Tokyo</h6>
            </div>
          </li>
          <li data-attr="color-sidebar">
            <div class="layout-img">
              <a (click)="dispatch('Madrid')">
                <img
                  src="assets/images/layout-images/madrid.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>Madrid</h6>
            </div>
          </li>
          <li data-attr="compact-small">
            <div class="layout-img">
              <a (click)="dispatch('Moscow')">
                <img
                  src="assets/images/layout-images/moscow.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>Moscow</h6>
            </div>
          </li>
          <li data-attr="box-layout" class="box-layout">
            <div class="layout-img">
              <a (click)="dispatch('NewYork')">
                <img
                  src="assets/images/layout-images/newyork.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>New York</h6>
            </div>
          </li>
          <li data-attr="enterprice-type">
            <div class="layout-img">
              <a (click)="dispatch('Singapore')">
                <img
                  src="assets/images/layout-images/singapore.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>Singapore</h6>
            </div>
          </li>
          <li data-attr="material-icon">
            <div class="layout-img">
              <a (click)="dispatch('Rome')">
                <img
                  src="assets/images/layout-images/romo.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>Rome</h6>
            </div>
          </li>
          <li data-attr="advance-type">
            <div class="layout-img">
              <a (click)="dispatch('Barcelona')">
                <img
                  src="assets/images/layout-images/barc.jpg"
                  class="img-fluid"
                  alt=""
                />
              </a>
              <h6>Barcelona</h6>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="tab-pane fade"
        [ngClass]="{ 'active show': customizer == 'option' }"
        id="c-pills-home"
        role="tabpanel"
        aria-labelledby="c-pills-home-tab"
      >
        <h6>Layout Type</h6>
        <ul class="main-layout layout-grid">
          <li
            data-attr="ltr"
            [ngClass]="{ active: layoutType == 'ltr' }"
            (click)="customizeLayoutType('ltr')"
          >
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-light sidebar"></li>
                <li class="bg-light body">
                  <span class="badge badge-primary">LTR</span>
                </li>
              </ul>
            </div>
          </li>
          <li
            data-attr="rtl"
            [ngClass]="{ active: layoutType == 'rtl' }"
            (click)="customizeLayoutType('rtl')"
          >
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-light body">
                  <span class="badge badge-primary">RTL</span>
                </li>
                <li class="bg-light sidebar"></li>
              </ul>
            </div>
          </li>
          <li
            data-attr="Box"
            class="box-layout"
            [ngClass]="{ active: layoutType == 'box-layout' }"
            (click)="customizeLayoutType('box-layout')"
          >
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-light sidebar"></li>
                <li class="bg-light body">
                  <span class="badge badge-primary">BOX</span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <h6>Sidebar Type</h6>
        <ul class="sidebar-type layout-grid">
          <li
            data-attr="normal-sidebar"
            [class.d-none]="screenwidth < 991"
            [ngClass]="{ active: sidebarType == 'horizontal-wrapper' }"
            (click)="customizeSidebarType('horizontal-wrapper')"
          >
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-dark sidebar"></li>
                <li class="bg-light body"></li>
              </ul>
            </div>
          </li>
          <li
            data-attr="compact-sidebar"
            [ngClass]="{ active: sidebarType == 'compact-wrapper' }"
            (click)="customizeSidebarType('compact-wrapper')"
          >
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-dark sidebar compact"></li>
                <li class="bg-light body"></li>
              </ul>
            </div>
          </li>
        </ul>
        <h6 class="">Sidebar Icon</h6>
        <ul class="sidebar-setting layout-grid">
          <li
            class="active"
            data-attr="stroke-svg"
            [ngClass]="{ active: icon == 'stroke-svg' }"
            (click)="svgIcon('stroke-svg')"
          >
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body bg-light">
              <span class="badge badge-primary">Stroke</span>
            </div>
          </li>

          <li
            data-attr="fill-svg"
            [ngClass]="{ active: icon == 'fill-svg' }"
            (click)="svgIcon('fill-svg')"
          >
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body bg-light">
              <span class="badge badge-primary">Fill</span>
            </div>
          </li>
        </ul>
        <h6 class="">Unlimited Color</h6>
        <ul class="layout-grid unlimited-color-layout">
          <input
            id="ColorPicker1"
            [(ngModel)]="primary_color"
            type="color"
            value="primary_color"
          />
          <input
            id="ColorPicker2"
            [(ngModel)]="secondary_color"
            type="color"
            value="secondary_color"
          />
          <button
            type="button"
            class="color-apply-btn btn btn-primary color-apply-btn"
            (click)="applyColor()"
          >
            Apply
          </button>
          <button
            type="button"
            class="color-apply-btn btn btn-primary color-apply-btn ms-2"
            (click)="resetColor()"
          >
            Reset
          </button>
        </ul>
        <h6>Mix Layout</h6>
        <ul class="layout-grid customizer-mix">
          <li
            class="color-layout"
            data-attr="default"
            [ngClass]="{ active: MIXLayout == 'default' }"
            (click)="customizeMixLayout('default')"
          >
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-light sidebar"></li>
                <li class="bg-light body"></li>
              </ul>
            </div>
          </li>
          <li
            class="color-layout"
            data-attr="dark-sidebar"
            [ngClass]="{ active: MIXLayout == 'dark-sidebar' }"
            (click)="customizeMixLayout('dark-sidebar')"
          >
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-dark sidebar"></li>
                <li class="bg-light body"></li>
              </ul>
            </div>
          </li>
          <li
            class="color-layout"
            data-attr="dark-only"
            [ngClass]="{ active: MIXLayout == 'dark-only' }"
            (click)="customizeMixLayout('dark-only')"
          >
            <div class="header bg-dark">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-dark sidebar"></li>
                <li class="bg-dark body"></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
