import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../../../../core/services/user.service';
import { AuthActions } from '../../../../../../../core/state/auth/auth.actions';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import { ActivatedRoute, Router } from '@angular/router';
import { ROLES } from '../../../../../../../shared/data/config/constants';


@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.scss'
})
export class EditProfileComponent implements OnInit{

  public editProfile!: FormGroup;
  public profile$ = this.store.select(selectAuth);
  public profile: any;
  public isMobile: boolean = false;
  public tokenExists!: string;
  public id: number = 0;
  public idusuario: string = '';
  public roles = ROLES;


  constructor(
    private store: Store,
    private _userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private activedRouter: ActivatedRoute,
    private router: Router
  ){
    this.editProfile = this.formBuilder.group({
      names: ['', Validators.required],
      username: ['', Validators.required],
      address: ['', Validators.required],
      cellphone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],

    });
  }

  ngOnInit(): void {
    const fullUrl = this.router.url;
    // Verifica si la URL contiene 'mobile'
    this.isMobile = fullUrl.includes('/mobile');
    if (this.isMobile) {
      this.loadUserProfileForMobile();
    } else {
      this.loadUserProfile();
    }
  }

  loadUserProfileForMobile(): void {
    this.activedRouter.params.subscribe((params) => {
      this.tokenExists = params['token'];
      this._userService.getUserByTokenApp(this.tokenExists).subscribe({
        next: (res) => {
          if (res.data.idusuario) {
            this._userService.getUser(res.data.idusuario).subscribe({
              next: (res) => {
                const profile = res.data;
                this.profile = profile;
                this.id = profile.idusuario;
                this.idusuario = profile.idusuario.toString();
                this.editProfile.disable();
                this.initalProfileData();
              },
              error: (err) => {
                // Manejo de errores
              },
            });
          }
        },
        error: (err) => {
          // Manejo de errores
        },
      });
    });
  }

  loadUserProfile(): void {
    this.profile$.subscribe((profile) => {
      if (profile.idusuario !== -1) {
        this.profile = profile;
        this.id = profile.idusuario;
        this.editProfile.disable();
        this.initalProfileData();
      }
    });
  }



  initalProfileData() {
    if (this.profile) {
      this.editProfile.patchValue({
        names: this.profile.nombres,
        username: this.profile.usuario,
        address: this.profile.direccion,
        cellphone: this.profile.celular,
        email: this.profile.correo,
      });
    }
  }

  onSubmitProfile() {
    const formData = new FormData();
    formData.append('userId', this.profile?.idusuario)
    formData.append('names', this.editProfile.get('names')?.value);
    formData.append('username', this.editProfile.get('username')?.value);
    formData.append('address', this.editProfile.get('address')?.value);
    formData.append('cellphone', this.editProfile.get('cellphone')?.value);
    formData.append('email', this.editProfile.get('email')?.value);
    formData.append('identification', this.profile?.identificacion);
    formData.append('externalId', this.profile?.id_externo);

    this.store.dispatch(
      AuthActions['[Auth]RequestUpdateUser']({
        updatedUser: formData,
      })
    );
    this.editProfile.disable();
  }

  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault(); // Prevenir la entrada de teclas que no sean números
    }
  }

  resetForm(profileForm: FormGroup) {
    this.initalProfileData();
    profileForm.disable();
  }

  enableFormProfile() {
    const fieldsToDisable = ['nombres', 'usuario', 'direccion'];
    if (this.editProfile.enabled) {
      this.editProfile.disable();
    } else {
      this.editProfile.enable();
      fieldsToDisable.forEach((field) => {
        this.editProfile.get(field)?.disable();
      });
    }
  }

}
