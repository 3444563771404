import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FinancialService } from '../../../../../../core/services/financial.service';
import { Observable, Subject, debounceTime, of, map } from 'rxjs';
import { StateService } from '../../../../../../core/services/state.service';
import {
  INTERBANK_TRANSDFER_OPTIONS,
  STATE_NAMES,
  TRANSACTION_TYPES,
  TYPE_USER_OPTION,
} from '../../../../../../shared/data/suscriptions-payments/constants';
import { HolderAccountBankService } from '../../../../../../core/services/holder-account-bank.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../../../core/services/user.service';
import { PlayerService } from '../../../../../../core/services/player.service';
import { ChargesWithdrawalsRequestService } from '../../../../../../core/services/charges-withdrawals-request.service';
import { FilterCommunicationService } from '../../../../../../core/services/filter-communication.service';
import moment from 'moment';
import { CONFIG_BASE_DATE_RANGEPICKER } from '../../../../../../core/constants/constants';
import { CustomNgSelect } from '../../../../../../core/interfaces/components.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { selectAuth } from '../../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { CountryService } from '../../../../../../core/services/country.service';
import { ROLES } from '../../../../../../shared/data/config/constants';
import { UserMainService } from '../../../../../user/services/user-main.service';

interface FormattedFilter {
  date_star: string | null;
  date_end: string | null;
  date_star_update: string | null;
  date_end_update: string | null;
}
@Component({
  selector: 'app-filter-cw',
  templateUrl: './filter-cw.component.html',
  styleUrl: './filter-cw.component.scss',
})
export class FilterCwComponent implements OnInit {
  public cwFormFilter!: FormGroup;
  public transformedDataBank: any;
  public transformedDataState: any;
  public optionsBanks$: any;
  public optionsState$: any;
  public optionsAgents$: any;
  public optionsPlayers$: any;
  public optionsTransactionTypes$: any;
  public optionsInterbankTransfer$: any;
  public optionsCountries$: any;
  public currentPageHolders = 1;
  public currentPageAgents = 1;
  public currentPagePlayers = 1;
  public currentPageBanks = 1;
  public currentPageBanksEnterprise = 1;
  public pageSize = 10;
  public id_country!: any;
  public allHoldersData: any[] = [];
  public allAgentsData: any[] = [];
  public allPlayersData: any[] = [];
  public allBankData: any[] = [];
  public allBankEnterpriseData: any[] = [];
  public optionsHolder$: any;
  public selectedBankId: any;
  public criteria: any;
  private holderSubject: Subject<string> = new Subject<string>();
  public isAgent = true;
  public showadvancedFilters: boolean = false;
  public selectedFilter: any;
  public optionTypeUser$: any;
  public createUser$: any;
  public updatedUser$: any;
  public responseCreateUser: any;
  public id_agente: any;
  public isMobile: boolean = false;
  public token!: string;
  public selectedCountryId!: number | null;
  public notCountrySelect: boolean = true;
  public notBankSelect: boolean = true;
  public roles = ROLES;


  //LOGGEDSUSER
  public profile$ = this.store.select(selectAuth);
  public profile: any;

  // data picker
  @Output()
  public onFilter: EventEmitter<Record<string, unknown>> = new EventEmitter<
    Record<string, unknown>
  >();
  public accounts$: Observable<CustomNgSelect<number>[]> = of([]);
  @Output() clearFilter: EventEmitter<void> = new EventEmitter<void>();

  public initialRange: Date[] = CONFIG_BASE_DATE_RANGEPICKER.initialRange
  public bsConfig = CONFIG_BASE_DATE_RANGEPICKER.bsConfig;

  constructor(
    private _userMainService: UserMainService,
    private _stateService: StateService,
    private _holderAccountBank: HolderAccountBankService,
    private fb: FormBuilder,
    private _userService: UserService,
    private _playerService: PlayerService,
    private _filterCommunicationService: FilterCommunicationService,
    private cwService: ChargesWithdrawalsRequestService,
    private router: Router,
    private activedRouter: ActivatedRoute,
    private store: Store,
    private _countryService: CountryService
  ) {
    this.initForm();
    this.holderSubject
      .pipe(
        debounceTime(400) // Adjust debounce time as needed (e.g., 300 milliseconds)
      )
      .subscribe((term: any) => {
        this.allAgentsData = [];
        this.allPlayersData = [];
        this.allHoldersData = [];
        this.allBankData = [];
        this.allBankEnterpriseData = [];
        if (this.isAgent) {
          this.loadAgents(term);
        } else {
          this.loadPlayers(term);
        }
        this.getBankData(term);
        this.getHoldersData(term);
      });
  }

  async ngOnInit(): Promise<void> {
    this.detectMobileMode();
    this.getBankData();
    this.getStateData();
    this.getTypeTransaction();

    if (this.isMobile) {
      await this.getUserDataForMobile();
    } else {
      await this.getUserDataForDesktop();
    }

    this.loadPlayers();
    this.getHoldersData();
    this.loadTypeTransfer();
    this.getCountries();
    this.getTypeUser();
    this.getUserCreatedRequestCharge();
    this.sendFilter();
  }


  // MOBILE
  detectMobileMode(): void {
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
  }

  async getUserDataForMobile(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.activedRouter.params.subscribe((params) => {
        this.token = params['token'];
        this._userService.getUserByTokenApp(this.token).subscribe({
          next: (res) => {
            if (res.data.idusuario) {
              this._userService.getUser(res.data.idusuario).subscribe({
                next: (res) => {
                  this.profile = res.data;
                  this.id_agente = this.profile.idusuario;
                  this.cwFormFilter
                    .get('id_agente')
                    ?.setValue(this.profile.idusuario);
                  resolve();
                },
                error: (err) => {
                  reject(err);
                },
              });
            } else {
              reject(new Error('No user id found'));
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    });
  }

  async getUserDataForDesktop(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.profile$.subscribe({
        next: (res) => {
          if (res.idusuario !== -1) {
            this.profile = res;
            if (this.profile.id_rol === 4) {
              this.cwFormFilter
                .get('id_agente')
                ?.setValue(this.profile?.idusuario);
            } else {
              this.loadAgents();
            }
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }


  //? LOAD DATA
  getBankData(searchTerm?: string) {
    this._userMainService.getAllBanksBySelect(
      this.currentPageBanks,
      this.pageSize,
      searchTerm
    ).subscribe((response) => {
      this.transformedDataBank = Array.isArray(response.data)
        ? response.data.map((item) => {
          return { label: item.bankName, value: item.bankId };
        })
        : [];

      this.allBankData = [...this.allBankData, ...this.transformedDataBank]
      this.allBankEnterpriseData = [...this.allBankEnterpriseData, ...this.transformedDataBank]
    })
  }

  getStateData() {
    this._stateService.geatAllData().subscribe((response) => {
      this.transformedDataState = response.data
        .filter((item: { n_estado: any }) => item.n_estado !== STATE_NAMES.NOT_ENTERED && item.n_estado !== STATE_NAMES.REJECTED_COMMENT)
        .map((item: { nombre: any; n_estado: any }) => {
          return { label: item.nombre, value: item.n_estado.toString() };
        });

      this.optionsState$ = of(this.transformedDataState);
    });
  }


  getTypeTransaction() {
    this.optionsTransactionTypes$ = of(TRANSACTION_TYPES);
  }

  getHoldersData(searchTerm?: string) {
    this._holderAccountBank
      .getAllHoldersAccountData(
        this.currentPageHolders,
        this.pageSize,
        this.id_country,
        this.selectedBankId,
        searchTerm
      )
      .subscribe((res) => {
        const response = res.data;
        const holderMap = new Map();

        response.data.forEach(
          (item: {
            holder: any;
            id_usuario: any;
            bank: any;
            account_number: any;
          }) => {
            // Capitalize the holder's name
            const holderName = this.capitalizeName(item.holder);

            // Si el id_usuario ya existe en el map, no se añadirá de nuevo
            if (!holderMap.has(item.id_usuario)) {
              holderMap.set(item.id_usuario, {
                label: holderName,
                value: item.id_usuario,
              });
            }
          }
        );

        // Convertimos el map de vuelta a un array
        const uniqueHolders = Array.from(holderMap.values());
        this.optionsHolder$ = of(uniqueHolders);
        this.allHoldersData = [...this.allHoldersData, ...uniqueHolders];
      });
  }

  loadAgents(searchTerm?: string) {
    this._userMainService
      .getAllReferredAndAgents(
        this.currentPageAgents,
        this.pageSize,
        searchTerm,
        this.id_country
      )
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: any; idusuario: any }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.optionsAgents$ = of(transformedData);
        this.allAgentsData = [...this.allAgentsData, ...transformedData];
      });
  }

  loadPlayers(searchTerm?: string) {
    this._playerService
      .getPlayers(
        this.id_country,
        this.currentPagePlayers,
        this.pageSize,
        searchTerm
      )
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario_jugador: any; id_jugador: any }) => {
            return { label: item.usuario_jugador, value: item.id_jugador };
          }
        );
        this.optionsPlayers$ = of(transformedData);
        this.allPlayersData = [...this.allPlayersData, ...transformedData];
      });
  }

  loadTypeTransfer() {
    this.optionsInterbankTransfer$ = of(INTERBANK_TRANSDFER_OPTIONS);
  }

  getTypeUser() {
    this.optionTypeUser$ = of(TYPE_USER_OPTION);
  }

  //*UTILS
  capitalizeName(name: string): string {
    return name
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  //*NG-SELECT
  onScrollToEnd() {
    if (this.isAgent) {
      this.currentPageAgents++;
      if (this.isAgent) {
        this.loadAgents(this.criteria);
      } else {
        this.loadAgents();
      }
    } else {
      this.currentPagePlayers++;
      if (!this.isAgent) {
        this.loadPlayers(this.criteria);
      } else {
        this.loadPlayers();
      }
    }
    this.currentPageHolders++;
    this.currentPageBanks++;
    this.getBankData(this.criteria)
    this.getHoldersData(this.criteria);
  }

  onSearch(term: string) {
    this.criteria = term;
    this.allAgentsData = [];
    this.allPlayersData = [];
    this.allHoldersData = [];
    this.allBankData = [];
    this.allBankEnterpriseData = [];
    this.currentPageBanks = 1;
    this.currentPageBanksEnterprise = 1;
    this.currentPageAgents = 1;
    this.currentPagePlayers = 1;
    this.currentPageHolders = 1;
    this.holderSubject.next(term);
  }

  onSearchBank() {
    this.selectedBankId = null;
  }

  onBankSelected(event: { value: number }) {
    this.selectedBankId = event.value;
  }

  onSelectionChange() {
    const controlName = this.isAgent ? 'id_agente' : 'id_jugador';
    const oppositeControlName = this.isAgent ? 'id_jugador' : 'id_agente';
    const selectedValue = this.cwFormFilter.get(controlName)?.value;

    // Establecer el valor del control seleccionado
    this.cwFormFilter.removeControl('id_personas');
    this.cwFormFilter.addControl(
      controlName,
      this.fb.control(selectedValue, Validators.required)
    );

    this.cwFormFilter.get(oppositeControlName)?.setValue(0);
  }

  //SWITCHES
  toggleSwitch() {
    const currentControlName = this.isAgent ? 'id_agente' : 'id_jugador';
    const oppositeControlName = this.isAgent ? 'id_jugador' : 'id_agente';

    this.cwFormFilter.get(currentControlName)?.reset();
    this.cwFormFilter.get(oppositeControlName)?.setValue(null);

    this.isAgent = !this.isAgent;
  }

  //*FORM
  initForm() {
    this.cwFormFilter = this.fb.group({
      receipt: [null],
      amount: [null],
      id_agente: [null],
      id_jugador: [null],
      interbank_transfer: [null],
      transaction_type: [null],
      state: [null],
      bank: [null],
      bank_enterprise: [null],
      holder: [null],
      identification: [null],
      beneficiary: [null],
      account_number: [null],
      created_by: [null],
      updated_by: [null],
      type_user: [null],
      country: [null],
      dateRange: [this.initialRange],
      dateUpdate: [null],
    });
  }

  sendFilter(): void {
    if (!this.cwFormFilter.valid) return;
    const filterValues = this.cwFormFilter?.value;
    const dateRange = filterValues?.dateRange;
    const dateUpdate = filterValues?.dateUpdate;
    const formattedFilter = {
      date_star:
        dateRange && dateRange[0]
          ? moment(dateRange[0]).format('YYYY-MM-DD 00:00:00')
          : null,
      date_end:
        dateRange && dateRange[1]
          ? moment(dateRange[1]).format('YYYY-MM-DD 23:59:59')
          : null,
    };

    if (dateUpdate !== null) {
      const formattedFilterDataUpdate = {
        date_star_update:
          dateUpdate && dateUpdate[0]
            ? moment(dateUpdate[0]).format('YYYY-MM-DD 00:00:00')
            : null,
        date_end_update:
          dateUpdate && dateUpdate[1]
            ? moment(dateUpdate[1]).format('YYYY-MM-DD 23:59:59')
            : null,
      };

      let filters = {
        receipt: filterValues.receipt?.trim(),
        amount: filterValues.amount,
        id_agente:
          this.profile?.id_rol === ROLES.AGENT
            ? this.profile.idusuario
            : filterValues.id_agente,
        id_jugador: filterValues.id_jugador,
        interbank_transfer: filterValues.interbank_transfer,
        transaction_type: filterValues.transaction_type,
        state: filterValues.state,
        bank: filterValues.bank,
        bank_enterprise: filterValues.bank_enterprise,
        identification: filterValues.identification,
        beneficiary: filterValues.beneficiary,
        account_number: filterValues.account_number,
        created_by: filterValues.created_by,
        updated_by: filterValues.updated_by,
        type_user: filterValues.type_user,
        holder: filterValues.holder,
        country: filterValues.country,
        date_star_update: formattedFilterDataUpdate.date_star_update,
        date_end_update: formattedFilterDataUpdate.date_end_update,
      };

      this._filterCommunicationService.changeFilter(filters);
    } else {
      let filters = {
        receipt: filterValues.receipt?.trim(),
        amount: filterValues.amount,
        id_agente:
          this.profile?.id_rol === ROLES.AGENT
            ? this.profile.idusuario
            : filterValues.id_agente,
        id_jugador: filterValues.id_jugador,
        interbank_transfer: filterValues.interbank_transfer,
        transaction_type: filterValues.transaction_type,
        state: filterValues.state,
        bank: filterValues.bank,
        bank_enterprise: filterValues.bank_enterprise,
        identification: filterValues.identification,
        beneficiary: filterValues.beneficiary,
        account_number: filterValues.account_number,
        created_by: filterValues.created_by,
        updated_by: filterValues.updated_by,
        type_user: filterValues.type_user,
        holder: filterValues.holder,
        country: filterValues.country,
        date_star: formattedFilter.date_star,
        date_end: formattedFilter.date_end,
      };

      this._filterCommunicationService.changeFilter(filters);
    }
  }

  formatDate(date: any, endOfDay: boolean): string | null {
    if (!date) return null;
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return endOfDay ? formattedDate + ' 23:59:59' : formattedDate + ' 00:00:00';
  }

  resetForm(): void {
    let clearFilter;
    this.cwFormFilter.reset({
      receipt: null,
      amount: null,
      id_agente:
        this.isMobile || this.profile?.id_rol === ROLES.AGENT
          ? this.profile?.idusuario
          : null,
      id_jugador: null,
      interbank_transfer: null,
      transaction_type: null,
      state: null,
      bank: null,
      bank_enterprise: null,
      identification: null,
      beneficiary: null,
      account_number: null,
      created_by: null,
      updated_by: null,
      type_user: null,
      holder: null,
      country: null,
      dateRange: this.initialRange,
      dateUpdate: null,
    });
    const dateRange = this.initialRange;

    // Formatear fechas si existen
    const formattedFilter = {
      date_start: dateRange && dateRange[0] ? moment(dateRange[0]).format('YYYY-MM-DD 00:00:00') : null,
      date_end: dateRange && dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DD 23:59:59') : null,
    };
    clearFilter = {
      receipt: null,
      amount: null,
      id_agente:
        this.isMobile || this.profile?.id_rol === ROLES.AGENT
          ? this.profile?.idusuario
          : null,
      id_jugador: null,
      interbank_transfer: null,
      transaction_type: null,
      state: null,
      bank: null,
      bank_enterprise: null,
      identification: null,
      beneficiary: null,
      account_number: null,
      created_by: null,
      updated_by: null,
      type_user: null,
      country: null,
      holder: null,
      date_star: formattedFilter.date_start,
      date_end: formattedFilter.date_end,
      dateUpdate: null,
    };

    this._filterCommunicationService.changeFilter(clearFilter);
  }

  getCountries() {
    this._countryService.getAllCountries().subscribe((res) => {
      const transformedData = res.data.map((item: { pais: number; id_pais: string; }) => {
        return {
          label: item.pais,
          value: item.id_pais
        }
      });
      this.optionsCountries$ = of(transformedData)
    });
  }

  getUserCreatedRequestCharge(): void {
    this.cwService.getUserCreatedRegister().subscribe((response) => {
      this.responseCreateUser = response.data.map(
        (item: { usuario: any; idusuario: any }) => {
          return { label: item.usuario, value: item.idusuario };
        }
      );

      this.createUser$ = of(this.responseCreateUser);
      this.updatedUser$ = of(this.responseCreateUser);
    });
  }
  advancedFilters() {
    this.showadvancedFilters = !this.showadvancedFilters;
  }
}
