export const environment = {
  production: true,
  test: false,
  API_URL: 'https://apicenter.sortiecuador.com/v1/api',
  API_FILES: 'https://apicenter.sortiecuador.com/src',
  JWT_SECRET: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55IjoiU09SVEkifQ.60hk7KL55XScPNIJcS7fRU_HCxJfYUbMzCfTH-SAB-U',
  API_PAYMENT: 'https://paymentsapi.zgameslatam.com/v1/api/monnet/create-withdrawal',
  API_GENERATE_KEY: 'https://paymentsapi.zgameslatam.com/v1/api/monnet/generate-key',
  API_URL_AFFILIATE_AGENT: 'https://apiafiliate.sortiecuador.com/v1/api',
  COMPANY: 'SORTI',
  SECRET_KEY: 'hqkmegv4mJDHoRfsCc5qh6hnH5oxtGQ1C6OLU5braA'
};
