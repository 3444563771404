import { Component } from '@angular/core';

@Component({
  selector: 'app-bonus-code',
  templateUrl: './bonus-code.component.html',
  styleUrl: './bonus-code.component.scss'
})
export class BonusCodeComponent {

}
