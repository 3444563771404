<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-xxl-12 col-xl-12 box-cSol-12">
      <div class="col-sm-12">
        <div class="row mt-3">
          <div class="col-12">
            <div class="mb-sm-0">
              <app-card-progress-request [data]="progress" [loading]="loading">
                <!-- <app-filter-cw></app-filter-cw> -->
              </app-card-progress-request>
            </div>
          </div>
        </div>
        <app-cw-table
          (totalPendingChange)="onTotalPendingChange($event)"
          (totalProcessedChange)="onTotalProcessedChange($event)"
          (totalChargeNotIncomeChange)="onTotalChargeNotIncomeChange($event)"
          (totalCorrectionChange)="onTotalCorrectionChange($event)"
          (totalBotProblemChange)="onTotalBotProblemChange($event)"
          (totalReverseChange)="onTotalReverseChange($event)"
          (totalCountChange)="onTotalChange($event)"
          (loading)="loading($event)"
        ></app-cw-table>
      </div>
    </div>
  </div>
</div>
