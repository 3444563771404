import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import moment from 'moment';
import { Observable, Subject, debounceTime, of } from 'rxjs';
import {
  agentAfiliateAccountant,
  CONFIG_BASE_DATE_RANGEPICKER,
  userActiveAndInactive,
} from '../../../../../../../core/constants/constants';
import { FilterCommunicationService } from '../../../../../../../core/services/filter-communication.service';
import { UserService } from '../../../../../../../core/services/user.service';
import { HolderAccountBankService } from '../../../../../../../core/services/holder-account-bank.service';
import { FinancialService } from '../../../../../../../core/services/financial.service';
import { ChargesWithdrawalsRequestService } from '../../../../../../../core/services/charges-withdrawals-request.service';
import { movementType } from '../../../../../../../shared/data/suscriptions-payments/constants';
import { StateService } from '../../../../../../../core/services/state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { selectAuth } from '../../../../../../../core/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { selectScreen } from '../../../../../../../core/state/screen/screen.selectors';
import { CountryService } from '../../../../../../../core/services/country.service';
import { ROLES } from '../../../../../../../shared/data/config/constants';
import { UserMainService } from '../../../../../../user/services/user-main.service';
import { USERS_TYPES } from '../../../../../../../shared/data/users/agents/constants';
import { BankOption } from '../../../../../../../core/interfaces/bank.interface';

@Component({
  selector: 'app-list-sp-table-filter',
  templateUrl: './list-sp-table-filter.component.html',
  styleUrl: './list-sp-table-filter.component.scss',
})
export class ListSpTableFilterComponent implements OnInit {
  public listSpFormFilter!: FormGroup;
  public optionsHolder$: any;
  public optionsAgents$: any;
  public optionsBanks$: any;
  public allBankData: BankOption[] = [];
  public currentPageBanks = 1;
  public optionsState$: any;
  public optionsCountries$: any;
  public optionsMovementType$: any;
  public createUser$: any;
  public id_country!: any;
  public currentPageAgents = 1;
  public currentPageCreatedBy = 1;
  public currentPageHolders = 1;
  public allAgentsData: any[] = [];
  public allHoldersData: any[] = [];
  public allCreatedBy: any[] = [];
  public pageSize = 10;
  public criteria: any;
  private filterSubject: Subject<string> = new Subject<string>();
  public showFilter: boolean = false;
  public selectedBankId: any;
  public transformedDataBank: any;
  public responseCreateUser: any;
  public id_agente: any;
  public isMobile: boolean = false;
  public token!: string;
  public selectedCountryId!: number | null;
  public notCountrySelect: boolean = true;
  public notBankSelect: boolean = true;
  public isAgent = true;
  public optionsAccountingAccount$!: Observable<string[]>;
  //LOGGEDSUSER
  public profile$ = this.store.select(selectAuth);
  public profile: any;

  //DATE
  public bsConfig = CONFIG_BASE_DATE_RANGEPICKER.bsConfig;
  public initialRange: Date[] = CONFIG_BASE_DATE_RANGEPICKER.initialRange;

  // REDUX
  public isMobile$ = this.store.select(selectScreen);
  public isMobileRedux!: boolean;
  public ROLES = ROLES;
  constructor(
    private fb: FormBuilder,
    private _userService: UserService,
    private _filterCommunicationService: FilterCommunicationService,
    private _holderAccountBank: HolderAccountBankService,
    private _userMainService: UserMainService,
    private cwService: ChargesWithdrawalsRequestService,
    private stateSevice: StateService,
    private router: Router,
    private activedRouter: ActivatedRoute,
    private store: Store,
    private _countryService: CountryService,
    private _financialService: FinancialService
  ) {
    this.initForm();
    this.filterSubject.pipe(debounceTime(400)).subscribe((term: any) => {
      this.allAgentsData = [];
      this.allCreatedBy = [];
      this.allHoldersData = [];
      this.allBankData = [];
      this.loadAgents(term);
      this.getHoldersData(term);
      this.getBankData(term);
    });
  }

  async ngOnInit(): Promise<void> {
    this.subscribeToMobileState();
    this.detectMobileMode();
    this.getAccountingAccountData();
    if (this.isMobile) {
      await this.getUserDataForMobile();
    } else {
      await this.getUserDataForDesktop();
    }

    this.getBankData();
    this.getHoldersData();
    this.getUserCreatedRequestCharge();
    this.getMovementType();
    this.getCountries();
    this.getStatesSp();
    this.sendFilter();
  }

  //MOBILE

  subscribeToMobileState(): void {
    this.isMobile$.subscribe((isMobile) => {
      this.isMobileRedux = isMobile;
    });
  }

  detectMobileMode(): void {
    const fullUrl = this.router.url;
    this.isMobile = fullUrl.includes('/mobile');
  }

  async getUserDataForMobile(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.activedRouter.params.subscribe((params) => {
        this.token = params['token'];
        this._userService.getUserByTokenApp(this.token).subscribe({
          next: (res) => {
            if (res.data.idusuario) {
              this._userService.getUser(res.data.idusuario).subscribe({
                next: (res) => {
                  this.profile = res.data;
                  this.id_agente = this.profile.idusuario;
                  this.listSpFormFilter
                    .get('id_agente')
                    ?.setValue(this.profile.idusuario);
                  resolve();
                },
                error: (err) => {
                  reject(err);
                },
              });
            } else {
              reject(new Error('No user id found'));
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    });
  }

  async getUserDataForDesktop(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.profile$.subscribe({
        next: (res) => {
          if (res.idusuario !== -1) {
            this.profile = res;
            if (this.profile.id_rol === ROLES.AGENT) {
              this.listSpFormFilter
                .get('id_agente')
                ?.setValue(this.profile?.idusuario);
            } else {
              this.loadAgents();
            }
            resolve();
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  //?LOAD DATA
  loadAgents(searchTerm?: string) {
    this._userMainService
      .getAllReferredAndAgents(
        this.currentPageAgents,
        this.pageSize,
        searchTerm,
        this.id_country,
        userActiveAndInactive,
        agentAfiliateAccountant
      )
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: any; idusuario: any }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.optionsAgents$ = of(transformedData);
        this.allAgentsData = [...this.allAgentsData, ...transformedData];
      });
  }
  getUserCreatedRequestCharge(): void {
    this.cwService.getUserCreatedRegister().subscribe((response) => {
      this.responseCreateUser = response.data.map(
        (item: { usuario: any; idusuario: any }) => {
          return { label: item.usuario, value: item.idusuario };
        }
      );

      this.createUser$ = of(this.responseCreateUser);
    });
  }
  getHoldersData(searchTerm?: string) {
    this._userService
      .getHolders(this.currentPageHolders, this.pageSize, searchTerm)
      .subscribe((response) => {
        const transformedData = response.data.map(
          (item: { usuario: string; idusuario: number }) => {
            return { label: item.usuario, value: item.idusuario };
          }
        );
        this.optionsHolder$ = of(transformedData);
        this.allHoldersData = [...this.allHoldersData, ...transformedData];
      });
  }

  getBankData(searchTerm?: string) {
    this._userMainService
      .getAllBanksBySelect(this.currentPageBanks, this.pageSize, searchTerm)
      .subscribe((response) => {
        this.transformedDataBank = response.data.map((item) => {
          return { label: item.bankName, value: item.bankId };
        });
        this.allBankData = [...this.allBankData, ...this.transformedDataBank];
      });
  }
  getMovementType() {
    this.optionsMovementType$ = of(movementType);
  }
  getStatesSp() {
    this.stateSevice.geatAllDataSp().subscribe((response) => {
      const transformedData = response.data.map(
        (item: { estado: number; n_estado: string }) => {
          return { label: item.estado, value: item.n_estado };
        }
      );
      this.optionsState$ = of(transformedData);
    });
  }
  getCountries() {
    this._countryService.getAllCountries().subscribe((res) => {
      const transformedData = res.data.map(
        (item: { pais: number; id_pais: string }) => {
          return {
            label: item.pais,
            value: item.id_pais,
          };
        }
      );
      this.optionsCountries$ = of(transformedData);
    });
  }

  //*NG-SELECT
  onScrollToEnd() {
    this.currentPageAgents++;
    this.loadAgents(this.criteria);
    this.currentPageHolders++;
    this.currentPageBanks++;
    this.getBankData(this.criteria);
    this.getHoldersData(this.criteria);
  }
  onSearch(term: string) {
    this.criteria = term;
    this.allAgentsData = [];
    this.allCreatedBy = [];
    this.allHoldersData = [];
    this.allBankData = [];
    this.currentPageAgents = 1;
    this.currentPageCreatedBy = 1;
    this.currentPageHolders = 1;
    this.currentPageBanks = 1;
    this.filterSubject.next(term);
  }
  onSearchBank() {
    this.selectedBankId = null;
  }

  onBankSelected(event: { value: number }) {
    this.selectedBankId = event.value;
  }

  //*TOGGLE
  toggleChart() {
    this.showFilter = !this.showFilter;
  }
  getShowFilterStyle(): { [key: string]: string } {
    return this.showFilter
      ? { overflow: 'visible', maxHeight: '600px' }
      : { overflow: 'hidden', maxHeight: '0' };
  }

  //*FORM
  initForm() {
    this.listSpFormFilter = this.fb.group({
      id_agente: [null],
      id_codigo: [null],
      amount: [null],
      receipt: [null],
      movement_type: [null],
      bank: [null],
      holder: [null],
      observation: [null],
      state: [null],
      created_by: [null],
      country: [null],
      dateRange: [this.initialRange],
      bank_date_range: [null],
    });
  }
  sendFilter(): void {
    const filterValues = this.listSpFormFilter?.value;
    const dateRange = filterValues?.dateRange;
    const bankDateRange = filterValues?.bank_date_range;

    const formattedFilter = {
      date_start:
        dateRange && dateRange[0]
          ? moment(dateRange[0]).format('YYYY-MM-DD 00:00:00')
          : null,
      date_end:
        dateRange && dateRange[1]
          ? moment(dateRange[1]).format('YYYY-MM-DD 23:59:59')
          : null,
      bank_date_start:
        bankDateRange && bankDateRange[0]
          ? moment(bankDateRange[0]).format('YYYY-MM-DD 00:00:00')
          : null,
      bank_date_end:
        bankDateRange && bankDateRange[1]
          ? moment(bankDateRange[1]).format('YYYY-MM-DD 23:59:59')
          : null,
    };

    let filters = {
      id_agente:
        this.isMobile || this.profile?.id_rol === ROLES.AGENT
          ? this.profile?.idusuario
          : filterValues.id_agente,
      id_codigo: filterValues.id_codigo,
      holder: filterValues.holder,
      datestart: formattedFilter.date_start,
      dateend: formattedFilter.date_end,
      bank_date_start: formattedFilter.bank_date_start,
      bank_date_end: formattedFilter.bank_date_end,
      receipt: filterValues.receipt?.trim(),
      amount: filterValues.amount,
      bank: filterValues.bank,
      state: filterValues.state,
      country: filterValues.country,
      movement_type: filterValues.movement_type,
      observation: filterValues.observation,
      created_by: filterValues.created_by,
      isAgent: this.isAgent,
    };

    this._filterCommunicationService.changeFilter(filters);
  }
  resetForm(): void {
    let clearFilter;

    this.listSpFormFilter.reset({
      amount: null,
      id_agente:
        this.isMobile || this.profile?.id_rol === ROLES.AGENT
          ? this.profile?.idusuario
          : null,
      holder: null,
      id_codigo: null,
      receipt: null,
      bank: null,
      observation: null,
      created_by: null,
      state: null,
      country: null,
      movement_type: null,
      dateRange: this.initialRange,
      bank_date_range: null,
    });

    clearFilter = {
      amount: null,
      id_agente:
        this.isMobile || this.profile?.id_rol === ROLES.AGENT
          ? this.profile?.idusuario
          : null,
      holder: null,
      receipt: null,
      bank: null,
      id_codigo: null,
      observation: null,
      created_by: null,
      state: null,
      country: null,
      movement_type: null,
      datestart: this.initialRange[0],
      dateend: this.initialRange[1],
      bank_date_start: null,
      bank_date_end: null,
    };
    this.isAgent = true;
    this._filterCommunicationService.changeFilter(clearFilter);
  }

  //*UTILS
  capitalizeName(name: string): string {
    return name
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  toggleSwitch() {
    const currentControlName = this.isAgent ? 'id_agente' : 'id_codigo';
    const oppositeControlName = this.isAgent ? 'id_codigo' : 'id_agente';

    this.listSpFormFilter.get(currentControlName)?.reset();
    this.listSpFormFilter.get(oppositeControlName)?.reset();
    this.listSpFormFilter.get(oppositeControlName)?.clearValidators();
    this.listSpFormFilter.get(currentControlName)?.clearValidators();
    this.listSpFormFilter.get(currentControlName)?.updateValueAndValidity();
    this.listSpFormFilter.get(oppositeControlName)?.updateValueAndValidity();

    this.isAgent = !this.isAgent;
  }

  getAccountingAccountData() {
    this._financialService
      .getAllAccountingAccountData()
      .subscribe((response) => {
        const transformedData = response.data.flatMap(
          (item: {
            subcuentas: any[];
            codigo_cuenta: string;
            nombre_cuenta: any;
            id_codigos: any;
          }) => {
            if (item.subcuentas && item.subcuentas.length > 0) {
              return item.subcuentas
                .filter((subcuenta: any) =>
                  subcuenta.codigo_cuenta.startsWith('6')
                )
                .map((subcuenta: any) => {
                  const label = `${subcuenta.codigo_cuenta} - ${subcuenta.nombre_cuenta}`;
                  return { label: label, value: subcuenta.id_codigos };
                });
            } else if (item.codigo_cuenta.startsWith('6')) {
              const label = `${item.codigo_cuenta} - ${item.nombre_cuenta}`;
              return { label: label, value: item.id_codigos };
            } else {
              return [];
            }
          }
        );
        this.optionsAccountingAccount$ = of(transformedData);
      });
  }
}
