import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'shared-tab-v1',
  templateUrl: './tab-v1.component.html',
  styleUrl: './tab-v1.component.scss',
})
export class TabV1Component implements OnChanges {
  @Input()
  public tabs: string[] = [];

  @Output()
  public tabChange: EventEmitter<string> = new EventEmitter<string>();

  public openTab: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['tabs']) {
      this.openTab = this.tabs[0] || '';
    }
  }

  onClickTab(tab: string) {
    if (tab === this.openTab) return;
    this.openTab = tab;
    this.tabChange.emit(this.openTab);
  }
}
